import StripePaymentHtml from './stripe.payment.html';

export function StripePaymentDirective () {
  const directive : ng.IDirective = {
    controller: 'StripePaymentCtrl',
    restrict  : 'E',
    scope     : {
      accountId  : '=',
      invoice    : '=',
      onError    : '&?',
      onSuccess  : '&?',
      publicKey  : '=',
      submitEvent: '@?'
    },
    templateUrl: StripePaymentHtml
  };

  return directive;
};
