import InspectionTemplateItemHtml from './inspection.template.item.html';

export function InspectionTemplateItemDirective () : ng.IDirective {
  const directive : ng.IDirective = {
    controller : 'InspectionTemplateItemCtrl',
    restrict   : 'E',
    templateUrl: InspectionTemplateItemHtml,
    scope      : {
      index    : '=',
      item     : '=',
      onRemove : '&',
      onReorder: '&',
      orderings: '=',
      types    : '='
    }
  };

  return directive;
};
