import { Injectable } from '@angular/core';

import { ChargifyServiceAjs } from './chargify.service.ajs';

@Injectable({
  providedIn: 'root'
})
export class ChargifyService {
  constructor (
    private chargifyService: ChargifyServiceAjs
  ) {}

  activate () : ng.IPromise<any> {
    return this.chargifyService.activate();
  }

  exportCancelations () : ng.IPromise<any> {
    return this.chargifyService.exportCancelations();
  }

  status ( status : string ) : boolean {
    return this.chargifyService.status(status);
  }
}