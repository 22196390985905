import { ApiModel }                       from '../api/models/api.model';
import { AttachmentModel }                from './models/attachment';
import { AttachmentOptionsModel }         from './models/attachment.options';
import { AttachmentThumbnailStatusModel } from './models/attachment.thumbnail-status';
import { SendAttachmentOptionsModel }     from './models/attachment.send-options';

import ImageViewerHtml from './attachment.image-viewer.html';
import VideoViewerHtml from './attachment.video-viewer.html';

interface XMLHttpRequestEventModel extends Event {
  target : XMLHttpRequest;
};

export class AttachmentsService {
  $q          : ng.IQService;
  $rootScope  : any;
  $translate  : ng.translate.ITranslateService;
  $uibModal   : ng.ui.bootstrap.IModalService;
  $window     : ng.IWindowService;
  api         : any;
  API         : ApiModel;
  confirmApi  : any;
  imageLoader : any;
  messengerApi: any;
  sessionApi  : any;

  constructor (
    $q           : ng.IQService,
    $rootScope   : any,
    $translate   : ng.translate.ITranslateService,
    $uibModal    : ng.ui.bootstrap.IModalService,
    $window      : ng.IWindowService,
    API          : ApiModel,
    confirm      : any,
    imageLoader  : any,
    messengerApi : any,
    sessionObjAPI: any,
    workshopAPI  : any
  ) {
    this.$q           = $q;
    this.$rootScope   = $rootScope;
    this.$translate   = $translate;
    this.$uibModal    = $uibModal;
    this.$window      = $window;
    this.API          = API;
    this.api          = workshopAPI;
    this.confirmApi   = confirm;
    this.imageLoader  = imageLoader;
    this.messengerApi = messengerApi;
    this.sessionApi   = sessionObjAPI;
  }

  getThumbnails ( thumbnails : AttachmentThumbnailStatusModel, attachments : Array<AttachmentModel> | AttachmentModel ) : ng.IPromise<any> {
    const deferred = this.$q.defer();

    attachments = angular.isArray(attachments) ? attachments : [ attachments ];
    attachments = attachments.filter(attachment => !this.isPdf(attachment));

    thumbnails.isLoading     = true;
    thumbnails.isSuccessful  = false;
    thumbnails.percentLoaded = 0;

    if (!attachments.length) {
      thumbnails.isLoading    = false;
      thumbnails.isSuccessful = true;

      return this.$q.when();
    }

    // the thumbnail url for video attachments
    // will be null, so we use a default image.
    angular.forEach(attachments, image => {
      image.thumbnail_url = this.getThumbnailIcon(image);
    });

    // Preload the attachments; then, update display when returned.
    this.imageLoader.preloadImages( attachments, 'thumbnail' )
    .then(( attachments : Array<AttachmentModel> ) => {
      // Loading was successful.
      thumbnails.isLoading    = false;
      thumbnails.isSuccessful = true;

      deferred.resolve(attachments);
    }, ( image : AttachmentModel ) => {
      // Loading failed on at least one image.
      thumbnails.isLoading    = false;
      thumbnails.isSuccessful = false;

      deferred.reject(image);
    },( event : any ) => {
      thumbnails.percentLoaded = event.percent;
    });

    return deferred.promise;
  }

  getThumbnailIcon ( attachment : AttachmentModel ) : string {
    if (this.isVideo(attachment)) {
      return '/assets/images/attachment.video.png';
    }
    if (this.isVehicleVisuals(attachment)) {
      const isCapricornUser = angular.isDefined(attachment.vehicle_visuals_capricorn_user)
        ? !!attachment.vehicle_visuals_capricorn_user
        : this.sessionApi.company().vehicle_visuals_capricorn_user;

      return `app/vehicle-visuals/img/${ isCapricornUser ? 'vehicle-visuals.capricorn': 'vehicle-visuals' }.svg`;
    }

    return attachment.thumbnail_url || attachment.attachment_url;
  }

  deleteAttachment ( attachment : AttachmentModel, options : AttachmentOptionsModel ) : ng.IPromise<boolean> {
    return this.confirmApi.generic(this.$translate.instant('JS_SPACE.CONFIRM.DELETE_ATTACHMENT'))
    .then(() => this.api.delete(options.routes.delete, attachment.id));
  }

  getVideoLength ( duration : number ) : string {
    if (!duration) {
      return '0';
    }

    const seconds = new BigDecimal(duration.toString());
    const sixty   = new BigDecimal('60');

    const minutes = seconds.divide(sixty);

    return minutes.setScale(0, BigDecimal.ROUND_UP).toString();
  }

  isImage ( attachment : AttachmentModel ) : boolean {
    return attachment.file_type === 'IMAGE';
  }

  isPdf ( attachment : AttachmentModel ) : boolean {
    return attachment.file_type === 'PDF';
  }

  isVehicleVisuals ( attachment : AttachmentModel ) : boolean {
    return attachment.file_type === 'vehicle visuals url';
  }

  isVideo ( attachment : AttachmentModel | File ) : boolean {
    const type = attachment instanceof File ? attachment.type : attachment.file_type;

    return type.toLowerCase().indexOf('video') >= 0;
  }

  isVideoValid ( video : File ) : boolean {
    const oneMillion         = new BigDecimal('1000000');
    const uploadLimitMb      = new BigDecimal(this.$rootScope.Company.company.upload_limit.toString());
    const videoByteSize      = new BigDecimal(video.size.toString());
    const uploadByteLimit    = uploadLimitMb.multiply(oneMillion);

    return videoByteSize.isLessThanOrEqualTo(uploadByteLimit);
  }

  sendVideo ( attachment : AttachmentModel, options : SendAttachmentOptionsModel ) : ng.IPromise<any> {
    const company  = this.sessionApi.company();

    if (options.method === 'sms') {
      return this.messengerApi.sms({
        attachment_type : 'video_attachment',
        attachment_table: options.table,
        attachment_id   : attachment.id,
        disclaimer      : 'Note: The link to access the video will be automatically appended to the end of this text when the message is sent.',
        message         : `View your video from ${ company.company_name }`,
        name            : 'Send Video Attachment',
        send_to         : options.contact ? options.contact.phone : ''
      });
    }

    return this.messengerApi.email({
      attachment_id   : attachment.id,
      attachment_table: options.table,
      attachment_type : 'video_attachment',
      body            : 'View video here: %AttachmentURL%',
      name            : 'Send Video Attachment',
      note            : '<span>Note: The link to access the video will be automatically inserted in place of the <strong>%AttachmentURL%</strong> tag</span>',
      email_address   : options.contact ? options.contact.email : '',
      subject         : `View your video from ${ company.company_name }`
    });
  }

  showAttachment ( attachment : AttachmentModel ) : void {
    if (this.isImage(attachment)) {
      this.$uibModal.open({
        controller : 'ImageViewerCtrl',
        templateUrl: ImageViewerHtml,
        windowClass: 'image-viewer',
        resolve    : {
          image: () => this.imageLoader.preloadImages([ attachment ]).then(images => images[0])
        }
      });
    }
    else if (this.isVideo(attachment)) {
      this.$uibModal.open({
        controller : 'VideoViewerCtrl',
        templateUrl: VideoViewerHtml,
        windowClass: 'video-modal',
        resolve    : {
          video: () => attachment
        }
      });
    }
    else {
      this.$window.open(attachment.attachment_url, '_blank');
    }
  }

  upload ( file : File, options : AttachmentOptionsModel ) : ng.IPromise<any> {
    const deferred = this.$q.defer();
    const fd       = new FormData();
    const self     = this;
    const xhr      = new XMLHttpRequest();

    if (self.isVideo(file) && !self.isVideoValid(file)) {
      return self.$q.reject(self.$translate.instant('COMPANY_SPACE.INTEGRATIONS.VIDEO_SETTINGS.VIDEO_IS_TOO_LARGE', {
        size: self.$rootScope.Company.company.upload_limit
      }));
    }

    return this.$q.when()
    .then(() => {
      const _deferred = this.$q.defer();

      fd.append(options.id_key, options.entity.id);
      fd.append('description', options.description);
      fd.append('file', file);

      if (self.isVideo(file)) {
        const video = document.createElement('video');

        video.preload = 'metadata';

        video.onloadedmetadata = function () {

          window.URL.revokeObjectURL(video.src);

          fd.append('minutes', self.getVideoLength(video.duration));

          _deferred.resolve();
        };

        video.src = URL.createObjectURL(file);
      }

      else {
        _deferred.resolve();
      }

      return _deferred.promise;
    })
    .then(() => {
      xhr.addEventListener('load', ( e : XMLHttpRequestEventModel ) => {
        // scope.uploading = false;

        // For some reason errors dont always go into the error callback, so check the status.
        if (e.target.status < 400) {
          // file = null;

          // scope.$broadcast('toggle');

          this.api.get(options.routes.get, options.entity.id)
          .then(( attachments : Array<AttachmentModel> ) => {
            deferred.resolve(attachments);
          });
        }
        else {
          const error = angular.fromJson(e.target.responseText);

          deferred.reject(( error && error.message ) ? error.message : error);
        }
      });

      xhr.addEventListener('error', ( e : XMLHttpRequestEventModel ) => {
        // scope.uploading = false;
        deferred.reject(e.target.responseText);
      });

      xhr.open('POST', `${ this.API.url }${ options.routes.create }`, true);
      xhr.withCredentials = true;

      xhr.send(fd);

      return deferred.promise;
    });
  };
}
