import { ApiServiceAjs }                 from '../api/api.service.ajs';
import { BindHtmlComponent }             from '../table/cell-components/bind-html.component';
import { Table }                         from '../table/types/table';
import { TableService }                  from '../table/table.service';
import { TableQueryParameters }          from '../table/types/table-query-parameters';
import { TransactionCenterBookingModel } from './models/transaction-center.booking';
import { TransactionCenterFiltersModel } from './models/transaction-center.filters';

export class TransactionCenterBookingsCtrl {
  bookings : Array<TransactionCenterBookingModel>;

  tableCacheKey = this.$state.current.data.tableCacheKey;

  filters : TransactionCenterFiltersModel = this.tableApi.setFiltersFromCache(this.tableCacheKey, {
    endDate  : this.dateParsing.weekRangeEnd(),
    range    : 'week',
    startDate: this.dateParsing.weekRangeStart(),
    value    : ''
  });

  tableSettings : Table = {
    broadcast: 'filterBookings',
    cache    : {
      enabled: true,
      key    : this.tableCacheKey
    },
    columns: [
      {
        field : 'event_date',
        header: {
          text: this.$translate.instant('JS_SPACE.COLUMNS.EVENT_DATE')
        },
        pipe: 'globalDate'
      },
      {
        field : 'customer_display_name',
        header: {
          text: this.$translate.instant('CUSTOMER_SPACE.CUSTOMER')
        }
      },
      {
        field : 'customer_mobile',
        header: {
          text: this.$translate.instant('GENERAL_SPACE.FIELD.MOBILE')
        },
        pipe: 'mobilePhone',
      },
      {
        field : 'vehicle_make',
        header: {
          text: this.$translate.instant('VEHICLE_SPACE.MAKE')
        }
      },
      {
        field : 'vehicle_model',
        header: {
          text: this.$translate.instant('VEHICLE_SPACE.MODEL')
        }
      },
      {
        field : 'description',
        header: {
          text: this.$translate.instant('GENERAL_SPACE.FIELD.DESCRIPTION')
        }
      },
      {
        classes: 'text-right',
        field  : 'estimated_hours',
        header : {
          classes: 'text-right',
          text   : this.$translate.instant('BOOKING_SPACE.ESTIMATED_HOURS')
        }
      },
      {
        classes  : 'text-left',
        component: {
          bindings: {
            inputs: {
              model: 'event_status',
              pipe : 'eventStatus'
            }
          },
          enabled: true,
          factory: BindHtmlComponent
        },
        header: {
          classes: 'text-left',
          text   : this.$translate.instant('GENERAL_SPACE.FIELD.STATUS')
        },
        sortable: false
      }
    ],
    empty: {
      text: this.$translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_BOOKINGS_FOUND')
    },
    pagination: {
      enabled   : true,
      pageSize  : this.tableApi.getCachedPaging(this.tableCacheKey).pageSize || 10,
      pageSizes : [ 10, 25, 50 ],
      queryCount: () => this.getCount()
    },
    rows: {
      highlight: {
        enabled: true
      },
      select: {
        enabled: true
      }
    },
    sorting: this.tableApi.setSortingFromCache(this.tableCacheKey, {
      direction: 'desc',
      field    : 'event_date'
    })
  };

  constructor (
    private $rootScope : ng.IRootScopeService,
    private $scope     : ng.IScope,
    private $state     : ng.ui.IStateService,
    private $translate : ng.translate.ITranslateService,
    private dateParsing: any,
    private tableApi   : TableService,
    private workshopAPI: ApiServiceAjs,
  ) {
    this.$scope.$watch(() => {
      return [
        this.filters.value,
        this.filters.endDate,
        this.filters.range,
        this.filters.startDate
      ];
    }, ( newVal, oldVal ) => {
      if ( newVal !== oldVal ) {
        this.$rootScope.$broadcast('filterBookings', this.filters);
      }
    }, true);

    this.$scope.$on('searchFilterChanged', ( event : ng.IAngularEvent, value : string ) => {
      if (!this.$state.transition) {
        this.filters.value = value;
      }
    });
  }

  getBookings ( params : TableQueryParameters ) : ng.IPromise<Array<TransactionCenterBookingModel>> {
    return this.workshopAPI.get('/transaction_center/bookings', [
      this.filters.startDate || this.dateParsing.weekRangeStart(),
      this.filters.endDate   || this.dateParsing.weekRangeEnd(),
      this.filters.value     || '*',
      params.field,
      params.direction,
      params.pageSize,
      params.offset
    ])
    .then( ( bookings : Array<TransactionCenterBookingModel> ) => {
      this.bookings = bookings;

      return bookings;
    });
  }

  getCount () : ng.IPromise<number> {
    return this.workshopAPI.get('/transaction_center/bookings_count', [
      this.filters.startDate || this.dateParsing.weekRangeStart(),
      this.filters.endDate   || this.dateParsing.weekRangeEnd(),
      this.filters.value     || '*'
    ])
    .then(( count : any ) => count.bookings_count);
  }

  isRange ( range : string ) : boolean {
    return range === this.filters.range;
  }

  setDateRange ( range : string ) : void {
    this.filters.range = range;

    switch ( this.filters.range ) {
      case 'month':
        this.filters.endDate   = this.dateParsing.thisMonthEnd();
        this.filters.startDate = this.dateParsing.thisMonthStart();
        break;
      case 'week':
        this.filters.endDate   = this.dateParsing.weekRangeEnd();
        this.filters.startDate = this.dateParsing.weekRangeStart();
        break;
      case 'today':
        this.filters.endDate   = this.dateParsing.today();
        this.filters.startDate = this.dateParsing.today();
        break;
      default:
        this.filters.endDate   = this.dateParsing.weekRangeEnd();
        this.filters.startDate = this.dateParsing.weekRangeStart();
        break;
    }
  }

  viewBooking ( booking : TransactionCenterBookingModel ) : void {
    this.$state.go('app.event', {
      customer_id: booking.customer_id,
      id         : booking.event_id,
      vehicle_id : booking.vehicle_id,
    });
  }
}