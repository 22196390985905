import { Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  styles: [ require('./action.component.scss') ],
  template: require('./link.component.html')
})
export class LinkComponent {
  @Input() model : string;
  @Input() record: any;

  @Output() onClick: EventEmitter<any> = new EventEmitter();

  handleClick ( $event : Event ) : void {
    this.onClick.emit(this.record);

    $event.stopPropagation();
  }
}