import 'angular';

import { AlertModalCtrl }   from './modal.alert.ajs';
import { CaptureModalCtrl } from './modal.capture.ajs';
import { ModalService }     from './modal.service.ajs';
import { NoteModalCtrl }    from './modal.note.ajs';
import { SyncModalCtrl }    from './modal.sync.ajs';

angular.module('app-lite.modals', [])
  .controller('AlertModalCtrl', AlertModalCtrl)
  .controller('CaptureModalCtrl', CaptureModalCtrl)
  .controller('NoteModalCtrl', NoteModalCtrl)
  .controller('SyncModalCtrl', SyncModalCtrl)
  .service('modalApi', ModalService);
