export class WindowServiceAjs {
  deferred : ng.IDeferred<boolean>;

  constructor (
    private $document: ng.IDocumentService,
    private $q       : ng.IQService,
    private $window  : ng.IWindowService
  ) {}

  _resolveVisibility () : void {
    if (this.$document[0].visibilityState === 'visible') {
      this.deferred.resolve(true);

      this.$document[0].removeEventListener('visibilitychange', this._resolveVisibility);

      this.deferred = null;
    }
  }

  open ( url : string, target = '_blank') : ng.IPromise<boolean> {
    this._resolveVisibility = this._resolveVisibility.bind(this);
    this.deferred           = this.$q.defer();

    this.$window.open(url, target);

    this.$document[0].addEventListener('visibilitychange', this._resolveVisibility);

    return this.deferred.promise;
  }
}