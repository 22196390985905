import { ApiServiceAjs }                       from '../api/api.service.ajs';
import { CodeTypeModel }                       from '../core/models/code-type';
import { LinkComponent }                       from '../table/cell-components/actions/link.component';
import { SessionServiceAjs }                   from '../sessions/session.service.ajs';
import { Table }                               from '../table/types/table';
import { TableQueryParameters }                from '../table/types/table-query-parameters';
import { TableService }                        from '../table/table.service';
import { TableSwitchControlComponent }         from '../table/cell-components/controls/switch-control/table-switch-control.component';
import { TransactionCenterFiltersModel }       from './models/transaction-center.filters';
import { TransactionCenterVendorInvoiceModel } from './models/transaction-center.vendor-invoice';

export class TransactionCenterVendorInvoicesCtrl implements ng.IOnInit {
  invoices      : Array<TransactionCenterVendorInvoiceModel>;
  statuses      : Array<CodeTypeModel>;
  tableSettings : Table;

  tableCacheKey = this.$state.current.data.tableCacheKey;

  filters : TransactionCenterFiltersModel = this.tableApi.setFiltersFromCache(this.tableCacheKey, {
    endDate  : '*',
    startDate: '*',
    status   : 'I',
    value    : ''
  });

  constructor (
    private $rootScope   : ng.IRootScopeService,
    private $scope       : ng.IScope,
    private $state       : ng.ui.IStateService,
    private $translate   : ng.translate.ITranslateService,
    private sessionObjAPI: SessionServiceAjs,
    private tableApi     : TableService,
    private workshopAPI  : ApiServiceAjs,
  ) {}

  $onInit() {
    const currencySettings = this.sessionObjAPI.currency();

    this.tableSettings = {
      broadcast: 'filterVendorInvoices',
      cache    : {
        enabled: true,
        key    : this.tableCacheKey
      },
      columns: [
        {
          field : 'post_date',
          header: {
            text: this.$translate.instant('GENERAL_SPACE.FIELD.POST_DATE')
          },
          pipe: 'globalDate'
        },
        {
          field : 'vendor_invoice_number',
          header: {
            text: this.$translate.instant('GENERAL_SPACE.FIELD.REFERENCE')
          }
        },
        {
          component: {
            bindings: {
              inputs: {
                model: 'vendor_company_name',
              },
              outputs: {
                onClick: event => this.viewVendor(event)
              }
            },
            enabled: true,
            factory: LinkComponent
          },
          field : 'vendor_company_name',
          header: {
            text: this.$translate.instant('VENDOR_SPACE.SUPPLIER')
          },
          sortable: true,
        },
        {
          field : 'invoice_type',
          header: {
            text: this.$translate.instant('GENERAL_SPACE.FIELD.INVOICE_TYPE')
          },
          pipe: 'invoiceType'
        },
        {
          field : 'vendor_invoice_payment_terms',
          header: {
            text: this.$translate.instant('GENERAL_SPACE.FIELD.PAYMENT_TERMS')
          }
        },
        {
          classes  : [ 'text-center' ],
          component: {
            bindings: {
              inputs: {
                disabled: true,
                model   : 'price_includes_tax'
              }
            },
            enabled: true,
            factory: TableSwitchControlComponent
          },
          field : 'price_includes_tax',
          header: {
            classes: 'text-center',
            text   : this.$translate.instant('GENERAL_SPACE.FIELD.PRICE_INCLUDES_TAX')
          },
          sortable: false,
        },
        {
          classes: 'text-right',
          field  : 'gst',
          header : {
            classes: 'text-right',
            text   : currencySettings.tax_name
          },
          pipe: 'globalCurrency'
        },
        {
          classes: 'text-right',
          field  : 'total',
          header : {
            classes: 'text-right',
            text   : this.$translate.instant('GENERAL_SPACE.FIELD.TOTAL')
          },
          pipe: 'globalCurrency'
        }
      ],
      empty: {
        text: this.$translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_INVOICES_FOUND')
      },
      pagination: {
        enabled   : true,
        pageSize  : this.tableApi.getCachedPaging(this.tableCacheKey).pageSize || 10,
        pageSizes : [ 10, 25, 50 ],
        queryCount: () => this.getCount()
      },
      rows: {
        highlight: {
          enabled: true
        }
      },
      sorting: this.tableApi.setSortingFromCache(this.tableCacheKey, {
        direction: 'desc',
        field    : 'post_date'
      })
    };

    this.$scope.$watch(() => {
      return [
        this.filters.startDate,
        this.filters.endDate,
        this.filters.status,
        this.filters.value
      ];
    }, ( newVal, oldVal ) => {
      if ( newVal !== oldVal ) {
        this.$rootScope.$broadcast('filterVendorInvoices', this.filters);
      }
    }, true);

    this.$scope.$on('searchFilterChanged', ( event : ng.IAngularEvent, value : string ) => {
      if (!this.$state.transition) {
        this.filters.value = value;
      }
    });
  }

  getInvoices ( params : TableQueryParameters ) : ng.IPromise<Array<TransactionCenterVendorInvoiceModel>> {
    return this.workshopAPI.get('/transaction_center/supplier_invoices', [
      this.filters.startDate || '*',
      this.filters.endDate   || '*',
      this.filters.status    || 'I',
      this.filters.value     || '*',
      params.field,
      params.direction,
      params.pageSize,
      params.offset
    ])
    .then( ( invoices : Array<TransactionCenterVendorInvoiceModel> ) => {
      this.invoices = invoices;

      return invoices;
    });
  }

  getCount () : ng.IPromise<number> {
    return this.workshopAPI.get('/transaction_center/supplier_invoices_count', [
      this.filters.startDate || '*',
      this.filters.endDate   || '*',
      this.filters.status    || 'I',
      this.filters.value     || '*',
    ])
    .then(( count : any ) => count.vendor_invoices_count);
  }

  viewInvoice ( invoice : TransactionCenterVendorInvoiceModel ) : void {
    this.$state.go('app.vendor-invoice', {
      id       : invoice.id,
      vendor_id: invoice.vendor_id
    });
  }

  viewVendor ( invoice : TransactionCenterVendorInvoiceModel ) : void {
    this.$state.go('app.vendor', {
      id: invoice.vendor_id
    });
  }
}
