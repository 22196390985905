import VendorInvoicesHtml from './transaction-center.vendor-invoices.html';

export const TransactionCenterVendorInvoicesComponent : ng.IComponentOptions = {
  bindings: {
    invoices: '=',
    statuses: '<'
  },
  controller  : 'TransactionCenterVendorInvoicesCtrl',
  controllerAs: '$ctrl',
  templateUrl : VendorInvoicesHtml,
};