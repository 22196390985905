import { Pipe, PipeTransform } from '@angular/core';

import { SessionService } from '../../core/sessions/session.service';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  constructor (
    private sessionService: SessionService
  ) {}

  transform ( number : string, isMobile ?: boolean) : string {
    const key         = isMobile ? 'mobile' : 'phone';
    const validations = this.sessionService.getSession().country_validations;

    // default to phone validations if mobile isn't present
    const validation  = validations[key].validation || validations.phone.validation;
    const mask        = validations[key].mask       || validations.phone.mask;
    const regexObj    = new RegExp(validation);

    if (!number) {
      return '';
    }

    else if (number.match(regexObj)) {
      return number.replace(regexObj, mask);
    }

    return number;
  }
}
