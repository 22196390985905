  /*
  * A wrapper so to speak of the standard ng-submit. Before the function
  * is called, a check is done to see if the form is valid. If not, the
  * submit is canceled and the flag is set.
  */
export function CoreValidSubmitDirective (
  $parse,
  $timeout,
  messages
) {
  return {
    require : 'form',
    restrict: 'A',
    link (scope, element, attrs, ctrl) {
      // Get the submit function
      var fn = $parse(attrs.validSubmit);

      element.bind('submit', evt => {
        $timeout(() => {
          if (ctrl.$pending) {
            return;
          }

          /*
          If the form is not valid, display
          the errors and cancel the submit
          */
          if (!ctrl.$valid) {
            scope.$apply(() => messages.clientError(ctrl));

            return;
          }

          scope.$apply(() => fn(scope, { $event: evt }));
        });
      });
    }
  };
}