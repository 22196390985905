import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid';

import { PublicComponent } from './public.component';

export const PublicStates : Array<NgHybridStateDeclaration> = [
  {
    component : PublicComponent,
    name      : 'ajs.app.public',
    redirectTo: 'login',
    url       : '/public'
  },
  {
    loadChildren: () => import('../login/login.module').then(module => module.LoginModule),
    name        : 'ajs.app.public.login.**',
    url         : '/login'
  },
  {
    loadChildren: () => import('../password/password.module').then(module => module.PasswordModule),
    name        : 'ajs.app.public.password.**',
    url         : '/password'
  },
  {
    loadChildren: () => import('../sign-up/sign-up.module').then(module => module.SignUpModule),
    name        : 'ajs.app.public.sign-up.**',
    url         : '/sign-up'
  }
];
