import * as angular from 'angular';

export function GridApiService (
  $q           : ng.IQService,
  $timeout     : ng.ITimeoutService,
  messages     : any,
  sessionObjAPI: any,
  workshopAPI  : any
) {
  class GridApi {
    $q            : ng.IQService       = $q;
    $timeout      : ng.ITimeoutService = $timeout;
    messages      : any                = messages;
    sessionObjAPI : any                = sessionObjAPI;
    workshopAPI   : any                = workshopAPI;

    pendingStream : any                = false;

    init (data : Array<any>, grid : any) : angular.IPromise<Array<any>> {
      this.initPreferences(grid);

      if (!data.length) {
        grid.pagingOptions.lastPageNumber = 1;
      }
      else if (data.length > grid.pagingOptions.pageSize) {
        data.pop();
      }
      else {
        grid.pagingOptions.lastPageNumber = grid.pagingOptions.currentPage;
      }

      return this.$q.when(data);
    }

    initPreferences ( grid : any ) : void {
      const preferences = angular.fromJson(localStorage.getItem(grid.storageKey));

      if (preferences) {
        /**
         * some preferences got set a long time ago and may have some
         * of the unwanted fields persisted. Reset them here.
         */
        if (preferences.paging && preferences.paging.lastPageNumber) {
          delete preferences.paging.lastPageNumber;
        }

        if (preferences.paging && preferences.paging.currentPage) {
          delete preferences.paging.currentPage;
        }

        grid.filterOptions = angular.equals({}, preferences.filtering) || !this.sessionObjAPI.user().cache_grid_filters ? grid.filterOptions : angular.extend(grid.filterOptions, preferences.filtering);
        grid.pagingOptions = angular.equals({}, preferences.paging)                                                ? grid.pagingOptions : angular.extend(grid.pagingOptions, preferences.paging);
        grid.sortInfo      = angular.equals({}, preferences.sorting)                                               ? grid.sortInfo      : angular.extend(grid.sortInfo, preferences.sorting);
      }
    }

    /**
     * for custom row templates, having interpolation in the class
     * attribute AND an ng-class no longer works. We still need
     * those column classes that were using interpolation so we
     * grab those whenever doing custom classes on row templates.
     * @param column ng-grid column object
     * @returns Array<string>
     */
    getColumnClasses ( column : any ) : Array<string> {
      return [ column.colIndex(), column.cellClass ];
    }

    getOffset ( options : any ) : number {
      const currentPage = new BigDecimal(options.currentPage.toString());
      const pageSize    = new BigDecimal(options.pageSize.toString());
      const offset      = pageSize.multiply(currentPage.subtract(BigDecimal.ONE));

      return offset.isNegative() ? 0 : offset.toString();
    }

    getPage ( url : string, params : any, grid : any, config : ng.IRequestConfig) : angular.IPromise<Array<any>> {
      return this.workshopAPI.get(url, params, null, config)
      .then(( data : Array<any> ) => {
        if (!data.length) {
          grid.pagingOptions.lastPageNumber = 1;
        }
        else if (data.length > grid.pagingOptions.pageSize) {
          grid.pagingOptions.lastPageNumber = undefined;
          data.pop();
        }
        else {
          grid.pagingOptions.lastPageNumber = grid.pagingOptions.currentPage;
        }

        return data;
      });
    }

    getPageCustom ( method : string, url : string, params : any, grid : any, config : ng.IRequestConfig ) : angular.IPromise<Array<any>> {
      return this.workshopAPI[method](url, params, null, config)
      .then(( data : Array<any> ) => {
        if (!data.length) {
          grid.pagingOptions.lastPageNumber = 1;
        }
        else if (data.length > grid.pagingOptions.pageSize) {
          grid.pagingOptions.lastPageNumber = undefined;
          data.pop();
        }
        else {
          grid.pagingOptions.lastPageNumber = grid.pagingOptions.currentPage;
        }

        return data;
      });
    }

    getPageSize ( options : any ) : string {
      const pageSize = new BigDecimal(options.pageSize.toString());

      return pageSize.add(BigDecimal.ONE).toString();
    }

    getPreferences ( key : string ) : any {
      const preferences = angular.fromJson(localStorage.getItem(key));

      if (!preferences) {
        return {
          filtering: {},
          paging   : {},
          sorting  : {
            directions: [],
            fields    : []
          }
        };
      }

      /**
       * some preferences got set a long time ago and may have some
       * of the unwanted fields persisted. Reset them here.
       */
      if (preferences.paging && preferences.paging.lastPageNumber) {
        delete preferences.paging.lastPageNumber;
      }

      if (preferences.paging && preferences.paging.currentPage) {
        delete preferences.paging.currentPage;
      }

      return preferences;
    }

    getStreamedPage ( url : string, params : any, grid : any, options : any = {} ) : angular.IPromise<Array<any>> {
      if (options.debounce && this.pendingStream) {
        this.$timeout.cancel(this.pendingStream);
      }

      if (options.debounce) {
        this.pendingStream = this.$timeout(() => {
          return this.stream(url, params, grid)
          .finally(() => {
            this.pendingStream = false;
          });
        }, 300);

        return this.pendingStream;
      }

      return this.stream(url, params, grid);
    }

    lastPage ( options : any ) : void {
      options.count()
      .then(( count : string ) => {
        options.currentPage    = Math.ceil(parseInt(count)/options.pageSize);
        options.lastPageNumber = options.currentPage;
      })
      .catch(err => {
        this.messages.error(err);
      });
    }

    resizeGrid ( grid : any ) : void {
      this.$timeout(() => {
        if (grid && grid.$gridServices) {
          grid.$gridServices.DomUtilityService.RebuildGrid(grid.$gridScope, grid.ngGrid);
        }
      });
    }

    resetCurrentPage ( grid : any ) : any {
      grid.pagingOptions.currentPage = 1;

      this.updatePreferences(grid);

      return grid;
    }

    stream ( url : string, params : any, grid : any ) : angular.IPromise<Array<any>> {
      return this.workshopAPI.stream(url, params)
      .then(( response : Array<any> ) => {
        if (!response.length) {
          grid.pagingOptions.lastPageNumber = 1;
        }
        else if (response.length > grid.pagingOptions.pageSize) {
          grid.pagingOptions.lastPageNumber = undefined;
          response.pop();
        }
        else {
          grid.pagingOptions.lastPageNumber = grid.pagingOptions.currentPage;
        }

        return response;
      });
    }

    stripObj ( s : string ) : string {
      const a = s
      .replace(/\[(\w+)\]/g, '.$1')
      .replace(/^\./, '')
      .split('.');

      return a[ a.length - 1 ];
    }

    updatePreferences ( grid : any ) : void {
      const paging = angular.copy(grid.pagingOptions);

      const preferences = {
        filtering: grid.filterOptions    || {},
        paging   : paging                || {},
        sorting  : grid.sortInfo         || {}
      };

      // some properties we don't want to
      // persist. We copy them above, then
      // reset them here.
      delete preferences.paging.currentPage;
      delete preferences.paging.lastPageNumber;

      localStorage.setItem(grid.storageKey, angular.toJson(preferences));
    }
  };

  return new GridApi();
};
