import { Component, Input } from '@angular/core';
import { NgbModal }         from '@ng-bootstrap/ng-bootstrap';

import { TableCountModel }      from '../table/models/table-count';
import { TableModel }           from '../table/models/table';
import { TableQueryModel }      from '../table/models/table-query';
import { VehicleCardComponent } from './vehicle.card.component';
import { VehicleComponent }     from './vehicle.component';
import { VehicleModel }         from './models/vehicle';
import { VehicleOptionsModel }  from './models/vehicle.options';
import { VehiclesModule }       from './vehicles.module';
import { VehiclesService }      from './vehicles.service';


@Component({
  selector   : 'wor-vehicles',
  template: require('./vehicles.component.html')
})
export class VehiclesComponent {
  @Input() vehicles: Array<VehicleModel>;

  constructor (
    private modalService   : NgbModal,
    private vehiclesService: VehiclesService
  ) {}

  tableSettings : TableModel = {
    classes: [ 'table--light' ],
    empty  : {
      message: 'No Vehicles'
    },
    pagination: this.vehiclesService.defaultPagination,
    rows      : {
      component: VehicleCardComponent,
      hover    : {
        enabled: true
      }
    },
    title: 'Vehicles'
  };

  getVehicleCount () : ng.IPromise<TableCountModel> {
    return this.vehiclesService.getVehicleCount();
  }

  getVehicles ( params : TableQueryModel ) : ng.IPromise<Array<VehicleModel>> {
    return this.vehiclesService.getVehicles(params.offset, params.pageSize);
  }

  viewVehicle ( vehicle : VehicleModel ) : void {
    this.vehiclesService.getVehicleOptions()
    .then(( options : VehicleOptionsModel ) => {
      try {
        const modal = this.modalService.open(VehicleComponent, {
          size: 'xl'
        });

        modal.componentInstance.options = options;
        modal.componentInstance.vehicle = vehicle || {};
      } catch (error) {
        console.error(error);
      }
    });
  }
}
