import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector   : 'list-box',
  template: require('./list-box.component.html')
})
export class ListBoxComponent {
  @Input() items       : Array<any> | any;
  @Input() labelField  : string;
  @Input() multiSelect : boolean;
  @Input() placeholder : string;
  @Input() title       : string;

  @Output() onSelect : EventEmitter<any> = new EventEmitter();

  handleSelect ( item : any ) : void {
    if ( !this.multiSelect ) {
      if ( Array.isArray(this.items) ) {
        for ( let i = 0; i < this.items.length; i++ ) {
          this.items[i].selected = false;
        }
      }
      else {
        for ( const key in this.items ) {
          this.items[key].selected = false;
        }
      }
    }

    item.selected = !item.selected;

    this.onSelect.emit(item);
  }

  hasItems () : boolean {
    if ( !this.items ) {
      return false;
    }

    return Array.isArray(this.items)
      ? !!this.items.length
      : !!Object.keys(this.items).length;
  }
}