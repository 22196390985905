import 'angular';

import AddCustomerFromBranchHtml        from './customer.add-from-branch.html';
import ChangeCustomerHtml               from './customer.change.html';
import CustomerBranchHistoryHtml        from './customer.branch-history.html';
import CustomerStatementSettingsHtml    from './customer.statement-settings.html';
import EmailCustomerStatementHtml       from './customer.email-statement.html';
import InvoicesForPaymentHtml           from '../invoices/invoices.for-payment.html';
import ResolveCustomerMatchesHtml       from './customer.resolve-matches.html';
import SendCustomerPortalInvitationHtml from './customer.send-portal-invitation.html';
import WindowFlipHtml                   from '../core/templates/window-flip.html';

export function CustomerService (
  $uibModal,
  $q,
  $rootScope,
  $translate,
  $window,
  API,
  confirm,
  messengerApi,
  workshopAPI
) {
  var Customer = function () {
    this.$uibModal  = $uibModal;
    this.$q         = $q;
    this.$rootScope = $rootScope;
    this.$translate = $translate;
    this.$window    = $window;

    this.confirm      = confirm;
    this.messengerApi = messengerApi;
    this.workshopAPI  = workshopAPI;

    this.API = API;
  };

  Customer.prototype.change = function () {
    const self = this;

    return self.$uibModal.open({
      controller : 'ChangeCustomerCtrl',
      keyboard   : false,
      templateUrl: ChangeCustomerHtml,
      resolve    : {
        customer: () => self.customer
      }
    }).result;
  };

  Customer.prototype.confirmPortalInvitation = function ( sendAsSms = false ) {
    const self = this;

    if ( sendAsSms && !self.customer.mobile ) {
      return self.$q.reject(self.$translate.instant('CUSTOMER_SPACE.CUSTOMER_PORTAL.SMS_NEEDED'));
    }

    return self.workshopAPI.post('/customers/send_customer_portal_invitation', {
      customer_id: self.customer.id,
      sms        : sendAsSms
    });
  };

  Customer.copyFromBranch = function (customer) {
    return workshopAPI.get('/customers/copy_from_branch', customer.vehicle_id ? [customer.id, customer.vehicle_id]: customer.id);
  };

  Customer.createCustomerFromOtherBranch = function (quickAdd) {
    return $uibModal.open({
      controller  : 'AddCustomerFromBranchCtrl',
      controllerAs: '$ctrl',
      keyboard    : false,
      templateUrl : AddCustomerFromBranchHtml,
      windowClass : 'x-lg-modal',
      resolve     : {
        customerQuickAdd: () => quickAdd
      }
    }).result;
  };

  Customer.prototype.delete = function () {
    const self = this;

    return this.confirm
      .generic(self.$translate.instant('JS_SPACE.CONFIRM.DELETE_CUSTOMER'))
      .then(() => self.workshopAPI.delete('/customer', self.customer.id));
  };

  Customer.prototype.email = function (email, body) {
    const self = this;

    return self.messengerApi.email({
      attachment_type: 4,
      body           : body,
      email_address  : email || self.customer.email,
      name           : self.customer.display_name
    });
  };

  Customer.prototype.sms = function ( customer = this.customer ) {
    const self = this;

    return self.messengerApi.sms({
      name   : customer.display_name,
      send_to: customer.mobile
    });
  };


  Customer.prototype.emailStatement = function () {
    const self = this;

    return self.$uibModal.open({
      controller       : 'EmailCustomerStatementCtrl',
      templateUrl      : EmailCustomerStatementHtml,
      windowClass      : 'flip-container',
      windowTemplateUrl: WindowFlipHtml,
      resolve          : {
        companyMessages: () => self.$rootScope.Company.getDefaultMessages(),
        customer       : () => self.customer
      }
    }).result;
  };

  Customer.prototype.findMatchingCustomers = function(customer) {
    const self = this;

    return self.workshopAPI.get('/branch_matching/find_matches_for_customer', customer.id);
  };

  Customer.prototype.getBranchCustomerInvoices = function() {
    var self = this;

    return workshopAPI
      .get('/customers/branch_invoice_count', [ self.customer.id, 'P', 'I'])
      .then(count => workshopAPI.get('/customers/branch_invoice_list', [
        self.customer.id,
        0,
        count,
        'P',
        'I',
        'post_date',
        'desc'
      ]));
  };

  Customer.getBranchCustomers = function (filters) {
    return workshopAPI.get('/customers/branch_find', [
      filters.search_type,
      filters.search_term,
      filters.offset,
      filters.pageSize,
      filters.field,
      filters.direction
    ],null,{ skipEncoding: true });
  };

  Customer.prototype.hasPaymentTerm = function () {
    const self = this;

    return self.customer.default_payment_terms
      && self.customer.default_payment_terms !== 'NONE';
  };

  Customer.prototype.isBiller = function () {
    const self = this;

    return self.customer.biller;
  };

  Customer.prototype.isBranchCustomer = function ( customer = this.customer ) {
    return customer.is_branch_customer;
  };

  Customer.prototype.isLinkedToHeadOffice = function ( customer = this.customer ) {
    return !!customer.branch_link;
  };

  Customer.prototype.isType = function ( type = 'company' ) {
    const self = this;

    switch (type) {
      case 'individual':
        return self.customer.customer_type === 'I';
      case 'company':
      default:
        return self.customer.customer_type === 'C';
    }
  };

  Customer.prototype.lookupBranchCustomerInvoices = function() {
    const self = this;

    return $uibModal.open({
      controller  : 'InvoicesForPaymentCtrl',
      controllerAs: '$ctrl',
      templateUrl : InvoicesForPaymentHtml,
      windowClass : 'x-lg-modal',
      resolve     : {
        customer: () => self.customer,
        invoices: () => self.getBranchCustomerInvoices()
      }
    }).result;
  };

  Customer.prototype.printStatement = function() {
    const self = this;

    return self.$uibModal.open({
      controller : 'CustomerStatementSettingsCtrl',
      templateUrl: CustomerStatementSettingsHtml,
      windowClass: 'md-modal'
    }).result.then(settings => {
      if (settings.statement) {
        self.$window.open(`${self.API.url}/reports/jasper_statements_306090/${self.customer.id}/${settings.as_at}.pdf`, '_blank');
      }
      else {
        self.$window.open(`${self.API.url}/reports/jasper_statements/${self.customer.id}/${settings.begin_date}/${settings.end_date}/${settings.include_paid}.pdf`, '_blank');
      }

      return;
    });
  };

  Customer.prototype.resolveMatches = function(customer, matches) {
    const self = this;

    return self.$uibModal.open({
      controller: 'ResolveCustomerMatchesCtrl',
      keyboard  : false,
      resolve   : {
        customer: () => customer,
        matches : () => matches
      },
      templateUrl: ResolveCustomerMatchesHtml,
      windowClass: 'x-lg-modal'
    })
    .result
    .catch(() => self.$q.when(customer));
  };

  Customer.prototype.save = function (settings = {}) {
    const self   = this;
    const method = self.customer.id ? 'patch' : 'post';
    const url    = self.customer.id ? '/customer' : '/customers';

    return self.workshopAPI[method](url, self.customer, 'customer', null, settings)
      .then(customer => {
        if (
          !self.customer.id
          && self.$rootScope.Company.isSharedAccounting()
          && self.$rootScope.Company.isAccountingSetup()
        ) {
          return self.findMatchingCustomers(customer)
            .then(matches => matches.length ?
                self.resolveMatches(customer, matches).then(() => customer) :
                customer
            );
        }

        return customer;
      });
  };

  Customer.prototype.saveMatch = function (match) {
    const self = this;

    return self.workshopAPI.post('/branch_matching/link_customer_ids', [
      { id: self.customer.id, accounting_id: match.accounting_id }
    ], 'links');
  };

  Customer.prototype.sendPortalInvitation = function () {
    const self = this;

    if (!self.customer.email) {
      return self.$q.reject(self.$translate.instant('CUSTOMER_SPACE.CUSTOMER_PORTAL.EMAIL_NEEDED'));
    }

    return self.$uibModal.open({
      controller: 'SendCustomerPortalInvitationCtrl',
      resolve   : {
        customer: () => self.customer
      },
      templateUrl: SendCustomerPortalInvitationHtml
    }).result;
  };

  Customer.prototype.validateCompanyName = function ( name ) {
    return workshopAPI.post('/customers/verify_company_name_uniqueness', {
      company_name: name
    })
    .then(result => result.company_name_unique);
  };

  Customer.prototype.validateFirstAndLastName = function ( name, field = 'first_name' ) {
    if ( field === 'first_name' && ( !name || !this.customer.last_name )) {
      return $q.when(true);
    }

    if ( field === 'last_name' && ( !name || !this.customer.first_name )) {
      return $q.when(true);
    }

    return workshopAPI.post('/customers/verify_name_uniqueness', {
      first_name: field == 'first_name' ? name : this.customer.first_name,
      last_name : field == 'last_name' ? name : this.customer.last_name,
    })
    .then(result => result.name_unique);
  };

  Customer.viewBranchHistory = function (customer) {
    return $uibModal.open({
      controller  : 'CustomerBranchHistoryCtrl',
      controllerAs: '$ctrl',
      templateUrl : CustomerBranchHistoryHtml,
      windowClass : 'x-lg-modal',
      resolve     : {
        customer: () => customer,
        invoices: () => workshopAPI.get('/customers/invoice_list', [
          customer.id,
          0,
          '*',
          '*',
          '*',
          'post_date',
          'desc'
        ])
      }
    }).result;
  };

  return Customer;
};
