import { Injector, NgModule } from '@angular/core';

import { SessionServiceAjs } from './session.service.ajs';

@NgModule({
  providers: [
    {
      deps      : [ '$injector' ],
      provide   : SessionServiceAjs,
      useFactory: ( $injector : Injector ) => $injector.get('sessionObjAPI')
    }
  ]
})
export class SessionsModule {}
