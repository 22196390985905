import { Inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'invoiceStatus',
})
export class InvoiceStatusPipe implements PipeTransform {

  constructor(
    @Inject('$translate') public $translate : ng.translate.ITranslateService
  ) {}

  transform( value : string ) {
    const typeMap = {
      C: this.$translate.instant('STATUS_TYPES.CLOSED'),
      O: this.$translate.instant('STATUS_TYPES.OPEN'),
      P: this.$translate.instant('STATUS_TYPES.PROCESSED')
    };

    return typeMap[value] || this.$translate.instant('STATUS_TYPES.OPEN');
  }
}