import { Component, Input } from '@angular/core';

import { VehicleModel } from './models/vehicle';

@Component({
  selector   : 'wor-vehicle-card',
  styles: [ require('./vehicle.card.component.scss') ],
  template: require('./vehicle.card.component.html')
})
export class VehicleCardComponent {
  @Input() record : VehicleModel
}
