import { DatePipe }            from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { SessionServiceAjs } from '../../sessions/session.service.ajs';

@Pipe({
  name: 'globalDate'
})
export class GlobalDatePipe implements PipeTransform {

  constructor(
    private datePipe         : DatePipe,
    private sessionServiceAjs: SessionServiceAjs
  ) {}

  transform( value : string, format : string ) : string {
    format = format || (this.sessionServiceAjs.countrySettings().date_format || 'mm/dd/yyyy').replace(/m/g, 'M');

    try {
      return this.datePipe.transform(value, format);
    } catch (error) {
      console.log('invalid date: ', value);

      return '';
    }
  }
}
