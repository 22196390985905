import 'core-js/proposals/reflect-metadata';
import 'zone.js';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { NgZone }                 from '@angular/core';
import { UIRouter, UrlService }   from '@uirouter/core';

import '../styles/scss/app-customer/shared.scss';

import './app.module.ajs';

import { AppModule } from './app.module';

platformBrowserDynamic().bootstrapModule(AppModule)
.then(platformRef => {
  // Initialize the Angular Module
  // get() the UIRouter instance from DI to initialize the router
  const urlService: UrlService = platformRef.injector.get(UIRouter).urlService;

  // Instruct UIRouter to listen to URL changes
  function startUIRouter() {
    urlService.listen();
    urlService.sync();
  }

  platformRef.injector.get<NgZone>(NgZone).run(startUIRouter);
});