import { Inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'months'
})
export class MonthsPipe implements PipeTransform {

  constructor(
    @Inject('$translate') public $translate : ng.translate.ITranslateService
  ) {}

  transform( month : number ) : string {
    const months: any = {
      1 : (`1 - ${ this.$translate.instant('GENERAL_SPACE.MONTHS.JANUARY') }`),
      2 : (`2 - ${ this.$translate.instant('GENERAL_SPACE.MONTHS.FEBRUARY') }`),
      3 : (`3 - ${ this.$translate.instant('GENERAL_SPACE.MONTHS.MARCH') }`),
      4 : (`4 - ${ this.$translate.instant('GENERAL_SPACE.MONTHS.APRIL') }`),
      5 : (`5 - ${ this.$translate.instant('GENERAL_SPACE.MONTHS.MAY') }`),
      6 : (`6 - ${ this.$translate.instant('GENERAL_SPACE.MONTHS.JUNE') }`),
      7 : (`7 - ${ this.$translate.instant('GENERAL_SPACE.MONTHS.JULY') }`),
      8 : (`8 - ${ this.$translate.instant('GENERAL_SPACE.MONTHS.AUGUST') }`),
      9 : (`9 - ${ this.$translate.instant('GENERAL_SPACE.MONTHS.SEPTEMBER') }`),
      10: (`10 - ${ this.$translate.instant('GENERAL_SPACE.MONTHS.OCTOBER') }`),
      11: (`11 - ${ this.$translate.instant('GENERAL_SPACE.MONTHS.NOVEMBER') }`),
      12: (`12 - ${ this.$translate.instant('GENERAL_SPACE.MONTHS.DECEMBER') }`)
    };

    return months[month];
  }
}

