import { NgModule } from '@angular/core';

import { apiServiceProvider } from './api.provider.ajs';
import { ApiService }         from './api.service';

@NgModule({
  providers: [
    // AJS upgraded service
    apiServiceProvider,

    ApiService
  ]
})
export class ApiModule {}
