import { FlipPayActivationSettingsModel } from './models/flippay.activation-settings';
import { FlipPayService }                 from './flippay.service.ajs';

export class ActivateFlipPayCtrl {
  $scope           : ng.IScope;
  $translate       : ng.translate.ITranslateService;
  $uibModalInstance: ng.ui.bootstrap.IModalInstanceService;
  flipPayApi       : FlipPayService;
  flipped          = false;
  messages         : any;
  settings         : FlipPayActivationSettingsModel = {
    notification_email: ''
  };

  constructor (
    $scope           : ng.IScope,
    $translate       : ng.translate.ITranslateService,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    flipPayApi       : FlipPayService,
    messages         : any
  ) {
    this.$uibModalInstance = $uibModalInstance;
    this.$scope            = $scope;
    this.$translate        = $translate;
    this.flipPayApi        = flipPayApi;
    this.messages          = messages;
  }

  activate () : void {
    this.flipPayApi.saveActivationSettings(this.settings)
    .then(() => this.$uibModalInstance.close(this.settings))
    .catch(( err : any ) => {
      this.messages.error(err);
    });
  }

  getTitle () : string {
    return this.settings.hasMerchantId
      ? this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.WORKSHOPPAY.CONNECT')
      : this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.WORKSHOPPAY.CREATE');
  }

  proceed ( hasMerchantId : boolean ) : void {
    this.settings.hasMerchantId = hasMerchantId;

    this.flipped = true;

    this.$scope.$emit('flipModal');
  }
}
