import '../../vendor/imagezoom/imagezoom.min';
import '../../vendor/imagezoom/imagezoom.css';

interface ImageZoomScope extends ng.IScope {
  height: number;
  width : number;
}

export function ImageZoomDirective () : ng.IDirective {
  const directive : ng.IDirective = {
    restrict: 'A',
    scope   : {},
    link    (
      scope   : ImageZoomScope,
      element : any
    ) {
      element.on('load', ( evt : any ) => {
        scope.height = evt.target.height;
        scope.width  = evt.target.width;

        element.css({
          'min-height': scope.height,
          'min-width' : scope.width
        });

        element.ImageZoom();

        if (!scope.$$phase) {
          scope.$apply();
        }
      });
    }
  };

  return directive;
};
