import 'angular';

import '../api/api.module';
import '../core/core.module.ajs';
import '../messages/messages.module.ajs';

import { TemplatedWordingListLiteCtrl } from './templates.templated-wording-list';
import { TemplatedWordingService }      from './templated-wording.service';

angular.module('app-lite.templates', [
  'app-lite.api',
  'app-lite.core',
  'app-lite.messages'
]).controller('TemplatedWordingListLiteCtrl', TemplatedWordingListLiteCtrl)
  .factory('templatedWording', TemplatedWordingService);
