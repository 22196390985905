import 'angular';

import { BlockApiModel } from '../models/block.api';

export function CoreBlockService (
  blockUI : angular.blockUI.BlockUIService
) {
  class Block implements BlockApiModel {
    blockUI : angular.blockUI.BlockUIService = blockUI;

    unblock ( instance ?: string ) : void {
      if (instance) {
        this.blockUI.instances.get(instance).stop();

        return;
      }

      angular.forEach(this.blockUI.instances, instance => {
        this.blockUI.instances.get(instance._id).stop();
      });
    }
  }

  return new Block();
}