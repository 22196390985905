import { Component, Input } from '@angular/core';
import { NgbActiveModal }   from '@ng-bootstrap/ng-bootstrap';

import { ApiErrorModel }          from '../core/api/models/api.error';
import { CustomerModel }          from '../../app-lite/customers/models/customer';
import { CustomerPasswordsModel } from './models/customer.passwords';
import { CustomerService }        from './customer.service';
import { ErrorHandlingService }   from '../core/errors/error-handling.service';
import { NotificationService }    from '../notifications/notification.service';

@Component({
  template: require('./customer.change-password.component.html')
})
export class ChangePasswordComponent {
  passwords: CustomerPasswordsModel = {};

  @Input() customer: CustomerModel;

  constructor (
    public activeModal          : NgbActiveModal,
    private customerService     : CustomerService,
    private errorHandlingService: ErrorHandlingService,
    private notificationService : NotificationService
  ) {}

  confirm () {
    this.customerService.changePassword(this.passwords)
    .then(() => {
      this.notificationService.show('Password Changed');

      this.activeModal.close();
    })
    .catch(( err : ApiErrorModel ) => {
      this.errorHandlingService.show(err);
    });
  }
};
