import SyncMyobHtml from './myob.sync.html';

export function MyobService (
  $q,
  $uibModal,
  workshopAPI
) {
  const Myob = function () {};

  Myob.prototype.checkExistingIntegrations = function () {
    return workshopAPI.get('/company/accounting_ids_exist/myob');
  };

  Myob.prototype.clearID = function () {
    return workshopAPI.post('/company/clear_accounting_contact_ids', { accounting_integration: 'myob', confirmation: 'CLEAR IDS' })
  };

  Myob.prototype.getOptions = function () {
    return $q.all({
      accounts: workshopAPI.get('/myob/accounts')
    });
  };

  Myob.prototype.shareAccount = function (shareAccountFlag) {
    return workshopAPI.get('/company/share_accounting', [ shareAccountFlag ? 't' : 'f' ]);
  };

  Myob.prototype.sync = function () {
    return $uibModal.open({
      controller : 'SyncMyobCtrl',
      templateUrl: SyncMyobHtml
    }).result;
  };

  return Myob;
}