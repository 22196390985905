import { Injectable } from '@angular/core';

import { ApiService }       from '../core/api/api.service';
import { SessionModel }     from '../core/sessions/models/session';
import { SessionService }   from '../core/sessions/session.service';
import { UserAccountModel } from '../users/models/user.account';

@Injectable()
export class SignUpService {
  constructor (
    private apiService    : ApiService,
    private sessionService: SessionService
  ) {}

  signUp ( account : UserAccountModel ) : ng.IPromise<SessionModel> {
    return this.apiService.post('/customer_portal_users/create_password', {
      password: account.password,
      token   : account.token
    })
    .then(( session : SessionModel ) => this.sessionService.setSession(session));
  }
}
