import { EventEmitter, Output } from '@angular/core';

import { TableService } from '../../table.service';

export class TableControlComponent {
  declare index : number;
  declare model : string;
  declare name  : string;
  declare record: any;

  _disabled: boolean;
  _required: boolean;

  get serializedName () : string {
    return `${this.name}-${this.index}`;
  }

  set disabled ( _disabled : boolean | Function ) {
    this._disabled = _disabled instanceof Function
      ? _disabled(this.record)
      : _disabled;
  }

  set required ( _required : boolean | Function ) {
    this._required = _required instanceof Function
      ? _required(this.record)
      : _required;
  }

  @Output() blur       : EventEmitter<Event> = new EventEmitter();
  @Output() focus      : EventEmitter<Event> = new EventEmitter();
  @Output() modelChange: EventEmitter<any>   = new EventEmitter();
  @Output() onChange   : EventEmitter<any>   = new EventEmitter();

  constructor (
    private service: TableService
  ) {}

  handleChange ( event : Event ) : void {
    this.onChange.emit(this.record);
  }

  handleModelChange ( event : Event ) : void {
    this.modelChange.emit(event);

    this.service.changes.next();
  }
}