import '../../../vendor/chosen/chosen.min';

export function CoreChosenDirective () {
  return {
    require: 'ngModel',
    restrict: 'A',
    scope: {
      data: '=',
      options: '='
    },
    link: function (scope, element, attrs, ctrl) {
      var options = {
        width: '100%',
        disable_search_threshold: 10,
        inherit_select_classes: true,
        placeholder_text_single: ' '
      };

      scope.$watch('data.length', function () {
        element.trigger('chosen:updated');
      });

      scope.$watch(function () {
        return ctrl.$modelValue
      }, function (newVal, oldVal) {
        element.trigger('chosen:updated');
      }, true);

      scope.$watch(function () {
        return ctrl.$dirty
      }, function (dirty) {
        if (dirty && ctrl.$invalid) {
          element.parent().find('.chosen-container').addClass('invalid');
        }
        else {
          element.parent().find('.chosen-container').removeClass('invalid');
        }
      }, true);

      scope.$watch(function() { return ctrl.$valid }, function(valid){
        if (!valid && ctrl.$dirty){
          element.parent().find('.chosen-container').addClass('invalid');
        }
        else {
          element.parent().find('.chosen-container').removeClass('invalid');
        }
      }, true);

      attrs.$observe('disabled', function() {
        return element.trigger('chosen:updated');
      });

      options = angular.extend(options, scope.options || {});

      element.chosen(options);
    }
  }
}