import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { NgModel }  from '@angular/forms';
import { UIRouter } from '@uirouter/angularjs';

import { EmailVerificationService } from './email-verification.service';
import { MessagesServiceAjs }       from '../messages/messages.service.ajs';

@Component({
  selector   : 'wor-email-verification',
  template: require('./email-verification.component.html')
})
export class EmailVerificationComponent implements OnInit {
  @Input() label : string;
  @Input() model : string;
  @Input() name  : string;

  @Output() modelChange: EventEmitter<boolean> = new EventEmitter();

  originalEmail : string;

  constructor (
    @Inject('$translate')
    public  $translate               : ng.translate.ITranslateService,
    private emailVerificationService : EmailVerificationService,
    private messagesService          : MessagesServiceAjs,
    private router                   : UIRouter
  ) {}

  ngOnInit(): void {
    this.originalEmail = this.model;
  }

  didVerificationFail () : boolean {
    return !this.hasEmailChanged()
      && this.emailVerificationService.didVerificationFail();
  }

  getEmailLabel ( email : NgModel ) : string {
    let status = '';

    if ( email.dirty && email.invalid ) {
      return this.$translate.instant('GENERAL_SPACE.ERROR_MESSAGE.INVALID_EMAIL');
    }

    if ( this.isEmailPending() ) {
      status = this.$translate.instant('JS_SPACE.EMAIL.PENDING');
    }
    else if ( this.isEmailVerified() ) {
      status = this.$translate.instant('JS_SPACE.EMAIL.VERIFIED');
    }
    else if ( this.didVerificationFail() ) {
      status = this.$translate.instant('JS_SPACE.EMAIL.VERIFICATION_FAILED');
    }
    else {
      status = this.$translate.instant('JS_SPACE.EMAIL.UNVERIFIED');
    }

    return `${ this.label || this.$translate.instant('GENERAL_SPACE.FIELD.EMAIL') } (${ status })`;
  }

  hasEmailChanged () : boolean {
    return this.model !== this.originalEmail;
  }

  isEmailPending () : boolean {
    return !this.hasEmailChanged()
      && this.emailVerificationService.isEmailPending();
  }

  isEmailUnverified () : boolean {
    return this.hasEmailChanged()
      || this.emailVerificationService.isEmailUnverified();
  }

  isEmailVerified () : boolean {
    return !this.hasEmailChanged()
      && this.emailVerificationService.isEmailVerified();
  }

  verifyEmail () : void {
    this.emailVerificationService.verify()
    .then(() => {
      this.messagesService.show(this.$translate.instant('JS_SPACE.EMAIL.VERIFICATION_SENT_MESSAGE'), 'success');

      this.router.stateService.reload();
    })
    .catch(( err : any ) => {
      this.messagesService.error(err);
    });
  }
}