import { Inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customerType'
})
export class CustomerTypePipe implements PipeTransform {
  constructor(
    @Inject('$translate') public $translate : ng.translate.ITranslateService
  ) {}

  transform( value : string, html : boolean ) : string {
    switch (value) {
      case 'C':
        return html
          ? '<div class="status bg-success"><span>Cash</span></div>'
          : this.$translate.instant('CUSTOMER_TYPES.CASH');
      case 'A':
        return html
          ? '<div class="status bg-box"><span>Account</span></div>'
          : this.$translate.instant('CUSTOMER_TYPES.ACCOUNT');
    }
  }
}