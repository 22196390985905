import { CustomerModel } from '../customers/models/customer';
import { InvoiceModel }  from './models/invoice';
import { Table }         from '../table/types/table';
import { TableColumn }   from '../table/types/table-column';
import { TableService }  from '../table/table.service';

export class InvoicesForPaymentCtrl implements ng.IOnInit {
  areAllSelected = false;
  columns        : Array<TableColumn>;
  customerApi    : any;
  selectedItems  : Array<InvoiceModel> = [];
  tableCacheKey  = 'branchCustomerInvoices';
  tableSettings  : Table;

  constructor (
    private $translate   : ng.translate.ITranslateService,
    private customer     : CustomerModel,
    Customer             : any,
    public invoices      : Array<InvoiceModel>,
    private tableApi     : TableService
  ) {
    this.customerApi = new Customer(this.customer);
  }

  $onInit () : void {
    this.columns = [
      {
        classes: 'text-left',
        field  : 'invoice_number',
        header : {
          text: this.$translate.instant('GENERAL_SPACE.FIELD.REFERENCE')
        }
      },
      {
        classes: 'text-left',
        field  : 'post_date',
        header : {
          text: this.$translate.instant('GENERAL_SPACE.FIELD.POST_DATE')
        },
        pipe: 'globalDate'
      },
      {
        classes: 'text-right',
        field  : 'total',
        header : {
          classes: 'text-right',
          text   : this.$translate.instant('JS_SPACE.COLUMNS.AMOUNT')
        },
        pipe: 'globalCurrency'
      },
      {
        classes: 'text-right',
        field  : 'balance_due',
        header : {
          classes: 'text-right',
          text   : this.$translate.instant('JS_SPACE.COLUMNS.BALANCE')
        },
        pipe: 'globalCurrency'
      }
    ];

    if (this.customerApi.isBranchCustomer()) {
      this.columns.splice(1, 0, {
        field : 'store_name',
        header: {
          text: this.$translate.instant('GENERAL_SPACE.FIELD.STORE')
        }
      });
    }

    this.tableSettings = {
      cache: {
        enabled: true,
        key    : this.tableCacheKey
      },
      columns: this.columns,
      empty  : {
        text: this.$translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_INVOICES_FOUND')
      },
      pagination: {
        enabled: false,
      },
      rows: {
        select: {
          enabled: true,
          multi  : true
        }
      },
      sorting: this.tableApi.setSortingFromCache(this.tableCacheKey, {
        direction: 'desc',
        field    : 'post_date'
      }),
      static: true
    };
  }

  handleInvoiceSelection ( invoice : InvoiceModel ) : void {
    if (invoice.selected) {
      this.selectedItems.push(invoice);
    }
    else {
      this.selectedItems = this.selectedItems.filter(item => item.id !== invoice.id);
    }

    this.areAllSelected = this.invoices.length === this.selectedItems.length;
  }

  toggleAll () : void {
    this.invoices.forEach(invoice => {
      invoice.selected = !this.areAllSelected;
    });

    this.areAllSelected = !this.areAllSelected;
    this.selectedItems  = this.areAllSelected
      ? this.invoices
      : [];
  }
}
