export function ImportVehicleHistoryHeadersLiteCtrl (
  $controller,
  $scope,
  messages,
  workshopAPI
) {
  $controller('import', { $scope: $scope });

  $scope.controllerName = 'ImportVehicleHistoryHeadersLiteCtrl';
  $scope.fileUploadID   = 'vehicleHistH_csv';
  $scope.importUrl      = '/imports/perform_historyh_import';

  // Everytime the file changes
  $('#'+$scope.fileUploadID).change(function(e) {
    // Make sure the file isn't blank
    if ($(e.target).val()) {
      // Set the flag so that buttons and such will activate
        $scope.fileSelected = true;
        // Set the value of the fake input to the filename w/out the fakepath
        // crap
        $('[ng-controller="ImportVehicleHistoryHeadersLiteCtrl"] .newfileholder').val($(e.target).val().replace('C:\\fakepath\\', ''));
    }
    else {
      // If the file has been deleted then set the flag to diable the needed
      // buttons and such
        $scope.fileSelected = false;
    }

    if (!$scope.$$phase) {
      $scope.$apply();
    }
  });

  // Get the columns specific to vehicles to populate the dropdown as well as the sample data and requirements
  workshopAPI.get('/imports/our_column_details', 'vehicle_histories').then(function (response) {
    $scope.ourColumns = response;
  }).catch(function (err) {
    messages.error(err);
  });

  // Get the transform functions specific to vehicles with all the parameters and equations
  workshopAPI.get('/imports/available_actions', 'vehicle_histories').then(function (response) {
    $scope.transformFunctions = response;
  }).catch(function (err) {
    messages.error(err);
  });
};