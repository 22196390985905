export function SaveConfirmationLiteCtrl ($scope, message, type) {
  $scope.message  = message;
  $scope.type     = type;
  $scope.remember = false;

  $scope.no = function () {
    $scope.$close({
      remember: $scope.remember,
      answer: false
    });
  };

  $scope.yes = function () {
    $scope.$close({
      remember: $scope.remember,
      answer: true
    });
  };
}