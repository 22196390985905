import 'angular';

import '../api/api.module';
import '../core/core.module.ajs';

import { AssemblyPaymentsTransactionCtrl } from './assembly-payments.transaction.ajs';

angular.module('app-lite.assembly-payments', [
  'app-lite.api',
  'app-lite.core'
])
.controller('AssemblyPaymentsTransactionCtrl', AssemblyPaymentsTransactionCtrl);
