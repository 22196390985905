
import { AttachmentModel }                from './models/attachment';
import { AttachmentOptionsModel }         from './models/attachment.options';
import { AttachmentsService }             from './attachments.service.ajs';
import { AttachmentThumbnailStatusModel } from './models/attachment.thumbnail-status';

import AttachmentsBoxHtml from './attachments.box.html';

interface XMLHttpRequestEventModel extends Event {
  target : XMLHttpRequest;
};

interface AttachmentsBoxScope extends ng.IScope {
  attachments     : Array<AttachmentModel>;
  delete          : ( attachment : AttachmentModel ) => void;
  emailVideo      : ( attachment : AttachmentModel ) => void;
  file            : File;
  isPdf           : ( attachment : AttachmentModel ) => boolean;
  isVehicleVisuals: ( attachment : AttachmentModel ) => boolean;
  isVideo         : ( attachment : AttachmentModel ) => boolean;
  options         : AttachmentOptionsModel;
  showAttachment  : ( attachment : AttachmentModel ) => void;
  smsVideo        : ( attachment : AttachmentModel ) => void;
  thumbnails      : AttachmentThumbnailStatusModel;
  upload          : () => void;
  uploading       : boolean;
};


export function AttachmentsBoxDirective (
  $state        : ng.ui.IStateService,
  $translate    : ng.translate.ITranslateService,
  attachmentsApi: AttachmentsService,
  messages      : any
) {
  return {
    restrict: 'E',
    scope   : {
      attachments: '=',
      options    : '='
    },
    templateUrl: AttachmentsBoxHtml,
    link ( scope   : AttachmentsBoxScope ) {
      scope.thumbnails = {
        isLoading    : true,
        isSuccessful : false,
        percentLoaded: 0
      };

      scope.delete = function( attachment : AttachmentModel ) {
        attachmentsApi.deleteAttachment(attachment, scope.options)
        .then(() => {
          $state.reload();

          messages.show($translate.instant('JS_SPACE.MESSAGES.DELETED.DELETED'), 'success');
        })
        .catch(err => {
          messages.error(err);
        });
      };

      scope.emailVideo = function ( attachment : AttachmentModel ) {
        attachmentsApi.sendVideo(attachment, {
          contact: scope.options.contact,
          method : 'email',
          table  : scope.options.table
        })
        .then(() => {
          messages.show($translate.instant('JS_SPACE.MESSAGES.EMAIL_SENT'), 'success');
        });
      };

      scope.isPdf = function ( attachment : AttachmentModel ) {
        return attachmentsApi.isPdf(attachment);
      };

      scope.isVehicleVisuals = function ( attachment : AttachmentModel ) {
        return attachmentsApi.isVehicleVisuals(attachment);
      };

      scope.isVideo = function ( attachment : AttachmentModel ) {
        return attachmentsApi.isVideo(attachment);
      };

      scope.showAttachment = function ( attachment : AttachmentModel ) {
        attachmentsApi.showAttachment(attachment);
      };

      scope.smsVideo = function ( attachment : AttachmentModel ) {
        attachmentsApi.sendVideo(attachment, {
          contact: scope.options.contact,
          method : 'sms',
          table  : scope.options.table
        })
        .then(() => {
          messages.show($translate.instant('GENERAL_SPACE.MESSAGE.SMS_SENT'), 'success');
        });
      };

      scope.upload = function () {
        scope.uploading = true;

        attachmentsApi.upload(scope.file, scope.options)
        .then(( attachments : Array<AttachmentModel> ) => {
          scope.file = null;

          scope.$broadcast('hide');

          /**
           * Set the attachments varable to the new list from the server.
           * Must be done this way so that the array doesn't lose it's reference
           * because the scope variable is technically initialized in the parent
           * scope.
           */
          scope.attachments.length = 0;
          Array.prototype.push.apply(scope.attachments, attachments);

          return attachmentsApi.getThumbnails(scope.thumbnails, attachments);
        })
        .catch(err => {
          messages.error(err);
        })
        .finally(() => {
          scope.uploading = false;

          scope.$broadcast('show');
        });
      };

      attachmentsApi.getThumbnails(scope.thumbnails, scope.attachments);
    }
  };
}