import { Injectable } from '@angular/core';

import { ApiService }           from '../core/api/api.service';
import { BookingModel }         from './models/booking';
import { TableCountModel }      from '../table/models/table-count';
import { TablePaginationModel } from '../table/models/table-pagination';

@Injectable({
  providedIn: 'root'
})
export class BookingsService {
  defaultPagination : TablePaginationModel = {
    enabled : true,
    offset  : 0,
    pageSize: 4
  };

  constructor (
    private apiService: ApiService
  ) {}

  getBookingCount () : ng.IPromise<TableCountModel> {
    return this.apiService.get('/customer_portal_dashboard/future_bookings_count');
  }

  getBookings ( offset = this.defaultPagination.offset, pageSize = this.defaultPagination.pageSize ) : ng.IPromise<Array<BookingModel>> {
    return this.apiService.get('/customer_portal_dashboard/future_bookings_index', [
      offset,
      pageSize
    ])
  }
}