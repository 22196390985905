import 'angular';

import '../api/api.module';
import '../core/core.module.ajs';
import '../messages/messages.module.ajs';

import { AddCustomerFromBranchCtrl }              from './customer.add-from-branch.ajs';
import { ChangeCustomerLiteCtrl }                 from './customer.change';
import { CustomerBranchHistoryCtrl }              from './customer.branch-history.ajs';
import { CustomerLiteCtrl }                       from './customer';
import { CustomerSelectionForAccountingLiteCtrl } from './customer.selection-for-accounting';
import { CustomerService }                        from './customer.service';
import { CustomersLiteCtrl }                      from './customers';
import { CustomerStatementSettingsLiteCtrl }      from './customer.statement-settings';
import { CustomerTypeFilter }                     from './customer.type.filter.ajs';
import { CustomerTypePipe }                       from './customer.type.pipe';
import { EmailCustomerStatementLiteCtrl }         from './customer.email-statement';
import { ResolveCustomerMatchesLiteCtrl }         from './customer.resolve-matches';
import { SendCustomerPortalInvitationCtrl }       from './customer.send-portal-invitation';

angular.module('app-lite.customers', [
  'app-lite.api',
  'app-lite.core',
  'app-lite.messages'
]).controller('AddCustomerFromBranchCtrl', AddCustomerFromBranchCtrl)
  .controller('ChangeCustomerLiteCtrl', ChangeCustomerLiteCtrl)
  .controller('CustomerBranchHistoryCtrl', CustomerBranchHistoryCtrl)
  .controller('CustomerLiteCtrl', CustomerLiteCtrl)
  .controller('CustomerSelectionForAccountingLiteCtrl', CustomerSelectionForAccountingLiteCtrl)
  .controller('CustomersLiteCtrl', CustomersLiteCtrl)
  .controller('CustomerStatementSettingsLiteCtrl', CustomerStatementSettingsLiteCtrl)
  .controller('EmailCustomerStatementLiteCtrl', EmailCustomerStatementLiteCtrl)
  .controller('ResolveCustomerMatchesLiteCtrl', ResolveCustomerMatchesLiteCtrl)
  .controller('SendCustomerPortalInvitationCtrl', SendCustomerPortalInvitationCtrl)
  .factory('CustomerLite', CustomerService)
  .filter('customerType', CustomerTypeFilter)
  .service('customerTypePipe', CustomerTypePipe);
