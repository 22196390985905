import 'angular';

import { ChangeVendorLiteCtrl }         from './vendor.change';
import { LinkedVendorsLiteCtrl }        from './vendors.linked-vendors';
import { ManuallyLinkVendorLiteCtrl }   from './vendor.manually-link';
import { ResolveVendorMatchesLiteCtrl } from './vendor.resolve-matches';
import { VendorLiteCtrl }               from './vendor';
import { VendorQuickAddLiteCtrl }       from './vendor.quick-add';
import { VendorSelectionLiteCtrl }      from './vendor.selection';
import { VendorServiceLite }            from './vendor.service';
import { VendorsLiteCtrl }              from './vendors';

angular.module('app-lite.vendors', [])
  .controller('ChangeVendorLiteCtrl', ChangeVendorLiteCtrl)
  .controller('LinkedVendorsLiteCtrl', LinkedVendorsLiteCtrl)
  .controller('ManuallyLinkVendorLiteCtrl', ManuallyLinkVendorLiteCtrl)
  .controller('ResolveVendorMatchesLiteCtrl', ResolveVendorMatchesLiteCtrl)
  .controller('VendorQuickAddLiteCtrl', VendorQuickAddLiteCtrl)
  .controller('VendorSelectionLiteCtrl', VendorSelectionLiteCtrl)
  .controller('VendorLiteCtrl', VendorLiteCtrl)
  .controller('VendorsLiteCtrl', VendorsLiteCtrl)
  .factory('VendorLite', VendorServiceLite);