import { NgModule, NgModuleFactoryLoader, SystemJsNgModuleLoader } from '@angular/core';
import { BrowserModule }                                           from '@angular/platform-browser';
import { UpgradeModule }                                           from '@angular/upgrade/static';
import { UIRouterUpgradeModule }                                   from '@uirouter/angular-hybrid';

import { AppComponent }        from './app.component';
import { CoreModule }          from './core/core.module';
import { NotificationsModule } from './notifications/notifications.module';
import { RouterConfig }        from './routing/router.config';
import { RouterStates }        from './routing/router.states';
import { SharedModule }        from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    UpgradeModule,
    SharedModule,
    CoreModule,
    NotificationsModule,
    UIRouterUpgradeModule.forRoot({
      config: RouterConfig,
      states: RouterStates
    })
  ],
  providers: [
    { provide: 'Window', useValue: window },
    { provide: NgModuleFactoryLoader, useClass: SystemJsNgModuleLoader },
  ]
})
export class AppModule {
  constructor (
    private upgrade : UpgradeModule
  ) {}

  ngDoBootstrap() {
    this.upgrade.bootstrap(document.body, ['app'], { strictDi: false });
  }
}
