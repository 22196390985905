import * as angular from 'angular';

import { downgradeInjectable } from '@angular/upgrade/static';

import { StripeCheckoutCtrl }          from './stripe.checkout.ajs';
import { StripePaymentCtrl }           from './stripe.payment.ajs';
import { StripePaymentDirective }      from './stripe.payment.directive.ajs';
import { StripeService }               from './stripe.service.ajs';
import { StripeStatusCtrl }            from './stripe.status.ajs';
import { StripeStatusDirective }       from './stripe.status.directive.ajs';
import { StripeStatusFilter }          from './stripe.status.filter.ajs';
import { StripeStatusPipe }            from './stripe.status.pipe';

angular.module('app-lite.stripe', [])
  .controller('StripeCheckoutCtrl', StripeCheckoutCtrl)
  .controller('StripePaymentCtrl', StripePaymentCtrl)
  .controller('StripeStatusCtrl', StripeStatusCtrl)
  .directive('stripePayment', StripePaymentDirective)
  .directive('stripeStatus', StripeStatusDirective)
  .filter('stripeStatus', StripeStatusFilter)
  .service('stripeApi', StripeService)
  .service('stripeStatusPipe', downgradeInjectable(StripeStatusPipe));

