import 'angular';

import '../api/api.module.ajs';
import '../core/core.module.ajs';

import { ConfirmService }                from './confirm.service.ajs';
import { CustomConfirmationLiteCtrl }    from './confirmation.custom';
import { EmailCtrl }                     from './messages.email.ajs';
import { GenericConfirmationLiteCtrl }   from './confirmation.generic';
import { HardConfirmationLiteCtrl }      from './confirmation.hard';
import { MessagesServiceAjs }            from './messages.service.ajs';
import { MessengerService }              from './messenger.service.ajs';
import { NotifyConfirmationLiteCtrl }    from './confirmation.notify';
import { QuestionConfirmationLiteCtrl }  from './confirmation.question';
import { RememberConfirmationLiteCtrl }  from './confirmation.remember';
import { SaveConfirmationLiteCtrl }      from './confirmation.save';
import { SendSmsCtrl }                   from './messages.sms.ajs';
import { SubscribeConfirmationLiteCtrl } from './confirmation.subscribe';
import { TextConfirmationLiteCtrl }      from './confirmation.text';

angular.module('app-lite.messages', [
  'app-lite.api',
  'app-lite.core'
]).controller('CustomConfirmationLiteCtrl', CustomConfirmationLiteCtrl)
  .controller('EmailCtrl', EmailCtrl)
  .controller('GenericConfirmationLiteCtrl', GenericConfirmationLiteCtrl)
  .controller('HardConfirmationLiteCtrl', HardConfirmationLiteCtrl)
  .controller('NotifyConfirmationLiteCtrl', NotifyConfirmationLiteCtrl)
  .controller('QuestionConfirmationLiteCtrl', QuestionConfirmationLiteCtrl)
  .controller('RememberConfirmationLiteCtrl', RememberConfirmationLiteCtrl)
  .controller('SaveConfirmationLiteCtrl', SaveConfirmationLiteCtrl)
  .controller('SendSmsCtrl', SendSmsCtrl)
  .controller('SubscribeConfirmationLiteCtrl', SubscribeConfirmationLiteCtrl)
  .controller('TextConfirmationLiteCtrl', TextConfirmationLiteCtrl)
  .service('confirm', ConfirmService)
  .service('messages', MessagesServiceAjs)
  .service('messengerApi', MessengerService);