export function CrudGridAPIService ($q, workshopAPI, messages) {
  return function () {
    return {
      data: [],
      editable: true,
      toggleEditMode: function (row, index) {
        if (row.id) {
          row.editing = !row.editing;
        } else {
          this.data.splice(index, 1);
        }
      },
      editRow: function (row, index) {
        var activeRow = this.data.filter(function (item) {
          return item.editing;
        });

        if (activeRow.length) {
          activeRow[0].editing = false;
        }

        row.editing = true;
      },
      addRow: function () {
        this.data.push({});
        this.editRow(this.data[this.data.length-1]);
      },
      removeRow: function (row, index, url, identifier) {
        var _this = this;
        var promise;

        if (row[identifier]) {
          promise = workshopAPI.delete(url, row[identifier])

          promise.catch(function (err) {
            messages.error(err);
          });

          return promise;
        }
        else {
          $q.defer().resolve(_this.data.splice(index, 1));

          return $q.defer().promise;
        }
      }
    };
  };
}