import { InspectionTemplateModel } from './models/inspection.template';

import { EditComponent } from '../table/cell-components/actions/edit.component';
import { TableService }  from '../table/table.service';

interface InspectionTemplatesScope extends ng.IScope {
  getTemplates : ( params : any ) => ng.IPromise<Array<InspectionTemplateModel>>;
  loaded       : boolean;
  paging       : any;
  select       : ( row : any ) => void;
  sorting      : any;
  tableCacheKey: string;
  tableSettings: any;
  templates    : Array<InspectionTemplateModel>;
}

export function InspectionTemplatesCtrl (
  $scope     : InspectionTemplatesScope,
  $state     : ng.ui.IStateService,
  $translate : ng.translate.ITranslateService,
  templates  : Array<InspectionTemplateModel>,
  tableApi   : TableService,
  workshopAPI: any
) {

  $scope.loaded        = true;
  $scope.tableCacheKey = 'templates';
  $scope.templates     = templates;

  $scope.tableSettings = {
    cache: {
      enabled: true,
      key    : $scope.tableCacheKey
    },
    columns: [
      {
        field : 'name',
        header: {
          text: $translate.instant('GENERAL_SPACE.FIELD.NAME')
        }
      },
      {
        field : 'description',
        header: {
          text: $translate.instant('GENERAL_SPACE.FIELD.DESCRIPTION')
        }
      },
      {
        component: {
          bindings: {
            outputs: {
              onEditClick: event => $scope.select(event)
            }
          },
          enabled: true,
          factory: EditComponent,
        },
        sortable: false,
      }
    ],
    empty: {
      text: $translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_PAYMENTS_FOUND')
    },
    pagination: {
      enabled   : true,
      pageSize  : tableApi.getCachedPaging($scope.tableCacheKey).pageSize || 10,
      pageSizes : [ 10, 20, 50, 100 ],
      queryCount: () => workshopAPI.get('/inspection_templates/count')
    },
    rows: {
      highlight: {
        enabled: true
      }
    },
    sorting: tableApi.setSortingFromCache($scope.tableCacheKey, {
      direction: 'asc',
      field    : 'name'
    })
  };

  $scope.select = function ( event : any ) {
    $state.go('app.inspection-template', {
      id: event.id || event.row.id
    });
  };

  $scope.getTemplates = ( params ) => {
    return workshopAPI.get('/inspection_templates', [
      params.offset,
      params.pageSize,
      params.field,
      params.direction,
    ]).then(templates => {
      $scope.templates = templates;

      return templates;
    });
  };
};