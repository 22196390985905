export function CoreValidatePhoneService (regexCodes){
  return {
    mobile (value) {
      const regex = regexCodes.mobile();

      return value ? value.match(regex) : true;
    },
    phone (value) {
      const regex = regexCodes.phone();

      return value ? value.match(regex) : true;
    }
  };
}
