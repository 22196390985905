import { Component, Input } from '@angular/core';

import { InvoiceService }          from './invoice.service';
import { OutstandingInvoiceModel } from './models/outstanding-invoice';
import { TableCellLinkComponent }  from '../table/table-cell.link.component';
import { TableCountModel }         from '../table/models/table-count';
import { TableModel }              from '../table/models/table';
import { TableQueryModel }         from '../table/models/table-query';

@Component({
  selector   : 'wor-outstanding-invoices',
  template: require('./outstanding-invoices.component.html')
})
export class OutstandingInvoicesComponent {
  @Input() outstandingInvoices: Array<OutstandingInvoiceModel>;

  tableSettings : TableModel = {
    columns: [
      {
        field : 'invoice_number',
        header: {
          title: 'Invoice #'
        }
      },
      {
        field : 'total',
        header: {
          title: 'Invoice Total'
        },
        pipe: 'globalCurrency'
      },
      {
        field : 'due_date',
        header: {
          title: 'Due Date'
        },
        pipe: 'globalDate'
      },
      {
        classes : [ 'text-right' ],
        bindings: {
          inputs: {
            title: 'View Invoice'
          },
          outputs: {
            onClick: ( invoice : OutstandingInvoiceModel ) => this.viewInvoice(invoice)
          }
        },
        component: TableCellLinkComponent
      }
    ],
    empty: {
      message: 'No Outstanding Invoices'
    },
    pagination: this.invoiceService.defaultPagination,
    rows      : {
      hover: {
        enabled: true
      },
      select: {
        enabled : true,
        property: 'active'
      }
    },
    title: 'Outstanding Invoices'
  };

  constructor(
    public invoiceService: InvoiceService
  ) {}

  getOutstandingInvoiceCount () : ng.IPromise<TableCountModel> {
    return this.invoiceService.getOutstandingInvoiceCount();
  }

  getOutstandingInvoices ( params : TableQueryModel ) : ng.IPromise<Array<OutstandingInvoiceModel>> {
    return this.invoiceService.getOutstandingInvoices(params.offset, params.pageSize);
  }

  viewInvoice ( invoice : OutstandingInvoiceModel ) {
    window.open(this.invoiceService.getInvoiceLink(invoice), '_blank');
  }
};

