import 'angular';

import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';

import { TableComponent } from './table.component';
import { TableService }   from './table.service';

angular.module('app-lite.table', [])
.directive('worTable', downgradeComponent({ component: TableComponent }))
.service('tableApi', downgradeInjectable(TableService));
