import { NgModule }                             from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { FormsModule }                          from '@angular/forms';
import {
  NgbButtonsModule,
  NgbCarouselModule,
  NgbDatepickerModule,
  NgbModalModule,
  NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';

import { CarouselComponent }               from './components/carousel/carousel.component';
import { ConfirmPasswordControlComponent } from './components/confirm-password-control/confirm-password-control.component';
import { ControlsModule }                  from '../../app-lite/shared/controls/controls.module';
import { CreatePasswordControlComponent }  from './components/create-password-control/create-password-control.component';
import { EqualValidator }                  from './validators/equal.validator';
import { FormSubmitComponent }             from './components/form-submit/form-submit.component';
import { GlobalCurrencyPipe }              from './pipes/global-currency.pipe';
import { GlobalDatePipe }                  from './pipes/global-date.pipe';
import { PasswordValidator }               from './validators/password.validator';
import { PhonePipe }                       from './pipes/phone.pipe';
import { SearchControlComponent }          from './components/search-control/search-control.component';

@NgModule({
  declarations: [
    // Components
    CarouselComponent,
    ConfirmPasswordControlComponent,
    CreatePasswordControlComponent,
    FormSubmitComponent,
    SearchControlComponent,

    // Pipes
    GlobalCurrencyPipe,
    GlobalDatePipe,
    PhonePipe,

    // Validators
    EqualValidator,
    PasswordValidator
  ],
  exports: [
    // Angular
    CommonModule,
    FormsModule,

    // Third party
    NgbButtonsModule,
    NgbDatepickerModule,
    NgbCarouselModule,
    NgbModalModule,
    NgbTooltipModule,

    ControlsModule,

    // Components
    CarouselComponent,
    ConfirmPasswordControlComponent,
    CreatePasswordControlComponent,
    FormSubmitComponent,
    SearchControlComponent,

    // Pipes
    GlobalCurrencyPipe,
    GlobalDatePipe,
    PhonePipe,

    // Validators
    EqualValidator,
    PasswordValidator
  ],
  imports: [
    // Angular
    CommonModule,
    FormsModule,

    // Third party
    NgbButtonsModule,
    NgbCarouselModule,
    NgbDatepickerModule,
    NgbModalModule,
    NgbTooltipModule,

    ControlsModule
  ],
  providers: [
    CurrencyPipe,
    DatePipe,
    GlobalCurrencyPipe,
    GlobalDatePipe
  ]
})
export class SharedModule {}