export function CoreUibConfig (
  $uibModalProvider  : ng.ui.bootstrap.IModalProvider,
  $uibTooltipProvider: ng.ui.bootstrap.ITooltipProvider
) {
  // Modals
  $uibModalProvider.options.backdrop       = 'static';

  // Tooltips / Popovers
  $uibTooltipProvider.options({
    appendToBody: true
  });
};
