import { NgModule } from '@angular/core';

import { InvoicesComponent }            from './invoices.component';
import { OutstandingInvoicesComponent } from './outstanding-invoices.component';
import { SharedModule }                 from '../shared/shared.module';
import { TableModule }                  from '../table/table.module';

@NgModule({
  declarations: [
    InvoicesComponent,
    OutstandingInvoicesComponent
  ],
  exports: [
    InvoicesComponent,
    OutstandingInvoicesComponent
  ],
  imports: [
    SharedModule,
    TableModule
  ]
})
export class InvoicesModule {}
