import { Directive, ElementRef, forwardRef, Input }  from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

import { CompanyCountryValidationsModel } from '../../companies/models/company-country-validations';
import { SessionServiceAjs }              from '../../sessions/session.service.ajs';

@Directive({
  selector : '[worPostcodeValidator]',
  providers: [
    {
      multi      : true,
      provide    : NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => PostcodeValidator)
    }
  ]
})
export class PostcodeValidator implements Validator {
  defaultValidation = '^\\d+$';

  mask : string;
  regex: RegExp;

  @Input ('validations')    validations: CompanyCountryValidationsModel;
  @Input ('worPostcodeValidator') value: string;

  constructor (
    private element        : ElementRef<HTMLInputElement>,
    private sessionService : SessionServiceAjs
  ) {}

  getAndSetValidations () {
    const countryValidations = this.sessionService.countryValidation();

    this.mask = this.validations
      ? this.validations.postcode.mask
      : countryValidations.postcode.mask;

    const regex = this.validations
      ? this.validations.postcode.validation
      : countryValidations.postcode.validation;

    this.regex = new RegExp(regex || this.defaultValidation);
  }

  validate (ctrl: AbstractControl) : { [ key: string ] : any } {
    this.getAndSetValidations();

    if (!ctrl.value) {
      return undefined;
    }

    if (ctrl.value.match(this.regex)) {
      if (this.mask) {
        this.element.nativeElement.value = ctrl.value
          .replace(this.regex, this.mask)
          .replace(/-$/, '');
      }

      return undefined;
    }

    return { phoneValidator: true };
  }
}