import { NgModule } from '@angular/core';

import { QuotesComponent } from './quotes.component';
import { SharedModule }    from '../shared/shared.module';
import { TableModule }     from '../table/table.module';

@NgModule({
  declarations: [
    QuotesComponent
  ],
  exports: [
    QuotesComponent
  ],
  imports: [
    SharedModule,
    TableModule
  ]
})
export class QuotesModule {}
