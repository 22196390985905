import '../../../vendor/chosen/chosen.min';

// Chosen directive for all dropdowns
export function CoreDropdownChosenDirective ($parse, messages) {
  return {
    require: "ngModel",
    restrict: 'A',
    link: function(scope, element, attrs, ctrl) {
      var options = {
        width: '100%',
        disable_search_threshold: 10,
        inherit_select_classes: true,
        placeholder_text_single: ' '
      }

      if (attrs.onChange) {
        element.on('change', scope[attrs.onChange]);
      }

      if (attrs.onBlur) {
        element.on('chosen:hiding_dropdown', scope[attrs.onBlur]);
      }

      scope.$watch(attrs.chosen, function(){
        element.trigger('chosen:updated');
      });

      scope.$watch(function(){return ctrl.$modelValue}, function(newVal, oldVal){
        element.trigger('chosen:updated');
      }, true);

      scope.$watch(function(){return ctrl.$dirty},function(dirty){
        if (dirty && ctrl.$invalid) element.parent().find('.chosen-container').addClass('invalid');
        else element.parent().find('.chosen-container').removeClass('invalid')  ;
      }, true);

      scope.$watch(function(){return ctrl.$valid}, function(valid){
        if (!valid && ctrl.$dirty) element.parent().find('.chosen-container').addClass('invalid');
        else element.parent().find('.chosen-container').removeClass('invalid')  ;
      }, true);

      attrs.$observe('disabled', function() {
        return element.trigger('chosen:updated');
      });

      element.chosen(options);

    }
  }
}