export function ProductGroupQuickAddLiteCtrl (
  $scope,
  $translate,
  categories,
  messages,
  sessionObjAPI,
  workshopAPI
) {
  $scope.categories = categories;
  $scope.session    = sessionObjAPI.base();

  $scope.save = function () {
    workshopAPI.post('/product_groups', $scope.group, 'product_group').then(group => {
      messages.show($translate.instant('JS_SPACE.MESSAGES.CREATED.PRODUCT_GROUP_ADDED'), 'success');

      $scope.$close(group);
    }).catch(err => {
      messages.error(err);
    });
  };
};