export const COUNTRIES = {
  australia      : 'aus',
  austria        : 'aut',
  belgium        : 'bel',
  brazil         : 'bra',
  bulgaria       : 'bgr',
  canada         : 'can',
  cyprus         : 'cyp',
  czech          : 'cze',
  denmark        : 'dnk',
  estonia        : 'est',
  france         : 'fra',
  georgia        : 'geo',
  germany        : 'deu',
  greece         : 'grc',
  hongKong       : 'hkg',
  hungary        : 'hun',
  india          : 'ind',
  indonesia      : 'idn',
  ireland        : 'irl',
  italy          : 'ita',
  japan          : 'jpn',
  latvia         : 'lva',
  lithuania      : 'ltu',
  luxembourg     : 'lux',
  malawi         : 'mwi',
  malaysia       : 'mys',
  malta          : 'mlt',
  mexico         : 'mex',
  netherlands    : 'nld',
  newZealand     : 'nzl',
  nigeria        : 'nga',
  northernIreland: 'nir',
  norway         : 'nor',
  philippines    : 'phl',
  poland         : 'pol',
  portugal       : 'prt',
  puertoRico     : 'pri',
  romania        : 'rou',
  singapore      : 'sgp',
  slovakia       : 'svk',
  slovenia       : 'svn',
  southAfrica    : 'zaf',
  spain          : 'esp',
  sweden         : 'swe',
  switzerland    : 'swz',
  uae            : 'ae',
  unitedKingdom  : 'gbr',
  usa            : 'usa',
  zimbabwe       : 'zwe'
};
