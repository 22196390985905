import { Injectable } from '@angular/core';

import { ApiService }              from '../core/api/api.service';
import { InvoiceModel }            from './models/invoice';
import { OutstandingInvoiceModel } from './models/outstanding-invoice';
import { TableCountModel }         from '../table/models/table-count';
import { TablePaginationModel }    from '../table/models/table-pagination';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  defaultPagination : TablePaginationModel = {
    enabled : true,
    offset  : 0,
    pageSize: 4
  };

  constructor (
    private apiService: ApiService
  ) {}

  getHistoryLink () : string {
    return `${ this.apiService.getEndpoint() }/customer_portal_dashboard/vehicle_history_report`;
  }

  getInvoiceCount () : ng.IPromise<TableCountModel> {
    return this.apiService.get('/customer_portal_dashboard/invoices_count');
  }

  getInvoiceLink ( invoice : InvoiceModel | OutstandingInvoiceModel ) : string {
    return `${ this.apiService.getEndpoint() }/customer_portal_dashboard/invoice_report/${ invoice.id }`;
  }

  getInvoices ( offset = this.defaultPagination.offset, pageSize = this.defaultPagination.pageSize ) : ng.IPromise<Array<InvoiceModel>> {
    return this.apiService.get('/customer_portal_dashboard/invoices_index', [
      offset,
      pageSize
    ]);
  }

  getOutstandingInvoiceCount () : ng.IPromise<TableCountModel> {
    return this.apiService.get('/customer_portal_dashboard/outstanding_invoices_count');
  }

  getOutstandingInvoices ( offset = this.defaultPagination.offset, pageSize = this.defaultPagination.pageSize ) : ng.IPromise<Array<OutstandingInvoiceModel>> {
    return this.apiService.get('/customer_portal_dashboard/outstanding_invoices_index', [
      offset,
      pageSize
    ]);
  }

  searchInvoices ( query : string ) : ng.IPromise<Array<InvoiceModel>> {
    return this.apiService.get('/customer_portal_dashboard/invoice_search', [ query ]);
  }
}