import 'angular';

import { BranchVendorInvoicesDirective } from './vendor-invoices.branch.directive';
import { BranchVendorInvoicesLiteCtrl }  from './vendor-invoices.branch';
import { VendorInvoicesForPaymentCtrl }  from './vendor-invoices.for-payment.ajs';

angular.module('app-lite.vendor-invoices', [])
  .controller('BranchVendorInvoicesLiteCtrl', BranchVendorInvoicesLiteCtrl)
  .controller('VendorInvoicesForPaymentCtrl', VendorInvoicesForPaymentCtrl)
  .directive('vendorBranchInvoices', BranchVendorInvoicesDirective);
