import { UrlService } from '@uirouter/core';

export function RouterStatesAjs (
  $locationProvider  : ng.ILocationProvider,
  $stateProvider     : ng.ui.IStateProvider,
  $urlRouterProvider : ng.ui.IUrlRouterProvider,
  $urlServiceProvider: UrlService
) {
  $stateProvider
  .state('ajs', {
    abstract: true,
    template: '<ui-view></ui-view>',
    url     : '/',
    resolve : {
      translateReady: ( $translate : ng.translate.ITranslateService ) : ng.IPromise<any> => $translate.onReady()
    }
  });

  $locationProvider.hashPrefix('');
  $urlRouterProvider.otherwise('/app/public/login');
  $urlServiceProvider.deferIntercept();
}
