import { AfterViewInit, Component, ContentChild, EventEmitter,
  Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { NgbCarousel }                            from "@ng-bootstrap/ng-bootstrap";

import { CarouselEvent } from "./models/carousel-event";
import { CarouselModel } from "./models/carousel";

@Component({
  selector   : 'wor-carousel',
  styles: [ require('./carousel.component.scss') ],
  template: require('./carousel.component.html')
})
export class CarouselComponent implements AfterViewInit, OnInit {
  @ContentChild(TemplateRef) templateRef;

  @Input() settings: CarouselModel;
  @Input() slides  : Array<any> = [];

  @Output() slid : EventEmitter<CarouselEvent> = new EventEmitter<CarouselEvent>();
  @Output() slide: EventEmitter<CarouselEvent> = new EventEmitter<CarouselEvent>();

  @ViewChild('carouselRef') carouselRef: NgbCarousel;

  getSlideID ( slide : any, index : number ) : string {
    return String(this.settings.identifier ? slide[this.settings.identifier] : index);
  }

  initSettings () : void {
    this.settings = {
      identifier              : undefined,
      paused                  : true,
      showNavigationArrows    : true,
      showNavigationIndicators: false,
      wrap                    : false,
      ...this.settings
    };
  }

  ngAfterViewInit() : void {
    if (this.settings.paused) {
      this.carouselRef.pause();
    }
  }

  ngOnInit() : void {
    this.initSettings();
  }
};

