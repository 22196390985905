import { Transition, TransitionService } from '@uirouter/core';

import { SessionServiceAjs } from '../sessions/session.service.ajs';

export function UsersResolver (
  $transitions : TransitionService,
  sessionObjAPI: SessionServiceAjs
) {
  $transitions.onStart({
    to: 'app.users'
  }, ( transition : Transition ) => {
    if ( !sessionObjAPI.isAdminUser() ) {
      return false;
    }
  });
}
