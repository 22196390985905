import { NgModule } from '@angular/core';

import { JobsComponent }          from './jobs.component';
import { JobsService }            from './jobs.service';
import { JobStatusCellComponent } from './job.status.cell.component';
import { SharedModule }           from '../shared/shared.module';
import { TableModule }            from '../table/table.module';

@NgModule({
  declarations: [
    JobsComponent,
    JobStatusCellComponent
  ],
  entryComponents: [
    JobStatusCellComponent
  ],
  exports: [
    JobsComponent,
    JobStatusCellComponent
  ],
  imports: [
    SharedModule,
    TableModule
  ],
  providers: [
    JobsService
  ]
})
export class JobsModule {}
