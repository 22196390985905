import 'angular';

import { ApiModel }      from '../../app-lite/api/models/api.model';
import { CustomerModel } from '../../app-lite/customers/models/customer';
import { InvoiceModel }  from '../../app-lite/invoices/models/invoice';
import { Table }         from '../../app-lite/table/types/table';

export class CustomerBranchHistoryCtrl {
  tableSettings: Table =  {
    columns: [
      {
        field : 'store_name',
        header: {
          text: 'Store Name'
        }
      },
      {
        field : 'invoice_number',
        header: {
          text: this.$translate.instant('GENERAL_SPACE.FIELD.REFERENCE')
        }
      },
      {
        field : 'post_date',
        header: {
          text: this.$translate.instant('GENERAL_SPACE.FIELD.POST_DATE')
        },
        pipe: 'globalDate'
      },
      {
        field : 'invoice_type',
        header: {
          text: this.$translate.instant('JS_SPACE.COLUMNS.TRAN_TYPE')
        },
        pipe: 'invoiceType'
      },
      {
        field : 'invoice_status',
        header: {
          text: this.$translate.instant('GENERAL_SPACE.FIELD.STATUS')
        },
        pipe: 'invoiceStatus'
      },
      {
        field : 'cash_or_account',
        header: {
          text: this.$translate.instant('GENERAL_SPACE.FIELD.TYPE')
        },
        pipe: 'customerType'
      },
      {
        field : 'total',
        header: {
          text: this.$translate.instant('JS_SPACE.COLUMNS.AMOUNT')
        },
        pipe: 'globalCurrency'
      },
      {
        field : 'balance_due',
        header: {
          text: this.$translate.instant('JS_SPACE.COLUMNS.BALANCE')
        },
        pipe: 'globalCurrency'
      }
    ],
    empty: {
      text: this.$translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_INVOICES_FOUND')
    },
    pagination: {
      enabled: false
    },
    rows: {
      highlight: {
        enabled: true
      }
    },
    sorting: {
      enabled: false
    },
    static: true
  };

  constructor (
    private $translate: ng.translate.ITranslateService,
    private $window   : ng.IWindowService,
    private API       : ApiModel,
    public customer   : CustomerModel,
    public invoices   : Array<InvoiceModel>
  ){}

  openReport ( invoice : InvoiceModel ) {
    this.$window.open(`${this.API.url}/reports/jasper_invoice/${invoice.id}.pdf`, '_blank');
  };
}
