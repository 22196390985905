import 'angular';
import '@uirouter/angularjs';
import '@uirouter/angular-hybrid';

import '../app-lite/app.module.ajs';

import { RouterStatesAjs } from './routing/router.states.ajs';

angular.module('app', [
  'ui.router',
  'ui.router.upgrade',
  'app-lite'
])
.config(RouterStatesAjs);
