import { Component, Input } from '@angular/core';

@Component({
  template: require('./table-cell.truthy.component.html')
})
export class TableCellTruthyComponent {
  @Input() field = 'id';
  @Input() record : any;

  isTruthy () : boolean {
    return !!this.record[this.field];
  }
}
