import { NgModule } from '@angular/core';

import { CustomerTypePipe } from './customer.type.pipe';

@NgModule({
  declarations: [
    CustomerTypePipe
  ],
  providers: [
    CustomerTypePipe,

    // Pipes must also be provided via string
    // if they are going to be used in a table
    // column. This allows the injector to be
    // able to load a pipe dynamically in the
    // TableCellComponent.
    {
      provide : 'customerType',
      useClass: CustomerTypePipe
    }
  ]
})
export class CustomersModule {}
