import { Inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'month'
})
export class MonthPipe implements PipeTransform {

  constructor(
    @Inject('$translate') public $translate : ng.translate.ITranslateService
  ) {}

  transform( month : number ) : string {
    const months: any = {
      1 : this.$translate.instant('GENERAL_SPACE.MONTHS.JANUARY'),
      2 : this.$translate.instant('GENERAL_SPACE.MONTHS.FEBRUARY'),
      3 : this.$translate.instant('GENERAL_SPACE.MONTHS.MARCH'),
      4 : this.$translate.instant('GENERAL_SPACE.MONTHS.APRIL'),
      5 : this.$translate.instant('GENERAL_SPACE.MONTHS.MAY'),
      6 : this.$translate.instant('GENERAL_SPACE.MONTHS.JUNE'),
      7 : this.$translate.instant('GENERAL_SPACE.MONTHS.JULY'),
      8 : this.$translate.instant('GENERAL_SPACE.MONTHS.AUGUST'),
      9 : this.$translate.instant('GENERAL_SPACE.MONTHS.SEPTEMBER'),
      10: this.$translate.instant('GENERAL_SPACE.MONTHS.OCTOBER'),
      11: this.$translate.instant('GENERAL_SPACE.MONTHS.NOVEMBER'),
      12: this.$translate.instant('GENERAL_SPACE.MONTHS.DECEMBER')
    };

    return months[month];
  }
}

