import JobsCenterHtml from './transaction-center.jobs.html';

export const TransactionCenterJobsComponent : ng.IComponentOptions = {
  bindings: {
    constraints     : '<',
    jobs            : '=',
    jobStatusOptions: '<',
    jobStatuses     : '<'
  },
  controller  : 'TransactionCenterJobsCtrl',
  controllerAs: '$ctrl',
  templateUrl : JobsCenterHtml,
};