import { DatePipe }            from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { SessionService } from '../../core/sessions/session.service';

@Pipe({
  name: 'globalDate'
})
export class GlobalDatePipe implements PipeTransform {
  constructor (
    private datePipe      : DatePipe,
    private sessionService: SessionService
  ) {}

  transform ( date : string ) : string {
    const settings = this.sessionService.getSession().country_settings;
    const format   = (settings.date_format || 'mm/dd/yyyy').replace(/m/g, 'M');

    return this.datePipe.transform(date, format);
  }
}
