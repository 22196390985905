import { Component, TemplateRef } from '@angular/core';

import { NotificationModel }   from './models/notification';
import { NotificationService } from './notification.service';

@Component({
  selector   : 'wor-notifications',
  styles: [ require('./notifications.component.scss') ],
  template: require('./notifications.component.html'),
})
export class NotificationsComponent {
  constructor(
    public notificationService: NotificationService
  ) {}

  isTemplate ( notification : NotificationModel ) : boolean {
    return notification ? notification.content instanceof TemplateRef : false;
  }
}
