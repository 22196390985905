export function ImportProductsLiteCtrl (
  $controller,
  $scope,
  messages,
  workshopAPI
) {
  $controller('import', { $scope: $scope });

  $scope.controllerName = 'ImportProductsLiteCtrl';
  $scope.fileUploadID   = 'product_csv';
  $scope.importUrl      = '/imports/perform_product_import';

  $scope.prepFormForImport = function ( form ) {
    form.append('is_price_file', $scope.is_price_file);

    return form;
  };

  // Everytime the file changes
  $('#'+$scope.fileUploadID)
  .on('change', e => {
    // Make sure the file isn't blank
    if ($(e.target).val()) {
      // Set the flag so that buttons and such will activate
        $scope.fileSelected = true;
        // Set the value of the fake input to the filename w/out the fakepath
        // crap
        $('[ng-controller="ImportProductsLiteCtrl"] .newfileholder')
        .val($(e.target)
        .val()
        .replace('C:\\fakepath\\', ''));
    }
    else {
      // If the file has been deleted then set the flag to disable the needed
      // buttons and such
        $scope.fileSelected = false;
    }

    if (!$scope.$$phase) {
      $scope.$apply();
    }
  });

  // Get the columns specific to products to populate the dropdown as well as the sample data and requirements
  workshopAPI.get('/imports/our_column_details', 'products')
  .then(columns => {
    $scope.ourColumns = columns;
  })
  .catch(err => {
    messages.error(err);
  });

  // Get the transform functions specific to products with all the parameters and equations
  workshopAPI.get('/imports/available_actions', 'products')
  .then(response => {
    $scope.transformFunctions = response;
  })
  .catch(err => {
    messages.error(err);
  });
};