export function CoreIFrameLoaderDirective ($translate) {
  return {
    restrict: 'A',
    link: function(scope, element, attrs, ctrl) {
      scope.text = $translate.instant('JS_SPACE.INTEGRATIONS.LOADING', { name: attrs.name });

      element.load(function (e) {
        scope.$apply(function () {
          scope.loaded = true;
        });
      });
    }
  };
}