import { Injectable } from "@angular/core";
import { Subject    } from "rxjs";

import { SessionServiceAjs } from '../sessions/session.service.ajs';
import { TablePagination }   from './types/table-pagination';
import { TableSort }         from './types/table-sort';

@Injectable({
  providedIn: 'root'
})
export class TableService {

  // Table control components will call .next() on this
  // subject when their model value changes. The
  // TableComponent subscribes to these outputs in order
  // to mark TableCellComponent's as ready for check.
  changes: Subject<void> = new Subject<void>();

  defaultCache = {
    filtering: {},
    paging   : {},
    sorting  : {}
  };

  key = 'tableCache';

  constructor (
    private sessionService : SessionServiceAjs
  ) {}

  getCachedFilters ( key : string ) : any {
    const cache = this.getCacheFromStorage();

    return this.isFilterCachingEnabled()
      ? ( cache.filtering[key] || {} )
      : {};
  }

  getCachedPaging ( key : string ) : TablePagination {
    const cache = this.getCacheFromStorage();

    if (cache.paging[key]) {
      cache.paging[key].offset   = parseInt(cache.paging[key].offset);
      cache.paging[key].page     = parseInt(cache.paging[key].page);
      cache.paging[key].pageSize = parseInt(cache.paging[key].pageSize);

      return cache.paging[key];
    }

    return {};
  }

  getCachedSorting ( key : string ) : TableSort {
    const cache = this.getCacheFromStorage();

    return cache.sorting[key] || {};
  }

  getCacheForTable ( key : string ) {
    return {
      filtering: this.getCachedFilters(key),
      paging   : this.getCachedPaging(key),
      sorting  : this.getCachedSorting(key)
    };
  }

  getCacheFromStorage () : any {
    const cache =  localStorage.getItem(this.key);

    if (cache) {
      return JSON.parse(cache);
    }

    const defaultCache = Object.assign({}, this.defaultCache);

    this.saveCacheToStorage(defaultCache);

    return defaultCache;
  }

  isFilterCachingEnabled () : boolean {
    return !!this.sessionService.user().cache_grid_filters;
  }

  saveCacheToStorage ( tableCache = this.defaultCache ) : void {
    localStorage.setItem(this.key, JSON.stringify(tableCache));
  }

  setFiltersFromCache ( key : string, filtering = {} ) : any {
    return Object.assign({}, filtering, this.getCachedFilters(key));
  }

  setSortingFromCache ( key : string, sorting : TableSort = {} ) : TableSort {
    return Object.assign({
      enabled: true
    }, sorting, this.getCachedSorting(key));
  }

  updateCachedFilters ( key : string, filtering = {} ) : void {
    const cache = this.getCacheFromStorage();

    cache.filtering[key] = filtering;

    this.saveCacheToStorage(cache);
  }

  updateCachedPaging ( key : string, paging : TablePagination = {} ) : void {
    const cache = this.getCacheFromStorage();

    cache.paging[key] = {
      pageSize: paging.pageSize
    };

    this.saveCacheToStorage(cache);
  }

  updateCachedSorting ( key : string, sorting : TableSort = {} ) : void {
    const cache = this.getCacheFromStorage();

    cache.sorting[key] = {
      direction: sorting.direction,
      field    : sorting.field
    };

    this.saveCacheToStorage(cache);
  }
}