import ReactivateSubscriptionHtml from './subscription.reactivate.html';
import WindowFlipHtml             from '../core/templates/window-flip.html';

export class SubscriptionServiceAjs {
  constructor (
    private $uibModal : ng.ui.bootstrap.IModalService
  ) {}

  reactivate ( session : any, balanceInCents : number ) : ng.IPromise<any> {
    return this.$uibModal.open({
      controller       : 'ReactivateSubscriptionCtrl',
      controllerAs     : '$ctrl',
      templateUrl      : ReactivateSubscriptionHtml,
      windowClass      : 'md-modal flip-container',
      windowTemplateUrl: WindowFlipHtml,
      resolve          : {
        balanceInCents: () => balanceInCents,
        session       : () => session
      }
    }).result;
  }
}