import { NgModule }     from '@angular/core';

import { ChangePasswordComponent } from './customer.change-password.component';
import { CustomerDetailComponent } from './customer.detail.component';
import { CustomerService }         from './customer.service';
import { SharedModule }            from '../shared/shared.module';
import { VehiclesModule }          from '../vehicles/vehicles.module';

@NgModule({
  declarations: [
    ChangePasswordComponent,
    CustomerDetailComponent
  ],
  entryComponents: [
    ChangePasswordComponent
  ],
  exports: [
    ChangePasswordComponent,
    CustomerDetailComponent
  ],
  imports: [
    SharedModule,
    VehiclesModule
  ],
  providers: [
    CustomerService
  ]
})
export class CustomersModule {}
