import { ApiServiceAjs }    from '../api/api.service.ajs';
import { MessengerService } from './messenger.service.ajs';

interface SendSmsScope extends ng.IScope {
  addTemplates      : () => void;
  block             : ng.blockUI.BlockUIService;
  isPhoneNumberValid: () => boolean;
  send              : () => void;
  sms               : any;
}

export function SendSmsCtrl (
  $scope           : SendSmsScope,
  $translate       : ng.translate.ITranslateService,
  $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
  blockUI          : ng.blockUI.BlockUIService,
  messages         : any,
  messengerApi     : MessengerService,
  sms              : any,
  templatedWording : any,
  validatePhone    : any
) {
  $scope.block = blockUI.instances.get('smsBlock');
  $scope.sms   = sms;

  $scope.addTemplates = function () {
    templatedWording('sms')
    .then(templates => {
      angular.forEach(templates, template => {
        $scope.sms.message = $scope.sms.message
          ? `${$scope.sms.message}\n\n${template.wording}`
          : template.wording;
      });
    });
  };

  $scope.isPhoneNumberValid = function () {
    return validatePhone.mobile($scope.sms.send_to);
  };

  $scope.send = function () {
    if (!$scope.isPhoneNumberValid()) {
      messages.error($translate.instant('GENERAL_SPACE.ERROR_MESSAGE.MOBILE_NUMBER_NOT_CORRECT'));
    }

    else if ($scope.sms.modalOnly) {
      $uibModalInstance.close($scope.sms);
    }
    else {
      $scope.block.start();

      messengerApi.sendSms($scope.sms)
      .then(() => {
        $uibModalInstance.close($scope.sms);
      })
      .catch(err => {
        messages.error(err);
      })
      .finally(() => {
        $scope.block.stop();
      });
    }
  };
}