import { AttachmentModel }    from './models/attachment';
import { AttachmentsService } from './attachments.service.ajs';

import AttachmentHtml  from './attachment.html';
import { AttachmentThumbnailStatusModel } from './models/attachment.thumbnail-status';

interface AttachmentScope extends ng.IScope {
  attachment        : AttachmentModel;
  isPdf             : () => boolean;
  showAttachment    : () => void;
  thumbnailStatus   : AttachmentThumbnailStatusModel;
  vehicleVisualsIcon: string;
};

export function AttachmentDirective (
  attachmentsApi: AttachmentsService
) : ng.IDirective {
  const directive : ng.IDirective = {
    restrict: 'E',
    scope   : {
      attachment: '='
    },
    templateUrl: AttachmentHtml,
    link ( scope : AttachmentScope ) {
      scope.thumbnailStatus   = {
        isLoading    : true,
        isSuccessful : false,
        percentLoaded: 0
      };

      scope.isPdf = function () {
        return attachmentsApi.isPdf(scope.attachment);
      };

      scope.showAttachment = function () {
        attachmentsApi.showAttachment(scope.attachment);
      };

      attachmentsApi.getThumbnails(scope.thumbnailStatus, scope.attachment);
    }
  };

  return directive;
};
