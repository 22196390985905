import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ControlContainer, NgForm }                from '@angular/forms';

import { TextControlComponent } from '../text-control/text-control.component';

@Component({
  selector   : 'wor-select-control',
  styles: [ require('./select-control.component.scss') ],
  template: require('./select-control.component.html'),

  viewProviders: [
    {
      provide    : ControlContainer,
      useExisting: NgForm
    }
  ]
})
export class SelectControlComponent extends TextControlComponent {
  @Input() placeholder: string;

  @ViewChild('selectControl') control !: ElementRef;

  handleModelChange ( event : any ) {
    this.modelChange.emit(event);

    this.control.nativeElement.blur();
  }
}