export function CoreRoundService (
  bigDecimalApi
) {
  return function (value, roundingVal) {
    var twenty = bigDecimalApi.get('20.00');

    value = bigDecimalApi.get(value);

    if (roundingVal) {
      return value.add(bigDecimalApi.get(roundingVal)).toString();
    }

    return value
      // multiply total by twenty
      .multiply(twenty)
        // Round to nearest integer
        .setScale(0, BigDecimal.ROUND_HALF_UP)
          // set scale back to two decimal places
          .setScale(2, BigDecimal.ROUND_HALF_UP)
            // divide by twenty
            .divide(twenty)
              // ensure scale is at two decimal places
              .setScale(2, BigDecimal.ROUND_HALF_UP).toString();
  };
}