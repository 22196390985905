import 'angular';

import { AddGroupToMatrixLiteCtrl } from './price-matrix.add-group';
import { AddPriceMatrixLiteCtrl }      from './price-matrix.add';
import { PriceMatrixSettingsLiteCtrl } from './price-matrix.settings';

angular.module('app-lite.price-matrix', [])
  .controller('AddGroupToMatrixLiteCtrl', AddGroupToMatrixLiteCtrl)
  .controller('AddPriceMatrixLiteCtrl', AddPriceMatrixLiteCtrl)
  .controller('PriceMatrixSettingsLiteCtrl', PriceMatrixSettingsLiteCtrl);
