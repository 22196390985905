interface ExpandableRowDirectiveScope extends ng.IScope {
  grid        : any;
  currentRow  : any;
  isExpandable: ( row : any ) => boolean;
  items       : Array<any>;
  row         : any;
}

export function ExpandableRowDirective (
  $timeout: ng.ITimeoutService
) : ng.IDirective {
  const directive : ng.IDirective = {
    scope: {
      grid        : '<',
      isExpandable: '&?',
      items       : '<',
      row         : '<'
    },
    link (
      scope  : ExpandableRowDirectiveScope,
      element: ng.IRootElementService,
      attrs  : ng.IAttributes
    ) {
      let clickTimeout;

      scope.$watch('row.entity.id', ( newId, oldId) => {
        if (newId !== oldId) {
          const currentRow = angular.element(`.${ scope.grid.gridId } .ngRow.expanded`);
          const rowHeight  = scope.grid.rowHeight;

          currentRow.height(rowHeight)
          .removeClass('expanded')
          .find('.extra-info')
          .css({
            opacity   : 0,
            visibility: 'hidden'
          });
        }
      });

      element.on('click', ( evt : Event ) => {
        if (scope.isExpandable && !scope.isExpandable(scope.row)) {
          return;
        }

        /**
         * get the currently selected row before the timeout to ensure it is not the
         * one that was just selected.
         *  */
        const currentRow = angular.element(`.${ scope.grid.gridId } .ngRow.expanded`);

        // if there is already a timeout active, cancel it.
        if (clickTimeout) {
          $timeout.cancel(clickTimeout);
        }

        /**
         * if there is a double click functionality on the row, then the row will
         * still expand when double clicked. To prevent this, we wrap the expanding
         * piece in a timeout and check for it on the double click event.
         * If there is a pending timeout then cancel it to prevent the click
         * functionality from firing.
         */
        clickTimeout = $timeout(() => {
          const rowElement = angular.element(`.${scope.grid.gridId } #row${ scope.row.rowIndex }`);
          const rowCount   = scope.items.length;
          const rowHeight  = scope.grid.rowHeight;

          // collapse the currently expanded row if there is one
          if (currentRow.length) {
            currentRow
            .height(rowHeight)
            .removeClass('expanded')
            .find('.extra-info')
            .css({
              opacity   : 0,
              visibility: 'hidden'
            });

            /**
             * if the currently selected row is the one one clicked
             * then it will be collapsed to update the grid height
             * accordingly.
             */
            if (currentRow.is(element.parent())) {
              angular.element(`.${ scope.grid.gridId } .ngCanvas`).height( rowCount * rowHeight );

              return;
            }
          }

          /**
           * this is where we expand the row. It needs to be wrapped in a
           * $timeout to allow the AngularJS cycle to run, ensuring that
           * the data is set and the height is proper.
           */
          $timeout(() => {
            const extraHeight = rowElement.find('.extra-info').outerHeight(true);

            rowElement
            .height(extraHeight + rowHeight)
            .addClass('expanded');

            angular.element(`.${ scope.grid.gridId } .ngCanvas`).height(rowCount * rowHeight + extraHeight);

            rowElement
            .find('.extra-info')
            .css({
              left      : 0,
              opacity   : 0,
              visibility: 'visible'
            })
            .animate({
              opacity: 1.0
            }, 500);
          });
        }, 200);
      });

      /**
       * here is where we check for any pending click timeouts
       * to cancel.
       */
      element.on('dblclick', ( event : Event ) => {
        if (clickTimeout) {
          $timeout.cancel(clickTimeout);
        }
      });
    }
  };

  return directive;
}
