import { CustomerSourceModel }  from './models/customer-source';
import { PaymentMethodModel }   from './models/payment-method';
import { ProductCategoryModel } from './models/product-category';
import { TemplateModel }        from './models/template';

export function CompanyListsCtrl (
  $scope              : any,
  customerSources     : Array<CustomerSourceModel>,
  documentTemplates   : Array<TemplateModel>,
  emailTemplates      : Array<TemplateModel>,
  invoiceNoteTemplates: Array<TemplateModel>,
  jobCardTemplates    : Array<TemplateModel>,
  paymentMethods      : Array<PaymentMethodModel>,
  productCategories   : Array<ProductCategoryModel>,
  productGroups       : any,
  sessionObjAPI       : any,
  smsTemplates        : Array<TemplateModel>,
  unitForSaleTemplates: Array<TemplateModel>
) {
  $scope.customerSources       = customerSources;
  $scope.documentTemplates     = documentTemplates;
  $scope.emailTemplates        = emailTemplates;
  $scope.jobCardTemplates      = jobCardTemplates;
  $scope.loaded                = true;
  $scope.paymentMethods        = paymentMethods;
  $scope.productCategories     = productCategories;
  $scope.productGroups         = productGroups;
  $scope.session               = sessionObjAPI.base();
  $scope.smsTemplates          = smsTemplates;
  $scope.invoiceNoteTemplates  = invoiceNoteTemplates;
  $scope.unitForSaleTemplates  = unitForSaleTemplates;
};