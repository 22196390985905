import 'angular';

import { VendorBranchPaymentLiteCtrl }          from './vendor-payment.branch';
import { VendorBranchPaymentsDirective }        from './vendor-payments.branch.directive';
import { VendorBranchPaymentService }           from './vendor-payment.branch.service';
import { VendorBranchPaymentsLiteCtrl }         from './vendor-payments.branch';
import { VendorPaymentLiteCtrl }                from './vendor-payment';
import { VendorPaymentsService }                from './vendor-payments.service.ajs';

angular.module('app-lite.vendor-payments', [])
  .controller('VendorBranchPaymentLiteCtrl', VendorBranchPaymentLiteCtrl)
  .controller('VendorBranchPaymentsLiteCtrl', VendorBranchPaymentsLiteCtrl)
  .controller('VendorPaymentLiteCtrl', VendorPaymentLiteCtrl)
  .directive('vendorBranchPayments', VendorBranchPaymentsDirective)
  .factory('VendorBranchPayment', VendorBranchPaymentService)
  .factory('vendorPaymentsApi', VendorPaymentsService);
