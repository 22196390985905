import 'angular';

import '../core/core.module.ajs';

import { ActivateChargifyCtrl}            from './chargify.activate.ajs';
import { ChargifyServiceAjs }             from './chargify.service.ajs';
import { ExportChargifyCancelationsCtrl } from './chargify.export-cancelations';

angular.module('app-lite.chargify', [
  'app-lite.core'
]).controller('ActivateChargifyCtrl', ActivateChargifyCtrl)
  .controller('ExportChargifyCancelationsCtrl', ExportChargifyCancelationsCtrl)
  .service('chargifyApi', ChargifyServiceAjs);
