export function LinkedCompaniesLookupLiteCtrl ($scope, $translate, companies) {
  $scope.companies     = companies;
  $scope.selectedItems = [];

  $scope.tableSettings = {
    columns: [
      {
        classes: 'text-left',
        field  : 'store_name',
        header : {
          text: $translate.instant('GENERAL_SPACE.FIELD.NAME')
        }
      }
    ],
    empty: {
      text: $translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_COMPANIES')
    },
    pagination: {
      enabled: false
    },
    rows: {
      highlight: {
        enabled: true
      },
      select: {
        enabled: true
      }
    },
    sorting: {
      enabled: false
    },
  };

  $scope.onRowClick = function () {
    $scope.selectedItems = $scope.companies.filter((row) => row.selected);
  };

  $scope.onRowDoubleClick = function (event) {
    $scope.$close(event.row);
  };

  $scope.confirm = function () {
    $scope.$close($scope.selectedItems[0]);
  };
}
