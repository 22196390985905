import angular from 'angular';

import '../../vendor/jquery-csv/jquery-csv-0.71.min';

import ImportProgressHtml from './import.progress.html';

export function ImportLiteCtrl (
  $compile,
  $interval,
  $uibModal,
  $scope,
  $translate,
  API,
  messages,
  sessionObjAPI,
  workshopAPI
) {
  // An extremely poor hack to default all phone type columns to extract_numeric
  $scope.phoneColumns = [
    'contact_phone',
    'phone',
    'mobile',
    'fax',
    'contact1_phone',
    'contact2_phone'
  ];

  // Specifies whether or not the csv file contains column names in the first row
  $scope.has_headers     = true;
  // The structure we will build for the transformation
  $scope.importStructure = [];
  $scope.loaded          = true;
  $scope.percentage      = 0;
  $scope.company         = sessionObjAPI.company();

  // Add a new column to the structure
  $scope.addColumn = function () {
    // Add a new column based on the column selected from the dropdown
    $scope.theirTransformedColumns.push({
      name  : $scope.newColumn.name,
      values: $scope.newColumn.values
    });

    // Also add it to the importStructure with the new index
    $scope.importStructure.push({
      action    : '',
      ours      : '',
      theirs    : ($scope.newColumn.index),
      user_input: []
    });

    // Reset the dropdown value
    $scope.newColumn = '';

    // Let the chosen plugin know that the value has changed
    $(`[ng-controller="${ $scope.controllerName }"] #select_column`).trigger('chosen:updated');
    $(`[ng-controller="${ $scope.controllerName }"] #select_column_div`).hide();

    if (!$scope.$$phase) {
      $scope.$apply();
    }
  };

  // Runs when they have clicked the analyze button after a file has been
  // selected.
  $scope.analyze = function () {
    var ext = $(`[ng-controller="${ $scope.controllerName }"] input#${$scope.fileUploadID}`)
    .val()
    .split('.')
    .pop()
    .toLowerCase();

    // Verify it is a csv file. If not, throws an error
    if($.inArray(ext, ['csv']) == -1) {
      messages.show($translate.instant('JS_SPACE.IMPORTS.INVALID_FILE_TYPE'), 'error');

      return false;
    }

    // Set flag so user sees sprite
    $scope.loading = true;

    // Get the file, then read it using HTML5's FileReader
    const file = document.getElementById($scope.fileUploadID).files[0];

    if ( file !== undefined ) {
      const reader = new FileReader();

      reader.onloadend = ( e ) => {
        // Unset flag
        $scope.loading = false;

        // Once its fully loaded, save the string, convert it
        // to an array of arrays, then build the grid
        $scope.csvString = e.target.result;

        // $scope.csvArray = $scope.CSVToArray(e.target.result, ',');
        $scope.csvArray = $.csv.toArrays(e.target.result);

        $scope.buildGrid();
      };

      // Start the reading
      reader.readAsText(file);
    }
    else {
      messages.show($translate.instant('JS_SPACE.IMPORTS.ERROR_READING_FILE'), 'error');

      return false;
    }
  };

  // Runs everytimethe after the file has been read OR when the checkbox
  // changes indicating whether or not the first row contains column names
  $scope.buildGrid = function() {
    if (!$scope.csvArray) {
      return;
    }

    // Third column, our column names, an example of the data and the
    // requirements
    $scope.ourTransformedColumns = [];

    // First column, their column name plus the first couple records
    // as an example
    $scope.theirTransformedColumns = [];

    // Their actual column names needs to be stored seperately for the
    // new column dropdown so new columns don't keep getting appended to
    // the dropdown as well as the grid
    $scope.theirColumns = [];

    // Reset the importStructure
    $scope.importStructure = [];

    // Now lets loop through the csv array and manipulate each array inside of it
    for ( let i = 0; i < $scope.csvArray[0].length ; i++ ) {

      // If first row contains column names
      if ($scope.has_headers) {

        // Each column gets an object in our import structure with empty data except
        // 'theirs'. Their column names don't matter on the backend so just specify the
        // index in the row
        $scope.importStructure.push({
          action    : 'ignore_column',
          ours      : '',
          theirs    : i,
          user_input: []
        });

        // BUT their column needs to display the name so do the same thing as the
        // import structure but display the name instead of the index
        $scope.theirTransformedColumns.push({
          name  : $scope.csvArray[0][i],
          values: []
        });

        // Build a list of column names for the new column dropdown
        $scope.theirColumns.push({
          index : i,
          name  : $scope.csvArray[0][i],
          values: []
        });

        let x = 1;

        // No we need to loop through the csv array to get some example data.
        // Get the first four records after the column names or all the records,
        // whichever comes first
        while ( x <= 4 && x < $scope.csvArray.length ) {
          $scope.theirTransformedColumns[i].values.push($scope.csvArray[x][i]);
          $scope.theirColumns[i].values.push($scope.csvArray[x][i]);

          x++;
        }
      }

      // If first row doesn't contain column names
      else {
        // Same as above, except we create a column row, with fake names to display
        $scope.importStructure.push({
          theirs    : i,
          ours      : '',
          action    : 'ignore_column',
          user_input: []
        });

        $scope.theirTransformedColumns.push({
          name  : `Column${ i + 1 }`,
          values: []
        });

        $scope.theirColumns.push({
          index : i,
          name  : `Column${ i + 1 }`,
          values: []
        });

        let x = 0;

        // This is the same too except we can start at 0 since their are no
        // column names to worry about
        while ( x < 4 && x < $scope.csvArray.length ) {
          $scope.theirTransformedColumns[i].values.push($scope.csvArray[x][i]);
          $scope.theirColumns[i].values.push($scope.csvArray[x][i]);

          x++;
        }
      }
    }

    if (!$scope.$$phase) {
      $scope.$apply();
    }
  };

  // If they cancel the import
  $scope.cancelImport = function () {
    workshopAPI.get('/imports/cancel_import')
    .then(() => {
      $scope.percentage = 0;

      $interval.cancel($scope.progress);

      $scope.progressDialog.close();

      messages.show($translate.instant('JS_SPACE.IMPORTS.IMPORT_CANCELED'), 'success');
    });
  };

  // When the + button is pressed. Show the dropdown
  // and make sure the plug in knows it should be blank
  $scope.chooseColumn = function () {
    $(`[ng-controller="${ $scope.controllerName }"] #select_column_div`).show();
    $(`[ng-controller="${ $scope.controllerName }"] #select_column`).trigger('chosen:updated');
  };

  // When a row is removed, we hide it for the user
  // but don't delete it from the import structure
  // because we don't wanna throw off indexes
  // so put ignore into the action so the server will ignore it
  $scope.ignore = function (index) {
    $scope.importStructure[index].action = 'ignore_column';

    $(`[ng-controller="${ $scope.controllerName }"] [index=${index}]`).hide();

    if (!$scope.$$phase) {
      $scope.$apply();
    }
  };

  // Once the structure is built and ready to import, the button
  // is click and this function runs.
  $scope.import = function () {
    const errors = [];

    let hasErrors = false;

    for ( let i = 0 ; i < $scope.importStructure.length ; i++ ) {
      if ($scope.importStructure[i].action
        && $scope.importStructure[i].action !== 'ignore_column'
        && !$scope.importStructure[i].ours
      ) {
        hasErrors = true;

        errors.push(i);
      }
    }

    if (hasErrors) {
      for ( let i = 0 ; i < errors.length ; i++ ) {
        $(`[index=${errors[i]}] [chosen="ourColumns"]`)
        .next()
        .addClass('import-invalid');
      }

      messages.show($translate.instant('JS_SPACE.IMPORTS.SELECT_OR_SPECIFY_COLUMNS'), 'error');

      return;
    }

    $scope.progressDialog = $uibModal.open({
      templateUrl: ImportProgressHtml
    });

    $scope.progressDialog
    .result
    .catch(() => {
      $scope.cancelImport();
    });

    // Get the csv file used
    const file = document.getElementById($scope.fileUploadID).files[0];

    // Stringify our import structure so we can pass it as a
    // multipart/formdata call
    const stringedStructure = JSON.stringify($scope.importStructure);

    let form = new FormData;

    // Specify whether there are column names included
    // in the first row so that row can be skipped if needed
    form.append('has_headers', $scope.has_headers);

    // Pass the stringed structure
    form.append('import_structure', stringedStructure);

    // Now finally the file
    form.append('csv_file', file);

    if ( angular.isFunction($scope.prepFormForImport) ) {
      form = $scope.prepFormForImport(form);
    }

    $scope.xhr = new XMLHttpRequest;

    $scope.xhr.onloadend = function (e) {
      $scope.percentage = 0;

      $interval.cancel($scope.progress);

      $scope.progressDialog.close();

      console.log(e);

      if (e.target.status !== 200) {
        messages.error(JSON.parse(e.target.responseText));
      }
      else {
        messages.show(e.target.responseText, 'success');
      }
    };

    $scope.xhr.onabort = function (e) {
      $scope.percentage = 0;

      $interval.cancel($scope.progress);

      $scope.progressDialog.close();

      console.log(e);

      messages.show($translate.instant('JS_SPACE.IMPORTS.FAILED'), 'error');
    };

    $scope.xhr.onerror = function (e) {
      $scope.percentage = 0;

      $interval.cancel($scope.progress);

      $scope.progressDialog.close();

      console.log(e);

      messages.show($translate.instant('JS_SPACE.IMPORTS.FAILED'), 'error');
    };

     // Start the call
    $scope.xhr.open('POST', `${ API.url }${ $scope.importUrl }`, true);

    $scope.xhr.withCredentials = true;

    // Send the formdata
    $scope.xhr.send(form);

    $scope.progress = $interval(() => {
      workshopAPI.get('/imports/import_progress')
      .then(progress => {
        $scope.percentage = ( parseFloat(progress) * 100 ).toFixed(1);

        if (!$scope.$$phase) {
          $scope.$apply();
        }
      });
    }, 2000);
  };

  // When the user selected one of our columns to use for a row,
  // this function is called
  $scope.moveToStructure = function (index) {

    // Moves the column selected into the import structure
    $scope.importStructure[index].ours = $scope.ourTransformedColumns[index].name;

    // Default fix it to "take as is"
    if ($scope.importStructure[index].action === 'ignore_column') {

      // If a the selected column is in my list of phone number columns, default to extract numbers
      if ($scope.phoneColumns.indexOf($scope.ourTransformedColumns[index].name) >= 0) {
        $scope.importStructure[index].action = 'extract_numeric';
      }

      // Otherwise give the standard default
      else {
        $scope.importStructure[index].action = 'leave_as_is';
      }
    }

    if (!$scope.$$phase) {
      $scope.$apply();
    }
  };

  // This is a bit of a tricky call but is used when a transform function is selected on a row
  $scope.showEquation = function (index) {

    // Find the div where the equation shows and make sure its blank
    $(`[ng-controller="${ $scope.controllerName }"] [index=${index}]`)
    .find('.equation')
    .find('div')
    .replaceWith('<div></div>');

    // Clear out any previously entered values for equations
    $scope.importStructure[index].user_input = [];

    // find the object matching the selected equation so we can get params and such
    const match = $scope.transformFunctions.filter(f => f.action === $scope.importStructure[index].action)[0];

    // If there is an equation...
    if (match.equation) {
      let equation = match.equation;

      // Make sure there are params
      if (match.param_count > 0) {

        // Loop through the params based on the specified count
        for ( let i = 0 ; i < match.param_count ; i++ ) {

          // For each parameter replace the flag string with an input box bound to the indexed uer_input array in the import structure
          equation = equation.replace(
            '{param'+(i+1)+'}',
            `<input class="paraminputs" id="param${ i + 1 }" ng-model="importStructure[${ index }].user_input[{2}]" />`
          );
        }

        // Now add an outer div to the dynamic equation html
        equation = `<div>${ equation }</div>`;

        // Finally insert it under the dropdown and...
        // most importantly, compile the html so it can be bootstrapped properly and the
        // ng-models will work
        $(`[ng-controller="${ $scope.controllerName }"] [index=${ index }]`)
        .find('.equation')
        .find('div')
        .replaceWith($compile(equation)($scope));
      }
    }

    console.log(equation);
  };
};