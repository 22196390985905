import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid';

import { BookingsService }    from '../bookings/bookings.service';
import { CustomerService }    from '../customers/customer.service';
import { DashboardComponent } from './dashboard.component';
import { InvoiceService }     from '../invoices/invoice.service';
import { JobsService }        from '../jobs/jobs.service';
import { QuotesService }      from '../quotes/quotes.service';
import { VehiclesService }    from '../vehicles/vehicles.service';

export const DashboardStates : Array<NgHybridStateDeclaration> = [
  {
    component: DashboardComponent,
    name     : 'ajs.app.private.dashboard',
    resolve  : [
      {
        deps     : [ BookingsService ],
        resolveFn: ( bookingsService : BookingsService ) => bookingsService.getBookings(),
        token    : 'bookings'
      },
      {
        deps     : [ CustomerService ],
        resolveFn: ( customerApi : CustomerService ) => customerApi.getDetails(),
        token    : 'customer'
      },
      {
        deps     : [ InvoiceService ],
        resolveFn: ( invoiceService : InvoiceService ) => invoiceService.getInvoices(),
        token    : 'invoices'
      },
      {
        deps     : [ JobsService ],
        resolveFn: ( jobsService : JobsService ) => jobsService.getJobs(),
        token    : 'jobs'
      },
      {
        deps     : [ InvoiceService ],
        resolveFn: ( invoiceService : InvoiceService ) => invoiceService.getOutstandingInvoices(),
        token    : 'outstandingInvoices'
      },
      {
        deps     : [ QuotesService ],
        resolveFn: ( quotesService : QuotesService ) => quotesService.getQuotes(),
        token    : 'quotes'
      },
      {
        deps     : [ VehiclesService ],
        resolveFn: ( vehiclesService : VehiclesService ) => vehiclesService.getVehicles(),
        token    : 'vehicles'
      },
      {
        deps     : [ VehiclesService ],
        resolveFn: ( vehiclesService : VehiclesService ) => vehiclesService.getDueDates(),
        token    : 'vehiclesDue'
      }
    ],
    url: '/dashboard'
  }
];
