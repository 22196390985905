interface VideoLoaderScope extends ng.IScope {
  onLoad: () => void;
}

export function VideoLoaderDirective (
  $timeout: ng.ITimeoutService
) : ng.IDirective {
  const directive : ng.IDirective = {
    restrict: 'A',
    scope   : {
      onLoad: '&'
    },
    link ( scope : VideoLoaderScope, element : ng.IRootElementService, attrs : ng.IAttributes ) {
      if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        $timeout(() => scope.onLoad());
      }
      else {
        element[0].addEventListener('loadeddata', () => {
          return $timeout(() => {
            scope.onLoad();

            /**
             * if the responsive attribute is present
             * resize the video to fit the screen
             */
            if (angular.isDefined(attrs.responsive)) {
              $timeout(() => {
                const totalHeight  = $('html').height();
                const videoHeight  = element.height();
                const videoOffset  = element.offset();
                const windowHeight = $(window).height();

                const bottomPadding = totalHeight - videoHeight - videoOffset.top;

                element.height(windowHeight - videoOffset.top - bottomPadding);
              });
            }

          });
        }, false);
      }
    }
  };

  return directive;
}
