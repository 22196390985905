import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

import { KEYCODES } from '../../../../app-lite/core/constants/core.keycodes.constant.ajs';

@Component({
  selector   : 'wor-search-control',
  styles: [require('./search-control.component.scss')],
  template: require('./search-control.component.html')
})
export class SearchControlComponent {
  @Input() name        = 'search';
  @Input() placeholder = 'Search...';

  @Output() clear : EventEmitter<null>   = new EventEmitter<null>();
  @Output() search: EventEmitter<string> = new EventEmitter<string>();

  @HostListener('keydown', ['$event']) handleKeypressEvent (event: KeyboardEvent): void {

    if (event.keyCode === KEYCODES.enter) {

      // if there is a query value, assume the
      // user wants to search. If not, assume
      // they want to clear.
      this.query ? this.onSearch() : this.onClear();
    }
  }

  // represents if the current state is
  // search results.
  isSearch: boolean;

  // the search query.
  query   : string;

  onClear (): void {
    this.isSearch = false;
    this.query    = null;

    this.clear.emit();
  }

  onSearch (): void {
    if (this.query) {
      this.isSearch = true;

      this.search.emit(this.query);
    }
  }
};

