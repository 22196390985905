export function CoreTypeaheadEditableCellDirective (
  $document,
  $timeout,
  KEYCODES
) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, element, attrs, ctrl) {
      var _lastClicked, _context, _row, _originalModel, _resolving;

      scope.$on('ngGridEventStartCellEdit', function () {
        element.focus();
      });

      $document.mousedown(function(e) {
        // The latest element clicked
        _lastClicked = $(e.target);
      });

      $document.keydown(function(e) {
        // The latest element clicked
        _lastClicked = $(e.target);
      });

      // when '_lastClicked == null' on blur, we know it was not caused by a click
      // but maybe by pressing the tab key
      $document.mouseup(function(e) {
        _lastClicked = null;
      });

      element.on('blur', function(e) {
        if (element.parent().find(_lastClicked).length < 1) {
          scope.$emit('ngGridEventEndCellEdit')
        }
      });

      element.on('keydown', function (e) {
        var keys = KEYCODES;

        /**
         * FIRST CASE
         * If the user has tabbed out of the field
         * AND there is a set _context for the search
         * object AND no item has been selected yet
         * AND there is a quickSearch function,
         * do the quick search and choose the item
         * if there is one found
         *
         * SECOND CASE
         * if the user has tabbed out of the field and
         * there IS an item selected already BUT
         * the new value for the field is different from the old
         * then we need to do a quick search as well.
         */
        if ( (e.keyCode === keys.tab &&
          !e.shiftKey &&
          _context &&
          !_row.entity[_context.identifier] &&
          _context.quickSearch) || // first case
          (e.keyCode  === keys.tab && //second case
          _row.entity[_context.identifier] &&
          _originalModel !== ctrl.$modelValue &&
          _context.quickSearch
        )) {
          _resolving = true;
          _context.quickSearch(ctrl.$modelValue).then(function (item) {
            scope.choose(item, _row, _context);
          }).finally(function () {
            _resolving = false;
          });

          e.preventDefault();
          e.stopPropagation();
        }
        // If we already have an item selected, just tab normally.
        else if (e.keyCode === keys.tab && _row.entity[attrs.identifier]) {
          $timeout(function () {
            scope.$emit('ngGridEventEndCellEdit');
          });
        }
        // Stop propagation on arrows so ng-grid doesnt ruin my life
        else if (e.keyCode === keys.left ||
          e.keyCode === keys.right ||
          e.keyCode === keys.up ||
          e.keyCode === keys.down
        ) {
          e.stopPropagation();
        }
        // "Enter" and "Home" do weird things when were editing these fields, not on my watch
        else if (e.keyCode === keys.home
          || e.keyCode === keys.enter)
        {
          e.preventDefault();
        }
      });

      /**
       * Called on input focus. Sets the _context of the
       * typeahead (the search object that it is
       * using from the controller) and keeps a reference
       * to the original data.
       */
      scope.init = function(context, row) {
        _context = context;
        _row = row;

        if (_row.entity[_context.identifier]) {
          _originalModel = ctrl.$modelValue;
        }
      };

      scope.choose = function (item, row, type) {
        var parent = $('.ngCell').has(element);
        type.choose(item, row);

        $timeout(function () {
          scope.$emit('ngGridEventEndCellEdit', true);
          parent.nextAll().has('[ng-cell-has-focus]:not(.disabled)').find('[ng-cell-has-focus]').first().trigger('click');
        });
      };

      scope.$on('ngGridEventEndCellEdit', function (evt, allowUpdate) {
        /**
         * If we have a _context and no item has been
         * selected, clear out any typed value
         */
        if (_context && !_row.entity[_context.identifier]) {
          ctrl.$setViewValue('');
          ctrl.$render();
        }

        /**
         * if there is an item that has been selected AND
         * the original value was not blank (an item was
         * selected before this focus) AND the original
         * value doesn't match the current, reset the value
         * to the original because evidentally a new item
         * was not found this time.
         */
        else if (_context &&
          !allowUpdate &&
          _row.entity[_context.identifier] &&
          _originalModel &&
          _originalModel !== ctrl.$modelValue
        ) {
          ctrl.$setViewValue(_originalModel);
          ctrl.$render();
        }
      });

    }
  };
}