import CompanyListsTemplatesHtml from './company-lists.templates.html';

export function CompanyListsTemplatesDirective () : ng.IDirective {
  const directive : ng.IDirective = {
    controller : 'CompanyListsTemplatesCtrl',
    restrict   : 'E',
    templateUrl: CompanyListsTemplatesHtml,
    scope      : {
      templates: '=',
      title    : '@?',
      type     : '@?'
    }
  };

  return directive;
};
