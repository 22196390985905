import { NgModule }              from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { SharedModule }    from '../shared/shared.module';
import { SignUpComponent } from './sign-up.component';
import { SignUpService }   from './sign-up.service';
import { SignUpStates }    from './sign-up.states';

@NgModule({
  declarations: [
    SignUpComponent
  ],
  imports: [
    SharedModule,
    UIRouterUpgradeModule.forChild({ states : SignUpStates })
  ],
  providers: [
    SignUpService
  ]
})
export class SignUpModule {}
