import { Component, OnInit }    from '@angular/core';
import { Transition, UIRouter } from '@uirouter/core';

import { ApiErrorModel }        from '../core/api/models/api.error';
import { ErrorHandlingService } from '../core/errors/error-handling.service';
import { SignUpService }        from './sign-up.service';
import { UserAccountModel }     from '../users/models/user.account';

@Component({
  styles: [ require('./sign-up.component.scss') ],
  template: require('./sign-up.component.html')
})
export class SignUpComponent implements OnInit {
  account: UserAccountModel = {};

  constructor (
    private errorHandlingService: ErrorHandlingService,
    private router              : UIRouter,
    private signUpService       : SignUpService,
    private transition          : Transition
  ) {}

  ngOnInit () {
    this.account.token = this.transition.params().token;
  }

  signUp () {
    this.signUpService.signUp(this.account)
    .then(() => {
      this.router.stateService.go('ajs.app.private.dashboard');
    })
    .catch(( err : ApiErrorModel ) => {
      this.errorHandlingService.show(err);
    });
  }
};
