import { TemplateModel } from './models/template';

interface TemplateScope extends ng.IScope {
  filterHtml: () => void;
  regoLabel : string;
  save      : () => void;
  template  : TemplateModel;
}

export function TemplateCtrl (
  $uibModalInstance : any,
  $rootScope     : any,
  $scope         : TemplateScope,
  messages       : any,
  sessionObjAPI  : any,
  template       : TemplateModel,
  workshopAPI    : any
) {
  $scope.regoLabel = $rootScope.Company.company.company_variable_fields.plate_number_name || sessionObjAPI.countrySettings().rego_label;
  $scope.template  = template;

  $scope.filterHtml = function () {
    $scope.template.wording
    ? $scope.template.wording = String($scope.template.wording).replace(/<[^>]+>/gm, '')
    : $scope.template.wording = '';
  };

  $scope.save = function () {
    const method = $scope.template.id ? 'patch' : 'post';
    const url    = $scope.template.id ? '/templated_wording' : '/templated_wordings';

    workshopAPI[method](url, $scope.template, 'templated_wording')
    .then(( template : TemplateModel ) => {
      $uibModalInstance.close(template);
    })
    .catch(err => {
      messages.error(err);
    });
  };

  if ($scope.template.wording_type === 'sms') {
    $scope.filterHtml();

    $scope.$watch('template.wording', ( value : string ) => {
      if (value && value.length > 160) {
        $scope.template.wording = value.substring(0, 160);
      }
    });
  }
}
