import { ApiServiceAjs } from '../api/api.service.ajs';

import CustomConfirmationHtml    from './confirmation.custom.html';
import GenericConfirmationHtml   from './confirmation.generic.html';
import HardConfirmationHtml      from './confirmation.hard.html';
import NotifyConfirmationHtml    from './confirmation.notify.html';
import QuestionConfirmationHtml  from './confirmation.question.html';
import RememberConfirmationHtml  from './confirmation.remember.html';
import SaveConfirmationHtml      from './confirmation.save.html';
import SubscribeConfirmationHtml from './confirmation.subscribe.html';
import TextConfirmationHtml      from './confirmation.text.html';

export class ConfirmService {
  $q         : ng.IQService;
  $uibModal  : ng.ui.bootstrap.IModalService;
  workshopAPI: ApiServiceAjs
  constructor (
    $q         : ng.IQService,
    $uibModal  : ng.ui.bootstrap.IModalService,
    workshopAPI: ApiServiceAjs
  ) {
    this.$q          = $q;
    this.$uibModal   = $uibModal;
    this.workshopAPI = workshopAPI;
  }

  custom ( options : any ) : ng.IPromise<any> {
    return this.$uibModal.open({
      controller: 'CustomConfirmationLiteCtrl',
      resolve   : {
        options: () => options
      },
      templateUrl: CustomConfirmationHtml,
    }).result;
  }

  generic ( message : string, type ?: string, options : any = {} ) : ng.IPromise<any> {
    options = angular.extend({
      cancelText : 'GENERAL_SPACE.SWITCH.NO',
      confirmText: 'GENERAL_SPACE.SWITCH.YES',
      size       : 'md'
    }, options);

    return this.$uibModal.open({
      controller: 'GenericConfirmationLiteCtrl',
      resolve   : {
        message: () => message,
        options: () => options,
        type   : () => type
      },
      size       : options.size,
      templateUrl: GenericConfirmationHtml,
      windowClass: `${ options.size }-modal`
    }).result
    .catch(() => this.$q.reject());
  }

  hard ( message : string, type : string ) : ng.IPromise<void> {
    return this.$uibModal.open({
      controller: 'HardConfirmationLiteCtrl',
      resolve   : {
        message: () => message,
        type   : () => type
      },
      templateUrl: HardConfirmationHtml
    }).result.catch(() => this.$q.reject());
  }

  notify ( options : any ) : ng.IPromise<void> {
    return this.$uibModal.open({
      controller: 'NotifyConfirmationLiteCtrl',
      resolve   : {
        options: () => options
      },
      templateUrl: NotifyConfirmationHtml,
    }).result;
  }

  question ( message : string, options : any = {} ) : ng.IPromise<boolean> {

    return this.$uibModal.open({
      controller: 'QuestionConfirmationLiteCtrl',
      resolve   : {
        icon   : () => options.icon,
        message: () => message,
        title  : () => options.title
      },
      templateUrl: QuestionConfirmationHtml
    }).result;
  }

  remember ( message : string, type : string, storageName : string ) : ng.IPromise<void> {
    return localStorage[storageName]
      ? this.$q.when()
      : this.$uibModal.open({
      controller: 'RememberConfirmationLiteCtrl',
      resolve   : {
        message    : () => message,
        storageName: () => storageName,
        type       : () => type
      },
      templateUrl: RememberConfirmationHtml,
    }).result;
  }

  save ( message : string, type ?: string ) : ng.IPromise<any> {
    return this.$uibModal.open({
      controller: 'SaveConfirmationLiteCtrl',
      resolve   : {
        message: () => message,
        type   : () => type
      },
      templateUrl: SaveConfirmationHtml,
    }).result;
  }

  subscribe ( message : string, options : any ) : ng.IPromise<any> {
    return this.$uibModal.open({
      controller: 'SubscribeConfirmationLiteCtrl',
      resolve   : {
        message: () => message,
        options: () => options,
        quote  : () => this.workshopAPI.get(options.routes.quote)
      },
      templateUrl: SubscribeConfirmationHtml,
    }).result.catch(() => this.$q.reject());
  }

  textConfirm ( message : string, phrase : string ) : ng.IPromise<void> {
    return this.$uibModal.open({
      controller: 'TextConfirmationLiteCtrl',
      resolve   : {
        message: () => message,
        phrase : () => phrase
      },
      templateUrl: TextConfirmationHtml,
    }).result;
  }
}