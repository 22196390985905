import { Injectable } from '@angular/core';

import { DateDelimitersModel } from './models/date.delimiters';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  DELIMITERS : DateDelimitersModel = {
    default: {
      adapter: '-',
      parser : '/'
    },
    short: {
      adapter: '/',
      parser : '/'
    }
  };

  containsDelimiter ( date : string ) : boolean {
    let doesContain = false;

    if (!date) {
      return false;
    }

    for ( const key in this.DELIMITERS) {
      for ( const nestedKey in this.DELIMITERS[key]) {
        if (date.indexOf(this.DELIMITERS[key][nestedKey]) > -1) {
          doesContain = true;

          break;
        }
      }
    }

    return doesContain;
  }

  pad ( number: number ) : string {
    return number < 10
    ? `0${ number }`
    : number.toString();
  }
}
