import 'angular';

import { downgradeInjectable } from '@angular/upgrade/static';

import { JobStatus }                      from './transaction-center.jobs.status.filter.ajs';
import { JobStatusBooking }               from './transaction-center.jobs.status-booking.filter.ajs';
import { JobStatusBookingPipe }           from './transaction-center.jobs.status-booking.pipe';
import { JobStatusPipe }                  from './transaction-center.jobs.status.pipe';
import { PrintJobsCtrl }                  from './transaction-center.jobs.print.ajs';
import { SendJobBulkSmsCtrl }             from './transaction-center.jobs.send-bulk-sms.ajs';
import { TransactionCenterJobsComponent } from './transaction-center.jobs.component.ajs';
import { TransactionCenterJobsCtrl }      from './transaction-center.jobs.ajs';

angular.module('app-lite.transaction-center.jobs', [])
  .component('worTcJobs', TransactionCenterJobsComponent)

  .controller('PrintJobsCtrl',             PrintJobsCtrl)
  .controller('SendJobBulkSmsCtrl',        SendJobBulkSmsCtrl)
  .controller('TransactionCenterJobsCtrl', TransactionCenterJobsCtrl)

  .filter('jobStatus',        JobStatus)
  .filter('jobStatusBooking', JobStatusBooking)

  .service('jobStatusPipe',        downgradeInjectable(JobStatusPipe))
  .service('jobStatusBookingPipe', downgradeInjectable(JobStatusBookingPipe));
