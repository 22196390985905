export function CoreEditableCellDirective ($timeout, KEYCODES) {
  return {
    restrict: 'A',
    link: function (scope, element) {

      element.keydown(function(e) {
        var keyCode = e.keyCode || e.which;

        if (keyCode === KEYCODES.tab && !e.shiftKey) {
          var parent = $('.ngCell').has(element);

          e.preventDefault();

          $timeout(function () {
            scope.$emit('ngGridEventEndCellEdit');
            parent.nextAll().has('[ng-cell-has-focus]').find('[ng-cell-has-focus]').has('[editable="true"]').first().trigger('click');
          });
        }
      });

    }
  };
}