export function CoreToProperCaseService () {
  return function (s) {
    s = s.replace(/_/g, ' ');

    const words = s.split(' ');

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    words.join(' ');

    return words.join(' ');
  };
}