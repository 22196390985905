import { NgModule } from '@angular/core';

import { ApiModule }      from './api/api.module';
import { ErrorsModule }   from './errors/errors.module';
import { SessionsModule } from './sessions/sessions.module';

@NgModule({
  imports: [
    ApiModule,
    ErrorsModule,
    SessionsModule
  ]
})
export class CoreModule {}
