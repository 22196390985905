import { Injectable }                            from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { DateService } from './date.service';

@Injectable()
export class ShortDateParserFormatter implements NgbDateParserFormatter {

  readonly DELIMITER = this.dateService.DELIMITERS.short.parser;

  constructor (
    private dateService: DateService
  ) {}

  parse ( value : string ) : NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);

      return {
        day  : 1,
        month: parseInt(date[0], 10),
        year : parseInt(date[1], 10)
      };
    }

    return null;
  }

  format ( date : NgbDateStruct | null ) : string {
    return date ? `${ this.dateService.pad(date.month) }${ this.DELIMITER }${ date.year }` : '';
  }
}
