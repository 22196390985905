import { ApiServiceAjs }      from '../api/api.service.ajs';
import { ChargifyQuoteModel } from './models/chargify.quote';
import { ChargifyServiceAjs } from './chargify.service.ajs';
import { CodeTypeModel }      from '../core/models/code-type';
import { RecpatchaService }   from '../recaptcha/recaptcha.service';
import { SessionServiceAjs }  from '../sessions/session.service.ajs';

import CapricornMemberTabHtml from './templates/capricorn-member.tab.html';
import PayByCardTabHtml       from './templates/pay-by-card.tab.html';

interface ActivateChargifyScope extends ng.IScope {
  activate                    : () => void;
  block                       : ng.blockUI.BlockUIService;
  countries                   : Array<CodeTypeModel>;
  getAnotherTrial             : () => void;
  getStates                   : () => void;
  getSubscription             : () => void;
  months                      : Array<number>;
  states                      : Array<CodeTypeModel>;
  subscription                : any;
  tabs                        : Array<any>;
  taxName                     : string;
  toggleBronzeMonthlyRenewal  : () => void;
  toggleBronzeYearlyRenewal   : () => void;
  toggleSilverMonthlyRenewal  : () => void;
  toggleSilverYearlyRenewal   : () => void;
  toggleGoldMonthlyRenewal    : () => void;
  toggleGoldYearlyRenewal     : () => void;
  togglePlatinumMonthlyRenewal: () => void;
  togglePlatinumYearlyRenewal : () => void;
  years                       : Array<number>;
}

export function ActivateChargifyCtrl (
  $rootScope       : any,
  $scope           : ActivateChargifyScope,
  $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
  blockUI          : ng.blockUI.BlockUIService,
  chargifyApi      : ChargifyServiceAjs,
  countries        : Array<CodeTypeModel>,
  dateParsing      : any,
  messages         : any,
  quotes           : ChargifyQuoteModel,
  recaptchaApi     : RecpatchaService,
  sessionObjAPI    : SessionServiceAjs,
  workshopAPI      : ApiServiceAjs
) {

  $scope.block        = blockUI.instances.get('activateChargifyBlock');
  $scope.countries    = countries;
  $scope.months       = dateParsing.getMonths();
  $scope.subscription = {};
  $scope.taxName      = $rootScope.Company.getTaxName();
  $scope.years        = dateParsing.years();

  $scope.tabs = [
    {
      title  : 'Pay By Card',
      active : true,
      content: PayByCardTabHtml
    }
  ];

  if ($rootScope.Company.country([ 'australia', 'newZealand' ])) {
    $scope.tabs.push({
      title  : 'I\'m A Capricorn Member',
      active : false,
      content: CapricornMemberTabHtml
    });
  };

  $scope.activate = function () {
    $scope.block.start();

    recaptchaApi.verifyUser()
    .then(() => workshopAPI.post('/chargify/update_payment', $scope.subscription, 'subscription'))
    .then(() => sessionObjAPI.refresh())
    .then(() => {
      $uibModalInstance.close();
    })
    .catch(err => {
      messages.error(err);
    })
    .finally(() => {
      $scope.block.stop();
    });
  };

  $scope.getStates = function () {
    chargifyApi.getStates($scope.subscription.billing_country)
    .then(states => {
      $scope.states = states;
    });
  };

  $scope.getAnotherTrial = function () {
    $scope.block.start();

    $scope.subscription.take_another_trial = true;

    workshopAPI.post('/chargify/update_payment', $scope.subscription, 'subscription')
    .then(() => sessionObjAPI.refresh())
    .then(() => {
      $uibModalInstance.close();
    })
    .catch(err => {
      messages.error(err);
    })
    .finally(() => {
      $scope.block.stop();
    });
  };

  $scope.getSubscription = function () {
    $scope.subscription = $rootScope.Company.hasDealership()
    ? {
      gold_month_by_year    : chargifyApi.getRate(quotes.ds_gold_yearly),
      monthly_gold_fee      : quotes.ds_gold_monthly,
      monthly_platinum_fee  : quotes.ds_platinum_monthly,
      monthly_silver_fee    : quotes.ds_silver_monthly,
      platinum_month_by_year: chargifyApi.getRate(quotes.ds_platinum_yearly),
      referred_code         : '',
      silver_month_by_year  : chargifyApi.getRate(quotes.ds_silver_yearly),
      subscription_period   : 'gold_yearly',
      subscription_type     : 'gold',
      yearly_gold_fee       : quotes.ds_gold_yearly,
      yearly_platinum_fee   : quotes.ds_platinum_yearly,
      yearly_silver_fee     : quotes.ds_silver_yearly
    }
    : {
      bronze_month_by_year  : chargifyApi.getRate(quotes.ws_bronze_yearly),
      gold_month_by_year    : chargifyApi.getRate(quotes.ws_gold_yearly),
      monthly_bronze_fee    : quotes.ws_bronze_monthly,
      monthly_gold_fee      : quotes.ws_gold_monthly,
      monthly_platinum_fee  : quotes.ws_platinum_monthly,
      monthly_silver_fee    : quotes.ws_silver_monthly,
      platinum_month_by_year: chargifyApi.getRate(quotes.ws_platinum_yearly),
      referred_code         : '',
      silver_month_by_year  : chargifyApi.getRate(quotes.ws_silver_yearly),
      subscription_period   : 'gold_yearly',
      subscription_type     : 'gold',
      yearly_bronze_fee     : quotes.ws_bronze_yearly,
      yearly_gold_fee       : quotes.ws_gold_yearly,
      yearly_platinum_fee   : quotes.ws_platinum_yearly,
      yearly_silver_fee     : quotes.ws_silver_yearly
    };
  };

  $scope.toggleBronzeMonthlyRenewal = function () {
    $scope.subscription.subscription_handle = 'wksw_bronze';
    $scope.subscription.subscription_period = 'bronze_monthly';
    $scope.subscription.subscription_type   = 'bronze';
  };

  $scope.toggleBronzeYearlyRenewal = function () {
    $scope.subscription.subscription_handle = 'wksw12_bronze';
    $scope.subscription.subscription_period = 'bronze_yearly';
    $scope.subscription.subscription_type   = 'bronze';
  };

  $scope.toggleSilverMonthlyRenewal = function () {
    $scope.subscription.subscription_handle = 'wksw';
    $scope.subscription.subscription_period = 'silver_monthly';
    $scope.subscription.subscription_type   = 'silver';
  };

  $scope.toggleSilverYearlyRenewal = function () {
    $scope.subscription.subscription_handle = 'wksw12';
    $scope.subscription.subscription_period = 'silver_yearly';
    $scope.subscription.subscription_type   = 'silver';
  };

  $scope.toggleGoldMonthlyRenewal = function () {
    $scope.subscription.subscription_handle = 'wksw_gold';
    $scope.subscription.subscription_period = 'gold_monthly';
    $scope.subscription.subscription_type   = 'gold';
  };

  $scope.toggleGoldYearlyRenewal = function () {
    $scope.subscription.subscription_handle = 'wksw12_gold';
    $scope.subscription.subscription_period = 'gold_yearly';
    $scope.subscription.subscription_type   = 'gold';
  };

  $scope.togglePlatinumMonthlyRenewal = function () {
    $scope.subscription.subscription_handle = 'wksw_plat';
    $scope.subscription.subscription_period = 'plat_monthly';
    $scope.subscription.subscription_type   = 'platinum';
  };

  $scope.togglePlatinumYearlyRenewal = function () {
    $scope.subscription.subscription_handle = 'wksw12_plat';
    $scope.subscription.subscription_period = 'plat_yearly';
    $scope.subscription.subscription_type   = 'platinum';
  };

  $scope.getSubscription();
}