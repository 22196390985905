import { CustomerModel }     from '../customers/models/customer';
import { EmailOptionsModel } from './models/messages.send-email.options';
import { RecpatchaService }  from '../recaptcha/recaptcha.service';

import EmailHtml from './messages.email.html';
import SMSHtml   from './messages.sms.html';

export class MessengerService {
  $filter     : ng.FilterFactory;
  $q          : ng.IQService;
  $uibModal   : ng.ui.bootstrap.IModalService;
  recaptchaApi: RecpatchaService;
  api         : any;

  emailOptions : EmailOptionsModel = {
    bodyParamName: 'email',
    size         : 'md',
    templateKey  : 'email',
    templateUrl  : EmailHtml,
    url          : '/system/send_email'
  };

  constructor (
    $filter     : ng.FilterFactory,
    $q          : ng.IQService,
    $uibModal   : ng.ui.bootstrap.IModalService,
    recaptchaApi: RecpatchaService,
    workshopAPI : any
  ) {
    this.$filter      = $filter;
    this.$q           = $q;
    this.$uibModal    = $uibModal;
    this.api          = workshopAPI;
    this.recaptchaApi = recaptchaApi;

  }

  email ( email : any = {}, options : EmailOptionsModel = {} ) : ng.IPromise<any> {
    const mergedOptions = this.getMergedOptions(options);
    const windowClass   = `${ mergedOptions.size }-modal`;

    email.subject = this.$filter('htmlToPlainText')(email.subject);

    return this.$uibModal.open({
      controller  : 'EmailCtrl',
      controllerAs: '$ctrl',
      templateUrl : mergedOptions.templateUrl,
      windowClass : windowClass,
      resolve     : {
        email  : () => email,
        options: () => mergedOptions
      }
    }).result;
  }

  getMergedOptions ( options : EmailOptionsModel = {} ) : EmailOptionsModel {
    return angular.extend({}, this.emailOptions, options);
  }

  sendBulkSms ( customers : Array<CustomerModel>, message : string ) : ng.IPromise<any> {
    return this.recaptchaApi.verifyUser()
    .then(( verified : boolean ) => this.api.post('/sms_logs/send_bulk_sms', {
      customer_list: customers,
      message
    }, 'customers'));
  }

  sendEmail ( email : any, options : EmailOptionsModel = {} ) : ng.IPromise<any> {
    const mergedOptions = this.getMergedOptions(options);

    return this.recaptchaApi.verifyUser()
    .then(( verified : boolean ) => this.api.post(
      mergedOptions.url,
      email,
      mergedOptions.bodyParamName ? mergedOptions.bodyParamName : null
    ));
  }

  sendSms ( sms : any ) : ng.IPromise<any> {
    return this.recaptchaApi.verifyUser()
    .then(( verified : boolean ) => this.api.post('/sms_logs/send', sms, 'sms'));
  }

  sms ( sms : any, options : any = {} ) : ng.IPromise<any> {
    return this.$uibModal.open({
      controller : 'SendSmsCtrl',
      templateUrl: options.templateUrl || SMSHtml,
      windowClass: 'md-modal',
      resolve    : {
        sms: () => sms
      }
    }).result;
  }
}
