import QuickbooksDisclaimerHtml from './templates/quickbooks.disclaimer.html';
import SyncQuickbooksHtml       from './quickbooks.sync.html';

export function QuickbooksService (
  $uibModal,
  $q,
  $translate,
  sessionObjAPI,
  workshopAPI
) {
  var Quickbooks = function () {};

  Quickbooks.prototype.authorize = function () {
    var self = this;

    if (sessionObjAPI.company().has_xero) {
      return $q.reject($translate.instant('JS_SPACE.INTEGRATIONS.ACCOUNTING_UNAUTH_FIRST', {
        name1: $translate.instant('COMPANY_SPACE.INTEGRATIONS.QUICKBOOKS.QUICKBOOKS_ONLINE'),
        name2: 'Xero'
      }));
    } else if (sessionObjAPI.company().has_myob) {
      return $q.reject($translate.instant('JS_SPACE.INTEGRATIONS.ACCOUNTING_UNAUTH_FIRST', {
        name1: $translate.instant('COMPANY_SPACE.INTEGRATIONS.QUICKBOOKS.QUICKBOOKS_ONLINE'),
        name2: 'MYOB'
      }));
    } else {
      return workshopAPI.get('/quickbooks_online/is_unlinked').then(function (data) {
        return data ? self.launchDisclaimer() : $q.when();
      }, function (err) {
        return $q.reject(err);
      });
    }
  };

  Quickbooks.prototype.checkExistingIntegrations = function () {
    return workshopAPI.get('/company/accounting_ids_exist/qbo');
  };

  Quickbooks.prototype.clearID = function () {
    return workshopAPI.post('/company/clear_accounting_contact_ids', { accounting_integration: 'qbo', confirmation: 'CLEAR IDS' });
  };

  Quickbooks.prototype.getAccounts = function () {
    return workshopAPI.get('/quickbooks_online/accounts');
  };

  Quickbooks.prototype.launchDisclaimer = function () {
    return $uibModal.open({
      templateUrl: QuickbooksDisclaimerHtml,
      windowClass: 'md-modal'
    }).result;
  };

  Quickbooks.prototype.shareAccount = function (shareAccountFlag) {
    if (shareAccountFlag) {
      return workshopAPI.get('/company/share_accounting', ['t']);
    }
    else {
      return workshopAPI.get('/company/share_accounting', ['f']);
    }
  };

  Quickbooks.prototype.sync = function () {
    return $uibModal.open({
      templateUrl: SyncQuickbooksHtml,
      controller : 'SyncQuickbooksCtrl'
    }).result;
  };

  return Quickbooks;
}