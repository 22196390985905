import 'angular';

import '../core/core.module.ajs';

import { API }           from './api.constant.ajs';
import { ApiServiceAjs } from './api.service.ajs';
import { HostConfig }    from './api.host-config.ajs';
import { STATUSCODES }   from './api.status-codes.constant.ajs.ts';

angular.module('app-lite.api', [
  'app-lite.core'
]).value('API', API)
  .config(HostConfig)
  .constant('STATUSCODES', STATUSCODES)
  .service('workshopAPI', ApiServiceAjs);
