import { S3OptionsModel } from './models/s3-options';

export function S3UploadService () {
  return (
    file   : File,
    options: S3OptionsModel
  ) => {
    const fd      = new FormData();
    const request = new XMLHttpRequest();

    fd.append('acl', options.acl);
    fd.append('Content-Type', options['Content-Type']);
    fd.append('key', options.key);
    fd.append('policy', options.policy);
    fd.append('success_action_redirect', options.success_action_redirect);
    fd.append('x-amz-signature', options.x_amz_signature);
    fd.append('x-amz-algorithm', options['x-amz-algorithm']);
    fd.append('x-amz-credential', options['x-amz-credential']);
    fd.append('x-amz-date', options['x-amz-date']);
    fd.append('file', file);

    return ({ request, fd });
  };
}