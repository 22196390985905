import { Component, Input } from '@angular/core';

import { TableControlComponent } from '../table-control.component';
import { TableService          } from '../../../table.service';

@Component({
  styles: [ require('../table-control.component.scss') ],
  template: require('./table-text-control.component.html')
})
export class TableTextControlComponent extends TableControlComponent {
  declare maxlength: number;

  _type: string;

  @Input() set type (_type: string) {
    this._type = _type ?? 'text';
  }

  constructor (
    service: TableService
  ) {
    super(service);
  }

  handleFocus ( $event : FocusEvent ) : void {
    ( $event.target as HTMLInputElement ).select();

    this.focus.emit();
  }
}