import 'angular';

import '../api/api.module';
import '../core/core.module.ajs';
import '../grids/grids.module';
import '../messages/messages.module.ajs';

import { BranchStockTransferLiteCtrl }   from './branches.stock-transfer';
import { BranchesDashboardLiteCtrl }     from './branches.dashboard';
import { BranchesLiteService }           from './branches.service';
import { LinkedCompaniesLookupLiteCtrl } from './branches.linked-companies.lookup';

angular.module('app-lite.branches', [
  'app-lite.api',
  'app-lite.core',
  'app-lite.grids',
  'app-lite.messages'
]).controller('BranchStockTransferLiteCtrl', BranchStockTransferLiteCtrl)
  .controller('BranchesDashboardLiteCtrl', BranchesDashboardLiteCtrl)
  .controller('LinkedCompaniesLookupLiteCtrl', LinkedCompaniesLookupLiteCtrl)
  .factory('BranchesLite', BranchesLiteService);
