import { VendorActionsComponent } from './vendor.actions.component';

export function VendorsLiteCtrl (
  $rootScope,
  $scope,
  $state,
  $translate,
  confirm,
  messages,
  messengerApi,
  tableApi,
  Vendor,
  workshopAPI
) {

  $scope.tableCacheKey   = 'vendors';
  $scope.Vendor          = new Vendor();

  $scope.filtering = tableApi.setFiltersFromCache($scope.tableCacheKey, {
    archived_only: false,
    value        : ''
  });

  $scope.tableSettings = {
    broadcast: 'filterVendors',
    cache    : {
      enabled: true,
      key    : $scope.tableCacheKey
    },
    columns: [
      {
        field : 'company_name',
        header: {
          text: $translate.instant('VENDOR_SPACE.SUPPLIER')
        }
      },
      {
        field : 'suburb',
        header: {
          text: $translate.instant('GENERAL_SPACE.FIELD.SUBURB')
        }
      },
      {
        field : 'phone',
        header: {
          text: $translate.instant('GENERAL_SPACE.FIELD.PHONE_NUMBER')
        },
        pipe: 'mobilePhone'
      },
      {
        field : 'web',
        header: {
          text: $translate.instant('JS_SPACE.COLUMNS.WEBSITE')
        }
      },
      {
        component: {
          bindings: {
            outputs: {
              onEditClick    : event => $scope.viewVendor(event),
              onPaymentClick : event => $scope.createPayment(event),
              onSMSClick     : event => $scope.sendSMS(event),
              onEmailClick   : event => $scope.sendEmail(event),
              onActivateClick: event => $scope.activate(event)
            }
          },
          enabled: true,
          factory: VendorActionsComponent,
        },
        sortable: false,
      }
    ],
    empty: {
      text: $translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_SUPPLIERS_FOUND')
    },
    pagination: {
      enabled   : true,
      pageSize  : tableApi.getCachedPaging($scope.tableCacheKey).pageSize || 10,
      pageSizes : [ 10, 25, 50 ],
      queryCount: () => workshopAPI.get('/filters/count', [
        $scope.filtering.value || '*',
        'vendors',
        $scope.filtering.archived_only
      ])
    },
    rows: {
      highlight: {
        enabled: true
      }
    },
    sorting: tableApi.setSortingFromCache($scope.tableCacheKey, {
      direction: 'asc',
      field    : 'company_name'
    })
  };

  $scope.activate = function (event) {
    confirm.generic($translate.instant('JS_SPACE.INTEGRATIONS.ACTIVATE', { name: event.company_name }))
    .then(() => workshopAPI.get('/vendors/reactivate_deleted', event.id))
    .then(() => {
      messages.show($translate.instant('JS_SPACE.MESSAGES.SUPPLIER_REACTIVATED'), 'success');

      $state.reload();
    })
    .catch(err => {
      messages.error(err);
    });
  };

  $scope.getVendors = ( params ) => {
    return workshopAPI.stream('/filters', [
      $scope.filtering.value || '*',
      'vendors',
      params.offset,
      params.pageSize,
      params.field,
      params.direction,
      $scope.filtering.archived_only
    ]).then(vendors => {
      $scope.vendors = vendors;

      return vendors;
    });
  };

  $scope.sendEmail = function (row) {
    messengerApi.email({
      name           : row.company_name,
      email_address  : row.email,
      body           : $scope.companyMessages.supplier_email_message,
      attachment_type: 5,
      vendor_id      : row.id
    })
    .then(() => {
      messages.show($translate.instant('JS_SPACE.MESSAGES.MESSAGE_SENT'), 'success');
    });
  };

  $scope.sendSMS = function (row) {
    messengerApi.sms({
      name   : row.company_name,
      send_to: row.mobile
    })
    .then(() => {
      messages.show($translate.instant('JS_SPACE.MESSAGES.MESSAGE_SENT'), 'success');
    });
  };

  $scope.viewVendor = function (vendor) {
    $state.go('app.vendor', {
      id: vendor.id
    });
  };

  $scope.createPayment = function (event) {
    if (event.is_branch_vendor) {
      $state.go('app.vendor-branch-payment', {
        vendor_id: event.id
      });
    }
    else {
      $state.go('app.vendor-payment', {
        vendor_id: event.id
      });
    }
  };

  $scope.createInvoice = function (vendor) {
    $state.go('app.vendor-invoice', {
      vendor_id: vendor.id
    });
  };

  $scope.createPurchaseOrder = function (vendor) {
    $state.go('app.purchase-order', {
      vendor_id: vendor.id
    });
  };

  $scope.$watch('[filtering.value, filtering.archived_only]', (newVal, oldVal) => {
    if (newVal !== oldVal) {
      $rootScope.$broadcast('filterVendors', $scope.filtering);
    }
  }, true);
}