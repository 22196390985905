import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'regex'
})
export class RegexPipe implements PipeTransform {
  transform( value : string, regex : any ) : string {
    regex = regex || {};

    if (!value) {
      return '';
    }

    var validation = new RegExp(regex.validation);

    return validation
      ? value.replace(validation, regex.mask)
      : value;
  }
}