import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid';

import { AppComponent } from '../app.component';

export const RouterStates : Array<NgHybridStateDeclaration> = [
  {
    component: AppComponent,
    name     : 'ajs.app',
    url      : 'app'
  },
  {
    loadChildren: () => import('../public/public.module').then(module => module.PublicModule),
    name        : 'ajs.app.public.**',
    url         : '/public'
  },
  {
    loadChildren: () => import('../private/private.module').then(module => module.PrivateModule),
    name        : 'ajs.app.private.**',
    url         : '/private'
  }
];
