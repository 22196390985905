import { Component, Input }         from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { TextControlComponent } from '../text-control/text-control.component';

@Component({
  selector   : 'wor-postcode-control',
  template: require('./postcode-control.component.html'),

  viewProviders: [
    {
      provide    : ControlContainer,
      useExisting: NgForm
    }
  ]
})
export class PostcodeControlComponent extends TextControlComponent {
  @Input() maxlength = 10;
}
