import { Component, Input } from '@angular/core';

import { CarouselEvent }       from '../shared/components/carousel/models/carousel-event';
import { TableModel }          from '../table/models/table';
import { VehicleDueDateModel } from './models/vehicle.due-date';
import { VehicleModel }        from './models/vehicle';
import { VehiclesService }     from './vehicles.service';

@Component({
  selector   : 'wor-vehicle-due-dates',
  template: require('./vehicle.due-dates.component.html')
})
export class VehicleDueDatesComponent {
  @Input() set vehicles (_vehicles: Array<VehicleModel>) {
    this._vehicles = _vehicles;

    this.vehicle  = this._vehicles[0];
    this.dueDates = this.vehicle?.due_dates;
  }

  // array of vehicle due dates used to
  // populate the table.
  dueDates: Array<VehicleDueDateModel> = [];
  vehicle : VehicleModel;

  tableSettings: TableModel = {
    columns: [
      {
        field : 'type',
        header: {
          title: 'Type of Service'
        }
      },
      {
        field : 'date',
        header: {
          title: 'Date'
        },
        pipe: 'globalDate'
      }
    ],
    title: 'Due Dates'
  };

  // array of customer vehicles used to
  // populate the carousel.
  _vehicles    : Array<VehicleModel> = [];

  constructor (
    private vehicleService: VehiclesService
  ) {}

  getBookingLink () : string {
    return this.vehicleService.getPublicBookingLink(this.vehicle);
  }

  initDueDates ( event : CarouselEvent ) : void {
    this.vehicle  = this._vehicles[Number(event.current)];
    this.dueDates = this.vehicle?.due_dates;
  }
};

