export function TemplatedWordingListLiteCtrl ($rootScope, $scope, $translate, messages, workshopAPI, templates, wording_type) {
  $scope.order         = 0;
  $scope.selectedItems = [];
  $scope.wording_type  = wording_type;

  if (angular.isString(templates)) {
    $scope.emptyGridText = templates;
  }
  else {
    $scope.templates = templates;
  }

  $scope.filterOptions = {
    filterText: ''
  };

  $scope.tableSettings = {
    broadcast: 'filterTemplates',
    columns: [
      {
        classes: 'text-left',
        field  : 'description',
        header : {
          text: $translate.instant('GENERAL_SPACE.FIELD.DESCRIPTION')
        }
      }
    ],
    empty: {
      text: $translate.instant('COMPANY_SPACE.LISTS.GRID.NO_TEMPLATES')
    },
    pagination: {
      enabled: false
    },
    rows: {
      highlight: {
        enabled: true
      },
      select: {
        enabled: true,
        multi  : true
      }
    },
    sorting: {
      direction: 'asc',
      enabled  : true,
      field    : 'description'
    },
    static: true
  };

  $scope.onRowClick = function ( event ) {
    if ( event.row.selected ) {
      event.row.order = $scope.order;

      $scope.order++;
    }

    $scope.selectedItems = $scope.templates.filter((row) => row.selected);

    $scope.allSelected = ($scope.selectedItems.length == $scope.templates.length);
  };

  $scope.selectAll = function () {
    $scope.allSelected = !$scope.allSelected;

    angular.forEach($scope.templates, function (template) {
      template.selected = $scope.allSelected;
    });

    $scope.selectedItems = $scope.templates.filter((row) => row.selected);
  };

  $scope.add = function () {
    $scope.$close($scope.selectedItems
      .sort((current, next) => current.order - next.order)
    );
  };

  $scope.getTemplates = ( params ) => {
    return workshopAPI.get('/templated_wordings', [
      0, '*',
      params.field,
      params.direction,
      $scope.wording_type || 'invoice',
      $scope.filterOptions.filterText || '*'
    ]).then(function (templates) {
      $scope.templates = templates;
      return templates;
    }, function (err) {
      messages.error(err);
    });
  }

  $scope.$watch('filterOptions', function (newVal, oldVal) {
    if (newVal !== oldVal) {
      $rootScope.$broadcast('filterTemplates');
    }
  }, true);
}
