import 'angular';

import { CompanyListsCtrl }               from './company-lists.ajs';
import { CompanyListsTemplatesCtrl }      from './company-lists.templates.ajs';
import { CompanyListsTemplatesDirective } from './company-lists.templates.directive.ajs';
import { CustomerSourcesCtrl }            from './company-lists.customer-sources.ajs';
import { PaymentMethodsCtrl }             from './company-lists.payment-methods.ajs';
import { ProductCategoriesCtrl }          from './company-lists.product-categories.ajs';
import { ProductGroupsCtrl }              from './company-lists.product-groups.ajs';
import { TemplateCtrl }                   from './company-lists.template.ajs';

angular.module('app-lite.company-lists', [])
  .controller('CompanyListsCtrl', CompanyListsCtrl)
  .controller('CompanyListsTemplatesCtrl', CompanyListsTemplatesCtrl)
  .controller('CustomerSourcesCtrl', CustomerSourcesCtrl)
  .controller('PaymentMethodsCtrl', PaymentMethodsCtrl)
  .controller('ProductCategoriesCtrl', ProductCategoriesCtrl)
  .controller('ProductGroupsCtrl', ProductGroupsCtrl)
  .controller('TemplateCtrl', TemplateCtrl)
  .directive('companyListsTemplates', CompanyListsTemplatesDirective);
