import { Component, OnInit } from '@angular/core';
import { UIRouter }          from '@uirouter/core';

import { SessionService } from '../core/sessions/session.service';

@Component({
  selector   : 'wor-navbar',
  styles: [ require('./navbar.component.scss') ],
  template: require('./navbar.component.html')
})
export class NavbarComponent {
  sessionId : string;

  constructor (
    private router        : UIRouter,
    private sessionService: SessionService
  ) {}

  hasSession () {
    return !!this.sessionService.getSessionId();
  }

  logout () {
    this.sessionService.logout()
    .then(() => {
      this.router.stateService.go('ajs.app.public.login');
    });
  }
}
