import { Component, Input } from '@angular/core';
import { NgbModal }         from '@ng-bootstrap/ng-bootstrap';

import { ChangePasswordComponent } from './customer.change-password.component';
import { CustomerModel }           from './models/customer';
import { VehicleModel }            from '../vehicles/models/vehicle';

@Component({
  selector   : 'wor-customer-detail',
  styles: [ require('./customer.detail.component.scss') ],
  template: require('./customer.detail.component.html')
})
export class CustomerDetailComponent {
  @Input() customer: CustomerModel = {};
  @Input() vehicles: Array<VehicleModel>;

  constructor (
    private modalService: NgbModal
  ) {}

  changePassword () {
    const modal = this.modalService.open(ChangePasswordComponent);

    modal.componentInstance.customer = this.customer;
  }
}
