import 'angular';

import '../../vendor/ng-grid/ng-grid-custom';

import { CrudGridAPIService }     from './crud-grid.service';
import { ExpandableRowDirective } from './expandable-row.directive.ajs';
import { GridApiService }         from './grid.service.ajs';
import { GridFilterDirective }    from './grid.filter.directive.ajs';
import { GridReorderService }     from './reorder-grid.service';
import { StaticLookupLiteCtrl }   from './grids.static-lookup';
import { StaticLookupService }    from './grids.static-lookup.service';

angular.module('app-lite.grids', [
  'ngGrid',

  'app-lite.api',
  'app-lite.messages'
]).controller('StaticLookupLiteCtrl', StaticLookupLiteCtrl)
  .directive('worExpandableRow', ExpandableRowDirective)
  .directive('filter', GridFilterDirective)
  .factory('crudGridAPI', CrudGridAPIService)
  .factory('gridAPI', GridApiService)
  .factory('reorderGrid', GridReorderService)
  .factory('staticLookup', StaticLookupService);
