import SyncXeroHtml from './xero.sync.html';

export function XeroService (
  $uibModal,
  workshopAPI
) {
  var Xero = function () {};

  Xero.prototype.accounts = function () {
    return workshopAPI.get('/xero/chart_of_accounts');
  };

  Xero.prototype.addXeroTax = function (tax) {
    return workshopAPI.post('/xero/adjust_tax_rates', tax, 'tax_rate');
  };

  Xero.prototype.checkExistingIntegrations = function () {
    return workshopAPI.get('/company/accounting_ids_exist/xero');
  };

  Xero.prototype.clearID = function () {
    return workshopAPI.post('/company/clear_accounting_contact_ids', { accounting_integration: 'xero', confirmation: 'CLEAR IDS' });
  };

  Xero.prototype.getTaxRates = function () {
    const self = this;

    return self.settings()
    .then(settings => settings.tax_rates);
  };

  Xero.prototype.settings = function () {
    return workshopAPI.get('xero/settings', null, null, {
      timeout: 30000
    });
  };

  Xero.prototype.shareAccount = function (shareAccountFlag) {
    return workshopAPI.get('/company/share_accounting', [ shareAccountFlag ? 't' : 'f' ]);
  };

  Xero.prototype.sync = function () {
    return $uibModal.open({
      controller : 'SyncXeroCtrl',
      templateUrl: SyncXeroHtml,
      windowClass: 'flip-container'
    }).result
    .then(conflicts => parseInt(conflicts));
  };

  Xero.prototype.taxRates = function () {
    return workshopAPI.get('xero/list_tax_rates/%1$s');
  };

  return Xero;
}