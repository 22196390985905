import { ContentChild, ElementRef, TemplateRef, ViewChild }   from '@angular/core';
import { Component, EventEmitter, Input, Output }             from '@angular/core';
import { ControlContainer, NgForm }                           from '@angular/forms';
import { NgbTypeahead }                                       from '@ng-bootstrap/ng-bootstrap';
import { Observable, of }                                     from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

import { TextControlComponent } from '../text-control/text-control.component';

@Component({
  selector     : 'wor-typeahead-control',
  styles: [ require('./typeahead-control.component.scss') ],
  template: require('./typeahead-control.component.html'),
  viewProviders: [{
    provide    : ControlContainer,
    useExisting: NgForm
  }]
})
export class TypeaheadControlComponent extends TextControlComponent {
  @Input() formatterFn: Function;
  @Input() placeholder: string;
  @Input() searchFn   : Function;

  @Input() set placement (_placement: string) {
    this._placement = _placement ?? 'bottom-left';
  }

  @Input() set resultFormatterFn (formatter: Function) {
    if (formatter) {
      this._resultFormatterFn = formatter;
    }
  }

  @Output() onSelect: EventEmitter<any> = new EventEmitter();

  @ContentChild(TemplateRef) templateRef;

  @ViewChild('controlRef') controlRef    : ElementRef;
  @ViewChild('typeaheadRef') typeaheadRef: NgbTypeahead;

  _placement: string;

  searching = false;

  _resultFormatterFn: Function = (result: string) => result;

  onKeyup ( $event : KeyboardEvent ) : void {
    if ( $event.key === 'Escape' ) {
      this.model = null;
    }
  }

  search = ( term: Observable<string> ): Observable<unknown> => {
    return !term
      ? of([])
      : term.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => this.searching = true),
        switchMap(query => query ? this.searchFn(query) : of([])),
        tap(() => this.searching = false)
      );
  }
}