export function EmailCustomerStatementLiteCtrl (
  $scope,
  messages,
  messengerApi,
  templatedWording
) {
  $scope.email = {
    attachment_id     : $scope.customer.id,
    attachment_type   : 1,
    body              : $scope.companyMessages.statement_email_message,
    email_address     : $scope.customer.email,
    name              : $scope.customer.display_name,
    attachment_filters: {
      as_at       : '*',
      begin_date  : '*',
      end_date    : '*',
      include_paid: true,
      statement   : false
    }
  };

  $scope.addTemplates = function () {
    templatedWording('email')
    .then(templates => {
      angular.forEach(templates, template => {
        if ($scope.email.message) {
          $scope.email.body = $scope.email.body + '\n\n' + template.wording;
        }
        else {
          $scope.email.body = template.wording;
        }
      });
    });
  };

  $scope.back = function () {
    $('.modal-content').removeClass('flip');
  };

  $scope.next = function () {
    $('.modal-content').addClass('flip');
  };

  $scope.send = function () {
    messengerApi.sendEmail($scope.email)
    .then(() => $scope.$close())
    .catch(err => messages.error(err));
  };
}
