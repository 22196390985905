import '../../../vendor/bootstrap-datepicker/bootstrap-datepicker.min';

export function CoreShortDateDirective () {
  return {
    require: 'ngModel',
    link: function(scope, element, attrs, ctrl) {
      // find the datepicker button and initialize it first
      if (element.parent().find('.date').length) element.parent().find('.date').datepicker({
        format: 'mm/yyyy',
        viewMode: 'months',
        minViewMode: 'months',
        autoclose: true,
        todayBtn: 'linked'
      }).
      on('changeDate', function(e) {
        // bootstrap-datepicker has a bug where it will sometimes
        // return the last day of the previous month from what was
        // selected. By adding one week to the returned date, we
        // ensure the retrieved month is the month selected.
        e.date = moment(e.date).add(7, 'days');

        var month = moment(e.date).format('MM');
        var year = moment(e.date).format('YYYY');

        $(e.target.nextElementSibling).val('mm/yyyy'.replace('mm', month).replace('yyyy', year));
        $(e.target.nextElementSibling).trigger('input');
      });

      ctrl.$parsers.push(function(value) {

        if (value && !value.includes("/") && (value.length == 4) && (value.includes("19") || value.includes("20"))) {
          var regexObj = /(\d{4})/;

        }
        else {
          var regexObj = /(\d{1,2})\/(\d{4})/;
        }



        //var regexObj = /(\d{1,2})\/(\d{4})/;

        if (!value) {
          ctrl.$setValidity('$valid', true);
          return ""
        }
        if (value.match(regexObj)) {
          if (value.length == 4) {
            var year = value.replace(regexObj, '$1');
            var currentYear = (new Date()).getFullYear() + 1;

            if (parseInt(year) <= parseInt(currentYear)) {
              ctrl.$setValidity('$valid', true);
              ctrl.$viewValue = value;
              ctrl.$render();
              return value
            }
            else {
              ctrl.$setValidity('$valid', false);
              return undefined;
            }

          }
          else {
            var month = value.replace(regexObj, '$1');
            var year = value.replace(regexObj, '$2');
            var currentYear = (new Date()).getFullYear() + 1;

            if (parseInt(month) <= 12 && parseInt(year) <= parseInt(currentYear)) {
              ctrl.$setValidity('$valid', true);
              ctrl.$viewValue = value;
              ctrl.$render();
              return value
            }
            else {
              ctrl.$setValidity('$valid', false);
              return undefined;
            }

          }


        }
        else {
          ctrl.$setValidity('$valid', false);
          return undefined;
        }
      });

      ctrl.$formatters.push(function(value) {
        if (value && !value.includes("/") && (value.length == 4) && (value.includes("19") || value.includes("20"))) {
          var regexObj = /(\d{4})/;

        }
        else {
          var regexObj = /(\d{1,2})\/(\d{4})/;
        }

        if (!value) {
          return '';
        }
        if (value.match(regexObj)) {
          return value;
        }
        else {
          return undefined
        }
      });
    }
  }
}