import 'angular';

import { TransactionCenterBookingsCtrl }            from './transaction-center.bookings.ajs';
import { TransactionCenterOrdersCtrl }              from './transaction-center.orders.ajs';
import { TransactionCenterBookingsComponent }       from './transaction-center.bookings.component.ajs';
import { TransactionCenterCtrl }                    from './transaction-center.ajs';
import { TransactionCenterOrdersComponent }         from './transaction-center.orders.component.ajs';
import { TransactionCenterStates }                  from './transaction-center.states.ajs';
import { TransactionCenterVendorInvoicesComponent } from './transaction-center.vendor-invoices.component.ajs';
import { TransactionCenterVendorInvoicesCtrl }      from './transaction-center.vendor-invoices.ajs';

import './inspections/transaction-center.inspections.module.ajs';
import './jobs/transaction-center.jobs.module.ajs';

angular.module('app-lite.transaction-center', [
  'app-lite.transaction-center.inspections',
  'app-lite.transaction-center.jobs'
])
.config(TransactionCenterStates)

.component('worTcBookings',       TransactionCenterBookingsComponent)
.component('worTcOrders',         TransactionCenterOrdersComponent)
.component('worTcVendorInvoices', TransactionCenterVendorInvoicesComponent)

.controller('TransactionCenterBookingsCtrl',       TransactionCenterBookingsCtrl)
.controller('TransactionCenterCtrl',               TransactionCenterCtrl)
.controller('TransactionCenterOrdersCtrl',         TransactionCenterOrdersCtrl)
.controller('TransactionCenterVendorInvoicesCtrl', TransactionCenterVendorInvoicesCtrl);
