import { NoteModalOptionsModel } from './models/modal.note.options';

interface NoteModalScope extends ng.IScope {
  close      : () => void;
  note       : string;
  title      : string;
}

export function NoteModalCtrl (
  $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
  $scope           : NoteModalScope,
  options          : NoteModalOptionsModel
) {
  $scope.note    = options.note;
  $scope.title   = options.title;

  $scope.close = function () : void {
    $uibModalInstance.close($scope.note);
  };
}