import { Component, OnInit }    from '@angular/core';
import { Transition, UIRouter } from '@uirouter/core';

import { ApiErrorModel }        from '../core/api/models/api.error';
import { ErrorHandlingService } from '../core/errors/error-handling.service';
import { SessionService }       from '../core/sessions/session.service';
import { UserAccountModel }     from '../users/models/user.account';

@Component({
  styles: [ require('./login.component.scss') ],
  template: require('./login.component.html')
})
export class LoginComponent implements OnInit {
  account: UserAccountModel = {};

  constructor (
    private errorHandlingService: ErrorHandlingService,
    private router              : UIRouter,
    private sessionService      : SessionService,
    private transition          : Transition
  ) {}

  ngOnInit () {
    this.account.username = this.transition.params().email || '';
  }

  login () : void {
    this.sessionService.login(this.account)
    .then(() => {
      this.router.stateService.go('ajs.app.private.dashboard');
    })
    .catch(( err : ApiErrorModel ) => {
      this.errorHandlingService.show(err);
    });
  }
}
