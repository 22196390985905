import { Component, EventEmitter, Inject, Input, Output} from '@angular/core';

@Component({
  template: require('./edit.component.html')
})
export class EditComponent {
  @Input() record: any;

  @Output() onEditClick: EventEmitter<any> = new EventEmitter();

  constructor (
    @Inject('$translate') public $translate: ng.translate.ITranslateService
  ) {}
}