import { ValidatorOptions } from '../models/validator.options';

interface UniquenessValidatorScope extends ng.IScope {
  modelOptions: ng.INgModelOptions;
  onValidate  : ( value : any ) => ng.IPromise<boolean>;
  options     : ValidatorOptions;
};

export function UniquenessValidatorDirective (
  $q: ng.IQService
) : ng.IDirective {
  const directive : ng.IDirective = {
    restrict: 'A',
    require : 'ngModel',
    scope   : {
      modelOptions: '<?',
      onValidate  : '&',
      options     : '<?'
    },
    link (
      scope  : UniquenessValidatorScope,
      element: ng.IRootElementService,
      attrs  : ng.IAttributes,
      ctrl   : ng.IFormController
    ) {
      scope.options = scope.options || {};

      const modelOptions : ng.INgModelOptions = {
        debounce: 500
      };

      ctrl.$overrideModelOptions({ ...modelOptions, ...scope.modelOptions });

      ctrl.$asyncValidators.isUnique = function ( modelValue : any, viewValue : any ) {
        if ( !scope.options.checkInitially && modelValue === ctrl.$modelValue ) {
          return $q.when(true);
        }

        return scope.onValidate({
          value: modelValue
        })
        .then(( isUnique : boolean ) => {
          ctrl.duplicate = !isUnique;

          return $q.when(!isUnique);
        });
      };
    }
  };

  return directive;
}
