import { Injectable } from '@angular/core';

import { NotificationModel } from './models/notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  defaults      : NotificationModel = {
    classname: 'bg-success',
    delay    : 2000,
    persist  : false
  };

  notifications : Array<NotificationModel> = [];

  remove ( notification : NotificationModel ) : void {
    this.notifications = this.notifications.filter(n => n !== notification);
  }

  show( content : string, options ?: NotificationModel ) {
    const notification : NotificationModel = Object.assign({}, this.defaults, options);

    notification.content = content;

    this.notifications.push(notification);
  }
}
