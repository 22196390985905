import { Component, Input } from '@angular/core';

import { BookingModel }    from './models/booking';
import { BookingsService } from './bookings.service';
import { TableCountModel } from '../table/models/table-count';
import { TableModel }      from '../table/models/table';
import { TableQueryModel } from '../table/models/table-query';

@Component({
  selector   : 'wor-bookings',
  template: require('./bookings.component.html')
})
export class BookingsComponent {
  @Input() bookings: Array<BookingModel>;

  tableSettings : TableModel = {
    columns: [
      {
        field : 'event_number',
        header: {
          title: 'Booking #'
        }
      },
      {
        field : 'description',
        header: {
          title: 'Description'
        }
      },
      {
        field : 'event_date',
        header: {
          title: 'date'
        },
        pipe: 'globalDate'
      }
    ],
    empty: {
      message: 'No Bookings Found'
    },
    pagination: this.bookingsService.defaultPagination,
    rows      : {
      select: {
        enabled : true,
        property: 'active'
      }
    },
    title: 'Future Bookings'
  };

  constructor (
    private bookingsService: BookingsService
  ) {}

  getBookingCount () : ng.IPromise<TableCountModel> {
    return this.bookingsService.getBookingCount();
  }

  getBookings ( params : TableQueryModel ) : ng.IPromise<Array<BookingModel>> {
    return this.bookingsService.getBookings(params.offset, params.pageSize);
  }
}