import { Component, Injector, Input } from '@angular/core';

import { PropertyPipe }     from '../shared/pipes/property.pipe';
import { TableColumnModel } from './models/table-column';

@Component({
  selector: 'wor-table-cell',
  template: require('./table-cell.component.html')
})
export class TableCellComponent {
  @Input() row: any;

  @Input() set column (_column: TableColumnModel) {

    // using the property pipe allows for
    // the extraction of nested fields.
    this.data = this.propertyPipe.transform(_column.field, this.row);

    // if a pipe is defined for the column, apply it.
    //
    // NOTE: in order for a pipe to be retrievable
    // in this manner, it must be provided as a
    // string in the TableModule.
    if (_column.pipe) {
      _column.pipeArgs = _column.pipeArgs || [];

      this.data = this.injector
        .get(_column.pipe)
        .transform(this.data, ..._column.pipeArgs);
    }
  }

  data: string;

  constructor (
    private injector    : Injector,
    private propertyPipe: PropertyPipe
  ) {}
}