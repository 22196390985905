import { CompanyCountryValidationsModel } from '../../companies/models/company.country-validations';
import { SessionServiceAjs }              from '../../sessions/session.service.ajs';

interface PostcodeValidatorScope extends ng.IScope {
  defaultValidation   : string;
  formValidityKey     : string;
  getAndSetValidations: () => void;
  mask                : string;
  regex               : RegExp;
  validations         : CompanyCountryValidationsModel;
};


export function PostcodeValidatorDirective (
  sessionObjAPI: SessionServiceAjs
) : ng.IDirective {
  const directive : ng.IDirective = {
    require: 'ngModel',
    scope  : {
      validations: '=?'
    },
    link (
      scope  : PostcodeValidatorScope,
      element: ng.IRootElementService,
      attrs  : ng.IAttributes,
      ctrl   : ng.INgModelController
    ) {
      scope.defaultValidation = '^\\d+$';
      scope.formValidityKey   = 'postcode';

      attrs.$set('autocomplete', 'postal-code');
      attrs.$set('maxlength', 10);

      scope.getAndSetValidations = function () {
        const countryValidations = sessionObjAPI.countryValidation();

        scope.mask = scope.validations
          ? scope.validations.postcode.mask
          : countryValidations.postcode.mask;

        const regex = scope.validations
          ? scope.validations.postcode.validation
          : countryValidations.postcode.validation;

        scope.regex = new RegExp(regex || scope.defaultValidation);
      };

      ctrl.$formatters.push(( value : string ) => {
        scope.getAndSetValidations();

        if (!value) {
          return '';
        }

        if (value.match(scope.regex)) {
          if (scope.mask) {
            value = value.replace(scope.regex, scope.mask);

            if (value.substring( value.length - 1 ) === '-') {
              value = value.slice(0, ( value.length - 1 ));
            }
          }
        }

        return value;
      });

      ctrl.$parsers.push(( value : string, keepPristine = false ) => {
        scope.getAndSetValidations();

        if (!value) {
          return '';
        }

        if (value.match(scope.regex)) {
          if (scope.mask) {
            value = value.replace(scope.regex, scope.mask);

            if (value.substring( value.length - 1 ) === '-') {
              value = value.slice(0, ( value.length - 1 ));
            }

            ctrl.$viewValue = value.toUpperCase();
            ctrl.$render();

            if (keepPristine) {
              ctrl.$setPristine();
            }

            return value.toUpperCase()
              .replace(scope.regex, '$1$2');
          }

          return value.toUpperCase();
        }

        ctrl.$setValidity(scope.formValidityKey, false);

        return value;
      });

      ctrl.$validators[scope.formValidityKey] = function ( modelValue : string, viewValue : string ) {
        scope.getAndSetValidations();

        if (!modelValue || scope.regex.test(modelValue)) {
          return true;
        }

        return false;
      };

      scope.$watch('validations', ( newValidations : CompanyCountryValidationsModel, oldValidations : CompanyCountryValidationsModel ) => {
        if (newValidations !== oldValidations) {
          angular.forEach(ctrl.$parsers, ( parser : (Function) ) => parser(ctrl.$modelValue, true));

          ctrl.$validate();
        }
      }, true);
    }
  };

  return directive;
}
