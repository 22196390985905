export function CustomersLiteCtrl (
  $rootScope,
  $scope,
  $state,
  $translate,
  confirm,
  messages,
  messengerApi,
  tableApi,
  workshopAPI
) {

  $scope.filtering = tableApi.setFiltersFromCache('customers', {
    archived_only: false,
    value        : ''
  });

  $scope.tableSettings = {
    broadcast: 'filterCustomers',
    cache    : {
      enabled: true,
      key    : 'customers'
    },
    columns: [
      {
        field : 'display_name',
        header: {
          text: $translate.instant('CUSTOMER_SPACE.CUSTOMER')
        }
      },
      {
        field : 'mobile',
        header: {
          text: $translate.instant('GENERAL_SPACE.FIELD.MOBILE')
        },
        pipe  : 'mobilePhone'
      },
      {
        field : 'phone',
        header: {
          text: $translate.instant('GENERAL_SPACE.FIELD.PHONE_NUMBER')
        },
        pipe  : 'phone'
      },
      $scope.actionsCell
    ],
    empty: {
      text: $translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_CUSTOMERS_FOUND')
    },
    pagination: {
      enabled   : true,
      pageSize  : tableApi.getCachedPaging('customers').pageSize || 10,
      pageSizes : [ 10, 25, 50 ],
      queryCount: () => workshopAPI.get('/filters/count', [
        $scope.filtering.value || '*',
        'customers',
        $scope.filtering.archived_only
      ])
    },
    rows: {
      highlight: {
        enabled: true
      }
    },
    sorting: tableApi.setSortingFromCache('customers', {
      direction: 'asc',
      field    : 'display_name'
    })
  };

  $scope.activate = function (row) {
    confirm
      .generic($translate.instant('JS_SPACE.INTEGRATIONS.ACTIVATE', { name: row.display_name }))
      .then(() => workshopAPI.get('/customers/reactivate_deleted', row.id))
      .then(() => {
        messages.show($translate.instant('JS_SPACE.MESSAGES.CUSTOMER_REACTIVATED'), 'success');

        $state.reload();
      });
  };

  $scope.getCustomers = ( params ) => {
    return workshopAPI.stream('/filters', [
      $scope.filtering.value || '*',
      'customers',
      params.offset,
      params.pageSize,
      params.field,
      params.direction,
      $scope.filtering.archived_only
    ]).then(customers => {
      $scope.customers = customers;

      return customers;
    });
  };

  $scope.onRowDoubleClick = function (event) {
    $scope.viewCustomer(event.row);
  };

  $scope.viewCustomer = function (customer) {
    if(customer.deleted){
      return
    }
    $state.go('app.customer', {
      id: customer.id
    });
  };

  $scope.sendEmail = function(row) {
    messengerApi.email({
      attachment_type: 4,
      body           : $scope.companyMessages.customer_email_message,
      customer_id    : row.id,
      email_address  : row.email,
      name           : row.company_name
    }).then(() => messages.show($translate.instant('JS_SPACE.MESSAGES.MESSAGE_SENT'), 'success'));
  };

  $scope.sendSMS = function (row) {
    messengerApi.sms({
      name   : row.display_name,
      send_to: row.mobile
    }).then(() => messages.show($translate.instant('JS_SPACE.MESSAGES.MESSAGE_SENT'), 'success'));
  };

  $scope.$watch(
    '[filtering.value, filtering.archived_only]',
    (newVal, oldVal) => {
      if (newVal !== oldVal) {
        $rootScope.$broadcast('filterCustomers', $scope.filtering);
      }
    },
  true);
}
