import 'angular';

import '../sessions/sessions.module';

import { BuySmsCtrl }     from './company.buy-sms';
import { CompanyService } from './company.service';

angular.module('app-lite.companies', [
  'app-lite.sessions'
])
  .controller('BuySmsCtrl', BuySmsCtrl)
  .factory('CompanyLite', CompanyService);
