import VendorBranchPaymentsHtml from './vendor-payments.branch.html';

export function VendorBranchPaymentsDirective () {
  return {
    controller: 'VendorBranchPaymentsLiteCtrl',
    restrict: 'E',
    templateUrl: VendorBranchPaymentsHtml,
    scope: {
      payments: '=',
      vendor: '='
    }
  };
}
