import { ApiServiceAjs }        from '../api/api.service.ajs';
import { CompanyMessagesModel } from '../companies/models/company.messages';
import { BigDecimalService }    from '../core/services/core.big-decimal.service.ajs';
import { CustomerModel }        from '../customers/models/customer';
import { FlipPayService }       from '../flippay/flippay.service.ajs';
import { InvoiceModel }         from '../invoices/models/invoice';
import { StripeService }        from '../stripe/stripe.service.ajs';
import { WorkshopPaySmsModel }  from './models/workshoppay.sms';

import RequestSmsPaymentHtml from './workshoppay.request-payment.sms.html';

export class WorkshopPayService {
  $q           : ng.IQService;
  $translate   : ng.translate.ITranslateService;
  $uibModal    : ng.ui.bootstrap.IModalService;
  api          : ApiServiceAjs
  bigDecimalApi: BigDecimalService;
  companyApi   : any;
  flipPayApi   : FlipPayService;
  stripeApi    : StripeService;

  constructor (
    $q           : ng.IQService,
    $translate   : ng.translate.ITranslateService,
    $uibModal    : ng.ui.bootstrap.IModalService,
    bigDecimalApi: BigDecimalService,
    Company      : any,
    flipPayApi   : FlipPayService,
    stripeApi    : StripeService,
    workshopAPI  : ApiServiceAjs
  ) {
    this.$q            = $q;
    this.$translate    = $translate;
    this.$uibModal     = $uibModal;
    this.api           = workshopAPI;
    this.bigDecimalApi = bigDecimalApi;
    this.companyApi    = new Company();
    this.flipPayApi    = flipPayApi;
    this.stripeApi     = stripeApi;
  }

  checkout ( invoice : InvoiceModel, token ?: string ) : ng.IPromise<stripe.paymentIntents.PaymentIntent | string> {
    return this.stripeApi.checkout(invoice, token);
  }

  getPaymentLink ( invoice : InvoiceModel, token : string ) : ng.IPromise<string> {
    return this.companyApi.hasIntegration('flippay')
      ? this.flipPayApi.getPaymentLink(invoice, token)
      : this.$q.when('');
  }

  hasWorkshopPay () : boolean {
    return this.companyApi.hasIntegration('stripe')
      || this.companyApi.hasIntegration('flippay');
  }

  isPaymentRequestAvailable ( invoice : InvoiceModel, isSale : boolean ) : boolean {
    const balance    = this.bigDecimalApi.get(invoice.balance_due, 2);
    const deposits   = this.bigDecimalApi.get(invoice.deposits_total, 2);
    const total      = this.bigDecimalApi.get(invoice.total, 2);
    // const invoiceApi = new this.Invoice(invoice);

    /**
     * TODO
     * the invoice api should be used here to check
     * the type and status. However, the invoice service
     * is still in the app folder. Until it is moved to
     * app-lite, it cannot be used here because this service
     * is also used on the workshopPay app which inherits app-lite.
     * So for now we put static checks.
     */
    return this.hasWorkshopPay()
      && !isSale
      && invoice.invoice_type !== 'C' //!invoiceApi.type('credit')
      && invoice.invoice_type !== 'Q' //!invoiceApi.type('quote')
      && invoice.invoice_status === 'P' //invoiceApi.status('processed')
      && balance.isPositive()
      && balance.equals(total)
      && deposits.isZero();
  }

  overrideCompanyInstance ( companyApi : any ) : void {
    this.companyApi = companyApi;
  }

  requestSmsPayment ( invoice : InvoiceModel, customer: CustomerModel ) : ng.IPromise<any> {
    if (!customer.mobile) {
      return this.$q.reject(this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.WORKSHOPPAY.CUSTOMER_HAS_NO_CONTACT', {
        name: customer.display_name,
        type: 'mobile'
      }));
    }

    return this.$uibModal.open({
      controller: 'RequestSmsWorkshopPayPaymentCtrl',
      resolve   : {
        companyMessages: () : ng.IPromise<CompanyMessagesModel> => this.companyApi.getDefaultMessages(),
        customer       : () => customer,
        invoice        : () => invoice
      },
      templateUrl: RequestSmsPaymentHtml
    }).result;
  }

  sendEmailRequest ( email : any, customer : CustomerModel ) : ng.IPromise<any> {
    return customer.email
      ? this.api.post('/system/send_email', {
        attachment_id  : email.invoice_id,
        attachment_type: 'payment_request',
        body           : email.message,
        email_address  : email.email,
        send_invoice   : email.send_invoice,
        subject        : email.subject
      }, 'email')
      : this.$q.reject(this.$translate.instant('COMPANY_SPACE.INTEGRATIONS.WORKSHOPPAY.CUSTOMER_HAS_NO_CONTACT', {
        name: customer.display_name,
        type: 'email'
      }));
  }

  sendSmsPaymentRequest ( sms : WorkshopPaySmsModel ) : ng.IPromise<boolean> {
    return this.api.post('/system/send_sms', sms);
  }
}
