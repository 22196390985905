import 'angular';

import { CodeTypeModel }      from '../../app-lite/core/models/code-type';
import { CustomerModel }      from '../../app-lite/customers/models/customer';
import { Table }              from '../../app-lite/table/types/table';

export class AddCustomerFromBranchCtrl {
  companyApi       : any;
  customerApi      : any;

  customers        : Array<CustomerModel>;
  selectedCustomer : CustomerModel;

  filters = {
    search_term: '',
    search_type: 'email',
  };

  //if using the customer screen or the invoice quick add, add email and mobile.
  searchTypes : Array<CodeTypeModel> = [
    {
      code       : 'customer_name',
      description: this.$translate.instant('JS_SPACE.COLUMNS.CUSTOMER_NAME')
    },
    {
      code       : 'email',
      description: this.$translate.instant('GENERAL_SPACE.FIELD.EMAIL')
    },
    {
      code       : 'mobile',
      description: this.$translate.instant('GENERAL_SPACE.FIELD.MOBILE')
    }
  ];

  tableSettings: Table = {
    columns: [
      {
        field : 'store_name',
        header: {
          text: this.$translate.instant('GENERAL_SPACE.FIELD.STORE_NAME')
        }
      },
      {
        classes: 'all-caps',
        field  : 'first_name',
        header : {
          text: this.$translate.instant('GENERAL_SPACE.FIELD.FIRST_NAME')
        },
      },
      {
        field : 'last_name',
        header: {
          text: this.$translate.instant('GENERAL_SPACE.FIELD.LAST_NAME')
        }
      },
      {
        field : 'email',
        header: {
          text: this.$translate.instant('GENERAL_SPACE.FIELD.EMAIL')
        }
      }
    ],
    empty: {
      text: this.$translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_CUSTOMERS_FOUND')
    },
    pagination: {
      enabled  : true,
      pageSize : 5,
      pageSizes: [ 5, 10, 25, 50 ]
    },
    rows: {
      highlight: {
        enabled: true
      },
      select: {
        enabled: true
      }
    },
    sorting: {
      direction: 'asc',
      enabled  : true,
      field    : 'store_name'
    },
    static: true
  };

  constructor (
    private $translate       : ng.translate.ITranslateService,
    public  $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
            Company          : any,
            Customer         : any,
    public  customerQuickAdd : CustomerModel
  ){
    this.companyApi        = new Company();
    this.customerApi       = Customer;

    //only when using invoice customer quick add, use plate number and vin as well as the above
    if (this.customerQuickAdd) {
      this.searchTypes.push(
      {
        code       : 'plate_number',
        description: this.$translate.instant('VEHICLE_SPACE.PLATE_NUMBER', { rego: 'Rego' })
      },
      {
        code       : 'vin',
        description: this.$translate.instant('VEHICLE_SPACE.VIN')
      });
    };
  }

  confirm () : ng.IPromise<void> {
    return this.customerApi
    .copyFromBranch(this.selectedCustomer)
    .then(( response : boolean ) => {
      this.$uibModalInstance.close(response);
    });
  }

  confirmCustomerSelection ( customer : CustomerModel ) : void {
    this.selectedCustomer = customer;

    this.confirm();
  }

  getCustomers ( params : any ) : void {
    params = params || {
      direction: 'asc',
      field    : 'first_name',
      offset   : 0,
      pageSize : 5
    };

    this.customerApi
    .getBranchCustomers({
      direction  : params.direction || 'asc',
      field      : params.field || 'first_name',
      offset     : params.offset || 0,
      pageSize   : params.pageSize || 5,
      search_term: this.filters.search_term,
      search_type: this.filters.search_type
    })
    .then((customers : Array<CustomerModel>) => this.customers = customers);
  };

  handleCustomerSelection ( customer : CustomerModel ) : void {
    this.selectedCustomer = customer.selected
      ? customer
      : null;
  }
};