import { ApiModel } from '../../app-lite/api/models/api.model';

interface ExportChargifyCancelationsScope extends ng.IScope {
  beginDate: string;
  endDate  : string;
  export   : () => void;
};

export function ExportChargifyCancelationsCtrl (
  $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,
  $scope           : ExportChargifyCancelationsScope,
  $window          : ng.IWindowService,
  API              : ApiModel,
  dateParsing      : any
) {
  $scope.endDate   = dateParsing.today();
  $scope.beginDate = dateParsing.today();

  $scope.export = function () {
    $window.open(`${ API.url }/chargify/cancelation_csv/${ $scope.beginDate }/${ $scope.endDate }`);

    $uibModalInstance.close();
  };
};
