export function VehicleFormSettingsLiteCtrl (
	$scope,
	formList,
	vehicle
) {
  $scope.formList = formList;

  $scope.settings = {
    form_code: '',
    vehicle  : vehicle
  };

  $scope.confirm = function () {
    $scope.$close($scope.settings);
  };
};