interface AlertModalScope extends ng.IScope {
  close  : () => void;
  message: string;
  title  : string;
  value  : string;
}

export function AlertModalCtrl (
  $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
  $scope           : AlertModalScope,
  message          : string,
  title            : string
) {
  $scope.message = message;
  $scope.title   = title;

  $scope.close = function () : void {
    $uibModalInstance.close($scope.value);
  };
};