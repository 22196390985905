import { Injectable } from '@angular/core';

import { ApiService }        from '../api/api.service';
import { STATUSCODES }       from '../api/api.status-codes.constant.ajs';
import { SessionServiceAjs } from '../sessions/session.service.ajs';
import { UserModel }         from '../users/models/user';

@Injectable({
  providedIn: 'root'
})
export class AzureSsoService {
  private _STATUSCODES = STATUSCODES;

  constructor (
    private apiService    : ApiService,
    private sessionService: SessionServiceAjs
  ) {}

  getLoginUrl () : ng.IPromise<string> {
    return this.apiService.get('/azure/azure_login')
    .then(response => response.azure_redirect_url);
  }

  getSessionUrl ( user : UserModel ) : ng.IPromise<string> {
    return this.apiService.post('/sessions/azure', user)
    .then(response => response.azure_redirect_url);
  }

  isSsoRequiredForLogin ( err : ng.IHttpResponse<any> ) : boolean {
    return err.status === this._STATUSCODES.ssoRequired;
  }

  reconnectUser ( user : UserModel ) : ng.IPromise<void> {
    return this.apiService.get('/azure/reauthenticate_azure_user', user.id)
    .then(( response : any ) => {
      return response.sso_cleared_for_reauthentication
        ? this.apiService.delete('/sessions')
        .finally(() => {
          this.sessionService.clear();

          window.location.assign('login.html');
        })
        : Promise.reject(response)
    });
  }
}