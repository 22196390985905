import { NgModule }              from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { NavbarModule }        from '../navbar/navbar.module';
import { SharedModule }        from '../shared/shared.module';
import { PrivateComponent }    from './private.component';
import { PrivateStates }       from './private.states';

@NgModule({
  declarations: [
    PrivateComponent
  ],
  imports: [
    SharedModule,
    NavbarModule,
    UIRouterUpgradeModule.forChild({ states: PrivateStates })
  ]
})
export class PrivateModule {}
