import { NgModule }              from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { LoginComponent } from './login.component';
import { LoginStates }    from './login.states';
import { SharedModule }   from '../shared/shared.module';

@NgModule({
  declarations: [
    LoginComponent
  ],
  imports: [
    SharedModule,
    UIRouterUpgradeModule.forChild({ states: LoginStates }),
  ]
})
export class LoginModule {}
