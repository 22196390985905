export function CoreStreamSearchService ($q, encodeParameter, API) {
  return function (category, typed, options) {
    var deferred = $q.defer();
    var url = encodeURI(`${API.url}/searches/${encodeParameter(typed)}/${category}`);
    var stream = new EventSource(url, { withCredentials: true });

    /**
     * Any options for the call. Available options are:
     * 		verbose: {boolean} - if set to true, promise AND stream will be returned
     * @type {object}
     */
    options = options || {};

    stream.addEventListener('message', (e) => {
      const data = JSON.parse(e.data);

      if (data.length <= 0) {
        stream.close();

        deferred.resolve([]);
      }
      else {
        deferred.resolve(data);
      }
    });

    stream.addEventListener('error', (e) => {
      stream.close();

      deferred.resolve([]);
    });

    return options.verbose ? {
      promise: deferred.promise,
      stream : stream
    } : deferred.promise;
  };
}