export function VehicleBranchHistoryLiteCtrl (
  $scope,
  $translate,
  $window,
  API,
  invoices,
  vehicle
) {
  $scope.invoices = invoices;
  $scope.vehicle  = vehicle;

  $scope.tableSettings = {
    columns: [
      {
        field : 'store_name',
        header: {
          text: 'Store Name'
        }
      },
      {
        field : 'invoice_number',
        header: {
          text: $translate.instant('GENERAL_SPACE.FIELD.REFERENCE')
        }
      },
      {
        field : 'post_date',
        header: {
          text: $translate.instant('GENERAL_SPACE.FIELD.POST_DATE')
        },
        pipe  : 'globalDate'
      },
      {
        field : 'invoice_type',
        header: {
          text: $translate.instant('JS_SPACE.COLUMNS.TRAN_TYPE')
        },
        pipe  : 'invoiceType'
      },
      {
        field : 'invoice_status',
        header: {
          text: $translate.instant('GENERAL_SPACE.FIELD.STATUS')
        },
        pipe  : 'invoiceStatus'
      },
      {
        field : 'cash_or_account',
        header: {
          text: $translate.instant('GENERAL_SPACE.FIELD.TYPE')
        },
        pipe  : 'customerType'
      },
      {
        field : 'total',
        header: {
          text: $translate.instant('JS_SPACE.COLUMNS.AMOUNT')
        },
        pipe  : 'globalCurrency'
      },
      {
        field : 'balance_due',
        header: {
          text: $translate.instant('JS_SPACE.COLUMNS.BALANCE')
        },
        pipe  : 'globalCurrency'
      }
    ],
    empty: {
      text: $translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_INVOICES_FOUND')
    },
    pagination: {
      enabled: false
    },
    rows: {
      highlight: {
        enabled: true
      }
    },
    sorting: {
      enabled: false
    },
    static: true
  };

  $scope.rowSelected = function (event) {
    $window.open(`${API.url}/reports/jasper_invoice/${event.row.id}.pdf`, '_blank');
  };
}
