import { Injectable }                    from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { DateService } from './date.service';

@Injectable()
export class ShortDateAdapter implements NgbDateAdapter<string> {

  readonly DELIMITER = this.dateService.DELIMITERS.short.adapter;

  constructor (
    private dateService: DateService
  ) {}

  fromModel ( value : string | null ) : NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);

      return {
        day  : 1,
        month: parseInt(date[0], 10),
        year : parseInt(date[1], 10)
      };
    }

    return null;
  }

  toModel ( date : NgbDateStruct | null ) : string | null {
    return date ? `${ this.dateService.pad(date.month) }${ this.DELIMITER }${ this.dateService.pad(date.year) }` : null;
  }
}
