import { Pipe, PipeTransform } from '@angular/core';

import { SessionServiceAjs } from '../../sessions/session.service.ajs';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  constructor(
    private sessionServiceAjs: SessionServiceAjs
  ) {}

  transform( value : string ) : string {
    const country    = this.sessionServiceAjs.countryValidation();
    const mask       = country.phone.mask;
    const validation = country.phone.validation;

    const regexObj = new RegExp(validation);

    if (!value) {
      return '';
    }
    else if (value.match(regexObj)) {
      return value.replace(regexObj, mask);
    }
    else {
      return `<span class="text-danger">${value}</span>`;
    }
  }
}

