
import { ApiServiceAjs }                 from '../api/api.service.ajs';
import { CodeTypeModel }                 from '../core/models/code-type';
import { LinkComponent }                 from '../table/cell-components/actions/link.component';
import { Table }                         from '../table/types/table';
import { TableService }                  from '../table/table.service';
import { TableQueryParameters }          from '../table/types/table-query-parameters';
import { TransactionCenterFiltersModel } from './models/transaction-center.filters';
import { TransactionCenterOrderModel }   from './models/transaction-center.order';

export class TransactionCenterOrdersCtrl {
  orders   : Array<TransactionCenterOrderModel>;
  statuses : Array<CodeTypeModel>;

  tableCacheKey = this.$state.current.data.tableCacheKey;

  filters : TransactionCenterFiltersModel = this.tableApi.setFiltersFromCache(this.tableCacheKey, {
    endDate        : '*',
    outstandingOnly: false,
    startDate      : '*',
    status         : '*',
    value          : ''
  });

  tableSettings : Table = {
    broadcast: 'filterOrders',
    cache    : {
      enabled: true,
      key    : this.tableCacheKey
    },
    columns: [
      {
        field : 'order_number',
        header: {
          text: this.$translate.instant('PURCHASE_ORDER_SPACE.ORDER_NUMBER')
        }
      },
      {
        field : 'order_date',
        header: {
          text: this.$translate.instant('GENERAL_SPACE.FIELD.DATE')
        },
        pipe: 'globalDate'
      },
      {
        component: {
          bindings: {
            inputs: {
              model: 'vendor_company_name',
            },
            outputs: {
              onClick: event => this.viewVendor(event)
            }
          },
          enabled: true,
          factory: LinkComponent
        },
        field : 'vendor_company_name',
        header: {
          text: this.$translate.instant('VENDOR_SPACE.SUPPLIER')
        },
        sortable: true,
      },
      {
        field : 'status',
        header: {
          text: this.$translate.instant('GENERAL_SPACE.FIELD.STATUS')
        },
        pipe: 'purchaseOrderStatus'
      },
      {
        component: {
          bindings: {
            inputs: {
              model: 'job_card_number',
            },
            outputs: {
              onClick: event => this.viewJobCard(event)
            }
          },
          enabled: true,
          factory: LinkComponent
        },
        field : 'job_card_number',
        header: {
          text: this.$translate.instant('GENERAL_SPACE.FIELD.ATTACHED_JOB_CARD')
        },
        sortable: true,
      },
      {
        classes: 'text-right',
        field  : 'total',
        header : {
          classes: 'text-right',
          text   : this.$translate.instant('GENERAL_SPACE.FIELD.SUBTOTAL')
        },
        pipe: 'globalCurrency'
      },
      {
        classes: 'text-right',
        field  : 'total_including_tax',
        header : {
          classes: 'text-right',
          text   : this.$translate.instant('GENERAL_SPACE.FIELD.TOTAL')
        },
        pipe: 'globalCurrency'
      }
    ],
    empty: {
      text: this.$translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_ORDERS_FOUND')
    },
    pagination: {
      enabled   : true,
      pageSize  : this.tableApi.getCachedPaging(this.tableCacheKey).pageSize || 10,
      pageSizes : [ 10, 25, 50 ],
      queryCount: () => this.getCount()
    },
    rows: {
      highlight: {
        enabled: true
      }
    },
    sorting: this.tableApi.setSortingFromCache(this.tableCacheKey, {
      direction: 'desc',
      field    : 'order_date'
    })
  };

  constructor (
    private $rootScope : ng.IRootScopeService,
    private $scope     : ng.IScope,
    private $state     : ng.ui.IStateService,
    private $translate : ng.translate.ITranslateService,
    private tableApi   : TableService,
    private workshopAPI: ApiServiceAjs,
  ) {
    this.$scope.$watch(() => {
      return [
        this.filters.startDate,
        this.filters.endDate,
        this.filters.outstandingOnly,
        this.filters.status,
        this.filters.value
      ];
    }, ( newVal, oldVal ) => {
      if ( newVal !== oldVal ) {
        this.$rootScope.$broadcast('filterOrders', this.filters);
      }
    }, true);

    this.$scope.$on('searchFilterChanged', ( event : ng.IAngularEvent, value : string ) => {
      if (!this.$state.transition) {
        this.filters.value = value;
      }
    });
  }

  getOrders ( params : TableQueryParameters ) : ng.IPromise<Array<TransactionCenterOrderModel>> {
    return this.workshopAPI.get('/transaction_center/purchase_orders', [
      this.filters.startDate       || '*',
      this.filters.endDate         || '*',
      this.filters.outstandingOnly || false,
      this.filters.status          || '*',
      this.filters.value           || '*',
      params.field,
      params.direction,
      params.pageSize,
      params.offset
    ])
    .then( ( orders : Array<TransactionCenterOrderModel> ) => {
      this.orders = orders;

      return orders;
    });
  }

  getCount () : ng.IPromise<number> {
    return this.workshopAPI.get('/transaction_center/purchase_orders_count', [
      this.filters.startDate       || '*',
      this.filters.endDate         || '*',
      this.filters.outstandingOnly || false,
      this.filters.status          || '*',
      this.filters.value           || '*',
    ])
    .then(( count : any ) => count.purchase_orders_count);
  }

  viewJobCard ( order : TransactionCenterOrderModel ) : void {
    this.$state.go('app.invoice', {
      customer_id: order.customer_id,
      id         : order.invoice_id,
      vehicle_id : order.vehicle_id
    })
  }

  viewOrder ( order : TransactionCenterOrderModel ) : void {
    this.$state.go('app.purchase-order', {
      vendor_id: order.vendor_id,
      id       : order.id
    });
  }

  viewVendor ( order : TransactionCenterOrderModel ) : void {
    this.$state.go('app.vendor', {
      id: order.vendor_id
    });
  }
}