import CustomerInfoHtml     from './templates/customer.info.html';
import CustomerQuickAddHtml from './templates/customer.quick-add.html';

export function CustomerSelectionForAccountingLiteCtrl (
  $rootScope,
  $scope,
  $state,
  $translate,
  $urlService,
  Customer,
  messages,
  sessionObjAPI,
  streamSearch,
  workshopAPI
) {

  $scope.company      = sessionObjAPI.company();
  $scope.customerApi  = Customer;
  $scope.formContent  = $scope.customer.id ? CustomerInfoHtml : CustomerQuickAddHtml;
  $scope.isAccounting = true;

  $scope.check = function () {
    if ($scope.customer.id) {
      $scope.$broadcast('open');
    }
  };

  $scope.close = function () {
    $scope.$broadcast('close');
  };

  $scope.createFromOtherBranch = function () {
    $scope.customerApi
      .createCustomerFromOtherBranch(true)
      .then(results => {
        if (results.vehicle) {
          $state.go($urlService.path().indexOf('invoice') > 1 ? 'app.invoice' : 'app.event', {
            customer_id: results.customer.id,
            vehicle_id : results.vehicle.id
          });

          messages.show('Customer & Vehicle Saved', 'success');
        }
        else {
          messages.show('Customer Saved', 'success');

          $scope.customerSelected(results.customer);
        }
      });
  };

  $scope.getCustomerBillerTag = function () {
    if ($rootScope.Company.hasIntegration('capricorn') && !$scope.customer.biller && $scope.customer.capricorn_member_number) {
      return $translate.instant('COMPANY_SPACE.INTEGRATIONS.CAPRICORN.CAPRICORN');
    }

    return $translate.instant('COMPANY_SPACE.BILLER');
  };

  $scope.init = function () {
    $scope.initContactMethods();
    $scope.initCustomerPriceTypes();
    $scope.initCustomerSources();
    $scope.initStates();
  };

  $scope.initContactMethods = function () {
    workshopAPI
      .get('/system/preferred_contact_method_types', null, 'contactMethods')
      .then(contacts => $scope.contact_method_types = contacts);
  };

  $scope.initCustomerPriceTypes = function () {
    workshopAPI
      .get('/system/customer_price_types', null, 'customerPriceTypes')
      .then(priceTypes => $scope.price_types = priceTypes);
  };

  $scope.initCustomerSources = function () {
    workshopAPI
      .get('/customer_sources', null, 'customerSources')
      .then(sources => $scope.customerSources = sources);
  };

  $scope.initStates = function () {
    workshopAPI
      .get('/system/state_types', null, 'states')
      .then(states => $scope.states = states);
  };

  $scope.save = function (form) {
    workshopAPI
      .post('/customers', $scope.customer, 'customer')
      .then(customer => {
        messages.show($translate.instant('JS_SPACE.MESSAGES.SAVED.CUSTOMER'), 'success');

        form.$setPristine();

        $scope.customerSelected(customer);
      }).catch(err => messages.error(err));
  };

  $scope.viewBranchHistory = function () {
    $scope.customerApi.viewBranchHistory($scope.customer);
  };

  $scope.customerSearch = {
    list    : [],
    selected: {
      id          : '',
      display_name: ''
    },
    choose: customer => $scope.customerSelected(customer),
    format: customer => customer ? customer.display_name: '',
    search: typed    => streamSearch('customers', typed).then(data => $scope.customerSearch.list.concat(data))
  };

  $scope.init();
}