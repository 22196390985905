import {
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  Output
} from '@angular/core';

import { PartstechOrderStatusModel } from './models/partstech.order-status';
import { PartstechService }          from './partstech.service';

@Component({
  selector   : 'wor-partstech-send-order-actions',
  template: require('./partstech.send-order.actions.component.html')
})
export class PartstechSendOrderActionsComponent {
  @Input() record: PartstechOrderStatusModel;

  @Output() onAddToCartClick: EventEmitter<any> = new EventEmitter();
  @Output() onDeleteClick   : EventEmitter<any> = new EventEmitter();

  constructor (
    @Inject('$translate')
    public $translate      : ng.translate.ITranslateService,
    @Inject(forwardRef(() => PartstechService))
    private parstechService: PartstechService
  ) {}

  canAddToCart () : boolean {
    return this.parstechService.isPartNotReady(this.record);
  }

  canDelete () : boolean {
    return !this.parstechService.isPartstechPart(this.record)
      || this.canAddToCart();
  }
}