import 'angular';

import '../api/api.module';
import '../core/core.module.ajs';
import '../messages/messages.module.ajs';

import { ImportBomHeadersLiteCtrl }            from './imports.bom-headers';
import { ImportBomItemsLiteCtrl }              from './imports.bom-items';
import { ImportCustomersLiteCtrl }             from './imports.customers';
import { ImportLiteCtrl }                      from './import';
import { ImportProductsLiteCtrl }              from './imports.products';
import { ImportSerialNumbersLiteCtrl }         from './imports.serial-numbers';
import { ImportStateTypesLiteCtrl }            from './imports.state-types';
import { ImportUnitsLiteCtrl }                 from './imports.units';
import { ImportVehicleHistoryHeadersLiteCtrl } from './imports.vehicle-history-headers';
import { ImportVehicleHistoryLinesLiteCtrl }   from './imports.vehicle-history-lines';
import { ImportVehiclesLiteCtrl }              from './imports.vehicles';
import { ImportVendorsLiteCtrl }               from './imports.vendors';

angular.module('app-lite.imports', [
  'app-lite.api',
  'app-lite.core',
  'app-lite.messages'
]).controller('import', ImportLiteCtrl)
.controller('ImportBomHeadersLiteCtrl', ImportBomHeadersLiteCtrl)
.controller('ImportBomItemsLiteCtrl', ImportBomItemsLiteCtrl)
.controller('ImportCustomersLiteCtrl', ImportCustomersLiteCtrl)
.controller('ImportProductsLiteCtrl', ImportProductsLiteCtrl)
.controller('ImportSerialNumbersLiteCtrl', ImportSerialNumbersLiteCtrl)
.controller('ImportStateTypesLiteCtrl', ImportStateTypesLiteCtrl)
.controller('ImportUnitsLiteCtrl', ImportUnitsLiteCtrl)
.controller('ImportVehicleHistoryHeadersLiteCtrl', ImportVehicleHistoryHeadersLiteCtrl)
.controller('ImportVehicleHistoryLinesLiteCtrl', ImportVehicleHistoryLinesLiteCtrl)
.controller('ImportVehiclesLiteCtrl', ImportVehiclesLiteCtrl)
.controller('ImportVendorsLiteCtrl', ImportVendorsLiteCtrl);