export function HardConfirmationLiteCtrl ($scope, message, messages, type) {
  $scope.message   = message;
  $scope.type      = type;
  $scope.code      = parseInt(Math.random()*10000);
  $scope.typedCode = '';

  $scope.confirm = function () {
    if ( $scope.code.toString() === $scope.typedCode.toString() ) {
      $scope.$close();
    }
    else {
      messages.show('Code does not match', 'error');
    }
  };
}