import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid';

import { PrivateComponent } from './private.component';

export const PrivateStates : Array<NgHybridStateDeclaration> = [
  {
    component : PrivateComponent,
    name      : 'ajs.app.private',
    redirectTo: 'ajs.app.private.dashboard',
    url       : '/private'
  },
  {
    loadChildren: () => import('../dashboard/dashboard.module').then(module => module.DashboardModule),
    name        : 'ajs.app.private.dashboard.**',
    url         : '/dashboard'
  }
];
