export function ManuallyLinkVendorLiteCtrl ($scope, $translate, company, messages, vendor, Vendor) {
  $scope.company = company;
  $scope.vendors = [];
  $scope.selectedItems = [];

  $scope.vendor = new Vendor(vendor);

  $scope.filters = {
    search_term: '',
    search_type: 'starts_with'
  };

  $scope.searchTypes = [
    { code: 'starts_with', description: 'Starts With' },
    { code: 'contains', description: 'Contains' }
  ];

  $scope.tableSettings = {
    columns: [
      {
        field : 'company_name',
        header: {
          text: $translate.instant('GENERAL_SPACE.FIELD.NAME')
        }
      },
      {
        field : 'phone',
        header: {
          text: $translate.instant('GENERAL_SPACE.FIELD.PHONE')
        },
        pipe  : 'phone',
      },
      {
        field : 'mobile',
        header: {
          text: $translate.instant('GENERAL_SPACE.FIELD.MOBILE')
        },
        pipe  : 'mobilePhone',
      },
      {
        field : 'email',
        header: {
          text: $translate.instant('GENERAL_SPACE.FIELD.EMAIL')
        }
      },
      {
        field : 'address1',
        header: {
          text: $translate.instant('GENERAL_SPACE.FIELD.ADDRESS')
        }
      },
      {
        field : 'address2',
        header: {
          text: $translate.instant('GENERAL_SPACE.FIELD.ADDRESS')
        }
      },
      {
        field : 'suburb',
        header: {
          text: $translate.instant('GENERAL_SPACE.FIELD.SUBURB')
        }
      },
      {
        field : 'state',
        header: {
          text: $translate.instant('GENERAL_SPACE.FIELD.STATE')
        }
      },
      {
        field : 'postcode',
        header: {
          text: $translate.instant('GENERAL_SPACE.FIELD.POSTCODE')
        }
      }
    ],
    empty: {
      text: $translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_SUPPLIERS_FOUND')
    },
    pagination: {
      enabled: false
    },
    rows: {
      highlight: {
        enabled: true
      },
      select: {
        enabled: true
      }
    },
    sorting: {
      enabled: false
    },
  };

  $scope.onRowClick = function () {
    $scope.selectedItems = $scope.vendors.filter((row) => row.selected);
  };

  $scope.onRowDoubleClick = function (event) {
    $scope.selectedItems = [event.row];
    $scope.confirm();
  };

  $scope.confirm = function () {
    $scope.vendor.saveLinkedVendor($scope.company, $scope.selectedItems[0]).then(() => {
      $scope.$close();
    }).catch(err => {
      messages.error(err);
    });
  };

  $scope.filter = function () {
    $scope.vendor.searchVendorsInCompany(company, $scope.filters).then(vendors => {
      $scope.vendors = vendors;
      $scope.selectedItems = $scope.vendors.filter((row) => row.selected);
    }).catch(err => {
      messages.error(err);
    });
  };
}