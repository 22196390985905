export function VendorQuickAddLiteCtrl (
  $scope,
  Company,
  workshopAPI
) {

  $scope.vendor  = {};

  $scope.save = function () {
    workshopAPI.post('/vendors', $scope.vendor, 'vendor').then(vendor => {
      $scope.$close(vendor);
    });
  };

  workshopAPI.get('/system/state_types', null, 'states').then(states => {
    $scope.states = states;
  });
}