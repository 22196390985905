import { InspectionTemplateItemModel } from './models/inspection.template.item';

import ProductSearchResultsHtml from '../products/templates/product.search.results.html';

interface InspectionTemplateItemScope extends ng.IScope {
  item           : InspectionTemplateItemModel;
  productSearch  : any;
  showTypeOptions: () => boolean;
};

export function InspectionTemplateItemCtrl (
  $scope     : InspectionTemplateItemScope,
  workshopAPI: any
) {

  $scope.showTypeOptions = function () : boolean {
    return $scope.item.inspection_type === 'GYR';
  };

  $scope.productSearch = {
    format     : ( value : any ) => value.item_code,
    query      : ( value : string ) => workshopAPI.get('/products/product_labor_list', [ value, 0, 5]),
    templateUrl: ProductSearchResultsHtml,
    choose ( product : any ) {
      $scope.item.product_item_code = product.item_code;
      $scope.item.product_id        = product.id;
    },
    value: {
      id       : $scope.item.product_id,
      item_code: $scope.item.product_item_code
    }
  };
};
