import { ApiServiceAjs }          from '../../../api/api.service.ajs';
import { FileUploadOptionsModel } from './models/file-upload.options';
import { SessionServiceAjs }      from '../../../sessions/session.service.ajs';
import { S3OptionsModel } from './models/s3-options';

export class FileUploadCtrl {
  $element      : ng.IRootElementService;
  $filter       : ng.FilterFactory;
  $q            : ng.IQService;
  $rootScope    : any;
  $state        : ng.ui.IStateService;
  $scope        : ng.IScope;
  $translate    : ng.translate.ITranslateService;
  messages      : any;
  s3Upload      : any;
  sessionObjAPI : SessionServiceAjs;
  workshopAPI   : ApiServiceAjs;

  acceptedTypes: string;
  file         : File;
  form         : ng.IFormController;
  options      : FileUploadOptionsModel;
  preview      : string;
  s3BucketLink : string;
  uploadReady  = false;
  uploading    = false;

  imageExtensions = [
    'gif',
    'jpeg',
    'jpg',
    'png'
  ];

  constructor (
    $element     : ng.IRootElementService,
    $filter      : ng.FilterFactory,
    $q           : ng.IQService,
    $rootScope   : any,
    $scope       : ng.IScope,
    $state       : ng.ui.IStateService,
    $translate   : ng.translate.ITranslateService,
    messages     : any,
    s3Upload     : any,
    sessionObjAPI: SessionServiceAjs,
    workshopAPI  : ApiServiceAjs
  ) {
    this.$element      = $element;
    this.$filter       = $filter;
    this.$q            = $q;
    this.$rootScope    = $rootScope;
    this.$scope        = $scope;
    this.$state        = $state;
    this.$translate    = $translate;
    this.messages      = messages;
    this.s3Upload      = s3Upload;
    this.sessionObjAPI = sessionObjAPI;
    this.workshopAPI   = workshopAPI;

    this.setupListener();
  }

  $onInit () {
    this.preview = this.options.file;

    this.acceptedTypes = this.getAcceptedTypes();
  }

  getAcceptedTypes () {
    switch (this.options.accept) {
      case 'images':
      default:
        return '.gif,.jpeg,.jpg,.png';
    }
  }

  getExtension ( filename : string ) {
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename) : ['png'];
  }

  isAdvertisement () {
    return this.options.type === 'advertisement';
  }

  prettifySize () {
    const mb = this.options.size / 1000000;

    if ( mb < 1 ) {
      const kb = mb * 1000;

      return `${ this.$filter('number')(kb) }KB`;
    }

    return `${ this.$filter('number')(mb) }MB`;
  }

  setupListener () {
    const self = this;

    self.$element.find('input[type="file"]').on('change', ( e : Event ) => {
      const file = ( e.target as HTMLInputElement ).files[0];

      if (file.size > self.options.size) {
        angular.element(e.target).val(null);

        self.messages.show(`${ self.options.type }'s cannot exceed ${ self.prettifySize() }`, 'error');

        return;
      }

      if (self.options.accept === 'images' && self.imageExtensions.indexOf(self.getExtension(file.name)[0]) < 0) {
        angular.element(e.target).val(null);

        let message = `File must be one of the following types: `;

        for (let i = 0 ; i < self.imageExtensions.length ; i++) {
          message = message + `${ (self.imageExtensions[i]).toUpperCase() }${ i === self.imageExtensions.length - 1 ? '' : ',' } `;
        }

        self.messages.show(message, 'error');

        return;
      }

      self.$scope.$apply(() => {
        self.file = file;
      });

      const reader = new FileReader();

      reader.onload = ( e : any ) => {
        self.$scope.$apply(() => {
          self.form.$setDirty();

          self.preview = e.target.result;
        });
      };

      self.uploadReady = true;

      reader.readAsDataURL(file);
    });
  }

  showPreview () {
    return this.preview && !this.options.hidePreview;
  }

  upload () {
    this.uploading = true;

    this.workshopAPI.get('/company/s3_bucket_link')
    .then(data => {
      this.s3BucketLink = data;

      return this.isAdvertisement()
        ? this.workshopAPI.get('/workshop_admin/s3_signature_for_ad_v4')
        : this.workshopAPI.get('/company/s3_signature_v4', [ this.options.type, this.getExtension(this.file.name) ]);
    })
    .then(( options : S3OptionsModel ) => {
      const call = this.s3Upload(this.file, options);

      call.request.onload = ( e : any ) => {
        if (e.target.status === 204) {
          return this.$q.when()
          .then(() => {
            return this.isAdvertisement()
            ? this.$q.when(true)
            : this.workshopAPI.post('/company/s3_path', {
              file_type: this.options.type,
              s3_link  : options.key
            });
          })
          .then(() => this.sessionObjAPI.refresh())
          .then(() => {
            this.uploading = false;

            if (this.options.saveRootScope) {
              this.$rootScope[this.options.type] = this.preview;
            }

            this.form.$setPristine();

            this.messages.show(this.$translate.instant('JS_SPACE.MESSAGES.UPLOAD_SUCCESSFUL'), 'success');

            this.$state.reload();
          })
          .catch(err => {
            this.uploading = false;

            this.messages.error(err);
          });
        }
      };

      call.request.onerror = ( e : any ) => {
        this.uploading = false;

        this.messages.show(e.target.responseText, 'error');
      };

      call.request.open('POST', this.s3BucketLink, true);
      call.request.send(call.fd);

    })
    .catch(err => {
      this.uploading = false;

      this.messages.error(err);
    });
  }
}