import { HotkeyMapModel } from './models/hotkey.map';

interface HotkeyScope extends ng.IScope {
  debounce       : number;
  handleKeyEvent : ( e : any ) => any;
  keyEvent       : 'keyup';
  map            : HotkeyMapModel;
  timeout        : ng.IPromise<void>;
}

export function HotkeyCtrl (
  $document: ng.IDocumentService,
  $scope   : HotkeyScope,
  $timeout : ng.ITimeoutService,
  KEYCODES : any
) {
  $scope.keyEvent = 'keyup';

  $scope.handleKeyEvent = function ( e : any ) {
    const keycode    = e.code || e.keyCode || e.which;
    const mappedKeys = Object.keys($scope.map);

    let keyPressed;

    for (const key in KEYCODES) {
      if (KEYCODES[key] === keycode) {
        keyPressed = key;
        break;
      }
    }

    if (mappedKeys.indexOf(keyPressed) >= 0) {
      $scope.map[keyPressed](keyPressed);
    }
  };

  angular.element($document[0].body).on($scope.keyEvent, ( e : any ) => {
    if ($scope.timeout) {
      return;
    }

    $scope.timeout = $timeout(() => {
      $scope.handleKeyEvent(e);

      $timeout.cancel($scope.timeout);

      $scope.timeout = null;
    }, $scope.debounce);
  });

  $scope.$on('$destroy', () => {
    $($document[0].body).off($scope.keyEvent);
  });
}
