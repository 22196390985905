import * as angular from 'angular';

import { ApiModel }          from './models/api.model';
import { ModalService }      from '../modals/modal.service.ajs';
import { SessionServiceAjs } from '../sessions/session.service.ajs';

export class ApiServiceAjs {
  $http          : ng.IHttpService;
  $q             : ng.IQService;
  $rootScope     : any;
  $timeout       : ng.ITimeoutService;
  $translate     : ng.translate.ITranslateService;
  $window        : ng.IWindowService;
  API            : ApiModel;
  autosoftCache  : ng.ICacheObject;
  encodeParameter: any;
  modalApi       : ModalService;
  removeUntouched: any;
  sessionObjAPI  : SessionServiceAjs;

  // used to prevent multiple alerts
  // from displaying simultaneously.
  isAlerted = false;

  constructor (
    $http          : ng.IHttpService,
    $q             : ng.IQService,
    $rootScope     : any,
    $timeout       : ng.ITimeoutService,
    $translate     : ng.translate.ITranslateService,
    $window        : ng.IWindowService,
    API            : any,
    autosoftCache  : ng.ICacheObject,
    encodeParameter: any,
    modalApi       : ModalService,
    removeUntouched: any,
    sessionObjAPI  : SessionServiceAjs
  ) {
    this.$http           = $http;
    this.$q              = $q;
    this.$rootScope      = $rootScope;
    this.$timeout        = $timeout;
    this.$translate      = $translate;
    this.$window         = $window;
    this.API             = API;
    this.autosoftCache   = autosoftCache;
    this.encodeParameter = encodeParameter;
    this.modalApi        = modalApi;
    this.removeUntouched = removeUntouched;
    this.sessionObjAPI   = sessionObjAPI;
  }

  private _isParamEmpty ( param : any ) : boolean {
    return param === undefined
      || param === null
      || param === '';
  }

  /**
   * a simple function that checks if the data
   * is an object, if it is, we stringify it and return it.
   * Used to transform requests that have been modified by outside functions.
   */
  private _stringify ( data : any ) : string {
    return angular.isObject(data) ? angular.toJson(data) : data;
  }

  private _validateSession (error) {
    let message = 'You have been logged out.';

    if ( !this.isAlerted && this.sessionObjAPI.isSessionError(error) ) {
      this.isAlerted = true;

      if (this.sessionObjAPI.isSessionExpired(error)) {
        message = this.$translate.instant('JS_SPACE.MESSAGES.SESSION.EXPIRED');
      }

      else if (this.sessionObjAPI.isSessionDeactivated(error)) {
        message = this.$translate.instant('JS_SPACE.MESSAGES.SESSION.DEACTIVATED');
      }

      this.sessionObjAPI.clear();
      this.$window.location.assign('login.html');

      this.modalApi
        .alert({
          message,
          title: 'Session Error'
        })
        .finally(() => this.isAlerted = false);
    }
  }

  delete ( url : string, id ?: string ) : angular.IPromise<any> {
    if (id) {
      url = url + '/' + this.encodeParameter(id);
    }

    return this.$timeout(() => {
      return this.$http.delete(encodeURI(`${this.API.url}${ url.charAt(0) === '/' ? '': '/' }${url}`), { withCredentials: true })
      .then(response => response.data)
      .catch(err => {
        this._validateSession(err);

        return this.$q.reject(err.data);
      });
    });
  }

  get ( url : string, params ?: any, cacheObj ?: string, config ?: any ) : angular.IPromise<any> {
    const cache  = this.autosoftCache;
    const cached = cache.get(cacheObj);

    config = angular.isObject(config) ? config: {};

    if (params || params === false || params === 0) {
      if ( !angular.isArray(params) ) {
        params = [params];
      }

      for (var i = 0; i < params.length; i++) {
        if (!this._isParamEmpty(params[i])) {
          url = url + '/' + this.encodeParameter( params[i].toString(), 'get' );
        }
      };
    }

    if (cached) {
      return this.$q.when(cached);
    }

    this.$rootScope.resolving = true;

    if (!config.skipEncoding) {
      url = encodeURI(url);
    }

    config.withCredentials = true;

    return this.$timeout(() => {
      return this.$http.get(`${this.API.url}${ url.charAt(0) === '/' ? '': '/' }${url}`, config)
      .then(response => {
        if (cacheObj) {
          cache.put(cacheObj, response.data);
        }

        return response.data;
      })
      .catch(err => {
        this._validateSession(err);

        return this.$q.reject(err.data);
      })
      .finally(() => {
        this.$rootScope.resolving = false;
      });
    });
  }

  patch ( url : string, data : any, name ?: string, cleanup ?: string, settings ?: any ) : angular.IPromise<any> {
    settings = angular.extend({
      data           : {},
      method         : 'PATCH',
      url            : `${this.API.url}${ url.charAt(0) === '/' ? '': '/' }${url}`,
      withCredentials: true
    }, settings || {});

    this.$rootScope.resolving = true;

    if (name) {
      settings.data[name] = data;
    }

    // If the parameters need to be flat
    else {
      settings.data = data || {};
    }

    if (settings.transformRequest) {
      settings.transformRequest = [settings.transformRequest, this._stringify];
    }

    if (cleanup) {
      settings.data[name][cleanup] = this.removeUntouched.remove(settings.data[name][cleanup]);
    }

    return this.$timeout(() => {
      return this.$http(settings)
      .then(result => settings.verbose ? result : result.data)
      .catch(err => {
        this._validateSession(err);

        return this.$q.reject(settings.verbose ? err : err.data);
      })
      .finally(() => {
        this.$rootScope.resolving = false;
      });
    });
  }

  post (url : string, data ?: any, name ?: string, cleanup ?: string, settings ?: any) : angular.IPromise<any> {
    settings = angular.extend({
      data           : {},
      method         : 'POST',
      url            : `${this.API.url}${ url.charAt(0) === '/' ? '': '/' }${url}`,
      withCredentials: true
    }, settings || {});

    this.$rootScope.resolving = true;

    // If the parameters need to be nested from the session id
    if (name) {
      settings.data[name] = data;

      if (cleanup && angular.isString(cleanup)) {
        settings.data[name][cleanup] = this.removeUntouched.remove(settings.data[name][cleanup]);
      }
      else if (cleanup) {
        settings.data[name] = this.removeUntouched.remove(settings.data[name]);
      }
    }

    // If the parameters need to be flat
    else {
      settings.data = data;
    }

    if (settings.transformRequest) {
      settings.transformRequest = [settings.transformRequest, this._stringify];
    }

    return this.$timeout(() => {
      return this.$http(settings)
      .then(result => settings.verbose ? result : result.data)
      .catch(err => {
        this._validateSession(err);

        return this.$q.reject(settings.verbose ? err : err.data);
      })
      .finally(() => {
        this.$rootScope.resolving = false;
      });
    });
  }

  stream ( url : string, params ?: any ) : angular.IPromise<any> {
    const deferred = this.$q.defer();

    if (params && !angular.isArray(params)) {
      params = [params];
    }
    else if (params) {
      for (var i = 0; i < params.length; i++) {
        url = url + '/' + this.encodeParameter( params[i].toString() );
      };
    }

    const stream : EventSource = new EventSource(encodeURI(`${this.API.url}${ url.charAt(0) === '/' ? '': '/' }${url}`), { withCredentials: true });

    stream.onmessage = function (e) {
      const data = JSON.parse(e.data);

      if (!data.length) {
        this.close();

        deferred.resolve([]);
      }
      else {
        deferred.resolve(data);
      }
    };

    stream.onerror = function () {
      this.close();

      deferred.resolve([]);
    };

    return deferred.promise;
  }
}