import { TemplateModel } from './models/template';

import TemplateHtml from './company-lists.template.html';

interface CompanyListsTemplatesScope extends ng.IScope {
  deleteTemplate: ( index : number, evt ?: ng.IAngularEvent ) => void;
  templates   : Array<TemplateModel>;
  title       : string;
  type        : string;
  viewTemplate: ( index : number, evt ?: ng.IAngularEvent ) => void;
}

export function CompanyListsTemplatesCtrl (
  $uibModal     : any,
  $scope     : CompanyListsTemplatesScope,
  $translate : ng.translate.ITranslateService,
  confirm    : any,
  messages   : any,
  workshopAPI: any
) {
  $scope.viewTemplate = function ( index : number ) {
    $uibModal.open({
      controller : 'TemplateCtrl',
      templateUrl: TemplateHtml,
      windowClass: 'lg-modal',
      resolve    : {
        template: () => (index || index === 0) ? $scope.templates[index]: { wording_type: $scope.type }
      }
    }).result
    .then((template) => {
      messages.show($translate.instant('JS_SPACE.MESSAGES.SAVED.TEMPLATE'), 'success');

      if (index || index === 0) {
        $scope.templates[index] = template;
      }
      else {
        $scope.templates.push(template);
      }
    });
  };

  $scope.deleteTemplate = function ( index : number  , evt : ng.IAngularEvent ) {
    confirm.generic($translate.instant('JS_SPACE.CONFIRM.DELETE_TEMPLATE'))
    .then(() => workshopAPI.delete('/templated_wording', $scope.templates[index].id))
    .then(() => {
      messages.show($translate.instant('JS_SPACE.MESSAGES.DELETED.TEMPLATE'), 'success');

      $scope.templates.splice(index, 1);
    })
    .catch(err => {
      messages.error(err);
    });

    evt.stopPropagation();
  };
};
