interface AttachmentFileModelScope extends ng.IScope {
  clearAttachment : () => void;
  progress        : number;
}

export function AttachmentFileModelDirective (
  $parse: ng.IParseService
) : ng.IDirective {
  const directive : ng.IDirective = {
    restrict: 'A',
    link    (
      scope  : AttachmentFileModelScope,
      element: any,
      attrs  : ng.IAttributes
    ) {
      const model       = $parse(attrs.fileModel);
      const modelSetter = model.assign;

      element.on('change', () => {
        const file  = element[0].files[0];

        modelSetter(scope, file);

        if (!scope.$$phase) {
          scope.$apply();
        }
      });

      scope.clearAttachment = function () {
        element.val('');
        element.trigger('change');

        scope.progress = 0;
      };
    }
  };

  return directive;
}