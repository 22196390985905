import 'angular';

import { BusinessIntelligenceDashboardCtrl }      from './business-intelligence.dashboard';
import { BusinessIntelligenceDashboardDirective } from './business-intelligence.dashboard.directive';
import { BusinessIntelligenceService }            from './business-intelligence.service';

angular.module('app-lite.business-intelligence', [])
  .controller('BusinessIntelligenceDashboardCtrl', BusinessIntelligenceDashboardCtrl)
  .directive('biDashboard', BusinessIntelligenceDashboardDirective)
  .factory('businessIntelligenceApi', BusinessIntelligenceService);
