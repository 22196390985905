import 'angular';

import 'angular-block-ui/dist/angular-block-ui.css';

import 'angular-block-ui';
import 'angular-sanitize';
import 'angular-translate';
import 'angular-translate-loader-static-files';

import '@uirouter/angularjs';
import '@uirouter/angular-hybrid';
import 'angular-ui-bootstrap';

import 'ua-device-detector';
import 're-tree';
import 'ng-device-detector';

import './controls/core.controls.module.ajs';
import './filters/core.filters.module.ajs';
import './directives/date-picker/date-picker.module.ajs';
import './directives/file-upload/file-upload.module.ajs';
import './directives/hotkey/hotkey.module.ajs';

import { downgradeInjectable }                from '@angular/upgrade/static';

import { COUNTRIES }                          from './constants/core.countries.constant.ajs';
import { KEYCODES }                           from './constants/core.keycodes.constant.ajs';

import { CoreBlockUIConfig }                  from './core.block-ui.config.ajs';
import { CoreQConfig }                        from './core.q.config.ajs';
import { CoreRouterConfig }                   from './core.router.config.ajs';
import { CoreTranslateConfig }                from './core.translate-config';
import { CoreUibConfig }                      from './core.uib.config.ajs';

import { CoreLiteCtrl }                       from './core';

import { CoreBoxTogglerDirective }            from './directives/core.boxToggler.directive';
import { CoreChosenDirective }                from './directives/core.chosen.directive';
import { CoreDateDirective }                  from './directives/core.date.directive';
import { CoreDropdownChosenDirective }        from './directives/core.dropdown-chosen.directive';
import { CoreEditableCellDirective }          from './directives/core.editableCell.directive';
import { CoreFlipModalDirective }             from './directives/core.flip-modal.directive';
import { CoreHideLoadingDirective }           from './directives/core.hide-loading.directive';
import { CoreIFrameLoaderDirective }          from './directives/core.iframe-loader.directive';
import { CoreIntegrationFormDirective }       from './directives/core.integration-form.directive';
import { CoreMaxLengthDirective }             from './directives/core.max-length.directive.ajs';
import { CoreReportDateDirective }            from './directives/core.report-date.directive';
import { CoreSectionToggleDirective }         from './directives/core.section-toggle.directive';
import { CoreShortDateDirective }             from './directives/core.short-date.directive';
import { CoreTypeaheadEditableCellDirective } from './directives/core.typeaheadEditableCell.directive';
import { CoreValidSubmitDirective }           from './directives/core.valid-submit.directive';
import { PhoneValidatorDirective }            from './directives/core.phone-validator.directive.ajs';
import { PostcodeValidatorDirective }         from './directives/core.postcode-validator.directive.ajs';
import { UniquenessValidatorDirective }       from './directives/core.uniqueness-validator.ajs';

import { BigDecimalService }                  from './services/core.big-decimal.service.ajs';
import { CoreAutosoftCacheService }           from './services/core.autosoft-cache.service';
import { CoreBlockService }                   from './services/core.block-ui.service.ajs';
import { CoreCurrencyAPIService }             from './services/core.currency.service';
import { CoreCurrencyInfoService }            from './services/core.currencyInfo.service';
import { CoreDateFormatService }              from './services/core.date-format.service';
import { CoreEncodeParameterService }         from './services/core.encode-parameter.service';
import { CoreIEService }                      from './services/core.internet-explorer.service';
import { CoreInheritorService }               from './services/core.inheritor.service';
import { CoreRemovedUntouchedService }        from './services/core.remove-untouched.service';
import { CoreRoundService }                   from './services/core.round.service';
import { CoreStreamSearchService }            from './services/core.stream-search.service';
import { CoreToProperCaseService }            from './services/core.to-proper-case.service';
import { CoreValidatePhoneService }           from './services/core.validate-phones.service';
import { DateParserService }                  from './services/date-parser.service';
import { DomainParserService }                from './services/core.domain.parser.service.ajs';
import { JsonApiService }                     from './services/core.json-api.service.ajs';
import { WindowServiceAjs }                   from './services/core.window.service.ajs';

angular.module('app-lite.core', [
  'blockUI',
  'ng.deviceDetector',
  'ngSanitize',
  'pascalprecht.translate',
  'ui.bootstrap',
  'ui.router',
  'ui.router.upgrade',

  'app-lite.core.controls',
  'app-lite.core.filters',
  'app-lite.core.date-picker',
  'app-lite.core.file-upload',
  'app-lite.core.hotkey'
]).config(CoreBlockUIConfig)
  .config(CoreQConfig)
  .config(CoreRouterConfig)
  .config(CoreTranslateConfig)
  .config(CoreUibConfig)
  .constant('COUNTRIES', COUNTRIES)
  .constant('KEYCODES', KEYCODES)
  .controller('CoreLiteCtrl', CoreLiteCtrl)
  .directive('boxToggler', CoreBoxTogglerDirective)
  .directive('chosen', CoreDropdownChosenDirective)
  .directive('date', CoreDateDirective)
  .directive('editableCell', CoreEditableCellDirective)
  .directive('flipModal', CoreFlipModalDirective)
  .directive('hideLoading', CoreHideLoadingDirective)
  .directive('iframeLoader', CoreIFrameLoaderDirective)
  .directive('integrationForm', CoreIntegrationFormDirective)
  .directive('mdsChosen', CoreChosenDirective)
  .directive('mdsMaxLength', CoreMaxLengthDirective)
  .directive('reportDate', CoreReportDateDirective)
  .directive('sectionToggle', CoreSectionToggleDirective)
  .directive('shortDate', CoreShortDateDirective)
  .directive('typeaheadEditableCell', CoreTypeaheadEditableCellDirective)
  .directive('validSubmit', CoreValidSubmitDirective)
  .directive('worPhoneValidator', PhoneValidatorDirective)
  .directive('worPostcodeValidator', PostcodeValidatorDirective)
  .directive('worUniquenessValidator', UniquenessValidatorDirective)
  .factory('autosoftCache', CoreAutosoftCacheService)
  .factory('blockApi', CoreBlockService)
  .factory('currencyAPI', CoreCurrencyAPIService)
  .factory('currencyInfo', CoreCurrencyInfoService)
  .factory('dateFormat', CoreDateFormatService)
  .factory('domainParser', DomainParserService)
  .factory('encodeParameter', CoreEncodeParameterService)
  .factory('IE', CoreIEService)
  .factory('inheritorApi', CoreInheritorService)
  .factory('removeUntouched', CoreRemovedUntouchedService)
  .factory('round', CoreRoundService)
  .factory('streamSearch', CoreStreamSearchService)
  .factory('toProperCase', CoreToProperCaseService)
  .factory('validatePhone', CoreValidatePhoneService)
  .service('jsonAPI', JsonApiService)
  .service('bigDecimalApi', BigDecimalService)
  .service('dateParsing', downgradeInjectable(DateParserService))
  .service('windowApi', WindowServiceAjs);
