import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm }                       from '@angular/forms';
import { NgbDateAdapter, NgbDateParserFormatter }         from '@ng-bootstrap/ng-bootstrap';

import { DateAdapter }         from './date.adapter';
import { DateParserFormatter } from './date.parser';
import { DateService }         from './date.service';

@Component({
  selector   : 'wor-date-control',
  styles: [require('./date-control.component.scss')],
  template: require('./date-control.component.html'),

  providers: [
    {
      provide : NgbDateAdapter,
      useClass: DateAdapter
    },
    { provide : NgbDateParserFormatter,
      useClass: DateParserFormatter
    }
  ],

  viewProviders: [
    {
      provide    : ControlContainer,
      useExisting: NgForm
    }
  ]
})
export class DateControlComponent implements OnInit {
  @Input() icon = 'date-toggle-icon';
  @Input() label: string;
  @Input() model: string;
  @Input() name : string;
  @Input() togglePosition = 'right';

  /**
   * if the required & disabled flags are present in the html
   * but not set to any value then it's value will
   * be an empty string. If that's the case, set an
   * internal variable to true here. We need this
   * truthy check for the label class.
   */
  @Input() set disabled ( _disabled : string | boolean ) {
    this._disabled = _disabled === '' || !!_disabled;
  }

  @Input() set required ( _required : string | boolean ) {
    this._required = _required === '' || !!_required;
  }

  @Output() blur: EventEmitter<Event> = new EventEmitter();
  @Output() focus: EventEmitter<Event> = new EventEmitter();
  @Output() modelChange: EventEmitter<boolean> = new EventEmitter();

  _disabled: boolean;
  _required: boolean;
  yearOnly = false;

  constructor (
    private dateService: DateService
  ) {}

  ngOnInit(): void {
    if (this.model && !this.dateService.containsDelimiter(this.model)) {
      this.yearOnly = true;
    }
  }

  isTogglePosition ( position : 'left' | 'right' = 'right' ) : boolean {
    return this.togglePosition === position;
  }
};
