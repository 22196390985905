import VendorSearchResultsHtml  from './templates/vendors.search.results.html';

export function ChangeVendorLiteCtrl ($scope, streamSearch, vendor) {
  $scope.vendor = vendor;

  $scope.searchResultsTemplateUrl = VendorSearchResultsHtml;

  $scope.search = {
    list: [],
    selected: {
      id: '',
      company_name: ''
    },
    search (typed) {
      const self = this;

      return streamSearch('vendors', typed).then(vendors => {
        return (self.list.concat(vendors));
      });
    },
    format (vendor) {
      return vendor ? vendor.company_name: '';
    }
  };

  $scope.confirm = function () {
    $scope.$close($scope.search.selected);
  };
}