import { Transition, TransitionService } from '@uirouter/core';

import { ApiServiceAjs } from '../api/api.service.ajs';
import { TableService }  from '../table/table.service';

import PrintJobsHtml      from './jobs/transaction-center.jobs.print.html';
import SendJobBulkSmsHtml from './jobs/transaction-center.jobs.send-bulk-sms.html';
import WindowFlipHtml     from '../core/templates/window-flip.html';

export class TransactionCenterCtrl {
  filters = {
    value: ''
  };

  constructor (
    private $rootScope  : any,
    private $scope      : ng.IScope,
    private $state      : ng.ui.IStateService,
    private $transitions: TransitionService,
    private $uibModal   : ng.ui.bootstrap.IModalService,
    private tableApi    : TableService,
    private workshopAPI : ApiServiceAjs
  ) {
    this.$scope.$watch(() => this.filters.value, ( newVal : string, oldVal : string ) => {
      if (newVal !== oldVal) {
        this.$scope.$broadcast('searchFilterChanged', newVal);
      }
    });

    this.$transitions.onStart({
      to: 'app.transaction-center.**'
    }, ( transition : Transition ) => {
      const cache = this.tableApi.getCacheForTable(transition.to().data.tableCacheKey);

      this.filters.value = cache.filtering.value || '';
    });
  }

  createNew () : void {
    const tab = this.$state.current.name.replace('app.transaction-center.','');

    switch ( tab ) {
      case 'bookings':
        this.$state.go('app.event');
        break;
      case 'inspections':
        this.$state.go('app.inspection');
        break;
      case 'jobs':
        this.$state.go('app.invoice');
        break;
      case 'orders':
        this.$state.go('app.purchase-order');
        break;
      case 'vendor-invoices':
        this.$state.go('app.vendor-invoice');
        break;
      default:
        break;
    }
  }

  isTab ( tab : string ) : boolean {
    return this.$state.current.name === `app.transaction-center.${ tab }`;
  }

  printJobsForDate () : ng.IPromise<any> {
    return this.$uibModal.open({
      controller  : 'PrintJobsCtrl',
      controllerAs: '$ctrl',
      templateUrl : PrintJobsHtml
    }).result;
  }

  sendBulkSMS () : ng.IPromise<any> {
    return this.$uibModal.open({
      controller       : 'SendJobBulkSmsCtrl',
      controllerAs     : '$ctrl',
      templateUrl      : SendJobBulkSmsHtml,
      windowClass      : 'lg-modal flip-container',
      windowTemplateUrl: WindowFlipHtml,
      resolve          : {
        constraints    : () => this.workshopAPI.get('/system/job_filter_constraint_types'),
        statusTypes    : () => this.workshopAPI.get('/system/filter_job_status_types'),
        messageSettings: () => this.$rootScope.Company.getDefaultMessages()
      }
    }).result;
  }
}
