export function BranchVendorInvoicesLiteCtrl (
  $rootScope,
  $scope,
  $translate,
  tableApi,
  workshopAPI
) {
  $scope.tableCacheKey = 'branchVendorInvoices';

  $scope.filtering = tableApi.setFiltersFromCache($scope.tableCacheKey, {
    begin_date : '*',
    end_date   : '*',
    import_type: '*',
    status_type: '*'
  });

  $scope.tableSettings = {
    broadcast: 'filterInvoices',
    cache    : {
      enabled: true,
      key    : $scope.tableCacheKey
    },
    columns: [
      {
        classes: 'text-left',
        field  : 'vendor_invoice_number',
        header : {
          text: $translate.instant('GENERAL_SPACE.FIELD.REFERENCE')
        }
      },
      {
        classes: 'text-left',
        field  : 'post_date',
        header : {
          text: $translate.instant('GENERAL_SPACE.FIELD.POST_DATE')
        },
        pipe: 'globalDate'
      },
      {
        classes: 'text-left',
        field  : 'invoice_type',
        header : {
          text: $translate.instant('JS_SPACE.COLUMNS.TRAN_TYPE')
        },
        pipe: 'invoiceType'
      },
      {
        classes: 'text-left',
        field  : 'invoice_status',
        header : {
          text: $translate.instant('GENERAL_SPACE.FIELD.STATUS')
        },
        pipe: 'invoiceStatus'
      },
      {
        classes: 'text-right',
        field  : 'total',
        header : {
          classes: 'text-right',
          text   : $translate.instant('JS_SPACE.COLUMNS.AMOUNT')
        },
        pipe: 'globalCurrency',
      },
      {
        classes: 'text-right',
        field  : 'balance_due',
        header : {
          classes: 'text-right',
          text   : $translate.instant('JS_SPACE.COLUMNS.BALANCE')
        },
        pipe: 'globalCurrency',
      }
    ],
    empty: {
      text: $translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_INVOICES_FOUND')
    },
    pagination: {
      enabled   : true,
      pageSize  : tableApi.getCachedPaging($scope.tableCacheKey).pageSize || 5,
      pageSizes : [ 5, 10, 25, 50 ],
      queryCount: () => workshopAPI.get('/vendors/invoice_count', [
        $scope.vendor.id,
        $scope.filtering.status_type,
        '*',
        $scope.filtering.import_type,
        $scope.filtering.begin_date,
        $scope.filtering.end_date
      ])
    },
    rows: {
      highlight: {
        enabled: true
      }
    },
    sorting: tableApi.setSortingFromCache($scope.tableCacheKey, {
      direction: 'desc',
      enabled  : true,
      field    : 'post_date'
    }),
  };

  $scope.getInvoices = ( params ) => {
    return workshopAPI.get('/vendors/invoice_list', [
      $scope.vendor.id,
      params.offset,
      params.pageSize,
      $scope.filtering.status_type,
      '*',
      $scope.filtering.import_type,
      $scope.filtering.begin_date || '*',
      $scope.filtering.end_date || '*',
      params.field,
      params.direction,
    ]).then(function (invoices) {
      $scope.invoices = invoices;

      return invoices;
    });
  };

  $scope.getStatusTypes = function () {
    return workshopAPI.get('/system/invoice_status_types').then(types => {
      types.unshift({
        code       : '*',
        description: $translate.instant('GENERAL_SPACE.BUTTON.ALL')
      });

      return types;
    });
  };

  $scope.$watch('[filtering.begin_date, filtering.end_date, filtering.status_type, filtering.import_type]', (newVal, oldVal) => {
    if (newVal !== oldVal) {
      $rootScope.$broadcast('filterInvoices', $scope.filtering);
    }
  }, true);

  $scope.getStatusTypes().then(types => {
    $scope.statusTypes = types;
  });
}