import { CustomerPortalService } from './customer-portal.service.ajs';
import { CompanySettingsModel }  from '../companies/models/company.settings';

interface CustomerPortalSettingsScope extends ng.IScope {
  activate  : () => void;
  deactivate: () => void;
  settings  : CompanySettingsModel;
};

export function CustomerPortalSettingsCtrl (
  $state           : ng.ui.IStateService,
  $scope           : CustomerPortalSettingsScope,
  $translate       : ng.translate.ITranslateService,
  customerPortalApi: CustomerPortalService,
  messages         : any,
  settings         : CompanySettingsModel
) {
  $scope.settings = settings;

  $scope.activate = function () {
    customerPortalApi.activate()
    .then(() => {
      messages.show($translate.instant('JS_SPACE.INTEGRATIONS.ACTIVATED', {
        name: $translate.instant('CUSTOMER_SPACE.CUSTOMER_PORTAL.TITLE')
      }), 'success');

      $state.reload();
    })
    .catch(err => {
      messages.error(err);
    });
  };

  $scope.deactivate = function () : void {
    customerPortalApi.deactivate()
    .then(() => {
      messages.show($translate.instant('JS_SPACE.INTEGRATIONS.DEACTIVATED', {
        name: $translate.instant('CUSTOMER_SPACE.CUSTOMER_PORTAL.TITLE')
      }), 'success');

      $state.reload();
    })
    .catch(err => {
      messages.error(err);
    });
  };
};
