export function ProductBranchStockLiteCtrl (
  $scope,
  $translate,
  products
) {
  $scope.products = products;

  $scope.tableSettings = {
    columns: [
      {
        field : 'store_name',
        header: {
          text: 'Store Name'
        }
      },
      {
        classes: 'all-caps',
        field  : 'item_code',
        header : {
          text: $translate.instant('PRODUCT_SPACE.ITEM_CODE')
        }
      },
      {
        classes: 'text-right',
        field  : 'quantity_on_hand',
        header : {
          classes: 'text-right',
          text   : $translate.instant('PRODUCT_SPACE.QTY')
        }
      },
      {
        classes: 'text-right',
        field  : 'qty_reserved',
        header : {
          classes: 'text-right',
          text   : 'Qty Reserved'
        }
      }
    ],
    empty: {
      text: $translate.instant('GENERAL_SPACE.STATUS.NO_RESULTS')
    },
    pagination: {
      enabled: false
    },
    sorting: {
      enabled: false
    },
    static: true
  };
};
