import BusinessIntelligenceDashboardHtml from './business-intelligence.dashboard.html';

export function BusinessIntelligenceDashboardDirective () {
  return {
    controller: 'BusinessIntelligenceDashboardCtrl',
    restrict  : 'E',
    scope     : {
      id: '='
    },
    templateUrl: BusinessIntelligenceDashboardHtml
  };
};
