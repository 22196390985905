import { blockUI } from 'angular';

import { InvoiceModel }  from '../invoices/models/invoice';
import { StripeService } from './stripe.service.ajs';

interface StripeCheckoutScope extends ng.IScope {
  accountId           : string;
  block               : blockUI.BlockUIService;
  handlePaymentError  : ( error : stripe.Error ) => void;
  handlePaymentSuccess: ( intent : stripe.paymentIntents.PaymentIntent ) => void;
  invoice             : InvoiceModel;
  publicKey           : string;
  submit              : () => void;
}

export function StripeCheckoutCtrl (
  $scope           : StripeCheckoutScope,
  $translate       : ng.translate.ITranslateService,
  $uibModalInstance: any,
  blockUI          : blockUI.BlockUIService,
  invoice          : InvoiceModel,
  messages         : any,
  stripeApi        : StripeService
) {
  $scope.accountId = stripeApi.getAccountId();
  $scope.block     = blockUI.instances.get('stripeCheckoutBlock');
  $scope.invoice   = invoice;
  $scope.publicKey = stripeApi.getPublicKey();

  $scope.handlePaymentError = function ( error : stripe.Error ) : void {
    $scope.block.stop();

    messages.error(error.message);
  };

  $scope.handlePaymentSuccess = function ( intent : stripe.paymentIntents.PaymentIntent ) : void {
    messages.show($translate.instant('JS_SPACE.MESSAGES.PAYMENT_SUCCESSFUL'), 'success');

    $uibModalInstance.close(intent);
  };

  $scope.submit = function () {
    $scope.block.start($translate.instant('GENERAL_SPACE.MESSAGE.SENDING'));

    $scope.$broadcast('collectStripePayment');
  };
}
