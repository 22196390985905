import { NgModule } from '@angular/core';

import { BookingsComponent } from './bookings.component';
import { BookingsService }   from './bookings.service';
import { SharedModule }      from '../shared/shared.module';
import { TableModule }       from '../table/table.module';

@NgModule({
  declarations: [
    BookingsComponent
  ],
  exports: [
    BookingsComponent
  ],
  imports: [
    SharedModule,
    TableModule
  ],
  providers: [
    BookingsService
  ]
})
export class BookingsModule {}
