export function StaticLookupLiteCtrl ($scope, items, options) {
  $scope.items   = items;
  $scope.options = options;

  $scope.grid = {
    columnDefs: options.columnDefs,
    data: 'items',
    multiSelect: false,
    enableHighlighting: true,
    rowHeight: 43,
    selectedItems: [],
    showFooter: false,
    virtualizationThreshold: $scope.items.length,
  };

  $scope.choose = function (createNew) {
    $scope.$close(createNew ? { id: 'new' } : $scope.grid.selectedItems[0]);
  };
}
