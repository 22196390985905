export class BigDecimalService {
  get ( number ?: string | number, scale ?: number ) : any {
    const convertedNumber = number
      ? new BigDecimal(number.toString())
      : BigDecimal.ZERO;

    return scale
      ? convertedNumber.setScale(scale)
      : convertedNumber;
  }
}
