import { NgModule } from '@angular/core';

import { ErrorHandlingService } from './error-handling.service';

@NgModule({
  providers: [
    ErrorHandlingService
  ]
})
export class ErrorsModule {}
