import { CustomerSourceModel } from './models/customer-source';

interface CustomerSourcesScope extends ng.IScope {
  crudGridAPI    : any;
  removeRow      : ( source : CustomerSourceModel, index : number ) => void;
  saveRow        : ( source : CustomerSourceModel, index : number ) => void;
  customerSources: Array<CustomerSourceModel>;
}

export function CustomerSourcesCtrl (
  $scope     : CustomerSourcesScope,
  $translate : ng.translate.ITranslateService,
  workshopAPI: any,
  messages   : any,
  crudGridAPI: any,
  confirm    : any
){
  $scope.crudGridAPI      = crudGridAPI();
  $scope.crudGridAPI.data = $scope.customerSources;

  $scope.removeRow = function (source, index) {
    confirm.generic($translate.instant('JS_SPACE.CONFIRM.DELETE_TEMPLATE'))
    .then(() => $scope.crudGridAPI.removeRow(source, index, '/customer_sources', 'id'))
    .then(() => {
      messages.show($translate.instant('JS_SPACE.MESSAGES.DELETED.CUSTOMER_SOURCE'), 'success');

      $scope.crudGridAPI.data.splice(index, 1);
    });
  };

  $scope.saveRow = function (source, index) {
    const method = source.id ? 'patch' : 'post';
    const url    = source.id ? '/customer_source' : '/customer_sources';

    workshopAPI[method](url, source, 'customer_source')
    .then(( sources : Array<CustomerSourceModel>) => {
      $scope.crudGridAPI.data = source.id ? $scope.crudGridAPI.data : sources;

      $scope.crudGridAPI.toggleEditMode(source, index);

      messages.show($translate.instant('JS_SPACE.MESSAGES.SAVED.CUSTOMER_SOURCE'), 'success');
    })
    .catch(err => {
      messages.error(err);
    });
  };
};