import { CustomerPortalService } from './customer-portal.service.ajs';
import { CompanySettingsModel }  from '../companies/models/company.settings';

import CustomerPortalSettingsHtml from './customer-portal.settings.html';

export function CustomerPortalStates (
  $stateProvider: ng.ui.IStateProvider
) {
  $stateProvider
  .state({
    controller : 'CustomerPortalSettingsCtrl',
    name       : 'app.customer-portal-settings',
    templateUrl: CustomerPortalSettingsHtml,
    url        : '/customer-portal-settings',
    resolve    : {
      settings: ( customerPortalApi : CustomerPortalService ) : ng.IPromise<CompanySettingsModel> => customerPortalApi.getSettings()
    }
  });
}