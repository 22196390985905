import OrdersHtml from './transaction-center.orders.html';

export const TransactionCenterOrdersComponent : ng.IComponentOptions = {
  bindings: {
    orders  : '=',
    statuses: '<'
  },
  controller  : 'TransactionCenterOrdersCtrl',
  controllerAs: '$ctrl',
  templateUrl : OrdersHtml,
};