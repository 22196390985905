import { UrlService } from '@uirouter/angularjs';

import { ApiModel }             from '../../api/models/api.model';
import { DomainParserApiModel } from '../models/domain.parser';

export function DomainParserService (
  $urlService : UrlService,
  API         : ApiModel
): DomainParserApiModel {
  class DomainParser implements DomainParserApiModel {
    apiSettings : ApiModel = API;

    getHostname () : string {
      const hostname  = $urlService.config.host();
      const subdomain = new RegExp(`${ this.apiSettings.subdomain }\.`, 'g');

      return hostname.replace(subdomain, '');
    }

    isCountry ( country : string ) : boolean {
      const domain = $urlService.config.host();

      return this.apiSettings.domains[country].indexOf(domain) > -1;
    }

    isHttps () : boolean {
      return $urlService.config.protocol() === 'https';
    }
  };

  return new DomainParser();
};
