import 'angular';

import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';

import { EmailVerificationComponent } from './email-verification.component';
import { EmailVerificationService }   from './email-verification.service';

angular.module('app-lite.email-verification', [])
.directive('worEmailVerification', downgradeComponent({ component: EmailVerificationComponent }))
.service('emailVerificationApi', downgradeInjectable(EmailVerificationService));
