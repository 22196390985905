import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomOption, QuillModules }                     from 'ngx-quill';

import * as Quill from 'quill';

@Component({
  selector   : 'wor-text-editor',
  styles: [ require('./text-editor.component.scss') ],
  template: require('./text-editor.component.html')
})
export class TextEditorComponent implements OnInit {
  @Input () hideToolbar: boolean;
  @Input () model      : string;
  @Input () readonly   : boolean;
  @Input () required   : boolean;

  @Output () readonly modelChange: EventEmitter<string>  = new EventEmitter();

  modules: QuillModules;
  options: Array<CustomOption>;

  ngOnInit () {
    this.modules = {
      toolbar: this.hideToolbar
        ? false
        : [
          [ { 'header': [ 1, 2, 3, 4, 5, 6, false ] } ],
          [ 'bold', 'italic', 'underline', 'strike' ],
          [ { 'color': [] }, { 'background': [] } ],
          [ { 'list': 'ordered' }, { 'list': 'bullet'}, { 'indent': '-1' }, { 'indent': '+1' } ],
          [ { 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' } ]
        ]
    };

    const Bold   = Quill.import('formats/bold');
    const Italic = Quill.import('formats/italic');

    Bold.tagName   = 'B';
    Italic.tagName = 'I';

    Quill.register(Bold, true);
    Quill.register(Italic, true);

    this.options = [
      {
        import   : 'attributors/style/align',
        whitelist: [ 'center', 'right', 'justify' ]
      }
    ];
  }
}
