import { Inject, Injectable } from '@angular/core';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class DateParserService {
  dateFormat = 'YYYY-MM-DD';
  days       = [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ];
  months     = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 ];

  constructor (
    @Inject('$rootScope') private $rootScope : any
  ) {}

  getDayName ( day : number ) : string {
    return this.days[ day - 1 ];
  }

  getFirstOfMonth ( date : moment.Moment ) : moment.Moment {
    return date.subtract(( parseInt(date.format('D')) - 1 ) , 'days');
  }

  getMonths () : Array<number> {
    return this.months;
  }

  isToday ( date : Date | moment.Moment | string ) : boolean {
    return moment(date)
      .startOf('day')
      .isSame(moment().startOf('day'));
  }

  pad ( digit : number ) : number | string  {
    return ( digit < 10 )
      ? '0' + digit
      : digit;
  }

  priorYears () : any[] {
    const current = moment().year();

    let years = [];

    for ( let i = 49; i >= -1; i-- ) {
      years.push(current - i);
    }

    return years;
  }

  sunday ( date : Date | moment.Moment | string ) : string {
    date = moment(date);

    return ( date.day() === 0 )
      ? date.format(this.dateFormat)
      : date.day(date.day() - date.day())
        .format(this.dateFormat);
  }

  thisMonthStart () : string {
    return moment()
      .startOf('month')
      .format(this.dateFormat);
  }

  thisMonthEnd () : string {
    return moment()
      .endOf('month')
      .format(this.dateFormat);
  }

  today () : string {
    return moment()
      .format(this.dateFormat);
  }

  todayWithUtcOffset ( utcOffset ?: string ) : string {
    return moment()
      .utcOffset(utcOffset
        ? utcOffset
        : this.$rootScope.Company.getUtcOffset()
      )
      .format(this.dateFormat);
  }

  tomorrow ( date : Date | moment.Moment | string ) : string {
    date = date
      ? moment(date)
      : moment();

    return date.add(1, 'd')
      .format(this.dateFormat);
  }

  // if end date is passed, get the start of the week. If not, find today and see when the start of that week is.
  weekRangeStart ( endDate : Date | moment.Moment | string ) : string {
    let date : moment.Moment;

    if ( endDate ) {
      date = moment(endDate);

      return date.date(date.date() - 6)
        .format(this.dateFormat);
    }
    else {
      date = moment();

      return date.date(date.date() - date.day())
        .format(this.dateFormat);
    }
  }

  // if start date is passed, get the end of the week. If not, find today and see when the end of that week is.
  weekRangeEnd ( startDate : Date | moment.Moment | string ) : string {
    let date : moment.Moment;

    if ( startDate ) {
      date = moment(startDate);

      return date.date(date.date() + 6)
        .format(this.dateFormat);
    }
    else {
      date = moment();

      return date.date(date.date() - date.day()+6)
        .format(this.dateFormat);
    }
  }

  years () : any[] {
    const current = moment().year();

    let years   = [];

    for ( let i = 0; i <= 10; i++ ) {
      years.push(current + i);
    }

    return years;
  }

  yesterday ( date : Date | moment.Moment | string ) : string {
    date = date
      ? moment(date)
      : moment();

    return date.subtract(1, 'd')
      .format(this.dateFormat);
  }
}