import LinkedCompaniesLookupHtml from './branches.linked-companies.lookup.html';

export function BranchesLiteService ($uibModal, gridAPI, sessionObjAPI, tableApi, workshopAPI) {
  var BranchesLite = function () {
    this.$uibModal = $uibModal;

    this.gridAPI       = gridAPI;
    this.sessionObjAPI = sessionObjAPI;
    this.workshopAPI   = workshopAPI;
  };

  BranchesLite.prototype.getCustomerInvoices = function (customer_id, offset) {
    const cache = tableApi.getCacheForTable('branchCustomerInvoices');

    return workshopAPI.get('/customers/branch_invoice_list', [
        customer_id,
        offset || 0,
        cache.paging.pageSize || 5,
        '*',
        '*',
        cache.sorting.field     || 'post_date',
        cache.sorting.direction || 'desc'
    ]);
  };

  BranchesLite.prototype.getCustomerPayments = function (customer_id, offset) {
    const cache = tableApi.getCacheForTable('customerBranchPayments');

    return workshopAPI.get('/customers/branch_payment_list', [
      customer_id,
      offset || 0,
      cache.paging.pageSize   || 5,
      cache.sorting.field     || 'post_date',
      cache.sorting.direction || 'desc'
    ]);
  };

  BranchesLite.prototype.getFranchiseLink = function () {
    const self = this;

    return self.workshopAPI.get('/franchise_link');
  };

  BranchesLite.prototype.getFranchiseLinks = function () {
    const self = this;

    return self.workshopAPI.get('/franchise_links');
  };

  BranchesLite.prototype.getLinkedCompanies = function () {
    const self = this;

    return self.workshopAPI.get('/company/branch_index');
  };

  BranchesLite.prototype.getStats = function () {
    const self = this;

    return self.workshopAPI.get('multibranch_dashboard/widget_stats');
  };

  BranchesLite.prototype.getVendorInvoices = function (vendor = {}) {
    const cache = tableApi.getCacheForTable('branchVendorInvoices');

    return workshopAPI.get('/vendors/branch_invoice_list', [
      vendor.id,
      0,
      cache.paging.pageSize || 5,
      '*',
      '*',
      cache.sorting.field     || 'post_date',
      cache.sorting.direction || 'desc'
    ]);
  };

  BranchesLite.prototype.getVendorPayments = function (vendor = {}) {
    const self = this;

    const cache = tableApi.getCacheForTable('vendorBranchPayments');

    return self.workshopAPI.get('/vendors/branch_payment_list', [
      vendor.id,
      0,
      cache.paging.pageSize   || 5,
      cache.sorting.field     || 'post_date',
      cache.sorting.direction || 'desc'
    ]);
  };

  BranchesLite.prototype.lookupLinkedCompanies = function () {
    const self = this;

    return self.$uibModal.open({
      controller: 'LinkedCompaniesLookupLiteCtrl',
      resolve   : {
        companies () {
          return self.getLinkedCompanies();
        }
      },
      templateUrl: LinkedCompaniesLookupHtml
    }).result;
  };

  BranchesLite.prototype.saveFranchiseLink = function (link) {
    const self = this;

    var response;

    return self.workshopAPI.patch('/franchise_link', link, 'franchise_link').then(result => {
      response = result;

      return self.sessionObjAPI.refresh();
    }).then(() => {
      return response;
    });
  };

  BranchesLite.prototype.transferStock = function (transfers) {
    const self = this;

    return self.workshopAPI.post('/product/branch_transfer', transfers);
  };

  return BranchesLite;
}
