import { Component, forwardRef, Inject, Input } from '@angular/core';
import { NgbActiveModal }                       from '@ng-bootstrap/ng-bootstrap';

import { MessagesServiceAjs }                 from '../messages/messages.service.ajs';
import { PartstechOrderStatusModel }          from './models/partstech.order-status';
import { PartstechSendOrderActionsComponent } from './partstech.send-order.actions.component';
import { PartstechService }                   from './partstech.service';
import { PurchaseOrderModel }                 from '../../app/purchase-orders/models/purchase-order';
import { TableQueryModel }                    from '../../app-customer/table/models/table-query';

@Component({
  selector   : 'wor-partstech-send-order',
  template: require('./partstech.send-order.component.html')
})
export class PartstechSendOrderComponent {
  @Input() order         : PurchaseOrderModel;
  @Input() orderStatuses : Array<PartstechOrderStatusModel>;

  tableSettings = {
    columns: [
      {
        field : 'item_code',
        header: {
          text: this.$translate.instant('PRODUCT_SPACE.PRODUCT')
        },
      },
      {
        field : 'description',
        header: {
          text: this.$translate.instant('GENERAL_SPACE.FIELD.DESCRIPTION')
        },
      },
      {
        field : 'quantity_ordered',
        header: {
          text: this.$translate.instant('PRODUCT_SPACE.QTY')
        },
      },
      {
        field : 'job_card_number',
        header: {
          text: this.$translate.instant('MENU_SPACE.JOB_CARD')
        },
      },
      {
        component: {
          bindings: {
            outputs: {
              onAddToCartClick: event => this.addToCart(event),
              onDeleteClick   : event => this.removeItem(event)
            }
          },
          enabled: true,
          factory: PartstechSendOrderActionsComponent,
        },
        sortable: false,
      }
    ],
    empty: {
      text: this.$translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_STOCK_ORDERS_FOUND')
    },
    pagination: {
      enabled: false
    },
    rows: {
      highlight: {
        enabled: true
      }
    },
    sorting: {
      enabled: false
    },
    static: true
  };

  constructor (
    @Inject('$translate')
    public  $translate      : ng.translate.ITranslateService,
    public  activeModal     : NgbActiveModal,
    private messagesService : MessagesServiceAjs,
    @Inject(forwardRef(() => PartstechService))
    private partstechService: PartstechService
  ) {}

  addToCart ( orderStatus : PartstechOrderStatusModel ) : void {
    this.partstechService.addPartToCart(orderStatus)
    .then(() => {
      this.getOrderStatuses();
    })
    .catch(err => {
      this.messagesService.error(err);
    });
  }

  getOrderStatuses ( params = {} as TableQueryModel ) : ng.IPromise<Array<PartstechOrderStatusModel>> {
    return this.partstechService.getOrderStatuses(this.order)
    .then((orderStatuses : Array<PartstechOrderStatusModel>) => {
      this.orderStatuses = orderStatuses;

      return orderStatuses;
    });
  }

  removeItem ( orderStatus : PartstechOrderStatusModel ) : void {
    this.partstechService.removePartFromOrder(orderStatus.item_id)
    .then(() => {
      this.getOrderStatuses();
    })
    .catch(err => {
      this.messagesService.error(err);
    });
  }
}