import widthFunctions from '../../../vendor/simpliq-theme/widthFunctions';

export function CoreSectionToggleDirective ($timeout) {
  return {
    scope: {
      sectionToggle: '@',
      open         : '@'
    },
    restrict: 'A',
    link    (scope, element, attrs) {

      scope.toggle = function (evt) {
        if (evt) {
          evt.stopPropagation();
        }

        scope.open = !scope.open;

        if (scope.open) {
          element.find('#chevron').addClass('fa-chevron-down').removeClass('fa-chevron-up');
        }
        else {
          element.find('#chevron').removeClass('fa-chevron-down').addClass('fa-chevron-up');
        }

        $timeout(() => {
          $(scope.sectionToggle ? scope.sectionToggle: '.box-content')
          .slideToggle(200, () => {
            widthFunctions();
            $(scope.sectionToggle).find('.grid').resize();
            scope.$emit('toggled');
          });
        });
      };

      element.on('click', evt => {
        scope.toggle(evt);
      });

      scope.$on('toggle', evt => {
        scope.toggle();
      });

      scope.$on('open', evt => {
        if (!scope.open) {
          scope.toggle();
        }
      });

      scope.$on('close', evt => {
        if (scope.open) {
          scope.toggle();
        }
      });

      if (!scope.open) {
        $(scope.sectionToggle ? scope.sectionToggle: '.box-content').hide();
      }
    }
  };
}