import { Inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'invoiceType',
})
export class InvoiceTypePipe implements PipeTransform {

  constructor(
    @Inject('$translate') public $translate : ng.translate.ITranslateService
  ) {}

  transform( value : string ) {
    const typeMap = {
      C: this.$translate.instant('INVOICE_TYPES.CREDIT'),
      I: this.$translate.instant('INVOICE_TYPES.INVOICE'),
      Q: this.$translate.instant('INVOICE_TYPES.QUOTE'),
      U: this.$translate.instant('INVOICE_TYPES.INTERNAL')
    };

    return typeMap[value] || this.$translate.instant('INVOICE_TYPES.INVOICE');
  }
}