import { EditComponent } from '../../app-lite/table/cell-components/actions/edit.component';

export function VendorBranchPaymentsLiteCtrl (
  $scope,
  $state,
  $translate,
  tableApi,
  workshopAPI
) {
  $scope.tableCacheKey  = 'vendorBranchPayments';

  $scope.tableSettings = {
    cache: {
      enabled: true,
      key    : $scope.tableCacheKey
    },
    columns: [
      {
        classes: 'text-left',
        field  : 'branch_vendor_payment_number',
        header : {
          classes: 'text-left',
          text   : $translate.instant('JS_SPACE.COLUMNS.PAYMENT_NUMBER')
        }
      },
      {
        classes: 'text-left',
        field  : 'post_date',
        header : {
          classes: 'text-left',
          text   : $translate.instant('GENERAL_SPACE.FIELD.POST_DATE')
        },
        pipe: 'globalDate',
      },
      {
        classes: 'text-left',
        field  : 'status',
        header : {
          classes: 'text-left',
          text   : $translate.instant('GENERAL_SPACE.FIELD.STATUS')
        },
        pipe: 'customerPaymentStatus',
      },
      {
        classes: 'text-right',
        field  : 'applied_amount',
        header : {
          classes: 'text-right',
          text   : $translate.instant('JS_SPACE.COLUMNS.APPLIED_AMOUNT')
        },
        pipe: 'globalCurrency',
      },
      {
        classes: 'text-right',
        field  : 'amount',
        header : {
          classes: 'text-right',
          text   : $translate.instant('JS_SPACE.COLUMNS.AMOUNT')
        },
        pipe: 'globalCurrency',
      },
      {
        component: {
          bindings: {
            outputs: {
              onEditClick: event => $scope.viewPayment(event)
            }
          },
          enabled: true,
          factory: EditComponent,
        },
        sortable: false,
      }
    ],
    empty: {
      text: $translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_PAYMENTS_FOUND')
    },
    pagination: {
      enabled   : true,
      pageSize  : tableApi.getCachedPaging($scope.tableCacheKey).pageSize || 5,
      pageSizes : [ 5, 10, 25, 50 ],
      queryCount: () => workshopAPI.get('/branch_vendor_payments/count')
    },
    rows: {
      highlight: {
        enabled: true
      }
    },
    sorting: tableApi.setSortingFromCache($scope.tableCacheKey, {
      direction: 'desc',
      field    : 'post_date'
    })
  };

  $scope.onRowDoubleClick = function (event) {
    $scope.viewPayment(event.row);
  };

  $scope.viewPayment = function (payment) {
    $state.go('app.vendor-branch-payment', {
      id       : payment.id,
      vendor_id: payment.vendor_id
    })
  };

  $scope.getPayments = ( params ) => {
    return workshopAPI.get('/vendors/branch_payment_list', [
      $scope.vendor.id,
      params.offset,
      params.pageSize,
      params.field,
      params.direction
    ]).then(payments => {
      $scope.payments = payments;

      return payments;
    });
  };

  $scope.$watch(
    () => $scope.payments.length,
    () => $scope.showPayments = !!length
  );
}