import { Injector }             from '@angular/core';
import { Transition, UIRouter } from '@uirouter/core';
import { ApiErrorModel } from '../core/api/models/api.error';

import { ErrorHandlingService } from '../core/errors/error-handling.service';
import { SessionService }       from '../core/sessions/session.service';

export function RouterConfig (
  router  : UIRouter,
  injector: Injector
) {
  const privateGlob = 'private.**';

  router.transitionService.onEnter({
    to: privateGlob
  }, ( transition : Transition ) => {
    const sessionService : SessionService = injector.get(SessionService);

    if (!sessionService.getSessionId()) {
      return transition.router.stateService.target('ajs.app.public.login');
    }
  });

  router.transitionService.onError({
    to: privateGlob
  }, ( transition : Transition ) => {
    const error                : ApiErrorModel        = transition.error().detail;
    const errorHandlingService : ErrorHandlingService = injector.get(ErrorHandlingService);
    const sessionService       : SessionService       = injector.get(SessionService);

    if (errorHandlingService.isSessionExpired(error)) {
      sessionService.clearSession();

      console.error(error);

      transition.router.stateService.go('ajs.app.public.login');

      return false;
    }
  });
}
