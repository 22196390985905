import { CompanyIntegrationsModel } from '../companies/models/company.integrations';
import { FlipPayAccountModel }      from './models/flippay.account';
import { FlipPayService }           from './flippay.service.ajs';

export class FlipPaySettingsCtrl {
  $state    : ng.ui.IStateService;
  $translate: ng.translate.ITranslateService;
  $window   : ng.IWindowService;
  accounts  : Array<FlipPayAccountModel> = [];
  flipPayApi: FlipPayService;
  messages  : any;
  settings  : CompanyIntegrationsModel;

  constructor (
    $state    : ng.ui.IStateService,
    $translate: ng.translate.ITranslateService,
    $window   : ng.IWindowService,
    flipPayApi: FlipPayService,
    messages  : any,
    settings  : CompanyIntegrationsModel
  ) {
    this.$state     = $state;
    this.$translate = $translate;
    this.$window    = $window;
    this.flipPayApi = flipPayApi;
    this.messages   = messages;
    this.settings   = settings;

    if (this.flipPayApi.isActive()) {
      this.flipPayApi.getAccounts()
      .then(( accounts : Array<FlipPayAccountModel> ) => {
        this.accounts = accounts;
      })
    }
  }

  activate () : void {
    this.flipPayApi.activate()
    .then(( url : string ) => {
      if (url) {
        this.$window.location.assign(url);
      }
      else {
        this.$state.reload();
      }
    })
    .catch(( err : any ) => {
      this.messages.error(err);
    });
  }

  deactivate () : void {
    this.flipPayApi.deactivate()
    .then(() => {
      this.$state.reload();
    })
    .catch(( err : any ) => {
      this.messages.error(err);
    });
  }

  isActive () : boolean {
    return this.flipPayApi.isActive();
  }

  isPending () : boolean {
    return this.flipPayApi.isPending();
  }

  save () : void {
    this.flipPayApi.saveSettings(this.settings)
    .then(() => {
      this.messages.show(this.$translate.instant('JS_SPACE.MESSAGES.SAVED.SETTINGS'), 'success');

      this.$state.reload();
    })
    .catch(( err : any ) => {
      this.messages.error(err);
    });
  }
}
