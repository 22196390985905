import { Component, Input } from '@angular/core';

import { BookingModel }            from '../bookings/models/booking';
import { CustomerModel }           from '../customers/models/customer';
import { InvoiceModel }            from '../invoices/models/invoice';
import { JobModel }                from '../jobs/models/job';
import { OutstandingInvoiceModel } from '../invoices/models/outstanding-invoice';
import { QuoteModel }              from '../../app-lite/core/models/quote';
import { VehicleModel }            from '../vehicles/models/vehicle';

@Component({
  styles: [ require('./dashboard.component.scss') ],
  template: require('./dashboard.component.html')
})
export class DashboardComponent {
  @Input() bookings           : Array<BookingModel>;
  @Input() customer           : CustomerModel;
  @Input() invoices           : Array<InvoiceModel>;
  @Input() jobs               : Array<JobModel>;
  @Input() outstandingInvoices: Array<OutstandingInvoiceModel>;
  @Input() quotes             : Array<QuoteModel>;
  @Input() vehicles           : Array<VehicleModel>;
  @Input() vehiclesDue        : Array<VehicleModel>;
}
