import { Injectable } from '@angular/core';

import { ApiService }           from '../core/api/api.service';
import { CodeTypeModel }        from '../../app-lite/core/models/code-type';
import { SessionService }       from '../core/sessions/session.service';
import { TableCountModel }      from '../table/models/table-count';
import { TablePaginationModel } from '../table/models/table-pagination';
import { VehicleModel }         from './models/vehicle';
import { VehicleOptionsModel }  from './models/vehicle.options';

@Injectable({
  providedIn: 'root'
})
export class VehiclesService {
  defaultPagination : TablePaginationModel = {
    enabled : true,
    offset  : 0,
    pageSize: 4
  };

  constructor (
    private apiService    : ApiService,
    private sessionService: SessionService
  ) {}

  createVehicle ( vehicle : VehicleModel ) : ng.IPromise<boolean> {
    return this.apiService.post('/customer_portal_dashboard/create_vehicle', { vehicle });
  }

  getDueDates () : ng.IPromise<Array<VehicleModel>> {
    return this.apiService.get('/customer_portal_dashboard/vehicle_due_dates_index');
  }

  getPlateNumberLabel () : string {
    const session = this.sessionService.getSession().country_settings;

    return session.rego_label;
  }

  getPublicBookingLink ( vehicle : VehicleModel = {} ) : string {
    const session = this.sessionService.getSession();

    return `${ window.location.origin }/${ session.bookings_page }${ session.bookings_company_url }?token=${ session.bookings_token }&customer_id=${ session.workshop_customer_id }&vehicle_id=${ vehicle.id }`;
  }

  getVehicleCount () : ng.IPromise<TableCountModel> {
    return this.apiService.get('/customer_portal_dashboard/vehicles_count');
  }

  getVehicleOptions () : ng.IPromise<VehicleOptionsModel> {
    let options : VehicleOptionsModel;

    return this.apiService.get('/customer_portal_dashboard/vehicle_types')
      .then(( response : VehicleOptionsModel ) => {
        options = response;

        return this.sessionService.getCountryStates();
      })
      .then(( states : Array<CodeTypeModel> ) => {
        options.states = states;

        return options;
      });
  }

  getVehicles ( offset = this.defaultPagination.offset, pageSize = this.defaultPagination.pageSize ) : ng.IPromise<Array<VehicleModel>> {
    return this.apiService.get('/customer_portal_dashboard/vehicles_index', [
      offset,
      pageSize
    ]);
  }
}
