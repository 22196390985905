import { Inject, Injectable } from '@angular/core';
import { NgbModal }           from '@ng-bootstrap/ng-bootstrap';

import { ApiService }                     from '../api/api.service';
import { ConfirmService }                 from '../messages/confirm.service.ajs';
import { MotorAccountReference }          from '../../app/motor/models/motor.account-reference';
import { PartstechAddLaborComponent }     from './partstech.add-labor.component';
import { PartstechEstimateModel }         from './models/partstech.estimate';
import { PartstechGroupModel }            from './models/partstech.group';
import { PartstechOrderStatusModel }      from './models/partstech.order-status';
import { PartstechPartsStatusModel }      from './models/partstech.parts-status';
import { PartstechSendOrderComponent }    from './partstech.send-order.component';
import { PartstechSettingsModel }         from './models/partstech.settings';
import { PartstechSubgroupModel }         from './models/partstech.sub-group';
import { PartstechSystemModel }           from './models/partstech.system';
import { PurchaseOrderModel }             from '../../app/purchase-orders/models/purchase-order';
import { RemoveComponent }                from '../table/cell-components/actions/remove.component';
import { ResolvePartstechPartsComponent } from './partstech.resolve-parts.component';
import { SessionServiceAjs }              from '../sessions/session.service.ajs';
import { VehicleModel }                   from '../vehicles/models/vehicle';
import { VendorModel }                    from '../vendors/models/vendor';

@Injectable({
  providedIn: 'root'
})
export class PartstechService {
  _errorKey = 'ERROR';

  priceTableColumns = [
    {
      classes: 'text-left',
      field  : 'item_code',
      header : {
        text: this.$translate.instant('PRODUCT_SPACE.ITEM_CODE')
      }
    },
    {
      classes: 'text-left',
      field  : 'type',
      header : {
        text: this.$translate.instant('GENERAL_SPACE.FIELD.TYPE')
      },
      pipe: 'partstechPriceChangeType'
    },
    {
      classes: 'text-right',
      field  : 'old_amount',
      header : {
        classes: 'text-right',
        text   : this.$translate.instant('PARTSTECH_SPACE.OLD_AMOUNT')
      },
      pipe: 'globalCurrency'
    },
    {
      classes: 'text-right',
      field  : 'new_amount',
      header : {
        classes: 'text-right',
        text   : this.$translate.instant('PARTSTECH_SPACE.NEW_AMOUNT')
      },
      pipe: 'globalCurrency'
    },
    {
      component: {
        bindings: {
          outputs: {
            onRemoveClick: event => this.removePartFromOrder(event.purchase_order_item_id)
          }
        },
        enabled: true,
        factory: RemoveComponent,
      },
      sortable: false,
    }
  ];

  unavailableTableColumns = [
    {
      classes: 'text-left',
      field  : 'item_code',
      header : {
        text: this.$translate.instant('PRODUCT_SPACE.ITEM_CODE')
      }
    },
    {
      classes: 'text-right',
      field  : 'quantity',
      header : {
        classes: 'text-right',
        text   : this.$translate.instant('PRODUCT_SPACE.QTY')
      },
      pipe: 'number'
    },
    {
      field : 'errors',
      header: {
        text: this.$translate.instant('PARTSTECH_SPACE.ERRORS')
      }
    },
    {
      component: {
        bindings: {
          outputs: {
            onRemoveClick: event => this.removePartFromOrder(event.purchase_order_item_id)
          }
        },
        enabled: true,
        factory: RemoveComponent,
      },
      sortable: false,
    }
  ];

  constructor (
    @Inject('$translate')
    private $translate     : ng.translate.ITranslateService,
    private modalService   : NgbModal,
    private apiService     : ApiService,
    private confirmService : ConfirmService,
    private sessionService : SessionServiceAjs
  ) {}

  activate () : ng.IPromise<any> {
    return this.confirmService.generic(this.$translate.instant('JS_SPACE.INTEGRATIONS.ACTIVATE', {
      name: 'Partstech'
    }))
    .then(() => this.apiService.get('/company_integrations/add_partstech'))
    .then(() => this.sessionService.refresh());
  }

  addLabor ( reference : MotorAccountReference, vehicle : VehicleModel ) : ng.IPromise<any> {
    return this.getSystems(vehicle)
    .then(systems => {
      const modalRef = this.modalService.open(PartstechAddLaborComponent, {
        windowClass: 'x-lg-modal'
      });

      modalRef.componentInstance.reference = reference;
      modalRef.componentInstance.systems   = systems;
      modalRef.componentInstance.vehicle   = vehicle;

      return modalRef.result;
    });
  }

  addLaborItem ( estimate : PartstechEstimateModel, reference : MotorAccountReference, system : PartstechSystemModel ) {
    return this.apiService.post(`partstech/add_labour_item_to_object`, {
      object_id  : reference.id,
      object_type: reference.type,
      row        : estimate,
      system_name: system.system_name,
    }, 'partstech_labour');
  };

  addPartToCart ( orderStatus : PartstechOrderStatusModel ) : ng.IPromise<any> {
    return this.apiService.post('/partstech/add_part_to_cart', {
      purchase_order_item_id: orderStatus.item_id
    });
  }

  deactivate () : ng.IPromise<any>  {
    return this.confirmService.generic(this.$translate.instant('JS_SPACE.INTEGRATIONS.DEACTIVATE', {
      name: 'Partstech'
    }))
    .then(() => this.apiService.get('/company_integrations/remove_partstech'))
    .then(() => this.sessionService.refresh());
  }

  getAvailabilityAndPrices ( order : PurchaseOrderModel ) : ng.IPromise<any> {
    return this.apiService.get('/partstech/check_availability_and_update_prices', order.id);
  }

  getColumns ( didPriceChange : boolean ) : Array<any> {
    return didPriceChange
      ? this.priceTableColumns
      : this.unavailableTableColumns;
  }

  getEstimates ( system : PartstechSystemModel, group : PartstechGroupModel, subGroupIds : Array<number>, vehicle : VehicleModel ) : ng.IPromise<Array<PartstechEstimateModel>> {
    return this.apiService.post('/partstech/request_labour_operations', {
        group_id     : group.group_id,
        sub_group_ids: subGroupIds,
        system_id    : system.system_id,
        vehicle_id   : vehicle.id
      }, 'labour_catalog')
      .then(response => response[0].operations);
  }

  getGroups ( system : PartstechSystemModel, systems : Array<PartstechSystemModel> ) : Array<PartstechGroupModel> {
    return systems.filter(s => s.system_id === system.system_id)[0].groups;
  }

  getOrders ( order : PurchaseOrderModel ) : ng.IPromise<Array<PurchaseOrderModel>> {
    return this.apiService.get('/partstech/partstech_orders_by_session_id', order.id);
  }

  getOrderCount ( order : PurchaseOrderModel ) : ng.IPromise<number> {
    return this.apiService.get('/purchase_orders/partstech_orders_by_session_count', order.id);
  }

  getOrderStatus ( order : PurchaseOrderModel ) : ng.IPromise<any> {
    return this.apiService.post('/partstech/check_part_status', {
      purchase_order_id: order.id
    });
  }

  getOrderStatuses ( order : PurchaseOrderModel ) : ng.IPromise<Array<PartstechOrderStatusModel>> {
    return this.getOrderStatus(order)
      .then(response => response.order_statuses);
  }

  getSubGroups ( group : PartstechGroupModel, groups : Array<PartstechGroupModel> ) : Array<PartstechSubgroupModel> {
    return groups.filter(g => g.group_id === group.group_id)[0].sub_groups;
  }

  getSystems ( vehicle : VehicleModel ) : ng.IPromise<Array<PartstechSystemModel>> {
    return this.apiService.get('/partstech/request_labour_groups', vehicle.id);
  }

  handleSessionResponse ( data = {} as any ) : any {
    if (data.Status !== this._errorKey ) {
      return data.RedirectUrl;
    }

    if (data.RedirectUrl) {
      return Promise.reject({ url: data.RedirectUrl });
    }

    return data.Error
    ? Promise.reject(data.Error.Message)
    : Promise.reject(data);
  }

  isPartNotReady ( part : PartstechOrderStatusModel ) : boolean {
    return this.isPartstechPart(part)
      && !part.ready_to_order;
  }

  isPartstechPart ( part : PartstechOrderStatusModel ) : boolean {
    return part.is_partstech_part;
  }

  isPartstechVendor ( vendor : VendorModel ) : boolean {
    return !!vendor.partstech_distributor;
  }

  placeOrder ( order : PurchaseOrderModel ) : ng.IPromise<any> {
    return this.apiService.post('/partstech/place_order', {
      purchase_order_id: order.id
    });
  }

  removePartFromOrder ( id : string ) : ng.IPromise<any> {
    return this.apiService.delete('/purchase_order_item', id);
  }

  requestSession ( type : string, object : any ) : ng.IPromise<any> {
    const params = {
      object_id   : object.id,
      request_type: type,
      session_id  : this.sessionService.sessionID(),
      ...(( type === 'inspection' )
        && {
          item_id: object.item.id
        }),
    };

    return this.apiService.post(`/partstech/${
      object.partstech_session_id
      ? 'update_partstech_quote'
      : 'create_partstech_quote'
    }`, params, 'data')
      .then(data => data['RedirectUrl']);
  }

  resolveParts ( order : PurchaseOrderModel, status : PartstechPartsStatusModel ) : ng.IPromise<any> {
    const modalRef = this.modalService.open(ResolvePartstechPartsComponent, {
      size: 'lg'
    });

    modalRef.componentInstance.order  = order;
    modalRef.componentInstance.status = status;

    return modalRef.result;
  }

  save ( integration : PartstechSettingsModel ) : ng.IPromise<any> {
    return this.apiService.patch('/company_integrations', integration, 'company_integration');
  }

  send ( order : PurchaseOrderModel ) : ng.IPromise<any> {
    return this.validateParts(order)
    .then(() => this.placeOrder(order));
  }

  sendOrder ( order : PurchaseOrderModel, orderStatuses : Array<PartstechOrderStatusModel> ) : ng.IPromise<any> {
    const modalRef = this.modalService.open(PartstechSendOrderComponent, {
      size: 'lg'
    });

    modalRef.componentInstance.order         = order;
    modalRef.componentInstance.orderStatuses = orderStatuses;

    return modalRef.result;
  }

  validateParts ( order : PurchaseOrderModel ) : ng.IPromise<any> {
    return this.getOrderStatus(order)
    .then(response => !response.order_statuses.length && response.ready_partstech_item_ids.length
      ? Promise.resolve(true)
      : this.sendOrder(order, response.order_statuses)
    )
    .then(() => this.getAvailabilityAndPrices(order))
    .then(( status : PartstechPartsStatusModel ) => status.parts_not_ready && status.parts_not_ready.length
      ? this.resolveParts(order, status)
      : Promise.resolve(true)
    );
  }
}
