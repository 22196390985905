import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector   : 'wor-form-submit',
  styles: [ require('./form-submit.component.scss') ],
  template: require('./form-submit.component.html'),

  viewProviders: [
    {
      provide    : ControlContainer,
      useExisting: NgForm
    }
  ]
})
export class FormSubmitComponent implements OnInit {
  @Input() label = 'Submit';
  @Input() type  : 'btn-danger' | 'btn-info' | 'btn-primary' | 'btn-success' | 'btn-warning' = 'btn-primary';

  constructor (
    public form: NgForm
  ) {}

  ngOnInit(): void {
    this.form.onSubmit = ( $event : Event ) : boolean => {
      if (this.form.invalid) {
        $event.preventDefault();

        for (const key in this.form.controls) {
          if (this.form.controls[key].errors) {
            this.form.controls[key].markAsDirty();
          }
        }

        return false;
      }

      this.form.ngSubmit.emit(this.form);

      return false;
    };
  }
}
