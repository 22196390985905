import 'angular';

import { ApiServiceAjs } from '../api/api.service.ajs';
import { CustomerModel } from '../customers/models/customer';
import { InvoiceModel }  from './models/invoice';
import { Table }         from '../table/types/table';

export class InvoicesForBillerPaymentCtrl {
  IE              : any;
  invoices        : Array<InvoiceModel>;
  isFlipped       = false;
  selectedBiller  : CustomerModel;
  selectedInvoices: Array<InvoiceModel> = [];

  billerTableSettings : Table = {
    columns: [
      {
        field : this.isVendor ? 'company_name' : 'display_name',
        header: {
          text: this.$translate.instant('COMPANY_SPACE.COMPANY')
        }
      }
    ],
    empty: {
      text: this.$translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_RECORDS_FOUND', {
        type: this.$translate.instant('COMPANY_SPACE.BILLER')
      })
    },
    pagination: {
      enabled: false
    },
    rows: {
      highlight: {
        enabled: true
      },
      select: {
        enabled: true
      }
    },
    sorting: {
      enabled: false
    },
    static: true
  };

  invoiceTableSettings : Table = {
    columns: [
      {
        field : this.isVendor ? 'vendor_invoice_number' : 'invoice_number',
        header: {
          text: this.$translate.instant('GENERAL_SPACE.FIELD.REFERENCE')
        }
      },
      {
        field : 'post_date',
        header: {
          text: this.$translate.instant('GENERAL_SPACE.FIELD.POST_DATE')
        },
        pipe    : 'date',
        pipeArgs: [ 'mediumDate' ]
      },
      {
        field : 'total',
        header: {
          text: this.$translate.instant('JS_SPACE.COLUMNS.AMOUNT')
        },
        pipe: 'globalCurrency'
      },
      {
        field : 'balance_due',
        header: {
          text: this.$translate.instant('JS_SPACE.COLUMNS.BALANCE')
        },
        pipe: 'globalCurrency'
      }
    ],
    empty: {
      text: this.$translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_INVOICES_FOUND')
    },
    pagination: {
      enabled: false
    },
    rows: {
      highlight: {
        enabled: true
      },
      select: {
        enabled: true,
        multi  : true
      }
    },
    sorting: {
      enabled: false
    },
    static: true
  };

  constructor (
    private $scope     : ng.IScope,
    private $translate : ng.translate.ITranslateService,
    private isVendor   : boolean,
    private workshopAPI: ApiServiceAjs,
    public  billers    : Array<CustomerModel>,
            IE         : any
  ) {
    this.IE = IE();
  }

  areAllSelected () : boolean {
    return this.invoices.length === this.selectedInvoices.length;
  }

  flip () : void {
    this.isFlipped = !this.isFlipped;

    this.$scope.$emit('flipModal');
  }

  handleInvoiceClick ( invoice : InvoiceModel ) : void {
    if (invoice.selected) {
      this.selectedInvoices.push(invoice);
    }
    else {
      this.selectedInvoices = this.invoices.filter(invoice => invoice.selected);
    }

    console.log(this.selectedInvoices);
  }

  selectBiller ( biller ?: CustomerModel ) : void {
    this.selectedBiller = biller || this.billers.filter(biller => biller.selected)[0];

    const url = this.isVendor
      ? '/vendors/invoice_list'
      : '/customers/invoice_list';

    const data = this.isVendor
      ? [ this.selectedBiller.id, 0, '*', 'P', 'I', '*', '*', '*', 'post_date', 'desc' ]
      : [ this.selectedBiller.id, 0, '*', 'P', 'I', 'post_date', 'desc' ];

    this.workshopAPI.get(url, data)
    .then(( invoices : Array<InvoiceModel> = [] ) => {
      this.invoices = invoices;

      this.flip();
    });
  }

  toggleAll () : void {
    if (this.areAllSelected()) {
      this.invoices.forEach(invoice => {
        invoice.selected = false;
      });

      this.selectedInvoices = [];
    }
    else {
      this.invoices.forEach(invoice => {
        invoice.selected = true;
      });

      this.selectedInvoices = this.invoices;
    }
  }
}
