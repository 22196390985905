import { NgModule }              from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { BookingsModule }     from '../bookings/bookings.module';
import { CustomersModule }    from '../customers/customers.module';
import { DashboardComponent } from './dashboard.component';
import { DashboardStates }    from './dashboard.states';
import { InvoicesModule }     from '../invoices/invoices.module';
import { JobsModule }         from '../jobs/jobs.module';
import { SharedModule }       from '../shared/shared.module';
import { QuotesModule }       from '../quotes/quotes.module';
import { VehiclesModule }     from '../vehicles/vehicles.module';

@NgModule({
  declarations: [
    DashboardComponent
  ],
  imports: [
    SharedModule,
    BookingsModule,
    CustomersModule,
    InvoicesModule,
    JobsModule,
    QuotesModule,
    VehiclesModule,
    UIRouterUpgradeModule.forChild({ states: DashboardStates }),
  ]
})
export class DashboardModule {}
