import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid';

import { ForgotPasswordComponent } from './password.forgot.component';
import { PasswordComponent }       from './password.component';
import { ResetPasswordComponent }  from './password.reset.component';

export const PasswordStates : Array<NgHybridStateDeclaration> = [
  {
    component : PasswordComponent,
    name      : 'ajs.app.public.password',
    redirectTo: 'ajs.app.public.forgot',
    url       : '/password'
  },
  {
    component: ForgotPasswordComponent,
    name     : 'ajs.app.public.password.forgot',
    url      : '/forgot'
  },
  {
    component: ResetPasswordComponent,
    name     : 'ajs.app.public.password.reset',
    url      : '/reset?reset_token'
  }
];
