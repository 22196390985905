import { UrlService } from '@uirouter/angularjs';

import { ApiModel } from './models/api.model';

export function HostConfig ($provide: ng.auto.IProvideService) {
  /**
   * we use the same branch for all live servers (production-branch).
   * Because of this we need to dynamically set the API url based on
   * the current hostname. Since the API constant is already injected
   * all over the app, it would be too much work to change all references
   * to use a function. Because of that, we changed the API constant to be
   * an AngularJS value. This way we can decorate it and change the url
   * at runtime based on the domain.
   */
  function delegate (
    $delegate   : ApiModel,
    $urlService : UrlService
  ) {
    try {
      $delegate.url = `${SERVER_ENDPOINT}`;
    }
    catch (error) {
      $delegate.url = `${$urlService.config.protocol()}://${$urlService.config.host()}${$delegate.url}`;
    }

    return $delegate;
  };

  $provide.decorator('API', delegate);
}