import EditableNumberCellHtml                from '../grids/templates/cell.number.editable.html';
import NumberCellHtml                        from '../grids/templates/cell.number.html';
import ProductSearchCellHtml                 from '../products/templates/product-search.cell.html';
import RemoveRowCellHtml                     from '../grids/templates/cell.remove-row.html';
import StockTransferProductSearchResultsHtml from '../products/templates/stock-transfer-product.search.results.html';

export function BranchStockTransferLiteCtrl (
  $rootScope,
  $scope,
  $state,
  $translate,
  branchesApi,
  messages,
  ProductSearch
) {
  $scope.Company       = $rootScope.Company;
  $scope.ProductSearch = new ProductSearch();

  $scope.grid = {
    data                   : 'transfers.product_transfer_list',
    enableCellSelection    : true,
    enableRowSelection     : false,
    enableCellEditOnFocus  : true,
    enableSorting          : false,
    rowHeight              : 43,
    virtualizationThreshold: 9999,
    columnDefs             : [
      {
        cellTemplate  : RemoveRowCellHtml,
        displayName   : '',
        enableCellEdit: false,
        width         : '52px'
      },
      {
        displayName         : $translate.instant('PRODUCT_SPACE.PRODUCT') ,
        editableCellTemplate: ProductSearchCellHtml,
        field               : 'item_code'
      },
      {
        cellClass     : 'text-center',
        cellFilter    : 'number',
        displayName   : 'Available',
        enableCellEdit: false,
        field         : 'quantity_available',
        width         : 100
      },
      {
        cellClass           : 'text-right',
        cellTemplate        : NumberCellHtml,
        displayName         : $translate.instant('PRODUCT_SPACE.QTY') ,
        editableCellTemplate: EditableNumberCellHtml,
        field               : 'quantity',
        max                 : row => parseFloat(row.entity.quantity_available)
      }
    ]
  };

  $scope.productSearch = {
    identifier: 'product_id',
    template: StockTransferProductSearchResultsHtml,
    search (typed) {
      return $scope.ProductSearch.searchBranchProducts($scope.transfers.transferring_company_id, typed);
    },
    format (product) {
      return angular.isObject(product) ? product.item_code: product;
    },
    choose (item, row) {
      row.entity.item_code          = item.item_code;
      row.entity.quantity           = 1;
      row.entity.product_id         = item.id;
      row.entity.quantity_available = parseFloat(item.quantity_on_hand || '0') - parseFloat(item.qty_reserved || '0');
    }
  };

  $scope.addItem = function () {
    var list = $scope.transfers.product_transfer_list;

    if (list[0] && list[0].product_id) {
      $scope.transfers.product_transfer_list.push($scope.getNewItem());
    }
    else {
      $scope.transfers.product_transfer_list = [ $scope.getNewItem() ];
    }
  };

  $scope.getNewItem = function () {
    return {
      item_code: '',
      product_id: '',
      quantity: 0,
      quantity_available: 0
    };
  };

  $scope.removeItem = function (row) {
    if (row.entity.product_id) {
      $scope.transfers.product_transfer_list.splice(row.rowIndex, 1);
    }

    if (!$scope.transfers.product_transfer_list.length) {
      $scope.addItem();
    }
  };

  $scope.transfer = function () {
    branchesApi.transferStock($scope.transfers).then(() => {
      messages.show('Stock Transferred', 'success');

      $scope.form.$setPristine();

      $state.go('app.products');
    }).catch(err => {
      messages.error(err);
    });
  };

  $scope.transfers = {
    transferring_company_id: $scope.Company.getId(),
    product_transfer_list: [$scope.getNewItem()]
  };
}
