import { InvoiceModel }               from '../invoices/models/invoice';
import { StripePaymentResponseModel } from './models/stripe.payment-response';

interface StripePaymentErrorCallbackModel {
  error: stripe.Error;
}
interface StripePaymentSuccessCallbackModel {
  intent: stripe.paymentIntents.PaymentIntent;
}
interface StripePaymentScope extends ng.IScope {
  accountId  : string;
  card       : stripe.elements.Element;
  elements   : stripe.elements.Elements;
  error      : string;
  invoice    : InvoiceModel;
  onError    : ( error  : StripePaymentErrorCallbackModel ) => stripe.Error;
  onSuccess  : ( intent : StripePaymentSuccessCallbackModel  ) => stripe.paymentIntents.PaymentIntent;
  publicKey  : string;
  submitEvent: string;
  stripe     : stripe.Stripe;
}

export function StripePaymentCtrl (
  $element: ng.IRootElementService,
  $timeout: ng.ITimeoutService,
  $scope  : StripePaymentScope
) {
  $scope.stripe = Stripe($scope.publicKey, {
    stripeAccount: $scope.accountId
  });

  $scope.elements = $scope.stripe.elements();
  $scope.card     = $scope.elements.create('card');

  $scope.card.mount($element.find('#stripe-card')[0]);

  $scope.card.on('change', evt => {
    $timeout(() => {
      $scope.error = evt.error ? evt.error.message : '';
    });
  });

  $scope.$on($scope.submitEvent || 'collectStripePayment', () => {
    $scope.stripe.confirmCardPayment($scope.invoice.stripe_client_secret, {
      payment_method: {
        card: $scope.card
      }
    })
    .then(( result : StripePaymentResponseModel ) => {
      if (result.error) {
        console.error(result.error);

        if ($scope.onError) {
          $timeout(() => {
            $scope.onError( { error: result.error });
          });
        }
      }
      else {
        if ($scope.onSuccess) {
          $timeout(() => {
            $scope.onSuccess({ intent: result.paymentIntent });
          });
        }
      }
    });
  });
};
