export function CustomerStatementSettingsLiteCtrl ($scope) {
  $scope.settings = {
    as_at       : '*',
    begin_date  : '*',
    end_date    : '*',
    include_paid: true,
    statement   : false
  };

  $scope.confirm = function () {
    $scope.$close($scope.settings);
  };
}
