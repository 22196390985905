export function CoreInheritorService ($controller) {
  function getChildPrototype (parent) {
    return Object.create(parent.prototype);
  }

  function inherit (child, parent) {
    parent.call(child);
  }

  function inheritCtrl (ctrl, scope) {
    $controller(ctrl, { $scope: scope });
  }

  function bootstrap (child, parent) {
    child.prototype = getChildPrototype(parent);

    Object.defineProperty(child.prototype, 'constructor', {
      value: child,
      enumerable: false, // so that it does not appear in 'for in' loop
      writable: true
    });

    for (const property in parent) {
      child[property] = parent[property];
    }

    return child;
  }

  return { bootstrap, inherit, inheritCtrl };
}
