import { NgModule }              from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { NavbarModule }        from '../navbar/navbar.module';
import { SharedModule }        from '../shared/shared.module';
import { PublicComponent }     from './public.component';
import { PublicStates }        from './public.states';

@NgModule({
  declarations: [
    PublicComponent
  ],
  imports: [
    SharedModule,
    NavbarModule,
    UIRouterUpgradeModule.forChild({ states: PublicStates })
  ]
})
export class PublicModule {}
