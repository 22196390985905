/**
 * Place in modal-content div of flip modals. Used to watch for
 * an emmited event to flip the modal.
 */
export function CoreFlipModalDirective ($rootScope) {
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      $rootScope.$on('flipModal', function () {
        element.toggleClass('flip');
      });
    }
  }
}
