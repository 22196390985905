import { VehicleHistoryReportSettingsModel } from './models/vehicle.history.report-settings';

export class VehicleHistoryReportCtrl {
  $uibModalInstance: ng.ui.bootstrap.IModalInstanceService;
  settings         : VehicleHistoryReportSettingsModel = {
    printTotal : false,
    showSummary: false
  };

  constructor (
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService
  ) {
    this.$uibModalInstance = $uibModalInstance;
  }

  confirm () : void {
    this.$uibModalInstance.close(this.settings);
  }
}
