import { ProductActionsComponent } from './product.actions.component';

export function ProductsLiteCtrl (
  $rootScope,
  $scope,
  $state,
  $translate,
  messages,
  Product,
  sessionObjAPI,
  tableApi,
  workshopAPI,
) {
  $scope.company       = sessionObjAPI.company();
  $scope.tableCacheKey = 'products';

  $scope.filtering = tableApi.setFiltersFromCache($scope.tableCacheKey, {
    inactive_only: false,
    value        : ''
  });

  $scope.columns = [
    {
      classes: 'all-caps',
      field  : 'item_code',
      header : {
        classes: 'all-caps',
        text   : $translate.instant('PRODUCT_SPACE.ITEM_CODE')
      }
    },
    {
      field : 'description',
      header: {
        text: $translate.instant('GENERAL_SPACE.FIELD.DESCRIPTION')
      }
    },
    {
      field : 'brand',
      header: {
        text: $translate.instant('PRODUCT_SPACE.BRAND')
      }
    },
    {
      classes: 'text-right',
      field  : 'retail_price',
      header : {
        classes: 'text-right',
        text   : $translate.instant('PRODUCT_SPACE.RETAIL_PRICE')
      },
      pipe: 'globalCurrency'
    },
    {
      classes: 'text-right',
      field  : $scope.company.price_includes_tax ? 'cost_including_tax': 'cost',
      header : {
        classes: 'text-right',
        text   : $translate.instant('PRODUCT_SPACE.COST')
      },
      pipe: 'globalCurrency'
    },
    {
      classes: 'text-right',
      field  : 'quantity_on_hand',
      header : {
        classes: 'text-right',
        text   : $translate.instant('PRODUCT_SPACE.QTY')
      }
    }
  ];

  if ($scope.company.reserved_stock) {
    $scope.columns.push({
      classes: 'text-right',
      field  : 'qty_available',
      header : {
        classes: 'text-right',
        text   : $translate.instant('PRODUCT_SPACE.QTY')
      }
    });
  }

  $scope.columns.push({
    component: {
      bindings: {
        outputs: {
          onActivateClick: event => $scope.activate(event),
          onEditClick    : event => $scope.select(event)
        }
      },
      enabled: true,
      factory: ProductActionsComponent,
    },
    sortable: false,
  });

  $scope.tableSettings = {
    broadcast: 'filterProducts',
    cache    : {
      enabled: true,
      key    : $scope.tableCacheKey
    },
    columns: $scope.columns,
    empty  : {
      text: $translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_PRODUCTS_FOUND')
    },
    pagination: {
      enabled   : true,
      pageSize  : tableApi.getCachedPaging($scope.tableCacheKey).pageSize || 10,
      pageSizes : [ 10, 25, 50 ],
      queryCount: () => workshopAPI.get('/filters/count', [
        $scope.filtering.value || '*',
        'products',
        $scope.filtering.inactive_only
      ])
    },
    rows: {
      highlight: {
        enabled: true
      }
    },
    sorting: tableApi.setSortingFromCache($scope.tableCacheKey, {
      direction: 'asc',
      field    : 'item_code'
    })
  };

  $scope.activate = function (row) {
    const product = new Product(row);

    product.activate().then(() => {
      messages.show($translate.instant('JS_SPACE.MESSAGES.PRODUCT_REACTIVATED'), 'success');

      $state.reload();
    }).catch(err => {
      messages.error(err);
    });
  };

  $scope.select = function (event) {
    $state.go('app.product', {
      id: event.id || event.row.id
    });
  };

  $scope.getProducts = ( params ) => {
    return workshopAPI.stream('/filters', [
      $scope.filtering.value || '*',
      'products',
      params.offset,
      params.pageSize,
      params.field,
      params.direction,
      $scope.filtering.inactive_only
    ]).then(products => {
      $scope.products = products;

      return products;
    });
  };

  $scope.$watch('[filtering.value, filtering.inactive_only]', (newVal, oldVal) => {
    if (newVal !== oldVal) {
      $rootScope.$broadcast('filterProducts', $scope.filtering);
    }
  }, true);
};