import { Component }                from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { TextControlComponent } from '../text-control/text-control.component';

@Component({
  selector   : 'wor-switch-control',
  styles: [require('./switch-control.component.scss')],
  template: require('./switch-control.component.html'),

  viewProviders: [
    {
      provide    : ControlContainer,
      useExisting: NgForm
    }
  ]
})
export class SwitchControlComponent extends TextControlComponent {};
