import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid';

import { LoginComponent } from './login.component';

export const LoginStates : Array<NgHybridStateDeclaration> = [
  {
    component: LoginComponent,
    name     : 'ajs.app.public.login',
    url      : '/login?email'
  }
];
