import { SessionServiceAjs } from '../../../app-lite/sessions/session.service.ajs';

function sessionServiceFactory ( i: any ) {
  return i.get('sessionObjAPI');
};

export const sessionServiceProvider = {
  deps      : ['$injector'],
  provide   : SessionServiceAjs,
  useFactory: sessionServiceFactory
};
