import { Component, Input, ViewChild } from '@angular/core';

import { ApiErrorModel }            from '../core/api/models/api.error';
import { ErrorHandlingService }     from '../core/errors/error-handling.service';
import { QuoteModel }               from './models/quote';
import { QuotesService }            from './quotes.service';
import { TableCellLinkComponent }   from '../table/table-cell.link.component';
import { TableCellSelectComponent } from '../table/table-cell.select.component';
import { TableCellTruthyComponent } from '../table/table-cell.truthy.component';
import { TableComponent }           from '../table/table.component';
import { TableCountModel }          from '../table/models/table-count';
import { TableModel }               from '../table/models/table';
import { TableQueryModel }          from '../table/models/table-query';
import { NotificationService } from '../notifications/notification.service';

@Component({
  selector   : 'wor-quotes',
  template: require('./quotes.component.html')
})
export class QuotesComponent {
  @Input() quotes: Array<QuoteModel>;

  @ViewChild('tableRef') tableRef: TableComponent;

  tableSettings : TableModel = {
    columns: [
      {
        classes  : [ 'table-cell-checkbox' ],
        component: TableCellSelectComponent,
        hideIf   : ( quote : QuoteModel ) => quote.quote_accepted_on
      },
      {
        field : 'job_card_number',
        header: {
          title: 'Quote #'
        }
      },
      {
        field : 'description',
        header: {
          title: 'Description'
        }
      },
      {
        field : 'total',
        header: {
          title: 'Total'
        },
        pipe: 'globalCurrency'
      },
      {
        classes : [ 'text-center' ],
        bindings: {
          inputs: {
            field: 'quote_accepted_on'
          }
        },
        component: TableCellTruthyComponent,
        header   : {
          classes: [ 'text-center' ],
          title  : 'Accepted'
        }
      },
      {
        classes : [ 'text-right' ],
        bindings: {
          inputs: {
            title: 'View Quote'
          },
          outputs: {
            onClick: ( quote : QuoteModel ) => this.viewQuote(quote)
          }
        },
        component: TableCellLinkComponent
      }
    ],
    empty: {
      message: 'No Quotes Found'
    },
    pagination: this.quotesService.defaultPagination,
    rows      : {
      hover: {
        enabled: true
      },
      select: {
        enabled: true
      }
    },
    title: 'Current Quotes'
  };

  constructor (
    private errorHandlingService: ErrorHandlingService,
    private notificationService : NotificationService,
    private quotesService       : QuotesService
  ) {}

  acceptQuote () : void {
    const quote = this.quotes.filter(quote => quote.selected)[0];

    this.quotesService.acceptQuote(quote)
    .then(() => this.tableRef.refresh())
    .then(( quotes : Array<QuoteModel> ) => {
      this.quotes = quotes;

      this.notificationService.show('Quote Accepted');
    })
    .catch(( err : ApiErrorModel ) => {
      this.errorHandlingService.show(err);
    });
  }

  getQuoteCount () : ng.IPromise<TableCountModel> {
    return this.quotesService.getQuoteCount();
  }

  getQuotes ( params : TableQueryModel ) : ng.IPromise<Array<QuoteModel>> {
    return this.quotesService.getQuotes(params.offset, params.pageSize);
  }

  isQuoteSelected () : boolean {
    return !!this.quotes.filter(quote => quote.selected).length;
  }

  viewQuote ( quote : QuoteModel ) {
    window.open(this.quotesService.getQuoteLink(quote), '_blank');
  }
}