import { ApiServiceAjs }                 from '../../api/api.service.ajs';
import { CodeTypeModel }                 from '../../core/models/code-type';
import { JobRowExpandComponent }         from './transaction-center.job.row.expand.component';
import { MessengerService }              from '../../messages/messenger.service.ajs';
import { SessionServiceAjs }             from '../../sessions/session.service.ajs';
import { Table }                         from '../../table/types/table';
import { TableQueryParameters }          from '../../table/types/table-query-parameters';
import { TableService }                  from '../../table/table.service';
import { TransactionCenterFiltersModel } from '../models/transaction-center.filters';

export class TransactionCenterJobsCtrl implements ng.IComponentController {
  company         : any;
  constraints     : Array<CodeTypeModel>;
  fleetCodeLabel  : string;
  jobs            : Array<any>;
  jobStatuses     : Array<CodeTypeModel>;
  jobStatusOptions: Array<CodeTypeModel>;
  regoLabel       : string;

  tableCacheKey = this.$state.current.data.tableCacheKey;

  filters : TransactionCenterFiltersModel = this.tableApi.setFiltersFromCache(this.tableCacheKey, {
    postDate   : '*',
    constrainBy: '*',
    jobStatus  : '*',
    value      : ''
  });

  tableSettings : Table = {
    broadcast: 'filterJobs',
    cache    : {
      enabled: true,
      key    : this.tableCacheKey
    },
    columns: [],
    empty  : {
      text: this.$translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_JOBS_FOUND')
    },
    pagination: {
      enabled   : true,
      pageSize  : this.tableApi.getCachedPaging(this.tableCacheKey).pageSize || 10,
      pageSizes : [ 10, 25, 50 ],
      queryCount: () => this.getCount()
    },
    rows: {
      expand: {
        bindings: {
          outputs: {
            onEditClick : event => this.viewJob(event),
            onLaborClick: event => this.$state.go('app.labor-items', { invoice_id: event.job.invoice.id }),
            onSMSClick  : event => this.sendSMS(event)
          }
        },
        enabled: true,
        factory: JobRowExpandComponent
      },
      highlight: {
        enabled: true
      },
      select: {
        enabled: true
      }
    },
    sorting: this.tableApi.setSortingFromCache(this.tableCacheKey, {
      direction: 'desc',
      field    : 'invoice.post_date'
    })
  };

  constructor (
    private $rootScope   : ng.IRootScopeService,
    private $scope       : ng.IScope,
    private $state       : ng.ui.IStateService,
    private $translate   : ng.translate.ITranslateService,
    private messages     : any,
    private messengerApi : MessengerService,
    private sessionObjAPI: SessionServiceAjs,
    private tableApi     : TableService,
    private workshopAPI  : ApiServiceAjs,
  ) {
    this.company        = this.sessionObjAPI.company();
    this.fleetCodeLabel = this.company.company_variable_fields.fleet_code_name || this.$translate.instant('VEHICLE_SPACE.FLEET_CODE');
    this.regoLabel      = this.company.company_variable_fields.plate_number_name || this.sessionObjAPI.countrySettings().rego_label;

    this.$scope.$watch(() => {
      return [
        this.filters.value,
        this.filters.postDate,
        this.filters.constrainBy,
        this.filters.jobStatus
      ];
    }, ( newVal, oldVal ) => {
      if ( newVal !== oldVal ) {
        this.$rootScope.$broadcast('filterJobs', this.filters);
      }
    }, true);

    this.$scope.$on('searchFilterChanged', ( event : ng.IAngularEvent, value : string ) => {
      if (!this.$state.transition) {
        this.filters.value = value;
      }
    });
  }

  $onInit () : void {
    this.buildColumns();
  }

  buildColumns () : void {
    this.tableSettings.columns =
    this.company.company_settings.show_fleet_code
    ?
    [
      {
        field : 'job.invoice.post_date',
        header: {
          text: this.$translate.instant('GENERAL_SPACE.FIELD.DATE')
        },
        pipe: 'globalDate'
      },
      {
        field : 'job.customer.display_name',
        header: {
          text: this.$translate.instant('CUSTOMER_SPACE.CUSTOMER')
        }
      },
      {
        field : 'job.vehicle.make',
        header: {
          text: this.$translate.instant('VEHICLE_SPACE.MAKE')
        }
      },
      {
        field : 'job.vehicle.model',
        header: {
          text: this.$translate.instant('VEHICLE_SPACE.MODEL')
        }
      },
      {
        field : 'job.vehicle.plate_number',
        header: {
          text: this.regoLabel
        }
      },
      {
        classes: 'text-center',
        field  : 'job.vehicle.fleet_code',
        header : {
          classes: 'text-center',
          text   : this.fleetCodeLabel
        }
      },
      {
        classes: 'text-center',
        field  : 'job.invoice.job_card_number',
        header : {
          classes: 'text-center',
          text   : this.$translate.instant('MENU_SPACE.JOB_CARD')
        }
      },
      {
        classes: 'text-left',
        field  : 'job.invoice.job_status',
        header : {
          classes: 'text-left',
          text   : this.$translate.instant('GENERAL_SPACE.FIELD.STATUS')
        },
        pipe    : 'jobStatus',
        pipeArgs: [ this.jobStatuses ]
      },
      {
        classes: 'text-left',
        field  : 'job.invoice.job_status_comment',
        header : {
          classes: 'text-left',
          text   : this.$translate.instant('JS_SPACE.COLUMNS.STATUS_COMMENT')
        },
      },
      {
        classes: 'text-right',
        field  : 'job.invoice.total',
        header : {
          classes: 'text-right',
          text   : this.$translate.instant('GENERAL_SPACE.FIELD.TOTAL')
        },
        pipe: 'globalCurrency'
      }
    ]
    :
    [
      {
        field : 'job.invoice.post_date',
        header: {
          text: this.$translate.instant('GENERAL_SPACE.FIELD.DATE')
        },
        pipe: 'globalDate'
      },
      {
        field : 'job.customer.display_name',
        header: {
          text: this.$translate.instant('CUSTOMER_SPACE.CUSTOMER')
        }
      },
      {
        field : 'job.vehicle.make',
        header: {
          text: this.$translate.instant('VEHICLE_SPACE.MAKE')
        }
      },
      {
        field : 'job.vehicle.model',
        header: {
          text: this.$translate.instant('VEHICLE_SPACE.MODEL')
        }
      },
      {
        field : 'job.vehicle.plate_number',
        header: {
          text: this.regoLabel
        }
      },
      {
        classes: 'text-center',
        field  : 'job.invoice.job_card_number',
        header : {
          classes: 'text-center',
          text   : this.$translate.instant('MENU_SPACE.JOB_CARD')
        }
      },
      {
        classes: 'text-left',
        field  : 'job.invoice.job_status',
        header : {
          classes: 'text-left',
          text   : this.$translate.instant('GENERAL_SPACE.FIELD.STATUS')
        },
        pipe    : 'jobStatus',
        pipeArgs: [ this.jobStatuses ]
      },
      {
        classes: 'text-left',
        field  : 'job.invoice.job_status_comment',
        header : {
          classes: 'text-left',
          text   : this.$translate.instant('JS_SPACE.COLUMNS.STATUS_COMMENT')
        }
      },
      {
        classes: 'text-right',
        field  : 'job.invoice.total',
        header : {
          classes: 'text-right',
          text   : this.$translate.instant('GENERAL_SPACE.FIELD.TOTAL')
        },
        pipe: 'globalCurrency'
      }
    ];
  }

  getCount () : ng.IPromise<number> {
    return this.workshopAPI.get('/job_center/count', [
      this.filters.value       || '*',
      this.filters.postDate    || '*',
      this.filters.constrainBy || '*',
      this.filters.jobStatus   || '*'
    ]);
  }

  getJobs ( params : TableQueryParameters ) : ng.IPromise<any> {
    return this.workshopAPI.stream('/job_center', [
      this.filters.value || '*',
      params.offset,
      params.pageSize,
      this.filters.postDate    || '*',
      this.filters.constrainBy || '*',
      this.filters.jobStatus   || '*',
      params.field.replace('job.', ''),
      params.direction
    ])
    .then( ( jobs : any ) => {
      this.jobs = jobs;

      return jobs;
    });
  }

  sendSMS ( row : any ) : void {
    this.messengerApi.sms({
      name      : row.job.customer.display_name,
      send_to   : row.job.customer.mobile,
      invoice_id: row.job.invoice.id
    })
    .then(() => {
      this.messages.show(this.$translate.instant('JS_SPACE.MESSAGES.MESSAGE_SENT'), 'success');
    });
  }

  viewJob ( job : any ) : void {
    this.$state.go('app.invoice', {
      customer_id: job.job.invoice.customer_id,
      id         : job.job.invoice.id,
      vehicle_id : job.job.invoice.vehicle_id
    });
  }
}