import { Injectable } from '@angular/core';

import { ApiService }           from '../core/api/api.service';
import { QuoteModel }           from './models/quote';
import { TableCountModel }      from '../table/models/table-count';
import { TablePaginationModel } from '../table/models/table-pagination';

@Injectable({
  providedIn: 'root'
})
export class QuotesService {
  defaultPagination : TablePaginationModel = {
    enabled : true,
    offset  : 0,
    pageSize: 4
  };

  constructor (
    private apiService: ApiService
  ) {}

  acceptQuote ( quote : QuoteModel ) : ng.IPromise<boolean> {
    return this.apiService.post('/customer_portal_dashboard/accept_quote', {
      invoice_id: quote.id
    });
  }

  getQuoteCount () : ng.IPromise<TableCountModel> {
    return this.apiService.get('/customer_portal_dashboard/quotes_count');
  }

  getQuoteLink ( quote : QuoteModel ) : string {
    return `${ this.apiService.getEndpoint() }/customer_portal_dashboard/invoice_report/${ quote.id }`;
  }

  getQuotes ( offset = this.defaultPagination.offset, pageSize = this.defaultPagination.pageSize ) : ng.IPromise<Array<QuoteModel>> {
    return this.apiService.get('/customer_portal_dashboard/quotes_index', [
      offset,
      pageSize
    ])
    .then(( quotes : Array<QuoteModel> ) => {
      return quotes.map(quote => {
        quote.unselectable = !!quote.quote_accepted_on;

        return quote;
      });
    });
  }
}