import StripeStatusHtml from './stripe.status.html';

export function StripeStatusDirective () {
  const directive : ng.IDirective = {
    controller: 'StripeStatusCtrl',
    restrict  : 'E',
    scope     : {
      status: '='
    },
    templateUrl: StripeStatusHtml
  };

  return directive;
}