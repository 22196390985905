import InspectionsHtml from './transaction-center.inspections.html';

export const TransactionCenterInspectionsComponent : ng.IComponentOptions = {
  bindings: {
    inspections: '=',
    statuses   : '<'
  },
  controller  : 'TransactionCenterInspectionsCtrl',
  controllerAs: '$ctrl',
  templateUrl : InspectionsHtml,
};