export function BusinessIntelligenceDashboardCtrl (
  $element,
  $scope,
  $timeout,
  businessIntelligenceApi
) {
  $scope.id;
  $scope.loading = true;
  $scope.status  = {};

  $scope.filters = {
    end_date: '',
    start_date: ''
  };

  $scope.clearFilters = function (form = $scope.form) {
    businessIntelligenceApi.clearFilters().then(() => {
      $timeout(() => {
        $scope.filters = {};

        form.$setPristine();
      });
    });
  };

  $scope.filter = function () {
    $scope.loading = true;

    businessIntelligenceApi.filter($scope.filters).finally(() => {
      $timeout(() => {
        businessIntelligenceApi.toggleAdvancedFilters();

        $scope.loading = false;
      });
    });
  };

  $scope.isStatus = function (status = 'ready') {
    return angular.isArray(status) ?
      status.indexOf($scope.status.state) > -1 :
      $scope.status.state === status;
  };

  $scope.print = function () {
    $timeout(() => {
      $element.find('#bi-frame')[0].scrollIntoView(false);

      businessIntelligenceApi.print();
    });
  };

  $scope.toggleAdvancedFilters = function () {
    businessIntelligenceApi.toggleAdvancedFilters();
  };

  businessIntelligenceApi.isReady()
  .then(status => {
    $scope.status = status;

    return businessIntelligenceApi.bootstrapFrame($element.find('#bi-frame iframe')[0]);
  })
  .then(() => {
    $scope.$watch('id', (newId, oldId) => {
      if (newId) {
        $scope.loading = true;

        businessIntelligenceApi.loadDashboard(newId).finally(() => {
          $timeout(() => {
            $scope.loading = false;
          });
        });
      }
      else {
        businessIntelligenceApi.hideFrame();
      }
    });
  })
  .catch((status = {}) => {
    $scope.status = status;
  });
}
