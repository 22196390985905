import 'angular';

import { downgradeInjectable } from '@angular/upgrade/static';

import '../api/api.module';
import '../core/core.module.ajs';
import '../customers/customers.module';
import '../messages/messages.module.ajs';

import { CustomerPaymentLiteCtrl }     from './customer-payment';
import { CustomerPaymentMethodFilter } from './customer-payment.method.filter.ajs';
import { CustomerPaymentMethodPipe }   from './customer-payment.method.pipe';
import { CustomerPaymentMethodsCtrl}   from './customer-payment.methods.ajs';
import { CustomerPaymentService }      from './customer-payment.service.ajs';
import { CustomerPaymentStatusFilter } from './customer-payment.status.filter.ajs';
import { CustomerPaymentStatusPipe }   from './customer-payment.status.pipe';

angular.module('app-lite.customer-payments', [
  'app-lite.api',
  'app-lite.core',
  'app-lite.customers',
  'app-lite.messages'
])
.controller('CustomerPaymentLiteCtrl', CustomerPaymentLiteCtrl)
.controller('CustomerPaymentMethodsCtrl', CustomerPaymentMethodsCtrl)
.filter('customerPaymentMethod', CustomerPaymentMethodFilter)
.filter('customerPaymentStatus', CustomerPaymentStatusFilter)
.service('customerPaymentMethodPipe', downgradeInjectable(CustomerPaymentMethodPipe))
.service('customerPaymentStatusPipe', downgradeInjectable(CustomerPaymentStatusPipe))
.service('customerPaymentApi', CustomerPaymentService);
