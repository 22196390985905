import { CodeTypeModel } from '../core/models/code-type';

interface InspectionGroupScope extends ng.IScope {
  delete: () => void;
  group : CodeTypeModel;
  save  : ( form : ng.IFormController ) => void;
}

export function InspectionGroupCtrl (
  $scope     : InspectionGroupScope,
  $state     : ng.ui.IStateService,
  $translate : ng.translate.ITranslateService,
  confirm    : any,
  group      : CodeTypeModel,
  messages   : any,
  workshopAPI: any
) {
  $scope.group = group;

  $scope.save = function (form) {
    const method = $scope.group.id ? 'patch' : 'post';
    const url    = $scope.group.id ? '/inspection_group' : '/inspection_groups';

    workshopAPI[method](url, $scope.group, 'inspection_group')
    .then(( group : CodeTypeModel ) => {
      $scope.group = group;

      messages.show($translate.instant('JS_SPACE.MESSAGES.SAVED.GROUP'), 'success');

      form.$setPristine();
      $state.go('app.inspection-group', {
        id: group.id
      });
    })
    .catch(err => {
      messages.error(err);
    });
  };

  $scope.delete = function () {
    confirm.generic($translate.instant('JS_SPACE.CONFIRM.DELETE_GROUP'))
    .then(() => workshopAPI.delete('/inspection_group', $scope.group.id))
    .then(() => {
      messages.show($translate.instant('JS_SPACE.MESSAGES.DELETED.GROUP'), 'success');

      $state.go('app.inspection-groups');
    })
    .catch(err => {
      messages.error(err);
    });
  };
};