import 'angular';

import { downgradeInjectable } from '@angular/upgrade/static';

import { TransactionCenterInspectionsComponent } from './transaction-center.inspections.component.ajs';
import { TransactionCenterInspectionsCtrl }      from './transaction-center.inspections.ajs';
import { TransactionCenterInspectionsService }   from './transaction-center.inspections.service';

angular.module('app-lite.transaction-center.inspections', [])
  .component('worTcInspections', TransactionCenterInspectionsComponent)

  .controller('TransactionCenterInspectionsCtrl', TransactionCenterInspectionsCtrl)

  .service('transactionCenterInspectionsApi', downgradeInjectable(TransactionCenterInspectionsService));
