import 'angular';

import { downgradeComponent } from '@angular/upgrade/static';

import { CopyToClipboardDirective } from './copy-to-clipboard/copy-to-clipboard.component';

angular.module('app-lite.shared.directives', [])
  .config(['$provide', $provide => {
    $provide.decorator('worCopyToClipboardDirective', ['$delegate', $delegate => {
      $delegate[0].restrict = 'EA';

      return $delegate;
    }]);
  }])
  .directive('worCopyToClipboard', downgradeComponent({ component: CopyToClipboardDirective }) as ng.IDirectiveFactory);
