import { Component }                from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { TextControlComponent } from '../text-control/text-control.component';

@Component({
  selector   : 'wor-email-control',
  template: require('./email-control.component.html'),

  viewProviders: [
    {
      provide    : ControlContainer,
      useExisting: NgForm
    }
  ]
})
export class EmailControlComponent extends TextControlComponent {}
