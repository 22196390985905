import { Component } from '@angular/core';
import { UIRouter }  from '@uirouter/core';

import { ApiErrorModel }        from '../core/api/models/api.error';
import { ErrorHandlingService } from '../core/errors/error-handling.service';
import { NotificationService }  from '../notifications/notification.service';
import { PasswordService }      from './password.service';
import { UserAccountModel }     from '../users/models/user.account';

@Component({
  template: require('./password.forgot.component.html')
})
export class ForgotPasswordComponent {
  account : UserAccountModel = {};

  constructor (
    private errorHandlingService: ErrorHandlingService,
    private notificationService : NotificationService,
    private passwordService     : PasswordService,
    private router              : UIRouter
  ) {}

  sendRequest () {
    this.passwordService.requestReset(this.account)
    .then(() => {
      this.notificationService.show('Please check your email. If an account exists for this email, you will be able to reset your password.');

      this.router.stateService.go('ajs.app.public.login');
    })
    .catch(( err : ApiErrorModel ) => {
      this.errorHandlingService.show(err);
    });
  }
};
