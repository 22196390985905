import PasswordControlHtml from './password-control.html';

export const PasswordControlComponent : ng.IComponentOptions = {
  bindings: {
    disabled       : '<?',
    form           : '=',
    label          : '@?',
    model          : '=',
    mustMatch      : '<?',
    name           : '@',
    placeholder    : '@?',
    required       : '<?',
    showHint       : '<?',
    validatePattern: '<?'
  },
  controller: 'PasswordControlCtrl',
  require   : {
    form: '^form'
  },
  templateUrl: PasswordControlHtml
};
