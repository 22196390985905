import 'angular';

import { ApiModel }      from '../api/models/api.model';
import { ApiServiceAjs } from '../api/api.service.ajs';
import { VehicleModel }  from './models/vehicle';

import AddVehicleFromBranchHtml from './vehicle.add-from-branch.html';
import VehicleBranchHistoryHtml from './vehicle.branch-history.html';
import VehicleFollowUpNotesHtml from './vehicle.follow-up-notes.html';
import VehicleFormSettingsHtml  from './vehicle.form-settings.html';

export class VehicleService {
  $rootScope : any;
  $translate : ng.translate.ITranslateService;
  $uibModal  : ng.ui.bootstrap.IModalService;
  $window    : ng.IWindowService;
  API        : ApiModel;
  confirm    : any;
  workshopAPI: ApiServiceAjs;

  constructor (
    $rootScope : any,
    $translate : ng.translate.ITranslateService,
    $uibModal  : ng.ui.bootstrap.IModalService,
    $window    : ng.IWindowService,
    API        : ApiModel,
    confirm    : any,
    workshopAPI: ApiServiceAjs
  ) {
    this.$rootScope  = $rootScope;
    this.$translate  = $translate;
    this.$uibModal   = $uibModal;
    this.$window     = $window;
    this.API         = API;
    this.confirm     = confirm;
    this.workshopAPI = workshopAPI;
  }

  calculatePriceExcludingTax ( costIncludingTax : any ) : number {
    const taxRate = new BigDecimal( this.$rootScope.Company.company.purchases_tax_rate.toString() ).setScale(2, BigDecimal.ROUND_HALF_UP).movePointLeft(2);

    costIncludingTax = new BigDecimal( costIncludingTax.toString() ).setScale(4, BigDecimal.prototype.ROUND_HALF_UP);

    const cost = costIncludingTax.divide(BigDecimal.ONE.add(taxRate)).setScale(4, BigDecimal.prototype.ROUND_HALF_UP);

    return parseFloat(cost);
  }

  calculatePriceIncludingTax ( cost : any ) : number {
    const taxRate = new BigDecimal( this.$rootScope.Company.company.purchases_tax_rate.toString() ).setScale(2, BigDecimal.ROUND_HALF_UP).movePointLeft(2);

    cost = new BigDecimal( cost.toString() ).setScale(2, BigDecimal.prototype.ROUND_HALF_UP);

    const tax = taxRate.multiply(cost).setScale(2, BigDecimal.prototype.ROUND_HALF_UP);

    return parseFloat(cost.add(tax).toString());
  }

  checkVinUniqueness ( vin : string ) : ng.IPromise<boolean> {
    return this.workshopAPI.post('/vehicle/verify_vin_uniqueness', {
      vin: vin
    });
  }

  copyFromBranch ( vehicle : VehicleModel, customer_id : string ) : ng.IPromise<VehicleModel> {
    return this.workshopAPI.get('/vehicles/copy_from_branch', [ vehicle.id, customer_id ]);
  }

  createVehicleFromOtherBranch ( customer_id : string ) : ng.IPromise<any> {
    return this.$uibModal.open({
      controller  : 'AddVehicleFromBranchCtrl',
      controllerAs: '$ctrl',
      keyboard    : false,
      templateUrl : AddVehicleFromBranchHtml,
      windowClass : 'x-lg-modal',
      resolve     : {
        customer_id: () => customer_id
      },
    }).result;
  }

  getBranchVehicles ( filters : any ) : ng.IPromise<Array<VehicleModel>> {
    return this.workshopAPI.post('/vehicles/list_vehicles_for_customer_from_head_office', filters);
  }

  getFormSettings ( vehicle : VehicleModel ) : ng.IPromise<any> {
    return this.$uibModal.open({
      controller : 'VehicleFormSettingsLiteCtrl',
      templateUrl: VehicleFormSettingsHtml,
      windowClass: 'md-modal',
      resolve    : {
        formList: () => this.workshopAPI.get('/system/vehicle_forms_for_state'),
        vehicle : () => vehicle
      }
    }).result;
  }

  isMusicalInstrument ( vehicle : VehicleModel ) : boolean {
    return vehicle.vehicle_group === 'MUSIC';
  }

  isUnit ( vehicle : VehicleModel ) : boolean {
    return !!vehicle.date_acquired;
  }

  isLinkedToHeadOffice ( vehicle : VehicleModel ) : boolean {
    return !!vehicle.branch_link;
  }

  printVehicleForm ( vehicle : VehicleModel ) : void {
    this.getFormSettings(vehicle)
    .then(settings => this.workshopAPI.get('/vehicle_form_serial_numbers/check_if_exists', [
      vehicle.id,
      settings.form_code
    ])
    .then(( serialNumber = {} ) => {
      if (serialNumber && serialNumber.id) {
        this.confirm.generic(this.$translate.instant('JS_SPACE.MESSAGES.FORM_ALREADY_PRINTED'))
        .then(() => this.$window.open(`${ this.API.url }/reports/jasper_vehicle_forms/${ vehicle.id }/${ settings.form_code }.pdf`, '_blank'));
      }
      else {
        this.workshopAPI.post('/vehicle_form_serial_numbers', {
          form_code : settings.form_code,
          vehicle_id: vehicle.id
        }, 'vehicle_form_serial_number')
        .then(() => this.$window.open(`${ this.API.url }/reports/jasper_vehicle_forms/${ vehicle.id }/${ settings.form_code }.pdf`, '_blank'));
      }
    }));
  }

  viewBranchHistory ( vehicle : VehicleModel ) : ng.IPromise<any> {
    return this.$uibModal.open({
      controller : 'VehicleBranchHistoryLiteCtrl',
      templateUrl: VehicleBranchHistoryHtml,
      windowClass: 'x-lg-modal',
      resolve    : {
        invoices: () => this.workshopAPI.get('/vehicles/invoice_list', [
          vehicle.id,
          0,
          '*',
          '*',
          '*'
        ]),
        vehicle: () => vehicle
      }
    }).result;
  }

  viewFollowUpNotes ( vehicle: VehicleModel, reference : string, view : string ) : ng.IPromise<any> {
    return this.$uibModal.open({
      controller : 'VehicleFollowUpNotesLiteCtrl',
      templateUrl: VehicleFollowUpNotesHtml,
      windowClass: 'lg-modal',
      resolve    : {
        vehicle  : () => vehicle,
        reference: () => reference,
        view     : () => view
      }
    }).result;
  };
}