import * as angular from 'angular';

import { ActivateFlipPayCtrl } from './flippay.activate.ajs';
import { FlipPayStates }       from './flippay.states.ajs';
import { FlipPayService }      from './flippay.service.ajs';
import { FlipPaySettingsCtrl } from './flippay.settings.ajs';

angular.module('app-lite.flippay', [])
  .config(FlipPayStates)
  .controller('ActivateFlipPayCtrl', ActivateFlipPayCtrl)
  .controller('FlipPaySettingsCtrl', FlipPaySettingsCtrl)
  .service('flipPayApi', FlipPayService);
