import { CustomerPaymentMethodModel } from './models/customer-payment.method';
import { CustomerPaymentModel }       from './models/customer-payment';

interface CustomerPaymentMethodsScope extends ng.IScope {
  payment       : CustomerPaymentModel;
  paymentMethods: Array<CustomerPaymentMethodModel>;
  tableSettings : any;
}

export function CustomerPaymentMethodsCtrl (
  $scope    : CustomerPaymentMethodsScope,
  $translate: ng.translate.ITranslateService,
  payment   : CustomerPaymentModel
) {
  $scope.payment        = payment;
  $scope.paymentMethods = $scope.payment.customer_payment_methods_attributes;

  $scope.tableSettings = {
    columns: [
      {
        classes: 'text-left',
        field  : 'payment_type',
        header : {
          text: $translate.instant('GENERAL_SPACE.FIELD.PAYMENT_METHOD')
        }
      },
      {
        classes: 'text-left',
        field  : 'reference',
        header :{
          text: $translate.instant('GENERAL_SPACE.FIELD.REFERENCE')
        }
      }
    ],
    empty: {
      text: 'No Payment Methods'
    },
    pagination: {
      enabled: false
    },
    sorting: {
      enabled: false
    },
    static: true
  };
}
