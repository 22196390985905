import { CodeTypeModel }      from '../core/models/code-type';
import { PaymentMethodModel } from './models/payment-method';

interface PaymentMethodsScope extends ng.IScope {
  accounts       : Array<CodeTypeModel>;
  crudGridAPI    : any;
  getAccounts    : () => void;
  isCashType     ( method : PaymentMethodModel ) : boolean;
  loadingAccounts: boolean;
  paymentMethods : Array<PaymentMethodModel>;
  removeRow      : ( method : PaymentMethodModel, index : number ) => void;
  saveRow        : ( method : PaymentMethodModel, index : number ) => void;
}

export function PaymentMethodsCtrl (
  $rootScope   : any,
  $scope       : PaymentMethodsScope,
  $translate   : ng.translate.ITranslateService,
  crudGridAPI  : any,
  messages     : any,
  workshopAPI  : any,
  confirm      : any
) {
  $scope.crudGridAPI      = crudGridAPI();
  $scope.crudGridAPI.data = $scope.paymentMethods;
  $scope.loadingAccounts  = true;

  $scope.getAccounts = function () {
    $rootScope.Company.getPaymentAccounts()
    .then(( accounts : Array<CodeTypeModel> ) => {
      $scope.accounts = accounts;
    })
    .finally(() => {
      $scope.loadingAccounts = false;
    });
  };

  $scope.isCashType = function ( method : PaymentMethodModel ) : boolean {
    return method.payment_method && method.payment_method.toLowerCase() === 'cash';
  };

  $scope.saveRow = function ( method : PaymentMethodModel, index : number ) : void {
    const protocol = method.id ? 'patch' : 'post';
    const url      = method.id ? '/invoice_payment_method' : '/invoice_payment_methods';

    workshopAPI[protocol](url, method, 'invoice_payment_method')
    .then(( methods : Array<PaymentMethodModel> ) => {
      $scope.crudGridAPI.data = method.id ? $scope.crudGridAPI.data : methods;

      $scope.crudGridAPI.toggleEditMode(method, index);

      messages.show($translate.instant('JS_SPACE.MESSAGES.SAVED.PAYMENT_METHOD'), 'success');
    })
    .catch(err => {
      messages.error(err);
    });
  };

  $scope.removeRow = function ( method : PaymentMethodModel, index : number ) : void {
    confirm.generic($translate.instant('JS_SPACE.CONFIRM.DELETE_TEMPLATE'))
    .then(() => $scope.crudGridAPI.removeRow(method, index, '/invoice_payment_methods', 'id'))
    .then(() => {
      messages.show($translate.instant('JS_SPACE.MESSAGES.DELETED.PAYMENT_METHOD'), 'success');

      $scope.crudGridAPI.data.splice(index, 1);
    });
  };

  $scope.getAccounts();
};