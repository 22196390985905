import { Component, Input } from '@angular/core';

import { JobModel } from './models/job';

@Component({
  selector   : 'wor-job-status-cell',
  template: require('./job.status.cell.component.html')
})
export class JobStatusCellComponent {
  @Input() record : JobModel;

  getStatusClass () : string {
    switch (this.record.job_status) {
      case ('A'):
      case ('C'):
      case ('F'):
        return 'badge-success';
      case ('I'):
      case ('N'):
      case ('W'):
        return 'badge-warning';
      case ('P'):
      case ('U'):
        return 'badge-danger';
      case ('B'):
      default:
        return 'badge-info';
    };
  }
}
