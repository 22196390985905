export function VendorPaymentLiteCtrl (
  $scope,
  $state,
  $translate,
  API,
  gridAPI,
  messages,
  sessionObjAPI,
  vendorPaymentsApi
) {
  $scope.api               = API;
  $scope.currency          = sessionObjAPI.currency();
  $scope.editable          = vendorPaymentsApi.isOpen($scope.payment);
  $scope.hasCredits        = vendorPaymentsApi.hasCredits($scope.vendor);
  $scope.loaded            = true;

  $scope.vendorSelected = function (vendor) {
    $state.go(vendor.is_branch_vendor ? 'app.vendor-branch-payment' : 'app.vendor-payment', {
      vendor_id: vendor.id
    });
  };

  $scope.grid = vendorPaymentsApi.getGridSettings($scope.vendor, $scope.editable);

  $scope.addBillerCredit = function () {
    vendorPaymentsApi.addBillerCredit($scope.payment);
  };

  $scope.addItems = function () {
    vendorPaymentsApi.addItems($scope.payment)
    .then(payment => {
      $scope.payment = payment;
    });
  };

  $scope.delete = function () {
    vendorPaymentsApi.delete($scope.payment)
    .then(() => {
      messages.show($translate.instant('JS_SPACE.MESSAGES.DELETED.PAYMENT'), 'success');

      $scope.form.$setPristine();
      $state.go('app');
    })
    .catch(err => {
      messages.error(err);
    });
  };

  $scope.email = function () {
    vendorPaymentsApi.email($scope.vendor, $scope.payment)
    .then(() => {
      messages.show($translate.instant('JS_SPACE.MESSAGES.EMAIL_SENT'), 'success');
    });
  };

  $scope.getRowColor = function (item, column) {
    const classes = gridAPI.getColumnClasses(column);

    if (!vendorPaymentsApi.isOpen($scope.payment)) {
      classes.push('readonly');
    }

    return classes;
  };

  $scope.process = function() {
    vendorPaymentsApi.process($scope.payment)
    .then(payment => {
      messages.show($translate.instant('JS_SPACE.MESSAGES.PROCESSED.PAYMENT'), 'success');

      if ($scope.payment.id) {
        $state.reload();

        return;
      }

      $state.go('app.vendor-payment', {
        vendor_id: payment.vendor_id,
        id       : payment.id
      });
    })
    .catch(err => {
      messages.error(err);
    });
  };

  $scope.removeItem = function (row) {
    vendorPaymentsApi.removeItem($scope.payment, row.rowIndex);
  };

  $scope.save = function () {
    vendorPaymentsApi.save($scope.payment)
    .then(payment => {
      $scope.form.$setPristine();

      messages.show($translate.instant('JS_SPACE.MESSAGES.SAVED.PAYMENT'), 'success');

      $state.go('app.vendor-payment', {
        vendor_id: $scope.vendor.id,
        id       : payment.id
      }, { reload: true });
    });
  };

  $scope.void = function () {
    vendorPaymentsApi.void($scope.payment)
    .then(() => {
      messages.show($translate.instant('JS_SPACE.MESSAGES.VOIDED.PAYMENT'), 'success');

      $state.go('app');
    }).catch(err => {
      messages.error(err);
    });
  };

  $scope.$watch('payment.vendor_payment_items_attributes', items => items.length ? vendorPaymentsApi.updateTotals($scope.payment) : null, true);
}