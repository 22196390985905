import { Pipe, PipeTransform } from '@angular/core';

import { SessionServiceAjs } from '../../sessions/session.service.ajs';

@Pipe({
  name: 'chargifyCurrency'
})
export class ChargifyCurrencyPipe implements PipeTransform {

  constructor(
    private sessionServiceAjs: SessionServiceAjs
  ) {}

  transform( value : any ) : string {
    const regexp    = /^[0-9.,]+$/;
    const subdomain = this.sessionServiceAjs.company().subscription_subdomain;

    if (typeof(value) === 'string' && !regexp.test(value)) {
      return value;
    }

    return subdomain === 'workshop-software-uk'
        || subdomain === 'ari-uk'
        || subdomain === 'workshop-software-eu'
        ? `£${value}`
        : `$${value}`;
  }
}
