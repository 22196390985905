import 'angular';

import { CodeTypeModel }  from '../../app-lite/core/models/code-type';
import { Table }          from '../table/types/table';
import { VehicleModel }   from '../vehicles/models/vehicle';
import { VehicleService } from './vehicle.service.ajs';

export class AddVehicleFromBranchCtrl {
  selectedVehicle: VehicleModel;
  vehicles       : Array<VehicleModel>;

  searchTypes : Array<CodeTypeModel> = [
    {
      code       : 'plate_number',
      description: this.$translate.instant('VEHICLE_SPACE.PLATE_NUMBER', { rego: 'Rego' })
    },
    {
      code       : 'vin',
      description: this.$translate.instant('VEHICLE_SPACE.VIN')
    }
  ];

  filters = {
    customer_id: this.customer_id,
    search_term: '',
    search_type: 'plate_number'
  };

  tableSettings: Table = {
    columns: [
      {
        classes: 'all-caps',
        field  : 'plate_number',
        header : {
          text: this.$translate.instant('VEHICLE_SPACE.PLATE_NUMBER', { rego: 'Rego' })
        },
      },
      {
        classes: 'text-left',
        field  : 'customer_name',
        header : {
          text: this.$translate.instant('JS_SPACE.COLUMNS.CUSTOMER_NAME')
        }
      },
      {
        classes: 'text-right',
        field  : 'vin',
        header : {
          classes: 'text-right',
          text   : this.$translate.instant('VEHICLE_SPACE.VIN')
        }
      },
      {
        classes: 'text-right',
        field  : 'make',
        header : {
          classes: 'text-right',
          text   : this.$translate.instant('VEHICLE_SPACE.MAKE')
        }
      },
      {
        classes: 'text-right',
        field  : 'model',
        header : {
          classes: 'text-right',
          text   : this.$translate.instant('VEHICLE_SPACE.MODEL')
        }
      },
      {
        classes: 'text-right',
        field  : 'build_date',
        header : {
          classes: 'text-right',
          text   : this.$translate.instant('VEHICLE_SPACE.BUILD_DATE')
        }
      }
    ],
    empty: {
      text: this.$translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_VEHICLES_FOUND')
    },
    pagination: {
      enabled  : true,
      pageSize : 5,
      pageSizes: [ 5, 10, 25, 50 ]
    },
    rows: {
      highlight: {
        enabled: true
      },
      select: {
        enabled: true
      }
    },
    sorting: {
      enabled: false
    },
    static: true
  };

  constructor (
    private $translate       : ng.translate.ITranslateService,
    public  $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    private customer_id      : string,
    private vehicleApi       : VehicleService
  ){
    this.getVehicles();
  }

  confirm () : ng.IPromise<void> {
    return this.vehicleApi
    .copyFromBranch(this.selectedVehicle, this.customer_id)
    .then(( response : VehicleModel ) => {
      this.$uibModalInstance.close(response);
    });
  }

  confirmVehicleSelection ( vehicle : VehicleModel ) : void {
    this.selectedVehicle = vehicle;

    this.confirm();
  }

  getVehicles ( params ?: any ) : void {
    params = params || {
      direction: 'asc',
      field    : 'plate_number',
      offset   : 0,
      pageSize : 5
    };

    this.vehicleApi
    .getBranchVehicles({
      customer_id: this.filters.customer_id,
      direction  : params.direction || 'asc',
      field      : params.field || 'plate_number',
      offset     : params.offset || 0,
      pageSize   : params.pageSize || 5,
      search_term: this.filters.search_term,
      search_type: this.filters.search_type
    })
    .then((vehicles : Array<VehicleModel>) => this.vehicles = vehicles);
  }

  handleVehicleSelection ( vehicle : VehicleModel ) : void {
    this.selectedVehicle = vehicle.selected
      ? vehicle
      : null;
  }
};