import { Component, Injector, Input } from '@angular/core';
import { isArray }                    from 'angular';

@Component({
  template: require('./bind-html.component.html')
})
export class BindHtmlComponent {
  @Input() model   : any;
  @Input() pipe    : string;
  @Input() pipeArgs: any;
  @Input() record  : any;

  get data () : string {
    let value = this.record[this.model];

    // if a pipe is defined for the column, apply it.
    //
    // NOTE: in order for a pipe to be retrievable
    // in this manner, it must be provided as a
    // string in the TableModule.
    if (this.pipe) {
      if (!isArray(this.pipeArgs)) {
        this.pipeArgs = [ this.pipeArgs ];
      }

      value = this.injector
        .get(this.pipe)
        .transform(value, ...this.pipeArgs);
    }

    return value;
  }

  constructor (
    private injector : Injector
  ) {}
}