import { Component, ComponentFactoryResolver, Input, OnInit, Type, ViewContainerRef } from "@angular/core";

import { DynamicTableComponentBindings } from "../../app-lite/table/types/dynamic-table-component-bindings";

@Component({
  selector: 'wor-dynamic-table-cell, wor-dynamic-table-row',
  template: `<ng-template></ng-template>`
})
export class DynamicTableComponent implements OnInit {
  @Input() bindings : DynamicTableComponentBindings;
  @Input() component: Type<Component>;
  @Input() record   : any;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit() {
    this.loadComponent();
  }

  loadComponent() {
    this.viewContainerRef.clear();

    const componentInstance = this.viewContainerRef.createComponent(this.componentFactoryResolver.resolveComponentFactory(this.component));

    // bind row input.
    componentInstance.instance['record'] = this.record;

    // bind inputs.
    if (this.bindings?.inputs) {
      for (const key of Object.keys(this.bindings.inputs)) {
        componentInstance.instance[key] = this.bindings.inputs[key];
      }
    }

    // subscribe to outputs.
    if (this.bindings?.outputs) {
      for (const key of Object.keys(this.bindings.outputs)) {
        componentInstance.instance[key]
          .subscribe((data: any) => this.bindings.outputs[key](data));
      }
    }
  }
}