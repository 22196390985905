import { Injectable } from '@angular/core';
import { NgbModal }   from '@ng-bootstrap/ng-bootstrap';

import { ApiService }            from '../api/api.service';
import { BillerLookupComponent } from './biller.lookup.component';
import { CustomerModel }         from '../customers/models/customer';

@Injectable({
  providedIn: 'root'
})
export class BillerService {

  constructor (
    private apiService  : ApiService,
    private modalService: NgbModal
  ) {}

  openLookup ( id : string, isVendor : boolean ) : ng.IPromise<any> {
    const url = isVendor
    ? '/vendors/biller_list'
    : '/customers/biller_list';

    const data = [
      id,
      0,
      '*',
      'P',
      'I',
      '*',
      '*',
      '*',
      isVendor ? 'company_name' : 'display_name',
      'desc'
    ];

    const modalRef = this.modalService.open(BillerLookupComponent, { windowClass: 'lg-modal' });

    this.apiService.get(url, data)
    .then(( billers : Array<CustomerModel> ) => {
      modalRef.componentInstance.billers  = billers;
    });

    modalRef.componentInstance.isVendor = isVendor;

    return modalRef.result;
  }
}