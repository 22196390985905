import { RemoveComponent }           from '../table/cell-components/actions/remove.component';
import { TableTextControlComponent } from '../table/cell-components/controls/text-control/table-text-control.component';

import AddGroupToMatrixHtml   from './price-matrix.add-group.html';
import AddPriceMatrixHtml     from './price-matrix.add.html';

export function PriceMatrixSettingsLiteCtrl (
  $uibModal,
  $q,
  $rootScope,
  $state,
  $scope,
  $translate,
  confirm,
  messages,
  workshopAPI
) {

  $scope.matrix        = $scope.matrices[0];
  $scope.matrix_items  = [];
  $scope.update_matrix = {
    percentage : 0,
    update_type: '*'
  };

  $scope.tableSettings = {
    columns: [
      {
        component: {
          bindings: {
            outputs: {
              onRemoveClick: event => $scope.removeItem(event)
            }
          },
          enabled: true,
          factory: RemoveComponent,
        },
        sortable: false,
      },
      {
        field : 'product_group_name',
        header: {
          text: $translate.instant('PRODUCT_SPACE.PRODUCT_GROUP')
        }
      },
      {
        field : 'description',
        header: {
          text: $translate.instant('GENERAL_SPACE.FIELD.DESCRIPTION')
        }
      },
      {
        classes  : 'text-right',
        component: {
          bindings: {
            inputs: {
              model: 'percentage',
              name : 'percentage',
              type : 'number'
            }
          },
          enabled: true,
          factory: TableTextControlComponent
        },
        header: {
          classes: 'text-right',
          text   : $translate.instant('PRICE_MATRIX_SPACE.PERCENTAGE')
        }
      },
    ],
    empty: {
      text: $translate.instant('VEHICLE_SPACE.NO_TYPES_FOR_DESCRIPTION', {
        name: $scope.matrix ? $scope.matrix.matrix_name : ''
      })
    }
  };

  $scope.addAllProductGroups = function () {
    confirm.generic($translate.instant('PRICE_MATRIX_SPACE.ADD_ALL_PRODUCTS_WARNING')).then(() => {
      return workshopAPI.get('price_matrices/add_all_product_groups_to_price_matrix', $scope.matrix.id);
    }).then(() => {
      $scope.initMatrix();
    }).catch(err => {
      messages.error(err);
    });
  };

  $scope.addMatrix = function () {
    $uibModal.open({
      controller : 'AddPriceMatrixLiteCtrl',
      templateUrl: AddPriceMatrixHtml
    })
    .result
    .then(result => {
      $scope.matrices     = result.matrices;
      $scope.matrix       = result.matrix;
      $scope.matrix_items = [];

      $state.reload();
    });
  };

  $scope.addProductGroup = function () {
    return $uibModal.open({
      controller : 'AddGroupToMatrixLiteCtrl',
      templateUrl: AddGroupToMatrixHtml,
      windowClass: 'md-modal',
      resolve    : {
        groups: () => workshopAPI.get('/price_matrices/groups_not_in_matrix', $scope.matrix.id)
      }
    })
    .result
    .then(groups => {
      return workshopAPI.post('/price_matrix_items', {
        matrix: $scope.matrix,
        groups: groups
      }, 'price_matrix_item');
    })
    .then(() => {
      $state.reload();
    })
    .catch(err => {
      messages.error(err);
    });
  };

  $scope.delete = function () {
    confirm.generic($translate.instant('PRICE_MATRIX_SPACE.DELETE_MATRIX', { code: $scope.matrix.matrix_name }))
    .then(() => workshopAPI.delete('/price_matrix', $scope.matrix.id))
    .then(() => {
      messages.show($translate.instant('PRICE_MATRIX_SPACE.MATRIX_DELETED', { code: $scope.matrix.matrix_name }), 'success');

      $state.reload();
    })
    .catch(err => {
      messages.error(err);
    });
  };

  $scope.getMatrixItems = function () {
    return $q.when()
    .then(() => $scope.matrix && $scope.matrix.id
      ? workshopAPI.get('/price_matrix_items', $scope.matrix.id)
      : $q.when([])
    )
    .then(items => {
      return items.map(item => {
        item.destroy = '0';

        return item;
      });
    });
  };

  $scope.initMatrix = function () {
    $scope.getMatrixItems()
    .then(items => {
      $scope.matrix_items = items;
    })
    .catch(err => {
      messages.error(err);
    });
  };

  $scope.pushToOtherBranches = function () {
    $scope.save()
    .then(matrix => {
      return $scope.session.platinum_or_higher && $rootScope.Company.isBranchOwner() ?
        confirm.generic('If Matrix does not exist in the other branches, it will be added. If it does exist, the matrix will be updated. Would you like to continue?') :
        $q.when();
    })
    .then(() => {
      return workshopAPI.get('/price_matrix/push_to_branches', $scope.matrix.id);
    })
    .then(existed => {
      messages.show($translate.instant(existed ? 'PRICE_MATRIX_SPACE.MATRIX_UPDATED_ON_BRANCHES' : 'PRICE_MATRIX_SPACE.MATRIX_ADDED_TO_BRANCHES'), 'success');
    });
  };

  $scope.removeItem = function (item) {
    confirm.generic($translate.instant('PRICE_MATRIX_SPACE.DELETE_ITEM', { code: item.product_group_name }))
    .then(() => workshopAPI.delete('/price_matrix_item', item.id))
    .then(() => {
      messages.show($translate.instant('PRICE_MATRIX_SPACE.ITEM_DELETED', {code: item.product_group_name}), 'success');

      $scope.initMatrix();
    })
    .catch(err => {
      messages.error(err);
    });
  };

  $scope.save = function () {
    return workshopAPI.patch('/price_matrix', {
      matrix_id   : $scope.matrix.id,
      matrix_items: $scope.matrix_items,
      price_matrix: $scope.matrix
    } , 'price_matrix_item')
    .then(items => $scope.getMatrixItems());
  };

  $scope.submit = function () {
    $scope.save()
    .then(() => {
      messages.show($translate.instant('PRICE_MATRIX_SPACE.SAVED'), 'success');
    })
    .catch(err => {
      messages.error(err);
    });
  };

  $scope.updateMatrixLines = function() {
    confirm.generic(
      $translate.instant($scope.update_matrix.update_type === 'zero_only' ? 'PRICE_MATRIX_SPACE.UPDATE_PRICE_MATRIX_WARNING_ZERO' : 'PRICE_MATRIX_SPACE.UPDATE_PRICE_MATRIX_WARNING_ALL')
    )
    .then(() => {
      return workshopAPI.post('/price_matrix_item/update_percentages', {
        matrix_id  : $scope.matrix.id,
        percentage : $scope.update_matrix.percentage,
        update_type: $scope.update_matrix.update_type
      }, 'update_percentages');
    })
    .then(() => {
      messages.show($translate.instant('PRICE_MATRIX_SPACE.PERCENTAGES_UPDATED'), 'success');

      $scope.initMatrix();
    })
    .catch(err => {
      messages.error(err);
    });
  };

  workshopAPI.get('/system/price_matrix_update_types/', null, 'price_matrix_update_types')
  .then(types => {
    $scope.price_matrix_update_types = types;
  });

  workshopAPI.get('/system/price_matrix_types/', null, 'price_matrix_types')
  .then(types => {
    $scope.price_matrix_types = types;
  });

  $scope.initMatrix();

}