import { Component, OnInit }    from '@angular/core';
import { Transition, UIRouter } from '@uirouter/core';

import { ApiErrorModel }        from '../core/api/models/api.error';
import { ErrorHandlingService } from '../core/errors/error-handling.service';
import { NotificationService }  from '../notifications/notification.service';
import { UserAccountModel }     from '../users/models/user.account';
import { PasswordService }      from './password.service';

@Component({
  template: require('./password.reset.component.html')
})
export class ResetPasswordComponent implements OnInit {
  account: UserAccountModel = {};

  constructor (
    private errorHandlingService: ErrorHandlingService,
    private notificationService : NotificationService,
    private passwordService     : PasswordService,
    private router              : UIRouter,
    private transition          : Transition
  ) {}

  ngOnInit () {
    this.account.token = this.transition.params().reset_token;
  }

  reset () {
    this.passwordService.reset(this.account)
    .then(() => {
      this.notificationService.show('Password Reset. Please Log in to continue');

      this.router.stateService.go('ajs.app.public.login', {
        email: this.account.username
      });
    })
    .catch(( err : ApiErrorModel ) => {
      this.errorHandlingService.show(err);
    });
  }
};
