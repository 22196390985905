import * as moment from 'moment';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateTime'
})
export class DateTimePipe implements PipeTransform {
  // As of now, this filter assumes input in the format
  // of 'YYYY-MM-DDThh:mm:ss.XXXZ'.
  transform( value : string, timeFormat = 'hh:mm:ss A' ) : string {
    let date, time;

    if (!value) {
      return null;
    }

    if (!value.includes('T')){
      date = value.split(' ')[0];
      time = value.split(' ')[1];
    }
    else {
      date = value.split('T')[0];
      time = value.split('T')[1];
    };

    date = moment(date).toString().split(' ');
    date = [date[1], date[2], date[3]];
    date = date.join(' ');

    if (time !== '00:00:00.000Z') {
      time = time.split('.')[0];
      time = moment(time, 'hh:mm:ss').format(timeFormat);

      date += ` ${time}`;
    }

    return date;
  }
}
