export function BranchesDashboardLiteCtrl (
  $rootScope,
  $scope,
  $translate,
  businessIntelligenceApi
) {

  $scope.tableSettings = {
    columns: [
      {
        field: 'store_name',
        header: {
          text: 'Company'
        }
      }
    ],
    empty: {
      text: 'No Linked Companies'
    },
    pagination: {
      enabled: false
    },
    sorting: {
      enabled: true
    },
  };

  $scope.tabs = [
    {
      active : true,
      heading: $translate.instant('DASHBOARD_SPACE.BUSINESS_INTELLIGENCE.HOME'),
      id     : 'home'
    }
  ];

  if ($rootScope.Company.hasIntegration('business-intelligence')) {
    $scope.tabs.push(
      {
        active     : false,
        dashboardId: $scope.businessIntelligenceDashboards.overview.dashboard_id,
        heading    : $translate.instant('DASHBOARD_SPACE.BUSINESS_INTELLIGENCE.OVERVIEW'),
        id         : 'bi-overview'
      },
      {
        active     : false,
        dashboardId: $scope.businessIntelligenceDashboards.customer.dashboard_id,
        heading    : $translate.instant('DASHBOARD_SPACE.BUSINESS_INTELLIGENCE.CUSTOMERS'),
        id         : 'bi-customers'
      },
      {
        active     : false,
        dashboardId: $scope.businessIntelligenceDashboards.vehicle.dashboard_id,
        heading    : $translate.instant('DASHBOARD_SPACE.BUSINESS_INTELLIGENCE.VEHICLES'),
        id         : 'bi-vehicles'
      },
      {
        active     : false,
        dashboardId: $scope.businessIntelligenceDashboards.product.dashboard_id,
        heading    : $translate.instant('DASHBOARD_SPACE.BUSINESS_INTELLIGENCE.PRODUCTS'),
        id         : 'bi-products'
      },
      {
        active     : false,
        dashboardId: $scope.businessIntelligenceDashboards.multibranch_snapshot.dashboard_id,
        heading    : $translate.instant('DASHBOARD_SPACE.BUSINESS_INTELLIGENCE.SNAPSHOT'),
        id         : 'bi-snapshot'
      }
    );
  }

  $scope.tab = $scope.tabs[0];

  $scope.handleTabClick = function (tab = {}) {
    angular.forEach($scope.tabs, tab => {
      tab.active = false;
    });

    $scope.tab = tab;

    tab.active = true;
  };

  $scope.isBusinessIntelligenceTab = function ( tab = $scope.tab ) {
    return businessIntelligenceApi.isTabBusinessIntelligence(tab);
  };
}
