import { AttachmentModel } from './models/attachment';

interface VideoViewerScope extends ng.IScope {
  video          : AttachmentModel;
  videoSource    : string;
};

export function VideoViewerCtrl (
  $sce  : ng.ISCEService,
  $scope: VideoViewerScope,
  video : AttachmentModel
) {
  $scope.video       = video;
  $scope.videoSource = $sce.trustAsResourceUrl($scope.video.attachment_url);
};
