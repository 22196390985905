import { Injectable } from '@angular/core';

import { ApiService }        from '../api/api.service';
import { SessionServiceAjs } from '../sessions/session.service.ajs';

@Injectable({
  providedIn: 'root'
})
export class EmailVerificationService {
  constructor (
    private apiService     : ApiService,
    private sessionService : SessionServiceAjs
  ) {}

  checkVerificationStatus () : ng.IPromise<boolean> {
    return this.apiService.get('/amazon_web_services/check_email_verification_status');
  }

  didVerificationFail ( company : any = this.getCompany() ) : boolean {
    return company.email_verified === 'N';
  }

  getCompany () : any {
    return this.sessionService.company();
  }

  isEmailPending ( company : any = this.getCompany() ) : boolean {
    return company.email_verified === 'P';
  }

  isEmailUnverified ( company : any = this.getCompany() ) : boolean {
    return !company.email_verified;
  }

  isEmailVerified ( company : any = this.getCompany() ) : boolean {
    return company.email_verified === 'V';
  }

  verify () : ng.IPromise<boolean> {
    return this.apiService.get('/company/verify_email_address');
  }
}