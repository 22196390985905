import TemplatedWordingListHtml from './templates..templated-wording-list.html';

export function TemplatedWordingService ($uibModal, workshopAPI) {
  return function (wording_type) {
    return $uibModal.open({
      templateUrl: TemplatedWordingListHtml,
      windowClass: 'lg-modal',
      resolve: {
        templates () {
          return workshopAPI.get('/templated_wordings', [0, '*', 'description', 'asc', wording_type, '*']);
        },
        wording_type: function() {
          return wording_type;
        }
      },
      controller: 'TemplatedWordingListLiteCtrl'
    }).result;
  };
}