export function CoreEncodeParameterService () {
  return function(param, encodeFor = 'rails') {
    const encodings = {
      base: [
        // periods
        {
          character: /\./g,
          encoding: '%2E'
        },
        // hash signs
        {
          character: /#/g,
          encoding: '%23'
        },
        // forward slashes
        {
          character: /\//g,
          encoding: '%2F'
        },
        // question marks
        {
          character: /\?/g,
          encoding: '%3F'
        },
        // semi colons
        {
          character: /;/g,
          encoding: '%3B'
        },
      ],
      get: [
        // percentage sign
        {
          character: /%/g,
          encoding: '%25'
        }
      ],
      hsy: [
        // equals
        {
          character: /=/g,
          encoding: '%3D'
        },
        // ampersand
        {
          character: /&/g,
          encoding: '%26'
        },
        // colon
        {
          character: /:/g,
          encoding: '%3A'
        }
      ],
      rails: []
    };

    const replaces = encodings.base.concat(encodings[encodeFor]);

    for (let i = 0; i < replaces.length; i++) {
      param = param.replace(replaces[i].character, replaces[i].encoding);
    }
    return param;
  };
}