import { EmailOptionsModel } from './models/messages.send-email.options';
import { MessengerService }  from './messenger.service.ajs';

export class EmailCtrl {
  $uibModalInstance: ng.ui.bootstrap.IModalInstanceService;
  blockUI          : ng.blockUI.BlockUIService;
  messages         : any;
  messengerApi     : MessengerService;
  templatedWording : any;

  block  : ng.blockUI.BlockUIService;
  email  : any;
  options: EmailOptionsModel;

  constructor (
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    blockUI          : ng.blockUI.BlockUIService,
    email            : any,
    messages         : any,
    messengerApi     : MessengerService,
    options          : EmailOptionsModel,
    templatedWording : any
  ) {
    this.$uibModalInstance = $uibModalInstance;
    this.blockUI           = blockUI;
    this.email             = email;
    this.messages          = messages;
    this.messengerApi      = messengerApi;
    this.options           = options;
    this.templatedWording  = templatedWording;

    this.block   = blockUI.instances.get('emailBlock');
  }

  addTemplates () {
    this.templatedWording(this.options.templateKey)
    .then(( templates : Array<any> ) => {
      angular.forEach(templates, template => {
        /**
         * some emailing requires the body field
         * and other require message. We handle
         * this inconsistency here.
         */

        this.email.body = this.email.body
          ? `${ this.email.body }\n\n${ template.wording }`
          : template.wording;

        this.email.message = this.email.message
          ? `${ this.email.message }\n\n${ template.wording }`
          : template.wording;
      });
    });
  }

  send () {
    if (this.options.modalOnly) {
      this.$uibModalInstance.close(this.email);
    }

    else {
      this.block.start();

      this.messengerApi.sendEmail(this.email, this.options)
      .then(() => {
        this.$uibModalInstance.close(this.email);
      })
      .catch(err => {
        this.messages.error(err);
      })
      .finally(() => {
        this.block.stop();
      });
    }
  }
}
