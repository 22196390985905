export function ResolveVendorMatchesLiteCtrl ($scope, $translate, Vendor, vendor, matches, messages) {
  $scope.matches  = matches;
  $scope.vendor   = new Vendor(vendor);

  $scope.tableSettings = {
    columns: [
      {
        classes: 'text-left',
        field  : 'display_name',
        header : {
          text: $translate.instant('GENERAL_SPACE.FIELD.NAME')
        },
      },
      {
        classes: 'text-left',
        field  : 'phone',
        header : {
          text: $translate.instant('GENERAL_SPACE.FIELD.PHONE')
        },
        pipe   : 'phone'
      },
      {
        classes: 'text-left',
        field  : 'mobile',
        header : {
          text: $translate.instant('GENERAL_SPACE.FIELD.MOBILE')
        },
        pipe   : 'mobilePhone'
      },
      {
        classes: 'text-left',
        field  : 'email',
        header : {
          text: $translate.instant('GENERAL_SPACE.FIELD.EMAIL')
        }
      },
      {
        classes: 'text-left',
        field  : 'address1',
        header : {
          text: $translate.instant('GENERAL_SPACE.FIELD.ADDRESS')
        }
      },
      {
        classes: 'text-left',
        field  : 'address2',
        header : {
          text: $translate.instant('GENERAL_SPACE.FIELD.ADDRESS')
        }
      },
      {
        classes: 'text-left',
        field  : 'suburb',
        header : {
          text: $translate.instant('GENERAL_SPACE.FIELD.SUBURB')
        }
      },
      {
        classes: 'text-left',
        field  : 'state',
        header : {
          text: $translate.instant('GENERAL_SPACE.FIELD.STATE')
        }
      },
      {
        classes: 'text-left',
        field  : 'postcode',
        header : {
          text: $translate.instant('GENERAL_SPACE.FIELD.POSTCODE')
        }
      }
    ],
    empty: {
      text: $translate.instant('GENERAL_SPACE.STATUS.NO_RESULTS')
    },
    pagination: {
      enabled: false
    },
    rows: {
      highlight: {
        enabled: true
      },
      select: {
        enabled: true
      }
    },
    sorting: {
      enabled: false
    },
  };

  $scope.onRowClick = function () {
    $scope.selectedItems = $scope.matches.filter((row) => row.selected);
  };

  $scope.onRowClick = function (event) {
    $scope.selectedItems = [event.row];
    $scope.confirm();
  };

  $scope.confirm = function () {
    $scope.vendor.saveMatch($scope.selectedItems[0]).then(() => {
      $scope.$close();
    }).catch(err => {
      messages.error(err);
    });
  };
}