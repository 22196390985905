import { Component, Input }         from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { TextControlComponent } from '../text-control/text-control.component';

@Component({
  selector   : 'wor-phone-control',
  template: require('./phone-control.component.html'),

  viewProviders: [
    {
      provide    : ControlContainer,
      useExisting: NgForm
    }
  ]
})
export class PhoneControlComponent extends TextControlComponent {
  @Input() maxlength = 30;
}
