export function CoreTranslateConfig ($translateProvider) {
  var sessionObject = angular.fromJson(sessionStorage.session_object);

  $translateProvider.useStaticFilesLoader(
    {
      prefix: 'lang/locale-',
      suffix: '.json'

    }
  ).preferredLanguage(sessionObject ? ( sessionObject.company_settings  ? ( sessionObject.company_settings.language || 'en-uk') : 'en-uk' ) : 'en-uk')
  .fallbackLanguage('en-uk')
  .useSanitizeValueStrategy('sanitizeParameters');
}