import { ApiServiceAjs }                       from '../../api/api.service.ajs';
import { CodeTypeModel }                       from '../../core/models/code-type';
import { Table }                               from '../../table/types/table';
import { TableService }                        from '../../table/table.service';
import { TableQueryParameters }                from '../../table/types/table-query-parameters';
import { TransactionCenterFiltersModel }       from '../models/transaction-center.filters';
import { TransactionCenterInspectionModel }    from '../models/transaction-center.inspection';
import { TransactionCenterInspectionsService } from './transaction-center.inspections.service';

export class TransactionCenterInspectionsCtrl {
  inspections : Array<TransactionCenterInspectionModel>;
  statuses    : Array<CodeTypeModel>;

  tableCacheKey = this.$state.current.data.tableCacheKey;

  filters : TransactionCenterFiltersModel = this.tableApi.setFiltersFromCache(this.tableCacheKey, {
    status: 'D',
    value : ''
  });

  tableSettings : Table = {
    broadcast: 'filterInspections',
    cache    : {
      enabled: true,
      key    : this.tableCacheKey
    },
    columns: this.transactionCenterInspectionsApi.buildColumns(this.filters),
    empty  : {
      text: this.$translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_INSPECTIONS_FOUND')
    },
    pagination: {
      enabled   : true,
      pageSize  : this.tableApi.getCachedPaging(this.tableCacheKey).pageSize || 10,
      pageSizes : [ 10, 25, 50 ],
      queryCount: () => this.getCount()
    },
    rows: {
      highlight: {
        enabled: true
      }
    },
    sorting: this.tableApi.setSortingFromCache(this.tableCacheKey, {
      direction: 'desc',
      field    : 'inspection_date'
    })
  };

  constructor (
    private $rootScope                     : ng.IRootScopeService,
    private $scope                         : ng.IScope,
    private $state                         : ng.ui.IStateService,
    private $translate                     : ng.translate.ITranslateService,
    private tableApi                       : TableService,
    private transactionCenterInspectionsApi: TransactionCenterInspectionsService,
    private workshopAPI                    : ApiServiceAjs,
  ) {
    this.$scope.$watch(() => {
      return [
        this.filters.value,
        this.filters.status
      ];
    }, ( newVal, oldVal ) => {
      if ( newVal !== oldVal ) {
        this.$rootScope.$broadcast('filterInspections', this.filters);
      }
      if (newVal[1] !== oldVal[1]) {
        this.tableSettings = this.transactionCenterInspectionsApi.getUpdatedColumns(this.tableSettings, this.filters);
      }
    }, true);

    this.$scope.$on('searchFilterChanged', ( event : ng.IAngularEvent, value : string ) => {
      if (!this.$state.transition) {
        this.filters.value = value;
      }
    });
  }

  getInspections ( params : TableQueryParameters ) : ng.IPromise<Array<TransactionCenterInspectionModel>> {
    return this.transactionCenterInspectionsApi.getInspections(this.filters, params)
    .then( ( inspections : Array<TransactionCenterInspectionModel> ) => {
      this.inspections = inspections;

      return inspections;
    });
  }

  getCount () : ng.IPromise<number> {
    return this.workshopAPI.get('/transaction_center/inspections_count', [
      this.filters.status || 'D',
      this.filters.value  || '*',
    ])
    .then(( count : any ) => count.inspections_count);
  }

  viewInspection ( inspection : TransactionCenterInspectionModel ) : void {
    this.$state.go('app.inspection', {
      customer_id: inspection.customer_id,
      vehicle_id : inspection.vehicle_id,
      id         : inspection.id
    });
  }
}