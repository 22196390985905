import { SyncModalOptionsModel } from './models/modal.sync.options';

interface SyncModalScope extends ng.IScope {
  options: SyncModalOptionsModel;
  sync   : () => void;
}

export function SyncModalCtrl (
  $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
  $scope           : SyncModalScope,
  messages         : any,
  options          : SyncModalOptionsModel
) {
  $scope.options = options;

  $scope.sync = function () {
    $scope.options.sync()
    .then(() => {
      $uibModalInstance.close();
    })
    .catch(( err : any ) => {
      messages.error(err);
    });
  };
}