export function CoreCurrencyAPIService (currencyInfo, sessionObjAPI, round) {
  return {
    info: function () {
      var sessionObj = sessionObjAPI.base();
      var currency = {
        symbol: sessionObj.country_settings.currency,
        sales_tax_rate: sessionObj.company_settings.sales_tax_rate,
        purchases_tax_rate: sessionObj.company_settings.purchases_tax_rate,
        tax_name: sessionObj.company_settings.tax_name || 'GST',
        price_includes_tax: sessionObj.company_settings.price_includes_tax,
        round_total: sessionObj.company_settings.round_total
      }
      return currency;
    },
    items: {
      getTotal: function (item, tax_free, editable) {
        var info = currencyInfo();
        var unit_price = new BigDecimal( item.unit_price ? item.unit_price.toString(): '0' ).setScale(2, BigDecimal.ROUND_HALF_UP);
        var quantity = new BigDecimal( item.quantity && item.quantity !== '-' ? item.quantity.toString(): '0' ).setScale(2, BigDecimal.ROUND_HALF_UP);
        var tax_rate = new BigDecimal( info.sales_tax_rate ? info.sales_tax_rate.toString(): '0' ).setScale(2, BigDecimal.ROUND_HALF_UP);
        var total = unit_price.multiply(quantity);

        if (!editable) {
          return item.amount || BigDecimal.ZERO.toString();
        }

        if (item.gst_free || info.price_includes_tax || tax_free) {
          return total.toString();
        }
        else {
          return total
            .multiply(tax_rate.movePointLeft(2))
              .setScale(2, BigDecimal.prototype.ROUND_HALF_UP)
                .add(total)
                  .setScale(2, BigDecimal.prototype.ROUND_HALF_UP).toString();
        }
      },
      getGST: function (item, tax_free, editable) {
        var info = currencyInfo();
        var unit_price = new BigDecimal( item.unit_price ? item.unit_price.toString(): '0' ).setScale(2, BigDecimal.ROUND_HALF_UP);
        var quantity = new BigDecimal( item.quantity && item.quantity !== '-' ? item.quantity.toString(): '0' ).setScale(2, BigDecimal.ROUND_HALF_UP);
        var tax_rate = new BigDecimal( info.sales_tax_rate ? info.sales_tax_rate.toString(): '0' ).setScale(2, BigDecimal.ROUND_HALF_UP);
        var total = unit_price.multiply(quantity);
        var gst = BigDecimal.ZERO;

        if (!editable) {
          return item.gst || BigDecimal.ZERO.toString();
        }

        else if (item.gst_free || tax_free) {
          return BigDecimal.ZERO.toString();
        }

        else if (info.price_includes_tax) {
          gst = total
            .subtract( total.divide( BigDecimal.ONE.add(tax_rate.movePointLeft(2)) ) )
            .setScale(2, BigDecimal.prototype.ROUND_HALF_UP)
            .toString();
        }
        else {
          gst = total
            .multiply(tax_rate.movePointLeft(2))
            .setScale(2, BigDecimal.prototype.ROUND_HALF_UP)
            .toString();
        }

        return gst;
      }
    },
    totals: {
      balance: function(data, items, closed) {
        var total = new BigDecimal(this.total(data, items, closed).toString()).setScale(2, BigDecimal.prototype.ROUND_HALF_UP);

        if (closed) {
          return data.balance_due || '0';
        }

        return total.toString();
      },
      gst: function(data, items, closed) {
        var info = currencyInfo();
        var company = sessionObjAPI.company();
        var gst = BigDecimal.ZERO;
        var subtotal = BigDecimal.ZERO;
        var freight = company.tax_freight ? new BigDecimal(data.freight ? data.freight.toString(): '0'): BigDecimal.ZERO;
        var taxRate = new BigDecimal(company.sales_tax_rate).movePointLeft(2);

        if (closed) {
          return data.gst;
        }

        else if (data.tax_free) {
          return gst.toString();
        }

        angular.forEach(items, function (item) {
          var unit_price = BigDecimal.ZERO;
          var quantity = BigDecimal.ZERO;

          if (!item.gst_free) {
            unit_price = new BigDecimal( item.unit_price ? item.unit_price.toString(): '0' ).setScale(2, BigDecimal.ROUND_HALF_UP);
            quantity = new BigDecimal( item.quantity && item.quantity !== '-' ? item.quantity.toString(): '0' ).setScale(2, BigDecimal.ROUND_HALF_UP);
            subtotal = subtotal.add(unit_price.multiply(quantity));
          }

        });

        if (info.price_includes_tax) {
          gst = subtotal
            .subtract( subtotal.divide( BigDecimal.ONE.add(taxRate) ) )
            .setScale(2, BigDecimal.prototype.ROUND_HALF_UP);
        }
        else {
          gst = subtotal
            .multiply(taxRate)
            .setScale(2, BigDecimal.prototype.ROUND_HALF_UP);
        }

        return gst.add(freight.multiply(taxRate).setScale(2, BigDecimal.prototype.ROUND_HALF_UP)).toString();
      },
      subtotal: function(data, items, closed) {
        var info = currencyInfo();
        var subtotal = BigDecimal.ZERO;
        var total = BigDecimal.ZERO
        var gst = BigDecimal.ZERO;

        if (closed) {
          return parseFloat(data.subtotal) || '0';
        }

        if (info.price_includes_tax && !data.tax_free) {
          angular.forEach(items, function (row){
            var gstCalc = data.tax_free ? BigDecimal.ZERO: new BigDecimal(row.getGST().toString());
            var itemTotal = new BigDecimal(row.getTotal().toString());

            gst = gst.add(gstCalc);
            total = total.add(itemTotal);
          });

          subtotal = total.subtract(gst);
        }
        else {
          angular.forEach(items, function (row){
            var unit_price = new BigDecimal( row.unit_price ? row.unit_price.toString(): '0' );
            var quantity = new BigDecimal( row.quantity && row.quantity !== '-' ? row.quantity.toString(): '0' );
            subtotal = subtotal.add( unit_price.multiply(quantity) );
          });
        }

        return subtotal ? subtotal.toString(): '0';
      },
      total: function(data, items, closed) {
        var info = currencyInfo();
        var total = new BigDecimal(this.subtotal(data, items, closed).toString()).setScale(2, BigDecimal.ROUND_HALF_UP);
        var gst = data.tax_free ? BigDecimal.ZERO: new BigDecimal(this.gst(data, items, closed).toString()).setScale(2, BigDecimal.ROUND_HALF_UP);
        var freight = new BigDecimal( data.freight ? data.freight.toString(): '0' ).setScale(2, BigDecimal.ROUND_HALF_UP);

        if (closed) {
          return parseFloat(data.total) || '0';
        }

        else if (info.round_total) {
          return round(total.add(gst).add(freight).toString())
        }

        return total.add(gst).add(freight).toString();
      },
      rounding: function (data, items, close) {
        var info = currencyInfo();
        var freight;
        var gst;
        var roundedTotal;
        var subtotal;

        if (!info.round_total) {
          return '0.00';
        }
        else if (closed) {
          return data.rounding;
        }
        else {
          subtotal = new BigDecimal(this.subtotal(data, items, closed).toString());
          gst = data.tax_free ? BigDecimal.ZERO: new BigDecimal(this.gst(data, items, closed).toString()).setScale(2, BigDecimal.ROUND_HALF_UP);
          freight = new BigDecimal( data.freight ? data.freight.toString(): '0' ).setScale(2, BigDecimal.ROUND_HALF_UP);
          roundedTotal = new BigDecimal( round(subtotal.add(gst).add(freight).toString() ));

          return (subtotal.add(gst).add(freight)).subtract(roundedTotal).negate().toString();
        }
      }
    }
  }
}