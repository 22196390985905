import { ApiModel }                         from '../api/models/api.model';
import { HotkeyMapModel }                   from '../core/directives/hotkey/models/hotkey.map';
import { InvoiceItemModel }                 from '../invoices/models/invoice.item';
import { InvoiceModel }                     from '../invoices/models/invoice';
import { VehicleHistoryChunkSettingsModel } from './models/vehicle.history.chunks';
import { VehicleHistoryService }            from './vehicle.history.service.ajs';
import { VehicleModel }                     from './models/vehicle';

interface VehicleHistoryScope extends ng.IScope {
  block                     : ng.blockUI.BlockUIService;
  chunkSettings             : VehicleHistoryChunkSettingsModel;
  clearSearch               : () => void;
  copyInvoice               : ( invoice : InvoiceModel, event : Event ) => void;
  filters                   : any;
  flipped                   : boolean;
  getInvoiceItems           : ( invoice ?: InvoiceModel ) => ng.IPromise<Array<InvoiceItemModel>>;
  getPage                   : () => ng.IPromise<Array<InvoiceModel>>;
  getNextInvoice            : () => any;
  getPreviousInvoice        : () => any;
  handleHotkey              : ( key : string ) => any;
  grid                      : any;
  hotkeyMap                 : HotkeyMapModel;
  invoice                   : InvoiceModel;
  invoiceItemsGrid          : any;
  invoiceItems              : Array<InvoiceItemModel>;
  invoices                  : Array<InvoiceModel>;
  isInvoiceItemRowExpandable: ( row : any ) => boolean;
  paging                    : any;
  printInvoice              : ( invoice : InvoiceModel, event : Event ) => void;
  resetInvoices             : () => ng.IPromise<Array<InvoiceModel>>;
  search                    : ( resetCurrentPage : boolean ) => void;
  select                    : ( invoice : InvoiceModel ) => void;
  sorting                   : any;
  vehicle                   : VehicleModel;
  viewInvoices              : () => void;
  viewInvoice               : ( invoice : InvoiceModel, event ?: Event ) => void;
  viewInvoiceItems          : ( invoice : InvoiceModel ) => void;
}

export function VehicleHistoryCtrl (
  $q               : ng.IQService,
  $scope           : VehicleHistoryScope,
  $state           : ng.ui.IStateService,
  $timeout         : ng.ITimeoutService,
  $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
  $window          : ng.IWindowService,
  API              : ApiModel,
  blockUI          : ng.blockUI.BlockUIService,
  gridAPI          : any,
  invoices         : Array<InvoiceModel>,
  vehicle          : VehicleModel,
  vehicleHistoryApi: VehicleHistoryService
) {
  $scope.block        = blockUI.instances.get('vehicleHistoryBlock');
  $scope.flipped      = false;
  $scope.invoice      = null;
  $scope.invoices     = invoices;
  $scope.invoiceItems = [];
  $scope.vehicle      = vehicle;

  $scope.grid = vehicleHistoryApi.getGridSettings($scope.vehicle);

  $scope.invoiceItemsGrid = vehicleHistoryApi.getItemGridSettings();

  $scope.clearSearch = function () {
    $scope.grid.filters.value = null;

    $scope.resetInvoices();
  };

  $scope.copyInvoice = function ( invoice : InvoiceModel, event : Event ) {
    vehicleHistoryApi.copyInvoice(invoice)
    .then(( invoice : InvoiceModel ) => $scope.viewInvoice(invoice));

    event.stopPropagation();
  };

  $scope.getInvoiceItems = function ( invoice = $scope.invoice ) {
    return vehicleHistoryApi.getItemPage(invoice, $scope.invoiceItemsGrid)
    .then(( items : Array<InvoiceItemModel> ) => {
      $scope.invoiceItems = items;

      gridAPI.init($scope.invoiceItems, $scope.invoiceItemsGrid);

      return items;
    });
  };

  $scope.getNextInvoice = function () {
    if ($scope.chunkSettings.isLastInvoice) {
      return;
    }

    $scope.block.start();

    if ($scope.chunkSettings.currentIndex === $scope.invoices.length - 1) {
      return $q.when()
      .then(() => vehicleHistoryApi.getNextChunk($scope.vehicle, $scope.chunkSettings))
      .then(( nextChunk : Array<InvoiceModel> ) => {
        $scope.invoices = $scope.invoices.concat(nextChunk);
        $scope.invoice  = nextChunk[0];

        return $scope.getInvoiceItems();
      })
      .finally(() => $scope.block.stop());
    }

    return vehicleHistoryApi.updateNextChunkSettings($scope.chunkSettings)
    .then(() => {
      $scope.invoice = $scope.invoices[ $scope.chunkSettings.currentIndex ];

      return $scope.getInvoiceItems()
      .finally(() => $scope.block.stop());
    });
  };

  $scope.getPage = function () {
    return vehicleHistoryApi.getPage($scope.vehicle, $scope.grid)
    .then(( invoices : Array<InvoiceModel> ) => {
      $scope.invoices = invoices;

      return invoices;
    });
  };

  $scope.getPreviousInvoice = function () {
    if ($scope.chunkSettings.isFirstInvoice) {
      return;
    }

    $scope.block.start();

    if (!$scope.chunkSettings.currentIndex && !$scope.chunkSettings.isFirstInvoice) {
      return $q.when()
      .then(() => vehicleHistoryApi.getPreviousChunk($scope.vehicle, $scope.chunkSettings))
      .then(( previousChunk : Array<InvoiceModel> ) => {
        $scope.invoices.unshift(...previousChunk);

        $scope.invoice = previousChunk[ previousChunk.length - 1 ];

        // must be updated manually due to variability
        $scope.chunkSettings.currentIndex = $scope.invoices.map(invoice => invoice.id).indexOf($scope.invoice.id);

        return $scope.getInvoiceItems();
      })
      .finally(() => $scope.block.stop());
    }

    // must be updated manually due to variability
    $scope.chunkSettings.currentIndex--;

    return vehicleHistoryApi.updatePreviousChunkSettings($scope.chunkSettings)
    .then(() => {
      $scope.invoice = $scope.invoices[ $scope.chunkSettings.currentIndex ];

      $scope.getInvoiceItems()
      .finally(() => $scope.block.stop());
    });
  };

  $scope.handleHotkey = function ( key : string ) {
    if ($scope.block.isBlocking() || !$scope.flipped) {
      return;
    }

    switch (key) {
      case 'left':
        $scope.getPreviousInvoice();
        break;
      case 'right':
        $scope.getNextInvoice();
        break;
    }
  };

  $scope.isInvoiceItemRowExpandable = function ( row : any ) {
    return row.entity.note;
  };

  $scope.printInvoice = function ( invoice : InvoiceModel, event : Event ) {
    $window.open(`${ API.url }/reports/jasper_invoice/${ invoice.id }`, '_blank');

    event.stopPropagation();
  };

  $scope.resetInvoices = function () {
    return vehicleHistoryApi.getFirstPage($scope.vehicle, $scope.grid)
    .then(( invoices : Array<InvoiceModel> ) => {
      $scope.invoices = [];

      return $timeout(() => {
        $scope.invoices = invoices;

        $scope.grid = vehicleHistoryApi.getGridSettings($scope.vehicle);

        gridAPI.init($scope.invoices, $scope.grid);

        return invoices;
      });

    });
  };

  $scope.search = function ( resetCurrentPage = true ) {
    if ( resetCurrentPage ) {
      $scope.grid = gridAPI.resetCurrentPage($scope.grid);
    }

    vehicleHistoryApi.search($scope.vehicle, $scope.grid)
    .then(( invoices : Array<InvoiceModel> ) => {
      $scope.invoices = invoices;
    });
  };

  $scope.select = ( row : any ) => {
    if (row.selected) {
      $scope.viewInvoiceItems(row.entity);

      row.selected = false;
    }

    row.selected = false;
  };

  $scope.viewInvoice = function ( invoice : InvoiceModel, event ?: Event ) {
    /**
     * this is also used when copying an invoice. a copied
     * invoice doesn't have and id so we need to check for that
     * so that null doesn't get passed in the url.
     */
    $state.go('app.invoice', {
      customer_id: invoice.customer_id,
      vehicle_id : invoice.vehicle_id,
      id         : invoice.id
    });

    $uibModalInstance.close();

    if (event) {
      event.stopPropagation();
    }
  };

  $scope.viewInvoiceItems = function ( invoice : InvoiceModel ) {
    $scope.block.start();

    $scope.invoice = invoice;

    const currentIndex = $scope.invoices.map(invoice => invoice.id).indexOf($scope.invoice.id);

    vehicleHistoryApi.getChunkSettings($scope.vehicle, currentIndex, $scope.grid)
    .then(( settings : VehicleHistoryChunkSettingsModel ) => {
      $scope.chunkSettings = settings;

      return $scope.getInvoiceItems();
    })
    .then(( items : Array<InvoiceItemModel> ) => {
      $scope.flipped = true;

      $scope.$emit('flipModal');
    })
    .finally(() => {
      $scope.block.stop();
    });
  };

  $scope.viewInvoices = function () {
    this.block.start();

    $scope.resetInvoices()
    .then(() => {
      $scope.invoice      = null;
      $scope.invoiceItems = [];
      $scope.flipped      = false;

      $scope.$emit('flipModal');
    })
    .finally(() => this.block.stop());

  };

  $scope.$watch('[ grid.pagingOptions.pageSize, grid.pagingOptions.currentPage, grid.sortInfo.directions, grid.sortInfo.fields ]', ( newVal : any, oldVal : any ) => {
    if (newVal !== oldVal) {
      if ($scope.grid.filters.value) {
        $scope.search(false);
      }
      else {
        $scope.getPage();
      }
    }
  }, true);

  $scope.$watch('[ invoiceItemsGrid.pagingOptions.pageSize, invoiceItemsGrid.pagingOptions.currentPage, invoiceItemsGrid.sortInfo.directions, invoiceItemsGrid.sortInfo.fields ]', ( newVal : any, oldVal : any ) => {
    if (newVal !== oldVal) {
      vehicleHistoryApi.getItemPage($scope.invoice, $scope.invoiceItemsGrid)
      .then(( items : Array<InvoiceItemModel> ) => {
        $scope.invoiceItems = items;
      });
    }
  }, true);

  $scope.$watch('grid.filters.value', ( newVal : string, oldVal : string ) => {
    if (newVal !== oldVal && !newVal) {
      $scope.clearSearch();
    }
  });

  gridAPI.init($scope.invoices, $scope.grid);

  $scope.hotkeyMap = {
    left : $scope.handleHotkey,
    right: $scope.handleHotkey
  };
};