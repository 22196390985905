import { NgModule }              from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { ForgotPasswordComponent } from './password.forgot.component';
import { PasswordStates }          from './password.states';
import { PasswordComponent }       from './password.component';
import { PasswordService }         from './password.service';
import { ResetPasswordComponent }  from './password.reset.component';
import { SharedModule }            from '../shared/shared.module';

@NgModule({
  declarations: [
    ForgotPasswordComponent,
    PasswordComponent,
    ResetPasswordComponent
  ],
  imports: [
    SharedModule,
    UIRouterUpgradeModule.forChild({ states: PasswordStates })
  ],
  providers: [
    PasswordService
  ]
})
export class PasswordModule {}
