import { ApiServiceAjs }                    from '../api/api.service.ajs';
import { InvoiceItemModel }                 from '../invoices/models/invoice.item';
import { InvoiceModel }                     from '../invoices/models/invoice';
import { SessionServiceAjs }                from '../sessions/session.service.ajs';
import { VehicleHistoryChunkSettingsModel } from './models/vehicle.history.chunks';
import { VehicleModel }                     from './models/vehicle';

import DefaultFooterHtml            from '../grids/templates/footer.html';
import VehicleHistoryActionCellHtml from './templates/vehicle.history.action.cell.html';
import VehicleHistoryHtml           from './vehicle.history.html';
import VehicleHistoryReportHtml     from './vehicle.history.report.html';
import VehicleHistoryRowHtml        from './templates/vehicle.history.row.html';
import VehicleInvoiceItemsRowHtml   from './templates/vehicle.invoice-items.row.html';
import WindowFlipHtml               from '../core/templates/window-flip.html';
import { VehicleHistoryReportSettingsModel } from './models/vehicle.history.report-settings';
import { ApiModel } from '../api/models/api.model';

export class VehicleHistoryService {
  $q        : ng.IQService;
  $uibModal : ng.ui.bootstrap.IModalService;
  $window   : ng.IWindowService;
  api       : ApiServiceAjs;
  API       : ApiModel;
  gridApi   : any;
  sessionApi: SessionServiceAjs;

  grid = {
    data              : 'invoices',
    enableHighlighting: true,
    filters           : {
      value: null
    },
    footerTemplate: DefaultFooterHtml,
    multiSelect   : false,
    pagingOptions : {
      currentPage: 1,
      pageSize   : 5,
      pageSizes  : [ 5, 10, 25, 50 ]
    },
    route      : '/vehicles/invoice_list',
    rowHeight  : 50,
    rowTemplate: VehicleHistoryRowHtml,
    showFooter : true,
    sortInfo   : {
      directions: [ 'desc' ],
      fields    : [ 'post_date' ]
    },
    storageKey        : 'grid',
    useExternalSorting: true,
    columnDefs        : [
      {
        cellFilter : 'globalDate',
        displayName: 'Date',
        field      : 'post_date'
      },
      {
        cellClass  : 'text-center',
        displayName: 'Invoice Number',
        field      : 'invoice_number',
        sortable   : false
      },
      {
        cellFilter : 'invoiceType',
        displayName: 'Type',
        field      : 'invoice_type',
        sortable   : false
      },
      {
        cellClass  : 'text-center',
        cellFilter : 'number',
        displayName: 'Odometer',
        field      : 'odometer',
        sortable   : false
      },
      {
        cellClass   : [ 'text-right' ],
        cellTemplate: VehicleHistoryActionCellHtml,
        sortable    : false
      }
    ]
  };

  itemGrid = {
    data              : 'invoiceItems',
    enableHighlighting: true,
    enableRowSelection: false,
    footerTemplate    : DefaultFooterHtml,
    pagingOptions     : {
      currentPage: 1,
      pageSize   : 5,
      pageSizes  : [ 5, 10, 25, 50 ]
    },
    route      : '/invoice_items/invoice_items_list',
    rowHeight  : 50,
    rowTemplate: VehicleInvoiceItemsRowHtml,
    showFooter : true,
    sortInfo   : {
      directions: [ 'asc' ],
      fields    : [ 'item_code' ]
    },
    useExternalSorting: true,
    columnDefs        : [
      {
        displayName: 'Item Code',
        field      : 'item_code'
      },
      {
        displayName: 'Description',
        field      : 'description',
        sortable   : false
      },
      {
        cellClass  : 'text-center',
        cellFilter : 'number',
        displayName: 'Quantity',
        field      : 'quantity',
        sortable   : false
      },
      {
        cellClass  : 'text-center',
        cellFilter : 'globalCurrency',
        displayName: 'Unit Price',
        field      : 'unit_price',
        sortable   : false
      },
      {
        cellClass  : 'text-right',
        cellFilter : 'globalCurrency',
        displayName: 'Total',
        field      : 'amount',
        sortable   : false
      }
    ]
  };

  constructor (
    $q           : ng.IQService,
    $uibModal    : ng.ui.bootstrap.IModalService,
    $window      : ng.IWindowService,
    API          : ApiModel,
    gridAPI      : any,
    sessionObjAPI: SessionServiceAjs,
    workshopAPI  : ApiServiceAjs
  ) {
    this.$q         = $q;
    this.$uibModal  = $uibModal;
    this.$window    = $window;
    this.api        = workshopAPI;
    this.API        = API;
    this.gridApi    = gridAPI;
    this.sessionApi = sessionObjAPI;
  }

  copyInvoice ( invoice : InvoiceModel ) : ng.IPromise<InvoiceModel> {
    return this.api.get('invoice/copy_invoice', invoice.id)
    .then(( invoice : InvoiceModel) => {
      this.sessionApi.setKey('invoice_copy', invoice);

      return invoice;
    });
  }

  getChunkSettings ( vehicle : VehicleModel, currentIndex : number, grid = this.getGridSettings(vehicle) ) : ng.IPromise<VehicleHistoryChunkSettingsModel> {
    return this.getCount(vehicle)
    .then(( count : number ) => {
      const currentOffset = parseInt(this.gridApi.getOffset(grid.pagingOptions));

      return {
        count,
        currentIndex,
        endOffset     : count <= grid.pagingOptions.pageSize ? ( count - 1 ) : currentOffset,
        isFirstInvoice: !currentOffset && !currentIndex,
        isLastInvoice : ( currentOffset + currentIndex ) === ( count - 1 ),
        pageSize      : grid.pagingOptions.pageSize,
        route         : grid.route,
        sortBy        : grid.sortInfo.fields[0],
        sortDirection : grid.sortInfo.directions[0],
        startOffset   : currentOffset
      };
    });
  }

  getCount ( vehicle : VehicleModel ) : ng.IPromise<number> {
    return this.api.get('/vehicles/invoice_count', [ vehicle.id, true ]);
  }

  getFirstPage ( vehicle : VehicleModel, grid = this.getGridSettings(vehicle) ) : ng.IPromise<Array<InvoiceModel>> {
    return this.api.get(grid.route, [
      vehicle.id,
      0,
      grid.pagingOptions.pageSize + 1,
      grid.sortInfo.fields[0],
      grid.sortInfo.directions[0],
      true
    ]);
  }

  getGridSettings ( vehicle : VehicleModel ) : any {
    const self = this;
    const settings : any = angular.copy(this.grid);

    settings.pagingOptions.count    = () => self.getCount(vehicle);
    settings.pagingOptions.lastPage = function () {
      return self.gridApi.lastPage(this);
    };

    return settings;
  }

  getItemGridSettings () : any {
    return angular.copy(this.itemGrid);
  }

  getItemPage ( invoice : InvoiceModel, grid = this.getItemGridSettings() ) : ng.IPromise<Array<InvoiceItemModel>> {
    return this.gridApi.getPage(grid.route, [
      invoice.id,
      this.gridApi.getOffset(grid.pagingOptions),
      this.gridApi.getPageSize(grid.pagingOptions),
      grid.sortInfo.fields[0],
      grid.sortInfo.directions[0]
    ], grid);
  }

  getNextChunk ( vehicle : VehicleModel, chunkSettings : VehicleHistoryChunkSettingsModel ) : ng.IPromise<Array<InvoiceModel>> {
    return this.updateNextChunkSettings(chunkSettings, true)
    .then(() => this.api.get(chunkSettings.route, [
      vehicle.id,
      chunkSettings.endOffset,
      chunkSettings.pageSize,
      chunkSettings.sortBy,
      chunkSettings.sortDirection,
      true
    ]));
  }

  getPage ( vehicle : VehicleModel, grid = this.getGridSettings(vehicle) ) : ng.IPromise<Array<InvoiceModel>> {
    return this.gridApi.getPage(grid.route, [
      vehicle.id,
      this.gridApi.getOffset(grid.pagingOptions),
      this.gridApi.getPageSize(grid.pagingOptions),
      grid.sortInfo.fields[0],
      grid.sortInfo.directions[0],
      true
    ], grid);
  }

  getPreviousChunk ( vehicle : VehicleModel, chunkSettings : VehicleHistoryChunkSettingsModel ) : ng.IPromise<Array<InvoiceModel>> {
    return this.updatePreviousChunkSettings(chunkSettings, true)
    .then(() => this.api.get(chunkSettings.route, [
      vehicle.id,
      chunkSettings.startOffset,
      chunkSettings.pageSize,
      chunkSettings.sortBy,
      chunkSettings.sortDirection,
      true
    ]));
  }

  isLastInvoice ( chunkSettings : VehicleHistoryChunkSettingsModel ) : boolean {
    // is this the last offset?
    if (chunkSettings.count - chunkSettings.endOffset <= chunkSettings.pageSize) {
      // is this the last item in the list?
      if (chunkSettings.endOffset - chunkSettings.startOffset === chunkSettings.currentIndex) {
        return true;
      }
    }
    return false;
  }

  search ( vehicle : VehicleModel, grid = this.getGridSettings(vehicle) ) : ng.IPromise<Array<InvoiceModel>> {
    return this.api.get('/vehicles/invoice_list_with_find', [
      vehicle.id,
      grid.filters.value,
      0,
      this.gridApi.getPageSize(grid.pagingOptions),
      grid.sortInfo.fields[0],
      grid.sortInfo.directions[0]
    ]);
  }

  updateNextChunkSettings ( chunkSettings : VehicleHistoryChunkSettingsModel, updateOffset = false ) : ng.IPromise<VehicleHistoryChunkSettingsModel> {
    chunkSettings.currentIndex++;
    chunkSettings.endOffset      = updateOffset ? chunkSettings.endOffset + chunkSettings.pageSize : chunkSettings.endOffset;
    chunkSettings.isFirstInvoice = false;
    chunkSettings.isLastInvoice  = this.isLastInvoice(chunkSettings);

    return this.$q.when(chunkSettings);
  }

  updatePreviousChunkSettings ( chunkSettings : VehicleHistoryChunkSettingsModel, updateOffset = false ) : ng.IPromise<VehicleHistoryChunkSettingsModel> {
    chunkSettings.isFirstInvoice = !chunkSettings.currentIndex && !chunkSettings.startOffset;
    chunkSettings.isLastInvoice  = false;
    chunkSettings.startOffset    = updateOffset ? chunkSettings.startOffset - chunkSettings.pageSize : chunkSettings.startOffset;

    return this.$q.when(chunkSettings);
  }

  view ( vehicle : VehicleModel ) : ng.IPromise<any> {
    return this.$uibModal.open({
      controller       : 'VehicleHistoryCtrl',
      templateUrl      : VehicleHistoryHtml,
      windowClass      : 'x-lg-modal flip-container',
      windowTemplateUrl: WindowFlipHtml,
      resolve          : {
        invoices: () => this.getFirstPage(vehicle),
        vehicle : () => vehicle
      }
    }).result;
  }

  viewReport ( vehicle : VehicleModel ) : ng.IPromise<VehicleModel> {
    return this.$uibModal.open({
      bindToController: true,
      controller      : 'VehicleHistoryReportCtrl',
      controllerAs    : '$ctrl',
      templateUrl     : VehicleHistoryReportHtml,
      resolve         : {
        vehicle: () => vehicle
      }
    }).result
    .then(( settings : VehicleHistoryReportSettingsModel ) => {
      this.$window.open(`${this.API.url}/reports/jasper_vehicle_history/${ vehicle.id }/${ settings.printTotal }/${ settings.showSummary }.pdf`, '_blank');

      return vehicle;
    });
  }
}
