import { Component, Input } from '@angular/core';
import { NgbActiveModal }   from '@ng-bootstrap/ng-bootstrap';

import { ApiErrorModel }        from '../core/api/models/api.error';
import { ErrorHandlingService } from '../core/errors/error-handling.service';
import { NotificationService }  from '../notifications/notification.service';
import { SessionService }       from '../core/sessions/session.service';
import { VehicleModel }         from './models/vehicle';
import { VehicleOptionsModel }  from './models/vehicle.options';
import { VehiclesService }      from './vehicles.service';

@Component({
  selector   : 'wor-vehicle',
  template: require('./vehicle.component.html')
})
export class VehicleComponent {
  plateNumberLabel  = this.vehiclesService.getPlateNumberLabel();

  @Input() options : VehicleOptionsModel;
  @Input() vehicle : VehicleModel = {};

  constructor (
    public  activeModal          : NgbActiveModal,
    private errorHandlingService: ErrorHandlingService,
    private notificationService : NotificationService,
    public  sessionService      : SessionService,
    private vehiclesService     : VehiclesService
  ) {}

  save () : void {
    this.vehiclesService.createVehicle(this.vehicle)
    .then(() => {
      this.notificationService.show('Vehicle Added');

      this.activeModal.close(this.vehicle);
    })
    .catch(( err : ApiErrorModel ) => {
      this.errorHandlingService.show(err);
    });
  }
}
