export function RememberConfirmationLiteCtrl ($scope, message, storageName, type) {
  $scope.message  = message;
  $scope.remember = false;
  $scope.type     = type;

  $scope.yes = function () {
    if ($scope.remember) {
      localStorage.setItem(storageName, true);
    }

    $scope.$close(true);
  }
}