import { Inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jobStatusBooking',
})
export class JobStatusBookingPipe implements PipeTransform {

  constructor(
    @Inject('$translate') public $translate : ng.translate.ITranslateService
  ) {}

  transform( value : string ) {
    const typeMap = {
      A: this.$translate.instant('JOB_STATUS_TYPES.AWAITING'),
      B: this.$translate.instant('JOB_STATUS_TYPES.BOOKED'),
      C: this.$translate.instant('JOB_STATUS_TYPES.COMPLETE'),
      F: this.$translate.instant('JOB_STATUS_TYPES.FINAL'),
      N: this.$translate.instant('JOB_STATUS_TYPES.NOTIFIED'),
      P: this.$translate.instant('JOB_STATUS_TYPES.PARTS'),
      W: this.$translate.instant('JOB_STATUS_TYPES.WIP')
    }

    return typeMap[value] || '';
  }
}