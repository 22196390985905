import { Directive, forwardRef, Input }               from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator }  from '@angular/forms';

@Directive({
  selector : '[worEqualValidator]',
  providers: [
    {
      multi      : true,
      provide    : NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => EqualValidator)
    }
  ]
})
export class EqualValidator implements Validator {
  @Input ('worEqualValidator') value: string;

  validate (ctrl: AbstractControl): { [key: string]: any } {
    return ctrl.value !== this.value ? { equalValidator: true } : undefined;
  }
}