import { CurrencyPipe }        from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { SessionService } from '../../core/sessions/session.service';

@Pipe({
  name: 'globalCurrency'
})
export class GlobalCurrencyPipe implements PipeTransform {
  constructor (
    private currencyPipe  : CurrencyPipe,
    private sessionService: SessionService
  ) {}

  transform ( value : string, roundTo = 2 ) : string {
    const settings = this.sessionService.getSession().country_settings;

    const currency = this.currencyPipe.transform(
      value,
      null,
      settings.currency || 'symbol',
      `1.${ settings.no_currency_fractions ? 0 : roundTo }-${ settings.no_currency_fractions ? 0 : roundTo }`
    );

    if (!currency) {
      return this.currencyPipe.transform('0');
    }

    return currency.replace(/[\.,]/g, ( character: string ) => character === '.' ? settings.decimal_mark : settings.thousands_separator);
  }
}
