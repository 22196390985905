import VendorInfoHtml          from './templates/vendor.info.html';
import VendorQuickAddHtml      from './templates/vendor.quick-add.html';
import VendorSearchResultsHtml from '../../app-lite/vendors/templates/vendors.search.results.html';

export function VendorSelectionLiteCtrl (
  $q,
  $scope,
  messages,
  streamSearch,
  workshopAPI
) {

  $scope.formContent = $scope.vendor.id ? VendorInfoHtml : VendorQuickAddHtml;

  $scope.vendorSearch = {
    list: [],
    selected: {
      id: '',
      company_name: ''
    },
    search (typed) {
      return streamSearch('vendors', typed).then(data => {
        return $scope.vendorSearch.list.concat(data);
      });
    },
    choose (vendor) {
      $scope.vendorSelected(vendor);
    },
    format (vendor) {
      return vendor ? vendor.company_name: '';
    },
    templateUrl: VendorSearchResultsHtml
  };

  $scope.check = function () {
    if ($scope.vendor.id) {
      $scope.$broadcast('open');
    }
  };

  $scope.close = function () {
    $scope.$broadcast('close');
  };

  $scope.save = function (form) {
    workshopAPI.post('/vendors', $scope.vendor, 'vendor').then(vendor => {
      form.$setPristine();

      messages.show('Supplier Saved', 'success');

      $scope.vendorSelected(vendor);
    }).catch(err => {
      messages.error(err);
    });
  };

  workshopAPI.get('/system/state_types/', null, 'states').then(states => {
    $scope.states = states;
  });
}