export function VehicleFollowUpNotesLiteCtrl (
  $scope,
  $state,
  $translate,
  confirm,
  messages,
  reference,
  vehicle,
  view,
  workshopAPI
) {
  $scope.reference = reference;
  $scope.vehicle   = vehicle;
  $scope.view      = view;

  $scope.addNotesToJobCard = function () {
    confirm
    .generic($translate.instant('JS_SPACE.CONFIRM.ADD_FOLLOW_UP_NOTES'))
    .then(() => workshopAPI.post($scope.view === 'event' ?
      '/event/add_follow_up_notes_to_job_card' :
      '/invoice/add_follow_up_notes_to_job_card', {
        comment     : $scope.vehicle.follow_up_comment,
        date        : $scope.vehicle.follow_up_date,
        reference_id: $scope.reference
      }, 'follow_up_details'))
    .then(() => {
      messages.show($translate.instant('JS_SPACE.MESSAGES.CREATED.FOLLOW_UP_NOTES'), 'success');

      $state.reload();
      $scope.$close();
    })
    .catch(err => messages.error(err));
  };

  $scope.clearFollowUpNotes = function () {
    confirm
    .generic($translate.instant('JS_SPACE.CONFIRM.CLEAR_FOLLOW_UP_NOTES'))
    .then(() => workshopAPI.get('/vehicles/clear_vehicle_follow_up_note', $scope.vehicle.id))
    .then(() => {
      messages.show($translate.instant('JS_SPACE.MESSAGES.DELETED.FOLLOW_UP_NOTES'), 'success');

      $state.reload();
      $scope.$close();
    })
    .catch(err => messages.error(err));
  };
};