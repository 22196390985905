import { Table } from "../types/table";

export const DEFAULTTABLESETTINGS: Table = {
  broadcast: undefined,
  cache    : {
    enabled: false
  },
  columns: [],
  empty  : {
    text: 'No Records Found'
  },
  pagination: {
    enabled   : false,
    offset    : 0,
    page      : 1,
    pageSize  : 5,
    pageSizes : [ 5 ],
    queryCount: undefined
  },
  rows: {
    add: {
      enabled: false
    },
    classes  : [],
    component: {
      bindings : {
        inputs : undefined,
        outputs: undefined
      },
      enabled: false,
      factory: undefined
    },
    delete: {
      enabled: false,
      icon   : 'fa fa-trash-o'
    },
    expand: {
      factory: undefined,
      enabled: false,
    },
    highlight: {
      enabled: false
    },
    reorder: {
      enabled   : false,
      identifier: 'id'
    },
    select: {
      enabled : false,
      identifier: 'selected',
      multi   : false
    }
  },
  sorting: {
    direction: 'asc',
    enabled  : false,
    field    : ''
  },
  static: false,
  title: ''
};