import CellCurrencyEditableHtml from '../grids/templates/cell.currency.editable.html';
import CellRemoveRowHtml        from '../grids/templates/cell.remove-row.html';
import RowDynamicColorHtml      from '../grids/templates/row.dynamic-color.html';
import VendorSearchResultsHtml  from '../vendors/templates/vendors.search.results.html';

export function VendorBranchPaymentLiteCtrl (
  $scope,
  $state,
  $translate,
  confirm,
  currencyAPI,
  gridAPI,
  messages,
  sessionObjAPI,
  Vendor,
  VendorBranchPayment
) {

  $scope.company                        = sessionObjAPI.company();
  $scope.currency                       = currencyAPI.info();
  $scope.editable                       = $scope.payment ? $scope.payment.status === 'O': true;
  $scope.itemsToDelete                  = [];
  $scope.loaded                         = true;
  $scope.vendorSearchResultsTemplateUrl = VendorSearchResultsHtml;

  $scope.Vendor                = new Vendor($scope.vendor);
  $scope.VendorBranchPayment   = new VendorBranchPayment ($scope.payment, $scope.vendor);

  $scope.payment               = $scope.VendorBranchPayment.payment;

  $scope.grid = {
    cellEditableCondition  : 'editable',
    data                   : 'payment.branch_vendor_payment_items_attributes',
    enableCellEditOnFocus  : true,
    enableCellSelection    : true,
    enableMultiSelect      : false,
    enableRowSelection     : false,
    rowHeight              : 43,
    rowTemplate            : RowDynamicColorHtml,
    virtualizationThreshold: 9999,
    columnDefs             : [
      {
        cellTemplate  : CellRemoveRowHtml,
        disabled      : !$scope.editable,
        enableCellEdit: false,
        width         : '43px'
      },
      {
        cellClass     : 'Uppercase ReadOnly',
        displayName   : $translate.instant('GENERAL_SPACE.FIELD.REFERENCE'),
        enableCellEdit: false,
        field         : 'vendor_invoice_number'
      },
      {
        cellClass     : 'Capitalize ReadOnly',
        cellFilter    : 'date:"dd/MM/yyyy"',
        displayName   : $translate.instant('GENERAL_SPACE.FIELD.POST_DATE'),
        enableCellEdit: false,
        field         : 'vendor_invoice_post_date',
        width         : '150px'
      },
      {
        cellClass     : 'Uppercase ReadOnly',
        displayName   : $translate.instant('GENERAL_SPACE.FIELD.STORE_NAME'),
        enableCellEdit: false,
        field         : 'store_name'
      },
      {
        cellClass     : 'align-right ReadOnly',
        cellFilter    : 'globalCurrency',
        displayName   : $translate.instant('GENERAL_SPACE.FIELD.BALANCE_DUE_LABEL'),
        enableCellEdit: false,
        field         : 'vendor_invoice_balance_due',
        width         : '150px'
      },
      {
        cellClass           : 'align-right',
        cellFilter          : 'globalCurrency',
        displayName         : $translate.instant('JS_SPACE.COLUMNS.APPLIED'),
        editableCellTemplate: CellCurrencyEditableHtml,
        field               : 'amount',
        width               : '115px'
      },
      {
        cellClass     : 'align-right ReadOnly',
        cellFilter    : 'globalCurrency',
        displayName   : $translate.instant('JS_SPACE.COLUMNS.BALANCE'),
        enableCellEdit: false,
        field         : 'getBalance()',
        width         : '115px'
      }
    ],
  };

  $scope.addCalcFunctions = function() {
    angular.forEach($scope.payment.branch_vendor_payment_items_attributes, row => {
      row.untouched = false;

      $scope.VendorBranchPayment.addCurrencyFuncs(row);
    });
  };

  $scope.addInvoices = function() {
    $scope.Vendor.lookupBranchVendorInvoices()
      .then(invoices => {
        if (!angular.isArray(invoices)) {
          invoices = [invoices];
        }

        angular.forEach(invoices, (invoice, i) => {
          if (!i && $scope.payment.branch_vendor_payment_items_attributes[0].untouched) {
            $scope.VendorBranchPayment.updatePaymentItemFromInvoice($scope.payment.branch_vendor_payment_items_attributes[0], invoice);
          }
          else {
            $scope.payment.branch_vendor_payment_items_attributes.push($scope.VendorBranchPayment.getPaymentItemFromInvoice(invoice));
          }
        });
      });
  };

  $scope.delete = function() {
    confirm
      .generic($translate.instant('JS_SPACE.CONFIRM.DELETE_PAYMENT'))
      .then(() => $scope.VendorBranchPayment.delete())
      .then(() => {
        $scope.form.$setPristine();
        messages.show($translate.instant('JS_SPACE.MESSAGES.DELETED.PAYMENT'), 'success');
        $state.go('app');
      }, err => messages.error(err));
  };

  $scope.getRowColor = function (item, column) {
    const classes = gridAPI.getColumnClasses(column);

    if (!$scope.editable) {
      classes.push('readonly');
    }

    return classes;
  };

  $scope.handleSave = function (payment) {
    $scope.form.$setPristine();

    if ($scope.payment.id) {
      $state.reload();
    }
    else {
      $state.go('app.vendor-branch-payment', {
        vendor_id: $scope.vendor.id,
        id       : payment.id
      });
    }

    $scope.VendorBranchPayment = new VendorBranchPayment(payment, $scope.vendor);
    $scope.payment             = $scope.VendorBranchPayment.payment;

    return payment;
  };

  $scope.process = function() {
    confirm
      .generic($translate.instant('JS_SPACE.CONFIRM.SAVE_PROCESS_PAYMENT'))
      .then(() => $scope.VendorBranchPayment.save($scope.itemsToDelete).then($scope.handleSave))
      .then(() => $scope.VendorBranchPayment.process())
      .then(() => {
        messages.show($translate.instant('JS_SPACE.MESSAGES.PROCESSED.PAYMENT'), 'success');

        $state.reload();
      }, err => messages.error(err));
  };

  $scope.removeItem = function (row) {
    let deletedItem = $scope.payment.branch_vendor_payment_items_attributes.splice(row.rowIndex, 1);

    if ($scope.payment.id && row.entity.id) {
      deletedItem[0]._destroy = '1';
      $scope.itemsToDelete    = $scope.itemsToDelete.concat(deletedItem);
    }
  };

  $scope.save = function () {
    $scope.VendorBranchPayment
      .save($scope.itemsToDelete)
      .then($scope.handleSave, err => messages.error(err))
      .then(() => messages.show($translate.instant('JS_SPACE.MESSAGES.SAVED.PAYMENT'), 'success'));
  };

  $scope.updateTotals = function () {
    $scope.payment.applied_amount = $scope.VendorBranchPayment.getAppliedAmount();
    $scope.payment.amount         = $scope.payment.applied_amount;
  };

  $scope.vendorSelected = function (vendor) {
    $state.go('app.vendor-branch-payment', {
      vendor_id: vendor.id
    });
  };

  $scope.void = function () {
    confirm
      .generic($translate.instant('JS_SPACE.CONFIRM.VOID_PAYMENT'))
      .then(() => $scope.VendorBranchPayment.void())
      .then(() => {
        messages.show($translate.instant('JS_SPACE.MESSAGES.VOIDED.PAYMENT'), 'success');
        $state.go('app');
      }, err => messages.error(err));
  };

  $scope.$watch(
    'payment.branch_vendor_payment_items_attributes',
    (items, oldItems) => {
      if (!items || !items.length) {
        $scope.payment.branch_vendor_payment_items_attributes.push($scope.VendorBranchPayment.getDefaultPaymentItem());
      }
      else if (items !== oldItems) {
        $scope.updateTotals();
      }
    },
  true);
}