import { NgModule }       from '@angular/core';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';

import { NotificationsComponent } from './notifications.component';
import { NotificationService }    from './notification.service';
import { SharedModule }           from '../shared/shared.module';

@NgModule({
  declarations: [
    NotificationsComponent
  ],
  exports: [
    NotificationsComponent
  ],
  imports: [
    NgbToastModule,
    SharedModule
  ],
  providers: [
    NotificationService
  ]
})
export class NotificationsModule {}
