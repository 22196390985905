interface CoreDatePickerScope extends ng.IScope {
  column: any;
  row   : any;
}

export function DatePickerCtrl (
  $element   : any,
  $scope     : CoreDatePickerScope,
  $timeout   : ng.ITimeoutService,
  dateFormat : any,
  dateParsing: any,
) {

  $element.datepicker({
    autoclose: true,
    format   : dateFormat(),
    todayBtn : 'linked'
  })
  .on('changeDate', e => {

    // Bootstrap datepicker is returning the wrong date
    // under specific daylight savings circumstances.
    // We add one hour to accomdate for this bug.
    e.date.setTime(e.date.getTime() + 1*60*60*1000);

    const date  = e.date.getDate();
    const month = e.date.getMonth() + 1;
    const year  = e.date.getFullYear();

    $timeout(() => {
      $scope.row.entity[$scope.column.field] = `${year}-${dateParsing.pad(month)}-${dateParsing.pad(date)}`;
    });
  });
}