import { RemoveComponent } from '../table/cell-components/actions/remove.component';

export function LinkedVendorsLiteCtrl ($scope, $translate, confirm, links, messages, vendor, Vendor) {
  $scope.links  = links;
  $scope.vendor = new Vendor(vendor);

  $scope.tableSettings = {
    columns: [
      {
        component: {
          bindings: {
            outputs: {
              onRemoveClick: event => $scope.removeItem(event)
            }
          },
          enabled: true,
          factory: RemoveComponent,
        },
        sortable: false,
      },
      {
        classes: 'text-left',
        field  : 'store_name',
        header : {
          text: 'Store Name'
        }
      },
      {
        classes: 'text-left',
        field  : 'company_name',
        header : {
          text: 'Company Name'
        }
      }
    ],
    empty: {
      text: $translate.instant('GENERAL_SPACE.STATUS.NO_RESULTS')
    },
    pagination: {
      enabled: false
    },
    sorting: {
      enabled: false
    },
    static: true
  };

  $scope.removeItem = function (row) {
    confirm.generic('Are you sure you want to delete this linked vendor?').then(() => {
      return $scope.vendor.deleteLinkedVendor(row);
    }).then(() => {
      messages.show($translate.instant('JS_SPACE.MESSAGES.DELETED.DELETED'), 'success');

      $scope.$close();
    }).catch(err => {
      messages.error(err);
    });
  }
}