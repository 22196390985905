import { FlipPayService }    from './flippay.service.ajs';
import { SessionServiceAjs } from '../sessions/session.service.ajs';

import FlipPaySettingsHtml from './flippay.settings.html';

export function FlipPayStates (
  $stateProvider: ng.ui.IStateProvider
) {
  $stateProvider
  .state({
    controller  : 'FlipPaySettingsCtrl',
    controllerAs: '$ctrl',
    name        : 'app.flippay-settings',
    templateUrl : FlipPaySettingsHtml,
    resolve     : {
      session : ( sessionObjAPI : SessionServiceAjs ) => sessionObjAPI.refresh(),
      settings: ( flipPayApi : FlipPayService ) => flipPayApi.getSettings()
    },
    url: '/flippay-settings'
  });
}
