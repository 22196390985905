import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';

import { ErrorHandlingService } from '../core/errors/error-handling.service';
import { TableModel }           from './models/table';
import { TableColumnModel }     from './models/table-column';
import { TableCountModel }      from './models/table-count';
import { TableQueryModel }      from './models/table-query';

@Component({
  selector   : 'wor-table',
  styles: [ require('./table.component.scss') ],
  template: require('./table.component.html')
})
export class TableComponent implements OnInit {

  // request used to fetch and update the
  // table data.
  @Input() onQuery: Function;

  // request used to get the total number
  // of records in the table.
  @Input() onQueryCount: Function;

  // settings used to define the table.
  @Input() settings: TableModel;

  // the dataset used to populate the table.
  @Input() data: Array<any> = [];

  // used to emit updated data after a change
  // has taken place.
  @Output() dataChange: EventEmitter<Array<any>> = new EventEmitter();

  // event emitted when a table row is clicked.
  @Output() rowClick: EventEmitter<any> = new EventEmitter();

  // event emitted when a table row is double clicked.
  @Output() rowDoubleClick: EventEmitter<any> = new EventEmitter();

  // query list of template references for each row in the table.
  @ViewChildren('rowRef') rowRefs: QueryList<ElementRef>;

  // represents whether or not the table component is
  // currently making an asynchronous request.
  busy      : boolean;

  // used to discern between single and double click events.
  clickTimer: any;

  // represents the total number of records in the table.
  count     : number;

  // used to flag when there are no
  // more records to fetch.
  lastPage  : boolean;

  constructor (
    private errorHandlingService: ErrorHandlingService
  ) {}

  initEmptySettings (): void {
    this.settings.empty = {
      message: 'No Records Found',
      ...this.settings.empty
    };
  }

  initLastPage (): void {
    this.lastPage = this.settings.pagination.page * this.settings.pagination.pageSize >= this.count;
  }

  initPagination (): Promise<null> {
    if (this.isPaginated()) {
      this.initPaginationPage();
      this.initPaginationOffset();

      return this.onQueryCount()
        .then(( result : TableCountModel ) => {
          this.count = result.count;

          this.initLastPage();

          return Promise.resolve();
        });
    }

    return Promise.resolve(null);
  }

  initPaginationOffset (): void {
    this.settings.pagination.offset = (this.settings.pagination.page - 1) * this.settings.pagination.pageSize;
  }

  initPaginationPage (): void {
    this.settings.pagination.page = 1;
  }

  initPaginationSettings (): void {
    this.settings.pagination = {
      enabled : false,
      offset  : 0,
      page    : 1,
      pageSize: 10,
      ...this.settings.pagination
    };
  }

  initRowSettings (): void {
    const rows = Object.assign({}, this.settings.rows);

    rows.hover = {
      enabled: false,
      ...this.settings.rows?.hover
    };

    rows.select = {
      enabled : false,
      multi   : false,
      property: 'selected',
      ...this.settings.rows?.select
    };

    this.settings.rows = rows;
  }

  isBusy (): boolean {
    return this.busy;
  }

  isColumnHidden (column: TableColumnModel, row: any): boolean {
    return column.hideIf && column.hideIf(row);
  }

  isEmpty (): boolean {
    return !this.data?.length;
  }

  isFirstPage (): boolean {
    return this.settings.pagination.page === 1;
  }

  isLastPage (): boolean {
    return this.lastPage;
  }

  isPaginated (): boolean {
    return this.settings.pagination.enabled;
  }

  nextPage (): void {
    if (!this.lastPage) {
      this.settings.pagination.page++;

      this.initPaginationOffset();
      this.query();
    }
  }

  ngOnInit (): void {

    // initializations.
    this.initEmptySettings();
    this.initPaginationSettings();
    this.initPagination();
    this.initRowSettings();
  }

  onRowClick (index: number, row: any): void {
    this.clickTimer = setTimeout(() => {
      this.onRowSingleClick(index, row);
    }, 250);
  }

  onRowDoubleClick (index: number, row: any): void {
    clearTimeout(this.clickTimer);

    this.clickTimer = undefined;

    this.rowDoubleClick.emit({ index, row });
  }

  onRowSingleClick (index: number, row: any): void {
    if (this.clickTimer) {

      // apply selected class if applicable.
      if (this.settings.rows.select.enabled && !row.unselectable) {

        // if row is being selected and multi select
        // is disabled, toggle all others to be unselected.
        if (!this.settings.rows.select.multi && !row[this.settings.rows.select.property]) {
          this.data.forEach(item => item[this.settings.rows.select.property] = false);
        }

        row[this.settings.rows.select.property] = !row[this.settings.rows.select.property];
      }

      this.rowClick.emit({ index, row });
    }
  }

  previousPage (): void {
    if (!this.isFirstPage()) {
      this.settings.pagination.page--;

      this.initPaginationOffset();
      this.query();
    }
  }

  query (): Promise<null> {
    let params : TableQueryModel;

    this.busy = true;

    // init pagination params, if
    // applicable.
    if (this.isPaginated()) {
      params = {
        offset  : this.settings.pagination.offset,
        pageSize: this.settings.pagination.pageSize
      };
    }

    return this.onQuery(params)
      .then(response => {

        // account for paginated data,
        // if applicable.
        if (this.isPaginated()) {
          this.initLastPage();
        }

        this.data = response;

        this.dataChange.emit(this.data);

        return Promise.resolve(this.data);
      })
      .catch(err => this.errorHandlingService.show(err))
      .finally(() => this.busy = false);
  }

  refresh (): Promise<null> {

    // if pagination is enabled, first initialize
    // its settings. Then refresh the table data.
    return this.initPagination().then(() => this.query());
  }
}