export function GridFilterDirective ( KEYCODES : any ) : ng.IDirective {
  const directive : ng.IDirective = {
    require : 'ngModel',
    restrict: 'A',
    link (
      scope  : ng.IScope,
      element: any,
      attrs  : ng.IAttributes,
      ctrl   : ng.INgModelController
    ) {
      const maxLength = 50;

      attrs.$set('maxlength', maxLength);

      const clearFilter = ( e : JQueryEventObject ) => {
        element.val('');
        element.trigger('input');
      };

      element.keydown(( e : JQueryEventObject ) => {
        if ( e.keyCode === KEYCODES.esc ) {
          clearFilter(e);

          e.stopPropagation();
        }
      });

      element.siblings()
      .find('a')
      .on('click', ( e : JQueryEventObject ) => {
        clearFilter(e);
      });
    }
  };

  return directive;
}