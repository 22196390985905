import { Injectable } from '@angular/core';
import { NgbModal }   from '@ng-bootstrap/ng-bootstrap';
import { UIRouter }   from '@uirouter/core';

import { ApiErrorModel }       from '../api/models/api.error';
import { ApiErrorTypesModel }  from '../api/models/api.error-types';
import { NotificationModel }   from '../../notifications/models/notification';
import { NotificationService } from '../../notifications/notification.service';
import { SessionService }      from '../sessions/session.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {
  errorTypes: ApiErrorTypesModel = {
    expired: 'X',
    field  : 'F',
    global : 'G',
    rights : 'I',
    server : 'R'
  };

  notificationDefaults: NotificationModel = {
    classname: 'bg-danger',
    persist  : true
  };

  constructor (
    private modalService       : NgbModal,
    private notificationService: NotificationService,
    private router             : UIRouter,
    private sessionService     : SessionService
  ) {}

  isServerError ( error : ApiErrorModel ) : boolean {
    return error.type === this.errorTypes.server;
  }

  isSessionExpired ( error : ApiErrorModel ) : boolean {
    return error.type === this.errorTypes.expired;
  }

  show ( error : ApiErrorModel, options ?: NotificationModel ) : void {
    const notification : NotificationModel = Object.assign({}, this.notificationDefaults, options);

    if (this.isSessionExpired(error)) {
      this.sessionService.clearSession();

      if (this.modalService.hasOpenModals()) {
        this.modalService.dismissAll();
      }

      this.notificationService.show(error.message, notification);

      this.router.stateService.go('ajs.app.public.login');
    }

    else if (this.isServerError(error)) {
      this.notificationService.show('Unknown Error, please contact support (support@workshopsoftware.com) for further details.', notification);
    }

    else if (error && error.message) {
      this.notificationService.show(error.message, notification);
    }

    console.error(error);
  }
}