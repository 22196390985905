import * as angular from 'angular';

import { AttachmentDirective }          from './attachment.directive.ajs';
import { AttachmentFileModelDirective } from './attachment.file-model.directive.ajs';
import { AttachmentsBoxDirective }      from './attachments.box.directive.ajs';
import { AttachmentsService }           from './attachments.service.ajs';
import { EmailAttachmentsCtrl }         from './attachments.email.ajs';
import { ImageLoaderService }           from './attachment.image-loader.service.ajs';
import { ImageViewerCtrl }              from './attachment.image-viewer.ajs';
import { ImageZoomDirective }           from './attachment.image-zoom.directive.ajs';
import { VideoLoaderDirective }         from './attachment.video-loader.directive.ajs';
import { VideoViewerCtrl }              from './attachment.video-viewer.ajs';

angular.module('app-lite.attachments', [])
  .controller('EmailAttachmentsCtrl', EmailAttachmentsCtrl)
  .controller('ImageViewerCtrl', ImageViewerCtrl)
  .controller('VideoViewerCtrl', VideoViewerCtrl)
  .directive('attachment', AttachmentDirective)
  .directive('attachmentsBox', AttachmentsBoxDirective)
  .directive('fileModel', AttachmentFileModelDirective)
  .directive('imageZoom', ImageZoomDirective)
  .directive('videoLoader', VideoLoaderDirective)
  .factory('imageLoader', ImageLoaderService)
  .service('attachmentsApi', AttachmentsService);
