import { Injectable }         from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { firstValueFrom }     from 'rxjs';

import { ApiService }                 from '../api/api.service';
import { ChargifyService }            from '../chargify/chargify.service';
import { RecaptchaVerificationModel } from './models/recaptcha.verification';

@Injectable({
  providedIn: 'root'
})
export class RecpatchaService {
  constructor (
    private apiService        : ApiService,
    private chargifyService   : ChargifyService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {}

  verifyUser () {
    if (!this.chargifyService.status('trialing')) {
      return new Promise(resolve => resolve(true));
    }

    return firstValueFrom(this.recaptchaV3Service.execute('verifyUser'))
    .then(( token : string ) => {
      return this.apiService.post('/system/validate_recaptcha_for_user', {
        response_token: token
      });
    })
    .then(( response : RecaptchaVerificationModel ) => {
      return response.success
        || Promise.reject('Cannot verify user');
    });
  }
}
