import { Injectable } from '@angular/core';

import { ApiService }             from '../core/api/api.service';
import { CustomerModel }          from './models/customer';
import { CustomerPasswordsModel } from './models/customer.passwords';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  constructor (
    private apiService: ApiService
  ) {}

  changePassword ( passwords : CustomerPasswordsModel ) : ng.IPromise<boolean> {
    return this.apiService.post('/customer_portal_users/change_password', passwords);
  }

  getDetails () : ng.IPromise<CustomerModel> {
    return this.apiService.get('/customer_portal_dashboard/customer');
  }
}
