export const KEYCODES = {
  down : 40 ,
  enter: 13,
  esc  : 27,
  home : 36,
  left : 37,
  right: 39,
  tab  : 9,
  up   : 38
};
