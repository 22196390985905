import { Inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customerPaymentStatus'
})
export class CustomerPaymentStatusPipe implements PipeTransform {

  constructor(
    @Inject('$translate') public $translate : ng.translate.ITranslateService
  ) {}

  transform( value : string ) : string {
    const typeMap = {
      C: this.$translate.instant('STATUS_TYPES.CLOSED'),
      O: this.$translate.instant('STATUS_TYPES.OPEN')
    };

    return typeMap[value] || this.$translate.instant('STATUS_TYPES.OPEN');
  }
}

