import { Pipe, PipeTransform } from '@angular/core';

import { CodeTypeModel } from '../../core/models/code-type';

@Pipe({
  name: 'jobStatus',
})
export class JobStatusPipe implements PipeTransform {
  transform( value : string, statuses : Array<CodeTypeModel> = [] ) {
    const status = statuses.filter(status => status.code === value)[0];

    return status ? status.description : '';
  }
}