import { Injectable } from '@angular/core';

import { ApiService }        from '../api/api.service';
import { CodeTypeModel }     from '../../../app-lite/core/models/code-type';
import { COUNTRIES }         from '../../../app-lite/core/constants/core.countries.constant.ajs';
import { SessionModel }      from './models/session';
import { SessionServiceAjs } from '../../../app-lite/sessions/session.service.ajs';
import { UserAccountModel }  from '../../users/models/user.account';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  readonly COUNTRIES = COUNTRIES;

  constructor(
    private apiService       : ApiService,
    private sessionServiceAjs: SessionServiceAjs
  ) {}

  clearSession () : void {
    this.sessionServiceAjs.clear();
  }

  getCountryStates () : ng.IPromise<Array<CodeTypeModel>> {
    const session = this.getSession();

    return this.apiService.get('/system/states_for_country', session.country_code);
  }

  getSessionId () : string {
    return this.sessionServiceAjs.sessionID();
  }

  getSession () : SessionModel {
    return this.sessionServiceAjs.base();
  }

  isCountry ( country : string ) : boolean {
    const countryCode = this.getSession().country_code;

    return this.COUNTRIES[country] === countryCode;
  }

  login ( user : UserAccountModel ) : ng.IPromise<SessionModel> {
    return this.apiService.post('/customer_portal_sessions/login', user)
    .then(( session : SessionModel ) => this.setSession(session));
  }

  logout () : ng.IPromise<any> {
    return this.apiService.delete('/customer_portal_sessions/destroy')
    .then(() => {
      this.clearSession();

      return Promise.resolve();
    });
  }

  setSession ( session : SessionModel ) : ng.IPromise<SessionModel> {
    this.sessionServiceAjs.setCookie(session.session_id);

    this.sessionServiceAjs.setKey('session_object', session);

    return Promise.resolve(session);
  }
};
