import { Directive, ElementRef, forwardRef, Input }               from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator }  from '@angular/forms';

import { CompanyCountryValidationsModel } from '../../companies/models/company-country-validations';
import { SessionServiceAjs }              from '../../sessions/session.service.ajs';

@Directive({
  selector : '[worPhoneValidator]',
  providers: [
    {
      multi      : true,
      provide    : NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => PhoneValidator)
    }
  ]
})
export class PhoneValidator implements Validator {
  defaultValidation = '^\\d+$';

  mask : string;
  regex: RegExp;

  @Input ('isMobile')       isMobile    ?: boolean;
  @Input ('validations')    validations ?: CompanyCountryValidationsModel;
  @Input ('worPhoneValidator') value     : string;

  constructor (
    private element        : ElementRef<HTMLInputElement>,
    private sessionService : SessionServiceAjs
  ) {}

  getAndSetValidations () {
    const countryValidations = this.sessionService.countryValidation();
    let regex;

    if (this.validations) {
      this.mask = this.isMobile && this.validations.mobile?.mask
        ? this.validations.mobile.mask
        : this.validations.phone.mask;

      regex = this.isMobile && this.validations.mobile?.validation
        ? this.validations.mobile.validation
        : this.validations.phone.validation;
    }
    else {
      this.mask = this.isMobile && countryValidations.mobile?.mask
        ? countryValidations.mobile.mask
        : countryValidations.phone.mask;

      regex = this.isMobile && countryValidations.mobile?.validation
        ? countryValidations.mobile.validation
        : countryValidations.phone.validation;
    }

    this.regex = new RegExp(regex || this.defaultValidation);
  }

  validate (ctrl: AbstractControl) : { [ key: string ] : any } {
    this.getAndSetValidations();

    if (!ctrl.value) {
      return undefined;
    }

    if (ctrl.value.match(this.regex)) {
      if (this.mask) {
        this.element.nativeElement.value = ctrl.value.replace(this.regex, this.mask);
      }

      return undefined;
    }

    return { phoneValidator: true };
  }
}