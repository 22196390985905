import { CodeTypeModel } from '../core/models/code-type';

import { EditComponent } from '../../app-lite/table/cell-components/actions/edit.component';

interface InspectionGroupsScope extends ng.IScope {
  groups       : Array<CodeTypeModel>;
  select       : ( row : any ) => void;
  tableSettings: any;
};

export function InspectionGroupsCtrl (
  $scope    : InspectionGroupsScope,
  $state    : ng.ui.IStateService,
  $translate: ng.translate.ITranslateService,
  groups    : Array<CodeTypeModel>
) {
  $scope.groups = groups;

  $scope.tableSettings = {
    columns: [
      {
        header: {
          text: $translate.instant('COMPANY_SPACE.SETTINGS.CODE')
        },
        field: 'code'
      },
      {
        header: {
          text: $translate.instant('GENERAL_SPACE.FIELD.DESCRIPTION')
        },
        field: 'description'
      },
      {
        component: {
          bindings: {
            outputs: {
              onEditClick: event => $scope.select(event)
            }
          },
          enabled: true,
          factory: EditComponent,
        },
        sortable: false,
      }
    ],
    empty: {
      text: $translate.instant('INSPECTIONS_SPACE.GROUPS.NO_INSPECTION_GROUPS_FOUND')
    },
    pagination: {
      enabled: false
    },
    rows: {
      highlight: {
        enabled: true
      }
    },
    sorting: {
      enabled: true
    },
  };

  $scope.select = function ( event : any ) {
    $state.go('app.inspection-group', {
      id: event.id || event.row.id
    });
  };
};