import {
  Component,
  Inject,
  Input,
  forwardRef,
  ChangeDetectorRef
} from '@angular/core';

import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgbActiveModal }     from '@ng-bootstrap/ng-bootstrap';

import { FlipperSidesModel }                      from '../shared/components/flipper/typings/flipper.sides';
import { MessagesServiceAjs }                     from '../messages/messages.service.ajs';
import { MotorAccountReference }                  from '../../app/motor/models/motor.account-reference';
import { PartstechEstimateModel }                 from './models/partstech.estimate';
import { PartstechGroupModel }                    from './models/partstech.group';
import { PartstechLaborEstimateActionsComponent } from './partstech.labor-estimate.actions.component';
import { PartstechService }                       from './partstech.service';
import { PartstechSubgroupModel }                 from './models/partstech.sub-group';
import { PartstechSystemModel }                   from './models/partstech.system';
import { Table }                                  from '../table/types/table';
import { VehicleModel }                           from '../vehicles/models/vehicle';

@Component({
  template: require('./partstech.add-labor.component.html')
})
export class PartstechAddLaborComponent {
  @Input() reference: MotorAccountReference;
  @Input() systems  : Array<PartstechSystemModel>;
  @Input() vehicle  : VehicleModel;

  @BlockUI('addParstechLaborFrontBlock') frontBlock : NgBlockUI;

  activeSide  : FlipperSidesModel = 'front';
  estimates   = [] as Array<PartstechEstimateModel>;
  group       = {} as PartstechGroupModel;
  groups      = [] as Array<PartstechGroupModel>;
  subGroups   = [] as Array<PartstechSubgroupModel>;
  subGroupIds = [] as Array<number>;
  system      = {} as PartstechSystemModel;

  tableSettings : Table = {
    columns: [
      {
        field  : 'operation_name',
        header : {
          text: this.$translate.instant('GENERAL_SPACE.FIELD.NAME')
        }
      },
      {
        field  : 'position.name',
        header : {
          text: this.$translate.instant('GENERAL_SPACE.FIELD.POSITION')
        }
      },
      {
        classes: 'text-center',
        field  : 'base_labor_time',
        header : {
          classes: 'text-center',
          text   : this.$translate.instant('GENERAL_SPACE.FIELD.BASE')
        }
      },
      {
        classes: 'text-center',
        field  : 'additional_labor_time',
        header : {
          classes: 'text-center',
          text   : this.$translate.instant('GENERAL_SPACE.FIELD.ADDITIONAL')
        }
      },
      {
        classes: 'text-center',
        field  : 'all_labor_time',
        header : {
          classes: 'text-center',
          text   : this.$translate.instant('GENERAL_SPACE.BUTTON.ALL')
        }
      },
      {
        component: {
          bindings: {
            outputs: {
              onSelectClick: event => this.selectEstimate(event)
            }
          },
          enabled: true,
          factory: PartstechLaborEstimateActionsComponent,
        },
        sortable: false,
      }
    ],
    empty: {
      text: this.$translate.instant('GENERAL_SPACE.STATUS.NO_RESULTS')
    },
    pagination: {
      enabled: false
    },
    sorting: {
      enabled: false
    },
    static: true
  };

  constructor (
    @Inject('$translate')
    public  $translate       : ng.translate.ITranslateService,
    public  activeModal      : NgbActiveModal,
    private changeDetectorRef: ChangeDetectorRef,
    private messagesService  : MessagesServiceAjs,
    @Inject(forwardRef(() => PartstechService))
    private partstechService : PartstechService
  ) {}

  changeSide ( side : FlipperSidesModel = 'back' ) : void {
    this.activeSide = side;

    this.changeDetectorRef.detectChanges();
  }

  getEstimates () : void {
    this.estimates = [];

    this.frontBlock.start();

    this.partstechService.getEstimates(this.system, this.group, this.subGroupIds, this.vehicle)
    .then(( estimates : Array<PartstechEstimateModel> ) => {
      this.estimates = estimates;

      this.changeSide('back');
    })
    .catch(err => {
      this.messagesService.error(err);
    })
    .finally(() => {
      this.frontBlock.stop();
    });
  }

  goBack () : void {
    this.estimates = [];

    this.changeSide('front');
  }

  selectEstimate ( estimate : PartstechEstimateModel ) : void {
    this.partstechService.addLaborItem(estimate, this.reference, this.system)
    .then(() => {
      this.messagesService.show(this.$translate.instant('PARTSTECH_SPACE.ITEM_ADDED'), 'success');
    })
    .catch(err => {
      this.messagesService.error(err);
    });
  }

  selectGroup ( group : PartstechGroupModel ) : void {
    this.estimates   = [];
    this.group       = group;
    this.subGroupIds = [];
    this.subGroups   = [];

    this.subGroups = this.partstechService.getSubGroups(group, this.groups);
  }

  selectSubGroup ( subGroup : PartstechSubgroupModel ) : void {
    this.estimates = [];

    if ( subGroup.selected ) {
      this.subGroupIds.push(subGroup.sub_group_id);
    }
    else {
      this.subGroupIds = this.subGroupIds.filter(id => id !== subGroup.sub_group_id);

      if (this.subGroupIds.length) {
        this.getEstimates();
      }
    }
  }

  selectSystem ( system : PartstechSystemModel ) : void {
    this.estimates   = [];
    this.group       = null;
    this.groups      = [];
    this.subGroupIds = [];
    this.subGroups   = [];
    this.system      = system;

    this.groups = this.partstechService.getGroups(system, this.systems);
  }
}