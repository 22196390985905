import { Component } from '@angular/core';

import { TableControlComponent } from '../table-control.component';
import { TableService          } from '../../../table.service';

@Component({
  styles: [ require('../table-control.component.scss'), require('./table-switch-control.component.scss') ],
  template: require('./table-switch-control.component.html')
})
export class TableSwitchControlComponent extends TableControlComponent {
  declare isOn: boolean;

  constructor (
    service: TableService
  ) {
    super(service);
  }
}