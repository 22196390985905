export function HotkeyDirective () : ng.IDirective {
  const directive : ng.IDirective = {
    controller: 'HotkeyCtrl',
    restrict  : 'A',
    scope     : {
      debounce: '<?',
      map     : '='
    }
  };

  return directive;
}
