import 'angular';

import { ProductCategoryModel } from './models/product-category';

interface ProductCategoriesScope extends ng.IScope {
  productCategories : Array<ProductCategoryModel>;
  crudGridAPI       : any;
  removeRow         : ( category : ProductCategoryModel, index : number ) => void;
  saveRow           : ( category : ProductCategoryModel, index : number ) => void;
}

export function ProductCategoriesCtrl (
  $scope     : ProductCategoriesScope,
  $translate : ng.translate.ITranslateService,
  crudGridAPI: any,
  messages   : any,
  workshopAPI: any,
  confirm    : any
) {
  $scope.crudGridAPI      = crudGridAPI();
  $scope.crudGridAPI.data = $scope.productCategories;

  $scope.removeRow = function ( category : ProductCategoryModel, index : number ) {
    confirm.generic($translate.instant('JS_SPACE.CONFIRM.DELETE_TEMPLATE'))
    .then(() => $scope.crudGridAPI.removeRow(category, index, '/product_category', 'id'))
    .then(() => {
      messages.show($translate.instant('JS_SPACE.MESSAGES.DELETED.PRODUCT_CATEGORY'), 'success');

      $scope.crudGridAPI.data.splice(index, 1);
    });
  };

  $scope.saveRow = function ( category : ProductCategoryModel, index : number ) {
    const method = category.id ? 'patch' : 'post';
    const url    = category.id ? '/product_category' : '/product_categories';

    workshopAPI[method](url, category, 'product_category')
    .then(( result : any ) => {
      angular.extend(category, result);

      $scope.crudGridAPI.toggleEditMode(category, index);

      messages.show($translate.instant('JS_SPACE.MESSAGES.SAVED.PRODUCT_CATEGORY'), 'success');
    })
    .catch(err => {
      messages.error(err);
    });
  };
};