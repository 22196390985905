import { ApiServiceAjs }        from '../api/api.service.ajs';
import { CustomerPaymentModel } from './models/customer-payment';

import CustomerPaymentMethodsHtml from './customer-payment.methods.html';

export class CustomerPaymentService {
  $uibModal: ng.ui.bootstrap.IModalService;
  api      : ApiServiceAjs

  constructor (
    $uibModal  : ng.ui.bootstrap.IModalService,
    workshopAPI: ApiServiceAjs
  ) {
    this.$uibModal = $uibModal;
    this.api       = workshopAPI;
  }

  getPayment ( id : string ) : ng.IPromise<CustomerPaymentModel> {
    return this.api.get('/customer_payment', id);
  }

  getPosPaymentTotal ( payment : CustomerPaymentModel, isRefund = false ) : string {
    const methodField = isRefund
    ? 'customer_refund_methods_attributes'
    : 'customer_payment_methods_attributes';

    let amount = BigDecimal.ZERO;

    const eftPaymentMethods = payment[methodField].filter(method => method.use_eftpos);

    if (eftPaymentMethods.length) {
      amount  = BigDecimal.ZERO;

      payment[methodField].forEach(method => {
        const lineAmount = new BigDecimal(method.amount.toString());

        if (method.use_eftpos) {
          amount = amount.add(lineAmount);
        }
      });
    }

    return amount.isZero() ? 0 : amount.toString();
  }

  viewPaymentMethods ( payment : CustomerPaymentModel ) : ng.IPromise<any> {
    return this.$uibModal.open({
      controller : 'CustomerPaymentMethodsCtrl',
      templateUrl: CustomerPaymentMethodsHtml,
      resolve    : {
        payment: () => payment
      }
    }).result;
  }
}
