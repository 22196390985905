export function TextConfirmationLiteCtrl ($scope, message, phrase) {
  $scope.message = message;
  $scope.phrase  = phrase;
  $scope.value   = '';

  $scope.confirm = function () {
    if ($scope.value === phrase) {
      $scope.$close();
    }
  };
}