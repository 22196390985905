import FileUploadHtml from './file-upload.html';

export function FileUploadDirective () : ng.IDirective {
  const directive : ng.IDirective = {
    bindToController: true,
    controller      : 'FileUploadCtrl',
    controllerAs    : '$ctrl',
    scope           : {
      options: '=options'
    },
    restrict   : 'E',
    templateUrl: FileUploadHtml
  };

  return directive;
}