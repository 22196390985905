export function AddGroupToMatrixLiteCtrl ($scope, $translate, groups) {
  $scope.groups        = groups;
  $scope.selectedItems = [];

  $scope.tableSettings = {
    columns: [
      {
        classes: 'text-left',
        field  : 'name',
        header :  {
          text: $translate.instant('PRODUCT_SPACE.PRODUCT_GROUP')
        }
      },
      {
        classes: 'text-left',
        field  : 'description',
        header :  {
          text: $translate.instant('GENERAL_SPACE.FIELD.DESCRIPTION')
        }
      }
    ],
    empty: {
      text: $translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_PRODUCT_GROUPS_FOUND')
    },
    pagination: {
      enabled: false
    },
    rows: {
      highlight: {
        enabled: true
      },
      select: {
        enabled: true,
        multi  : true
      }
    },
    sorting: {
      enabled: false
    },
    static: true
  };

  $scope.onRowClick = function () {
    $scope.selectedItems = $scope.groups.filter((row) => row.selected);
  };

  $scope.add = function () {
    $scope.$close($scope.selectedItems);
  }
}