export function CoreRemovedUntouchedService () {
  return {
    remove: function(items) {
      var touched = items.filter(function (item) {
        return !item.untouched;
      });

      return touched;
    }
  }
}