import * as moment from 'moment';

import '../../../vendor/bootstrap-datepicker/bootstrap-datepicker.min';

export function CoreDateDirective ($filter, dateFormat) {
  const _FORMATTER_REGEX = /^(\d{4})\/(\d{1,2})\/(\d{1,2})$/;
  const _PARSER_REGEX    = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;

  return {
    require: 'ngModel',
    scope  : {
      startDate: '='
    },
    link: function(scope, element, attrs, ctrl) {
      const _DATEFORMAT  = 'dd/mm/yyyy';
      const getStartDate = ( date ) => new Date(moment(date).utc());
      let   startDate    = '';

      // find the datepicker button and initialize it first
      const datePickerElm = element.prev('span')
      .datepicker({
        autoclose: true,
        format   : dateFormat(),
        startDate: startDate,
        todayBtn : 'linked'
      })
      .on('changeDate', e => {

        // Bootstrap datepicker is returning the wrong date
        // under specific daylight savings circumstances.
        // We add one hour to accommodate for this bug.
        e.date.setTime(e.date.getTime() + 1*60*60*1000);

        const date  = e.date.getDate();
        const month = e.date.getMonth() + 1;
        const year  = e.date.getFullYear();

        element.val(
          dateFormat()
          .replace('dd', date)
          .replace('mm', month)
          .replace('yyyy', year)
        );

        element.trigger('input');
      });

      ctrl.$parsers.push(value => {
        const blankVal = angular.isUndefined(attrs.filter)
          ? ''
          : '*';

        if (!value) {
          ctrl.$setValidity('date', true);

          return blankVal;
        }
        if (value.match(_PARSER_REGEX)) {
          ctrl.$setValidity('date', true);

          ctrl.$viewValue = value;

          ctrl.$render();

          if (!dateFormat() || dateFormat() === _DATEFORMAT) {
            value = value.replace(_PARSER_REGEX, '$2/$1/$3');
          }

          const fullDate = new Date(value);
          const date     = fullDate.getDate();
          const month    = fullDate.getMonth() + 1;
          const year     = fullDate.getFullYear();

          if ( startDate && startDate > fullDate ) {
            ctrl.$setValidity('date', false);

            return blankVal;
          }

          if (attrs.format) {
            return $filter('date')(fullDate, attrs.format);
          }
          else if (attrs.timeless) {
            return `${ year }-${ month < 10 ? `0${ month }` : month }-${ date < 10 ? `0${ date }` : date }`;
          }
          else {
            return fullDate.toISOString();
          }
        }
        else {
          ctrl.$setValidity('date', false);
          return blankVal;
        }
      });

      ctrl.$formatters.push(value => {
        if (!value) {
          return '';
        }

        value = value.replace(/-/g, '/');

        if (value.match(_FORMATTER_REGEX)) {
          if (dateFormat() === _DATEFORMAT) {
            return value.replace(_FORMATTER_REGEX, '$3/$2/$1');
          }
          else {
            return value.replace(_FORMATTER_REGEX, '$2/$3/$1');
          }
        }
        else {
          return '';
        }
      });

      scope.$watch('startDate', () => {
        const fullDate = new Date(element.val());

        if ( scope.startDate ) {
          if ( !dateFormat() || dateFormat() === _DATEFORMAT ) {
            scope.startDate = scope.startDate.replace(_PARSER_REGEX, '$2/$1/$3');
          }

          startDate = getStartDate(scope.startDate);
        }

        if ( startDate && startDate > fullDate ) {
          ctrl.$setValidity('date', false);
        }

        datePickerElm.datepicker('setStartDate', startDate);
      });
    }
  };
}