import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripeStatus',
})
export class StripeStatusPipe implements PipeTransform {
  transform( value : 'A' | 'C' | 'P' | null ) {
    const statuses = {
      A: 'Approved',
      C: 'Canceled',
      P: 'Pending'
    };

    return value ? statuses[value] : '';
  }
}