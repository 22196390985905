export function QuestionConfirmationLiteCtrl (
  $scope,
  icon,
  message,
  title
) {
  $scope.icon    = icon;
  $scope.message = message;
  $scope.title   = title;
}
