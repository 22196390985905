import { Directive, forwardRef, Input }               from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator }  from '@angular/forms';

@Directive({
  selector : '[worPasswordValidator]',
  providers: [
    {
      multi      : true,
      provide    : NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => PasswordValidator)
    }
  ]
})
export class PasswordValidator implements Validator {
  strengthPattern = new RegExp(/[A-Z]/);

  @Input ('worPasswordValidator') value: string;

  validate (ctrl: AbstractControl) : { [ key: string ] : any } {
    if (ctrl.value && ctrl.value.length < 8) {
      return { passwordLengthValidator: true };
    }

    if (ctrl.value && !this.strengthPattern.test(ctrl.value)) {
      return { passwordStrengthValidator: true };
    }

    return undefined;
  }
}