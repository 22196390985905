export function ResolveCustomerMatchesLiteCtrl ($scope, $translate, messages) {
  $scope.selectedItems = [];

  $scope.tableSettings = {
    columns: [
      {
        field : 'display_name',
        header: {
          text: $translate.instant('GENERAL_SPACE.FIELD.NAME')
        }
      },
      {
        field : 'phone',
        header: {
          text: $translate.instant('GENERAL_SPACE.FIELD.PHONE')
        },
        pipe: 'phone'
      },
      {
        field : 'mobile',
        header: {
          text: $translate.instant('GENERAL_SPACE.FIELD.MOBILE')
        },
        pipe: 'mobilePhone'
      },
      {
        field : 'email',
        header: {
          text: $translate.instant('GENERAL_SPACE.FIELD.EMAIL')
        }
      },
      {
        field : 'address1',
        header: {
          text: $translate.instant('GENERAL_SPACE.FIELD.ADDRESS')
        }
      },
      {
        field : 'address2',
        header: {
          text: $translate.instant('GENERAL_SPACE.FIELD.ADDRESS')
        }
      },
      {
        field : 'suburb',
        header: {
          text: $translate.instant('GENERAL_SPACE.FIELD.SUBURB')
        }
      },
      {
        field : 'state',
        header: {
          text: $translate.instant('GENERAL_SPACE.FIELD.STATE')
        }
      },
      {
        field : 'postcode',
        header: {
          text: $translate.instant('GENERAL_SPACE.FIELD.POSTCODE')
        }
      }
    ],
    empty: {
      text: $translate.instant('GENERAL_SPACE.STATUS.NO_RESULTS')
    },
    pagination: {
      enabled: false
    },
    rows: {
      highlight: {
        enabled: true
      },
      select: {
        enabled: true
      }
    },
    sorting: {
      enabled: false
    },
  };

  $scope.confirm = function () {
    $scope.customer
      .saveMatch($scope.selectedItems[0])
      .then(() => $scope.$close())
      .catch(err => messages.error(err));
  };

  $scope.selectMatch = function () {
    $scope.selectedItems = $scope.matches.filter(match => match.selected);
  };
}
