import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';

import { DndDropEvent } from 'ngx-drag-drop';
import { Subscription } from 'rxjs';

import { DEFAULTTABLESETTINGS } from './constants/default-table-settings.constant';
import { Table                } from './types/table';
import { TableCellComponent   } from './table-cell.component';
import { TableColumn          } from './types/table-column';
import { TableQueryEvent }      from './types/table-query-event';
import { TableQueryParameters } from './types/table-query-parameters';
import { TableRowEvent        } from './types/table-row-event';
import { TableService         } from './table.service';

@Component({
  selector   : 'wor-table',
  styles: [ require('./table.component.scss') ],
  template: require('./table.component.html')
})
export class TableComponent implements AfterViewInit, OnChanges, OnDestroy {
  // setter used to initialize table settings.
  @Input() set settings (_settings: Table) {
    this._settings = {
      broadcast : _settings?.broadcast ?? DEFAULTTABLESETTINGS.broadcast,
      cache     : _settings?.cache ?? DEFAULTTABLESETTINGS.cache,
      columns   : _settings?.columns ?? DEFAULTTABLESETTINGS.columns,
      empty     : Object.assign({}, DEFAULTTABLESETTINGS.empty,      _settings?.empty),
      pagination: Object.assign({}, DEFAULTTABLESETTINGS.pagination, _settings?.pagination),
      rows      : {
        classes  : _settings?.rows?.classes ?? DEFAULTTABLESETTINGS.rows.classes,
        add      : Object.assign({}, DEFAULTTABLESETTINGS.rows.add,       _settings?.rows?.add),
        component: Object.assign({}, DEFAULTTABLESETTINGS.rows.component, _settings?.rows?.component),
        delete   : Object.assign({}, DEFAULTTABLESETTINGS.rows.delete,    _settings?.rows?.delete),
        expand   : Object.assign({}, DEFAULTTABLESETTINGS.rows.expand,    _settings?.rows?.expand),
        highlight: Object.assign({}, DEFAULTTABLESETTINGS.rows.highlight, _settings?.rows?.highlight),
        reorder  : Object.assign({}, DEFAULTTABLESETTINGS.rows.reorder,   _settings?.rows?.reorder),
        select   : Object.assign({}, DEFAULTTABLESETTINGS.rows.select,    _settings?.rows?.select)
      },
      sorting: Object.assign({}, DEFAULTTABLESETTINGS.sorting, _settings?.sorting),
      static : _settings?.static ?? DEFAULTTABLESETTINGS.static,
      title  : _settings?.title ?? DEFAULTTABLESETTINGS.title,
      theme  : _settings?.theme
    };

    this.initPagination();
  }

  // setter used to initialize the table data.
  @Input() set data ( _data : Array<any> ) {
    /**
     * if the data comes back on a query empty, then we know
     * the previous page was indeed the last page. So, revert
     * the page and data and mark it as last page.
     */
    if (this.pagination
      && _data
      && !_data.length
      && this.page > 1
    ) {
      this.page--;

      this.initLastPage();
    }

    /**
     * if the data comes back less than the page size
     * then we know were on the last page.
     */
    else if (this.pagination
      && this._isGoingForward
      && _data
      && _data.length < this.pageSize
    ) {

      this._data = _data;

      this.initLastPage();
    }

    /**
     * after the initial data, we query one more than
     * the page size to see if there is another page.
     * At that point, if the data length matches the
     * page size then we know it is the last page.
     */
    else if (!this._isFirstDataChange
      && this.pagination
      && this._isGoingForward
      && _data
      && _data.length === this.pageSize
    ) {

      this._data = _data;

      this.initLastPage();
    }

    /**
     * once the data gets to the table component, we query one
     * more than the page size to determine if there are more
     * records. So when the data comes back, if it is one more
     * than the page size, pop off the last item to ensure
     * visible page size is correct.
     */
    else if (this.pagination
      && this._isGoingForward
      && _data
      && _data.length > this.pageSize
    ) {
      _data.pop();

      this._data    = _data;
      this.lastPage = false;
    }

    else {
      this._data    = _data;
      this.lastPage = false;
    }

    /**
     * WAU-234
     *
     * Sometimes, like in some modals, data isn't retrieved
     * before loading the table. It loads on controller load.
     * In these cases, the first pass of _data is undefined.
     * We wan't to ignore this case so only set the first
     * change flag if data is defined.
     */
    if (_data) {
      this._isFirstDataChange = false;
    }
  }

  // used to emit updated data after a change
  // has taken place.
  @Output() dataChange    : EventEmitter<Array<any>>      = new EventEmitter();

  // event emitted to fetch and update the
  // table data.
  @Output() query         : EventEmitter<TableQueryEvent> = new EventEmitter();

  // event emitted when a table row is added.
  @Output() rowAdd        : EventEmitter<null>            = new EventEmitter();

  // event emitted when a table row is clicked.
  @Output() rowClick      : EventEmitter<TableRowEvent>   = new EventEmitter();

  // event emitted when a table row is collapsed.
  @Output() rowCollapse   : EventEmitter<TableRowEvent>   = new EventEmitter();

  // event emitted when a table row is deleted.
  @Output() rowDelete     : EventEmitter<TableRowEvent>   = new EventEmitter();

  // event emitted when a table row is double clicked.
  @Output() rowDoubleClick: EventEmitter<TableRowEvent>   = new EventEmitter();

  // event emitted when a table row is expanded.
  @Output() rowExpand     : EventEmitter<TableRowEvent>   = new EventEmitter();

  // event emitted when a table row is selected.
  @Output() rowSelect     : EventEmitter<TableRowEvent>   = new EventEmitter();

  // event emitted when a table row is unselected.
  @Output() rowUnselect   : EventEmitter<TableRowEvent>   = new EventEmitter();

  @Output() settingsChange: EventEmitter<Table>           = new EventEmitter();

  @ViewChildren('tableCellRef') tableCellRefs: QueryList<TableCellComponent>;

  _broadcastListener : Function;

  // represents whether or not the table component is
  // currently making an asynchronous request.
  _busy      : boolean;

  // used to discern between single and double click events.
  _clickTimer: any;

  // represents the total number of records in the table.
  _count     : number;

  // the dataset used to populate the table.
  _data      : Array<any> = [];

  // used to determine if data is being set for first time.
  _isFirstDataChange = true;

  // used to determine if were paging forwards or backwards.
  _isGoingForward = true;

  // used to flag when there are no
  // more records to fetch.
  _lastPage  : boolean;

  // used for shift selecting
  _lastSelectedRowIndex : number;

  // service subscription reference so we can unsubscribe
  _serviceSubscription : Subscription;

  // settings used to define the table and
  // its behavior.
  _settings  : Table = DEFAULTTABLESETTINGS;

  get add () : boolean {
    return this._settings.rows.add.enabled;
  }

  // returns whether or not the table sorting
  // is in ascending order.
  get ascending () : boolean {
    return this._settings.sorting.direction === 'asc';
  }

  // returns the defined table columns.
  get columns () : Array<TableColumn> {
    return this._settings.columns;
  }

  // returns whether or not rows can be deleted.
  get delete () : boolean {
    return this._settings.rows.delete.enabled;
  }

  // returns the icon to use for row deletion.
  get deleteIcon () : string {
    return this._settings.rows.delete.icon;
  }

  // returns whether or not the table sorting
  // is in descending order.
  get descending () : boolean {
    return this._settings.sorting.direction === 'desc';
  }

  // returns whether or not dynamic components
  // are to be used for rows.
  get dynamicRow () : boolean {
    return this._settings.rows.component.enabled;
  }

  // returns component bindings to be used for
  // a dynamic table component.
  get dynamicRowComponentBindings () : object {
    return this._settings.rows.component.bindings;
  }

  // returns the component factory to be rendered
  // for a dynamic table component.
  get dynamicRowComponentFactory () : ComponentFactory<Component> {
    return this._settings.rows.component.factory;
  }

  // returns whether or not the table is empty.
  get empty () : boolean {
    return !this._data?.length;
  }

  // returns whether or not rows can be expanded.
  get expand () : boolean {
    return this._settings.rows.expand.enabled;
  }

  // returns the component factory to be rendered
  // for expanded rows.
  get expandedRowComponent () : ComponentFactory<Component> {
    return this._settings.rows.expand.factory;
  }

  // returns component bindings to be used for
  // a dynamic table component.
  get expandedRowComponentBindings () : object {
    return this._settings.rows.expand.bindings;
  }

  // returns whether or not the table is busy
  // with a query.
  get busy () : boolean {
    return this._busy;
  }

  // sets whether or not the table is busy
  // with a query.
  set busy ( busy : boolean ) {
    this._busy = busy;
  }

  // returns whether or not the current
  // page is 1.
  get firstPage () : boolean {
    return this._settings.pagination.page === 1;
  }

  // returns whether or not rows can be
  // highlighted.
  get highlight () : boolean {
    return this._settings.rows.highlight?.enabled;
  }

  // returns whether or not the current
  // page is the last page.
  get lastPage () : boolean {
    return this._lastPage;
  }

  // sets whether or not the current page
  // is the last page.
  set lastPage ( lastPage : boolean ) {
    this._lastPage = lastPage;
  }

  // returns whether or not multiple rows
  // can be selected.
  get multiSelect () : boolean {
    return this._settings.rows.select.multi;
  }

  // returns the record offset to be used in
  // table queries.
  get offset () : number {
    return this._settings.pagination.offset;
  }

  // sets the record offset to be used in
  // table queries.
  set offset ( offset : number ) {
    this._settings.pagination.offset = offset;
  }

  // returns the current page being used
  // for table pagination.
  get page () : number {
    return this._settings.pagination.page;
  }

  // sets the current page to be used for
  // table pagination.
  set page ( page : number ) {
    this._settings.pagination.page = page;
  }

  // returns the page size to be used for
  // table pagination.
  get pageSize () : number {
    return this._settings.pagination.pageSize;
  }

  // sets the page size to be used for
  // table pagination.
  set pageSize ( size : number ) {
    this._settings.pagination.pageSize = size;
  }

  // returns the page size options that can
  // be used for table pagination.
  get pageSizes () : Array<number> {
    return this._settings.pagination.pageSizes;
  }

  // returns whether or not pagination is
  // enabled for the table.
  get pagination () : boolean {
    return this._settings.pagination.enabled;
  }

  // returns whether or not reordering of
  // rows is enabled.
  get reorder () : boolean {
    return this._settings.rows.reorder.enabled;
  }

  // returns the identifying property of
  // data records to be used when handling
  // drag and drop events.
  get reorderIdentifier () : string {
    return this._settings.rows.reorder.identifier;
  }

  get static () : boolean {
    return this._settings.static;
  }

  // returns whether or not rows can be
  // selected.
  get select () : boolean {
    return this._settings.rows.select.enabled;
  }

  // returns the property to be used that
  // signifies whether or not a record is
  // selected.
  get selectIdentifier () : string {
    return this._settings.rows.select.identifier;
  }

  // returns whether or not sorting is
  // enabled for the table.
  get sorting () : boolean {
    return this._settings.sorting.enabled;
  }

  // returns the title of the table.
  get title () : string {
    return this._settings.title;
  }

  constructor (
    @Inject('$rootScope')
    private $rootScope        : any,
    private changeDetectorRef : ChangeDetectorRef,
    private service           : TableService,
  ) {}

  ngAfterViewInit () : void {

    // listen for specified event to be broadcast and
    // do a fresh query when fired.
    if (this._settings.broadcast) {
      this._broadcastListener = this.$rootScope.$on(this._settings.broadcast, ( event : any, filtering : any ) => {
        this.resetPagination();

        if (this.service.isFilterCachingEnabled() && this._settings.cache.enabled && this._settings.cache.key) {
          this.service.updateCachedFilters(this._settings.cache.key, filtering);
        }

        this.handleQuery();
      });
    }

    // Subscribe to the changes Subject which emits an
    // event whenever a table control component's model
    // has changed. Mark all table cell components as
    // ready for check. TODO: this should be made more
    // specific to only call markForCheck() on the
    // specific index from which the event originated.
    this._serviceSubscription = this.service
      .changes
      .subscribe(() => this.tableCellRefs.forEach(component => component.markForCheck()));
  }

  ngOnChanges ( changes : SimpleChanges ) : void {
    if (changes.data && !changes.data.isFirstChange()) {
      this.busy = false;
    }
  }

  ngOnDestroy () : void {
    if (this._broadcastListener) {
      this._broadcastListener();
    }

    if (this._serviceSubscription) {
      this._serviceSubscription.unsubscribe();
    }
  }

  getHeaderClasses ( column : TableColumn ) : Array<string> {
    let classes = [];

    if (this.sorting && this.isColumnSortable(column)) {
      classes.push('sortable');
    }
    if (this.ascending && this.isColumnSorted(column)) {
      classes.push('sorted--ascending');
    }
    if (this.descending && this.isColumnSorted(column)) {
      classes.push('sorted--descending');
    }

    if (column.header?.classes instanceof Array) {
      classes = classes.concat(column.header.classes);
    }
    else if (column.header?.classes) {
      classes.push(column.header.classes);
    }

    return classes;
  }

  getQueryParams () : TableQueryParameters {
    const params : TableQueryParameters = {};

    const pageSize = typeof this.pageSize === 'string'
      ? parseInt(this.pageSize)
      : this.pageSize;

    // init pagination params, if
    // applicable.
    if (this.pagination) {
      params.offset   = this.offset;
      params.pageSize = this._isGoingForward ? ( pageSize + 1 ) : pageSize;
    }

    // init sorting params, if
    // applicable.
    if (this.sorting) {
      params.direction = this._settings.sorting.direction;
      params.field     = this._settings.sorting.field;
    }

    return params;
  }

  handleDrop ( event : DndDropEvent ) : void {

    // get index of dragged record.
    const index = this._data.findIndex(record => record[this.reorderIdentifier] === event.data[this.reorderIdentifier]);

    if (index >= 0) {

      // remove the dragged record from its original index
      // and place it at the event index. If the record's
      // original index is less than the event index, we
      // subtract 1 to account for itself.
      this._data.splice(event.index > index ? (event.index - 1) : event.index, 0, this._data.splice(index, 1)[0]);
    }
  }

  handleFirstPageClick () : void {
    if (!this.firstPage) {
      this._isGoingForward = false;

      this.initPaginationPage();
      this.initPaginationOffset();
      this.handleQuery();
    }
  }

  handleHeaderClick ( column : TableColumn ) : void {

    // ensure sorting is enabled. The sortable property
    // on a column is optional, so we want to
    // explicitly check for false.
    if (this.sorting && column.sortable !== false) {

      // if selected field is already sorted,
      // toggle direction.
      if (this._settings.sorting.field === column.field) {
        this._settings.sorting.direction = this._settings.sorting.direction === 'asc'
          ? 'desc'
          : 'asc';
      }

      // init sorting direction and field.
      else {
        this._settings.sorting.direction = 'asc';
        this._settings.sorting.field = column.field;
      }

      if (this._settings.cache.enabled && this._settings.cache.key) {
        this.service.updateCachedSorting(this._settings.cache.key, this._settings.sorting);
      }

      // reset pagination params (if applicable).
      if (this.pagination) {
        this.resetPagination();
      }

      // fetch sorted data.
      this.handleQuery();
    }
  }

  handleLastPageClick () : void {
    if (!this.lastPage) {
      this._isGoingForward = true;
      this.busy            = true;

      this._settings.pagination.queryCount()
        .then(( result : number ) => {
          this._count = result;

          this.initLastPage();

          this.page = Math.floor(this._count / this.pageSize) + (this._count % this.pageSize ? 1 : 0);

          this.initPaginationOffset();
          this.handleQuery();
        })
        .finally(() => {
          this.busy = false;
        });
    }
  }

  handleNextPageClick () : void {
    if (!this.lastPage) {
      this._isGoingForward = true;

      this.page++;

      this.initPaginationOffset();
      this.handleQuery();
    }
  }

  handlePageSizeChange () : void {
    this.resetPagination();

    if (this._settings.cache.enabled && this._settings.cache.key) {
      this.service.updateCachedPaging(this._settings.cache.key, this._settings.pagination);
    }

    this.handleQuery();
  }

  handlePrevPageClick () : void {
    if (!this.firstPage) {
      this._isGoingForward = false;

      this.page--;

      this.initPaginationOffset();
      this.handleQuery();
    }
  }

  handleQuery () : void {
    this.busy = true;

    this.query.emit({ params: this.getQueryParams() });
  }

  handleRowAdd () : void {
    if (this.add) {
      this.rowAdd.emit();
    }
  }

  handleRowClick ( index : number, row : any, evt : PointerEvent ) : void {
    if (this._clickTimer) {
      clearTimeout(this._clickTimer);
    }

    this._clickTimer = setTimeout(() => {
      this.handleRowSingleClick(index, row, evt);
    }, 250);
  }

  handleRowDelete ( index : number, row : any, event: Event ) : void {
    event.stopPropagation();

    if (this.delete) {
      this.rowDelete.emit({ index, row });
    }
  }

  handleRowDoubleClick ( index : number, row : any ) : void {
    clearTimeout(this._clickTimer);

    this._clickTimer = undefined;

    // emit row double click event.
    this.rowDoubleClick.emit({ index, row });
  }

  handleRowExpand ( index : number, row : any ) : void {
    if (this.expand) {

      // if row is being expanded, collapse all
      // others and emit applicable event(s).
      if (!row.expanded) {
        this._data.forEach(( item : any, jIndex : number ) => {
          if (item.expanded) {
            item.expanded = false;

            this.rowCollapse.emit({ index: jIndex, row: item });
          }
        });
      }

      row.expanded = !row.expanded;

      // emit applicable row expand/collapse event.
      this[row.expanded ? 'rowExpand' : 'rowCollapse'].emit({ index, row });

      // Change Detector invoked to update the changes to the digest cycle
      this.changeDetectorRef.detectChanges();
    }
  }

  handleRowSelect ( index : number, row : any, evt : PointerEvent ) : void {
    if (this.select && !row.unselectable) {

      // if row is being selected and multi select
      // is disabled, unselect all others and emit
      // applicable event(s).
      if (!this.multiSelect && !row[this.selectIdentifier]) {
        this._data.forEach(( item : any, jIndex : number ) => {
          if (item[this.selectIdentifier]) {
            item[this.selectIdentifier] = false;

            this.rowUnselect.emit({ index: jIndex, row: item });
          }
        });
      }
      /**
       * If multi-select is enabled, the shift key was pressed,
       * and the row is about to be "selected", then we want
       * to select all the rows in between the one just clicked
       * and the last row that was selected.
       *
       * REFERENCE: WOR-4065
       */
      else if (
        this.multiSelect
        && evt.shiftKey
        && !row[this.selectIdentifier]
        && this._lastSelectedRowIndex >= 0
      ) {
        if (this._lastSelectedRowIndex < index) {
          for (let i = this._lastSelectedRowIndex; i <= index; i++) {
            this._data[i][this.selectIdentifier] = true;

            this.rowSelect.emit({
              index: i,
              row  : this._data[i]
            });
          }
        }
        else if (this._lastSelectedRowIndex > index) {
          for (let i = index; i <= this._lastSelectedRowIndex; i++) {
            this._data[i][this.selectIdentifier] = true;

            this.rowSelect.emit({
              index: i,
              row  : this._data[i]
            });
          }
        }

        this._lastSelectedRowIndex = index;

        return;
      }

      row[this.selectIdentifier] = !row[this.selectIdentifier];

      // emit applicable row select/unselect event.
      this[row[this.selectIdentifier] ? 'rowSelect' : 'rowUnselect'].emit({ index, row });

      /**
       * set the last selected row index to the row that was
       * selected. If the row is being unselected, then reset
       * the variable.
       */
      this._lastSelectedRowIndex = row[this.selectIdentifier]
        ? index
        : null;
    }
  }

  handleRowSingleClick ( index : number, row : any, evt : PointerEvent ) : void {
    if (this._clickTimer) {

      // handle row expansion.
      this.handleRowExpand(index, row);

      // handle row selection.
      this.handleRowSelect(index, row, evt);

      // emit row click event.
      this.rowClick.emit({ index, row });
    }
  }

  initLastPage () : void {
    this.lastPage = true;
  }

  initPagination () : Promise<null> {
    if (this.pagination) {
      this.initPaginationPage();
      this.initPaginationOffset();
    }

    return Promise.resolve(null);
  }

  initPaginationOffset () : void {
    this.offset = (this.page - 1) * this.pageSize;
  }

  initPaginationPage () : void {
    this.page = 1;
  }

  isColumnHidden ( column : TableColumn, row : any ) : boolean {
    return column.hideIf && column.hideIf(row);
  }

  isColumnSortable ( column : TableColumn ) : boolean {
    return column.sortable !== false;
  }

  isColumnSorted ( column : TableColumn ) : boolean {
    return this.sorting && this._settings.sorting.field === column.field;
  }

  isTableColored () : boolean {
    return this._settings.theme === 'colored';
  }

  refresh () : any {

    // if pagination is enabled, first initialize
    // its settings. Then refresh the table data.
    this.initPagination().then(() => this.handleQuery());
  }

  resetPagination () : void {
    this._isGoingForward = true;

    this.initPagination();
  }
}