import 'angular';

import './components/components.module.ajs';
import './controls/controls.module.ajs';
import './directives/directives.module.ajs';

angular.module('app-lite.shared', [
  'app-lite.shared.components',
  'app-lite.shared.controls',
  'app-lite.shared.directives'
]);