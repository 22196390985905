import AlertModalHtml   from './modal.alert.html';
import CaptureModalHtml from './modal.capture.html';
import NoteModalHtml    from './modal.note.html';
import SyncModalHtml    from './modal.sync.html';

import { AlertModalOptionsModel }   from './models/modal.alert.options';
import { CaptureModalOptionsModel } from './models/modal.capture.options';
import { NoteModalOptionsModel }    from './models/modal.note.options';
import { SyncModalOptionsModel }    from './models/modal.sync.options';

export class ModalService {
  $uibModal: ng.ui.bootstrap.IModalService;

  constructor(
    $uibModal  : ng.ui.bootstrap.IModalService,
  ) {
    this.$uibModal = $uibModal;
  }

  addNote ( options : NoteModalOptionsModel ) : ng.IPromise<any> {
    return this.$uibModal.open({
      controller: 'NoteModalCtrl',
      resolve   : {
        options: () => options
      },
      templateUrl: NoteModalHtml,
      windowClass: 'lg-modal'
    }).result;
  };

  alert ( options : AlertModalOptionsModel ) : ng.IPromise<any> {
    return this.$uibModal.open({
      controller: 'AlertModalCtrl',
      resolve   : {
        message: () => options.message,
        title  : () => options.title
      },
      templateUrl: AlertModalHtml,
      windowClass: 'lg-modal'
    }).result;
  };

  capture ( options : CaptureModalOptionsModel ) : ng.IPromise<string> {
    return this.$uibModal.open({
      controller: 'CaptureModalCtrl',
      resolve   : {
        capture: () => options.capture,
        title  : () => options.title
      },
      templateUrl: CaptureModalHtml
    }).result;
  };

  sync ( options : SyncModalOptionsModel ) : ng.IPromise<any> {
    return this.$uibModal.open({
      controller: 'SyncModalCtrl',
      resolve   : {
        options: () => options
      },
      templateUrl: SyncModalHtml
    }).result;
  };
};
