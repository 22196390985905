import { ApiServiceAjs }      from '../../api/api.service.ajs';
import { ConfirmService }     from '../../messages/confirm.service.ajs';
import { MessagesServiceAjs } from '../../messages/messages.service.ajs';

export class PrintJobsCtrl {
  date = '';

  constructor (
    private $filter     : ng.FilterFactory,
    private $q          : ng.IQService,
    private $sce        : ng.ISCEService,
    private $translate  : ng.translate.ITranslateService,
    private $window     : ng.IWindowService,
    private confirm     : ConfirmService,
    private messages    : MessagesServiceAjs,
    private workshopAPI : ApiServiceAjs
  ) {}

  printJobsForDate () : void {
    this.confirm.generic(this.$translate.instant('JS_SPACE.CONFIRM.PRINT_JOB_CARDS_FOR_DATE', {
      date: this.$filter('globalDate')(this.date, 'longDate')
    }))
    .then(() => this.workshopAPI.get('/invoices/get_jobs_for_today', this.$filter('globalDate')(this.date, 'yyyy-MM-dd')))
    .then(response => {
      if (response.invoices.marine_invoices.length && !response.invoices.other_invoices.length) {
        return this.workshopAPI.post('/reports/jasper_job_card_bulk', {
          invoice_id_string: response.invoice_id_string.marine,
          invoices         : response.invoices.marine_invoices,
          job_card_type    : 'marine'
        }, 'print', null, { responseType: 'arraybuffer' });
      }
      else if (response.invoices.other_invoices.length && !response.invoices.marine_invoices.length) {
        return this.workshopAPI.post('/reports/jasper_job_card_bulk', {
          invoice_id_string: response.invoice_id_string.no_marine,
          invoices         : response.invoices.other_invoices,
          job_card_type    : 'default'
        }, 'print', null, { responseType: 'arraybuffer' });
      }

      return this.$q.all([
        this.workshopAPI.post('/reports/jasper_job_card_bulk', {
          invoice_id_string: response.invoice_id_string.marine,
          invoices         : response.invoices.marine_invoices,
          job_card_type    : 'marine'
        }, 'print', null, { responseType: 'arraybuffer' }),

        this.workshopAPI.post('/reports/jasper_job_card_bulk', {
          invoice_id_string: response.invoice_id_string.no_marine,
          invoices         : response.invoices.other_invoices,
          job_card_type    : 'default'
        }, 'print', null, { responseType: 'arraybuffer' })
      ]);
    })
    .then(response => {
      response = angular.isArray(response) ? response : [ response ];

      angular.forEach(response, pdf => {
        const file    = new Blob([pdf], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);

        this.$window.open(this.$sce.trustAsResourceUrl(fileURL), '_blank');
      });
    })
    .catch(err => {
      this.messages.error(err);
    });
  }
}
