export function SessionRegexCodeService (sessionObjAPI){
  return {
    mobile () {
      const validation = sessionObjAPI.countryValidation();

      if (validation.mobile && validation.mobile.validation) {
        return validation.mobile.validation;
      }
      else if (validation.phone && validation.phone.validation) {
        return validation.phone.validation;
      }
      else {
        return '^\\d+$';
      }
    },
    phone () {
      const validation = sessionObjAPI.countryValidation();

      if (validation.phone && validation.phone.validation) {
        return validation.phone.validation;
      }
      else {
        return '^\\d+$';
      }
    },
  };
}
