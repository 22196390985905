import { ApiServiceAjs }     from '../../app-lite/api/api.service.ajs';
import { CodeTypeModel }     from '../core/models/code-type';
import { SessionServiceAjs } from '../../app-lite/sessions/session.service.ajs';

import ActivateHtml                   from './chargify.activate.html';
import ExportChargifyCancelationsHtml from './chargify.export-cancelations.html';

export class ChargifyServiceAjs {
  $uibModal  : ng.ui.bootstrap.IModalService;
  account    : any;
  sessionApi : SessionServiceAjs;
  workshopAPI: ApiServiceAjs;

  constructor (
    $uibModal    : ng.ui.bootstrap.IModalService,
    sessionObjAPI: SessionServiceAjs,
    workshopAPI  : ApiServiceAjs
  ) {
    this.$uibModal   = $uibModal;
    this.sessionApi  = sessionObjAPI;
    this.workshopAPI = workshopAPI;

    this.account = this.sessionApi.company();
  }

  activate () : ng.IPromise<any> {
    return this.$uibModal.open({
      controller : 'ActivateChargifyCtrl',
      keyboard   : false,
      templateUrl: ActivateHtml,
      windowClass: 'lg-modal',
      resolve    : {
        countries: [ 'workshopAPI', ( workshopAPI : ApiServiceAjs ) => workshopAPI.get('/system/country_types', null, 'countries', { bypass: true })],
        quotes   : [ 'workshopAPI', ( workshopAPI : ApiServiceAjs ) => workshopAPI.get('/chargify/quote_all_for_activation')]
      }
    }).result;
  }

  exportCancelations () : ng.IPromise<any> {
    return this.$uibModal.open({
      controller : 'ExportChargifyCancelationsCtrl',
      templateUrl: ExportChargifyCancelationsHtml
    }).result;
  }

  getRate ( value : string ) : string {
    const symbol = (
      this.account.subscription_subdomain === 'workshop-software-uk' ||
      this.account.subscription_subdomain === 'ari-uk'
    ) ? '£' : '$';

    try {
      const rate   = new BigDecimal(value);
      const twelve = new BigDecimal('12');

      return ` (${ symbol }${ rate.divide(twelve).toString() } x 12)`;
    }
    catch (error) {
      return '';
    }
  }

  getStates ( country : string ) : ng.IPromise<Array<CodeTypeModel>> {
    return this.workshopAPI.get('/system/states_for_country', country, null, {
      bypass: true
    });
  }

  status ( status : string ) : boolean {
    return this.account.subscription_status
      && this.account.subscription_status.toLowerCase() === status.toLowerCase();
  }

}
