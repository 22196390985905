import { NgModule }     from '@angular/core';

import { DynamicTableComponent }    from './dynamic-table.component';
import { GlobalCurrencyPipe }       from '../shared/pipes/global-currency.pipe';
import { GlobalDatePipe }           from '../shared/pipes/global-date.pipe';
import { PropertyPipe }             from '../shared/pipes/property.pipe';
import { SharedModule }             from '../shared/shared.module';
import { TableCellComponent }       from './table-cell.component';
import { TableCellLinkComponent }   from './table-cell.link.component';
import { TableCellTruthyComponent } from './table-cell.truthy.component';
import { TableComponent }           from './table.component';
import { TableCellSelectComponent } from './table-cell.select.component';


@NgModule({
  declarations: [

    // Components
    DynamicTableComponent,
    TableCellComponent,
    TableCellLinkComponent,
    TableCellSelectComponent,
    TableCellTruthyComponent,
    TableComponent,

    // Pipes
    PropertyPipe
  ],
  entryComponents: [
    TableCellLinkComponent,
    TableCellSelectComponent,
    TableCellTruthyComponent
  ],
  exports: [

    // Components
    DynamicTableComponent,
    TableCellComponent,
    TableCellLinkComponent,
    TableCellSelectComponent,
    TableCellTruthyComponent,
    TableComponent,

    // Pipes
    PropertyPipe
  ],
  imports: [
    SharedModule
  ],
  providers: [
    PropertyPipe,

    // Pipes must also be provided via string
    // if they are going to be used in a table
    // column. This allows the injector to be
    // able to load a pipe dynamically in the
    // TableCellComponent.
    {
      provide : 'globalCurrency',
      useClass: GlobalCurrencyPipe
    },
    {
      provide : 'globalDate',
      useClass: GlobalDatePipe
    }
  ]
})
export class TableModule { }