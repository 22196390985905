import { CurrencyPipe }        from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { SessionServiceAjs } from '../../sessions/session.service.ajs';

@Pipe({
  name: 'globalCurrency'
})
export class GlobalCurrencyPipe implements PipeTransform {

  constructor(
    private currencyPipe     : CurrencyPipe,
    private sessionServiceAjs: SessionServiceAjs
  ) {}

  transform( value : any, rounding : number, info : any = this.sessionServiceAjs.currency() ) : string {
    let maskedValue = this.currencyPipe.transform(value, undefined, info.symbol || info.currency);

    if (typeof value === 'number' && value !== 0) {
      value = value.toString();
    }

    if (rounding && maskedValue) {
      maskedValue = `${info.symbol || info.currency}${ parseFloat(value).toFixed(rounding) }`;
    }

    if (info.no_currency_fractions && maskedValue) {
      maskedValue = maskedValue.substring(0, maskedValue.indexOf('.'));
    }

    return maskedValue
      ? maskedValue.replace(/[\.,]/g, (m: string) => m == '.' ? info.decimal_mark : info.thousands_separator)
      : '';
  }
}
