export function CoreCurrencyInfoService (sessionObjAPI) {
  return function() {
    var sessionObj = sessionObjAPI.base();
    var currency = {
      symbol: sessionObj.country_settings.currency,
      sales_tax_rate: sessionObj.company_settings.sales_tax_rate,
      purchases_tax_rate: sessionObj.company_settings.purchases_tax_rate,
      tax_name: sessionObj.company_settings.tax_name ? sessionObj.company_settings.tax_name: 'GST',
      price_includes_tax: sessionObj.company_settings.price_includes_tax,
      round_total: sessionObj.company_settings.round_total
    }
    return currency
  }
}