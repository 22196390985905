export function AddPriceMatrixLiteCtrl ($scope, workshopAPI, messages) {
  $scope.save = function (form) {
    workshopAPI.post('/price_matrices', $scope.matrix, 'price_matrix').then(matrices => {
      form.$setPristine();

      $scope.$close({
        matrices,
        matrix: $scope.matrix
      });
    }).catch(err => {
      messages.error(err, form);
    });
  };

  workshopAPI.get('/system/price_matrix_types/', null, 'price_matrix_types').then(types => {
    $scope.price_matrix_types = types;
  });
}