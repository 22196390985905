import { Injectable } from '@angular/core';

import { ApiService }       from '../core/api/api.service';
import { UserAccountModel } from '../users/models/user.account';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {
  constructor(
    private apiService: ApiService
  ) {}

  requestReset ( account : UserAccountModel ) : ng.IPromise<boolean> {
    return this.apiService.post('/customer_portal_users/request_reset_email', {
      email: account.username
    });
  }

  reset ( account : UserAccountModel ) : ng.IPromise<boolean> {
    return this.apiService.post('/customer_portal_users/reset_password', {
      email   : account.username,
      password: account.password,
      token   : account.token
    });
  }
};
