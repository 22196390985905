import { Component, Input } from '@angular/core';

import { JobModel }               from './models/job';
import { JobsService }            from './jobs.service';
import { JobStatusCellComponent } from './job.status.cell.component';
import { TableCountModel }        from '../table/models/table-count';
import { TableModel }             from '../table/models/table';
import { TableQueryModel }        from '../table/models/table-query';

@Component({
  selector   : 'wor-jobs',
  template: require('./jobs.component.html')
})
export class JobsComponent {
  @Input () jobs: Array<JobModel>;

  tableSettings : TableModel = {
    columns: [
      {
        field : 'job_card_number',
        header: {
          title: 'Job #'
        }
      },
      {
        field : 'plate_number',
        header: {
          title: 'Vehicle'
        }
      },
      {
        field : 'description',
        header: {
          title: 'Description'
        }
      },
      {
        classes  : [ 'text-right' ],
        component: JobStatusCellComponent
      }
    ],
    empty: {
      message: 'No Open Jobs'
    },
    pagination: this.jobsService.defaultPagination,
    rows      : {
      select: {
        enabled : true,
        property: 'active'
      }
    },
    title: 'Open Jobs'
  };

  constructor (
    private jobsService: JobsService
  ) {}

  getJobCount () : ng.IPromise<TableCountModel> {
    return this.jobsService.getJobCount();
  }

  getJobs ( params : TableQueryModel ) : ng.IPromise<Array<JobModel>> {
    return this.jobsService.getJobs(params.offset, params.pageSize);
  }
}
