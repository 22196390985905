export function CoreMaxLengthDirective () : ng.IDirective {
  const directive : ng.IDirective = {
    require : 'ngModel',
    restrict: 'A',
    link (
      scope  : ng.IScope,
      element: ng.IRootElementService,
      attrs  : ng.IAttributes,
      ctrl   : ng.INgModelController
    ) {
      const maxlength = Number(attrs.mdsMaxLength);

      ctrl.$parsers.push(value => {
        if (!value) {
          ctrl.$setValidity('maxlength', true);

          return value;
        }

        ctrl.$setValidity('maxLength', value.length <= maxlength);

        return value;
      });
    }
  };

  return directive;
};

