import { NgModule } from '@angular/core';

import { PhoneValidator }    from './phone.validator';
import { PostcodeValidator } from './postcode.validator';

@NgModule({
  declarations: [
    PhoneValidator,
    PostcodeValidator
  ],
  exports: [
    PhoneValidator,
    PostcodeValidator
  ]
})
export class ValidatorsModule {}
