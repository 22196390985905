import widthFunctions from '../../../vendor/simpliq-theme/widthFunctions';

export function CoreBoxTogglerDirective ($timeout) {
  return {
    restrict: 'A',
    scope: {
      boxContent: '@',
      isOpen: '=?'
    },
    link: function (scope, element, attrs) {
      scope.open = function (skipAnimation) {
        element.find('#chevron').addClass('fa-chevron-down')
          .removeClass('fa-chevron-up');

        $timeout(function () {
          if (skipAnimation) {
            $(scope.boxContent || '.box-content').show();
            return;
          }

          $(scope.boxContent || '.box-content').slideToggle(200, function() {
            widthFunctions();
            $(scope.boxContent).find('.grid').resize();
          });
        });
      };

      scope.close = function (skipAnimation) {
        element.find('#chevron').addClass('fa-chevron-up')
          .removeClass('fa-chevron-down');

        $timeout(function () {
          if (skipAnimation) {
            $(scope.boxContent || '.box-content').hide();
            return;
          }

          $(scope.boxContent || '.box-content').slideToggle(200, function() {
            widthFunctions();
            $(scope.boxContent).find('.grid').resize();
          });
        });
      };

      scope.toggle = function () {
        scope.isOpen = !scope.isOpen;

        if (!scope.$$phase) {
          scope.$apply();
        }
      }

      element.on('click', scope.toggle);

      scope.$watch('isOpen', function (newVal, oldVal) {
        if (newVal !== oldVal) {
          if (newVal) {
            scope.open();
          }
          else {
            scope.close();
          }
        }
      });

      if (angular.isUndefined(scope.isOpen)) {
        scope.isOpen = true;
      }
      else if (scope.isOpen) {
        scope.open(true);
      }
      else {
        scope.close(true);
      }
    }
  }
}