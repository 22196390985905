import { ApiServiceAjs } from '../../../app-lite/api/api.service.ajs';

function apiServiceFactory ( i: any ) {
  return i.get('workshopAPI');
};

export const apiServiceProvider = {
  deps      : ['$injector'],
  provide   : ApiServiceAjs,
  useFactory: apiServiceFactory
};