import { ApiServiceAjs }        from '../api/api.service.ajs';
import { CompanySettingsModel } from '../companies/models/company.settings';
import { SessionServiceAjs }    from '../sessions/session.service.ajs';

export class CustomerPortalService {
  $translate: ng.translate.ITranslateService;
  api       : ApiServiceAjs;
  confirm   : any;
  sessionApi: SessionServiceAjs;

  constructor (
    $translate   : ng.translate.ITranslateService,
    confirm      : any,
    sessionObjAPI: SessionServiceAjs,
    workshopAPI  : ApiServiceAjs
  ) {
    this.$translate = $translate;
    this.api        = workshopAPI;
    this.confirm    = confirm;
    this.sessionApi = sessionObjAPI;
  }

  activate () : ng.IPromise<boolean> {
    return this.confirm.generic(this.$translate.instant('JS_SPACE.INTEGRATIONS.ACTIVATE', {
      name: this.$translate.instant('CUSTOMER_SPACE.CUSTOMER_PORTAL.TITLE')
    }))
    .then(() => this.api.patch('/company_settings', { has_customer_portal: true }, 'company_setting'))
    .then(() => this.sessionApi.refresh());
  }

  deactivate () : ng.IPromise<boolean> {
    return this.confirm.generic(this.$translate.instant('JS_SPACE.INTEGRATIONS.DEACTIVATE', {
      name: this.$translate.instant('CUSTOMER_SPACE.CUSTOMER_PORTAL.TITLE')
    }))
    .then(() => this.api.patch('/company_settings', { has_customer_portal: false }, 'company_setting'))
    .then(() => this.sessionApi.refresh());
  };

  getSettings () : ng.IPromise<CompanySettingsModel> {
    return this.api.get('/company_settings');
  }
}