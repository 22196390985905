import { AttachmentModel } from './models/attachment';

interface ImageViewerScope extends ng.IScope {
  image : AttachmentModel;
};

export function ImageViewerCtrl (
  $scope : ImageViewerScope,
  image  : AttachmentModel
) {
  $scope.image = image;
};
