import '../../vendor/jquery-noty/jquery.noty.min';
import '../../vendor/jquery-noty/jquery.noty.css';
import '../../vendor/jquery-noty/noty_theme_default.css';

import { SessionServiceAjs } from '../sessions/session.service.ajs';

interface ScopeWithForm extends ng.IScope {
  form : ng.IFormController;
}

export class MessagesServiceAjs {
  $rootScope   : any;
  $window      : ng.IWindowService;
  sessionObjAPI: SessionServiceAjs;
  toProperCase : any;

  messageSelector = '#message.app-notification';

  constructor (
    $rootScope   : any,
    $window      : ng.IWindowService,
    sessionObjAPI: SessionServiceAjs,
    toProperCase : any
  ) {
    this.$rootScope    = $rootScope;
    this.$window       = $window;
    this.sessionObjAPI = sessionObjAPI;
    this.toProperCase  = toProperCase;
  }

  _getDefaultForm () : ng.IFormController {
    const formElement = angular.element('form');

    return formElement.length
      ? ( formElement.scope() as ScopeWithForm ).form
      : null;
  }

  clientError ( form : ng.IFormController ) : void {
    let isFirstLoop = true;

    for (const type in form.$error) {
      if (angular.isArray(form.$error[type])) {
        form.$error[type].forEach(error => {
          if (form[error.$name].$setViewValue) {
            form[error.$name].$setViewValue(form[error.$name].$viewValue);
            form[error.$name].$setDirty();
          }
          else {
            this.clientError(form[error.$name]);

            return;
          }

          if (isFirstLoop) {
            $('#' + error.$name).trigger('focus');
          }

          isFirstLoop = false;
        });
      }
    }

    if (isFirstLoop) {
      $(this.messageSelector).noty({
        closeWith: [ 'click' ],
        text     : '<div class="msgClose"><i class="fa fa-times"></i></div>Please Fix Errors',
        type     : 'error'
      });
    }
  }

  error ( data : any, form : ng.IFormController = this._getDefaultForm() ) : void {
    // For calls that require loading images, the property is set
    // to false so that the button reappears
    this.$rootScope.errorStatusCode = undefined;
    this.$rootScope.saving          = false;

    if (angular.isString(data)) {
      console.error(data);

      $(this.messageSelector).noty({
        closeWith   : ['click'],
        dismissQueue: true,
        force       : true,
        killer      : true,
        maxVisible  : 5,
        text        : `<div class="msgClose"><i class="fa fa-times"></i></div>${ this.toProperCase(data) }`,
        timeout     : false,
        type        : 'error'
      });

      return;
    }

    if (data && data.message !== undefined) {
      // All field level errors.
      if (data.type === 'F') {
        let firstLoop = true;

        for (const property in data.field_errors) {
          // If there is a matching element to bind to, then
          // display the error there.
          if ($(`#${ property }`).length) {
            const control = form[property];

            control.serverError = true;
            control.serverMessage = this.toProperCase(data.field_errors[property][0]);

            $(`#${ property }`)
            .removeClass('ng-pristine')
            .addClass('ng-dirty');

            $(`#${ property }`)
            .removeClass('ng-valid')
            .addClass('ng-invalid');

            $(`#${ property }_label`)
            .removeClass('ng-show')
            .addClass('ng-hide');

            if (firstLoop) {
              $(`#${ property }`).focus();
            }

            firstLoop = false;

            $(`#${ property }`).on('focus', () => {
              control.serverError = false;

              $(`#${ property }_label`)
              .removeClass('ng-hide')
              .addClass('ng-show');

              $(`#${ property }`).unbind("focus");
            });

            $(`#${ property }`).on('keypress', () => {
              control.serverError = false;

              $(`#${ property }_label`)
              .removeClass('ng-hide')
              .addClass('ng-show');

              $(`#${ property }`).unbind("keypress");
            });
          }
          // If not then the errors are displayed on the top in a list
          else {
            let message = data.message;

            for ( const property in data.field_errors ) {
              for ( let i = 0; i < data.field_errors[property].length; i++ ) {
                if (property === 'base') {
                  message = data.message.concat(`<br>${data.field_errors[property][i]}<br>`);
                }
                else {
                  message = data.message.concat(`<br>${property}: ${data.field_errors[property][i]} <br>`);
                }
              }
            }

            $(this.messageSelector).noty({
              closeWith   : ['click'],
              dismissQueue: true,
              force       : true,
              killer      : true,
              maxVisible  : 5,
              text        : `<div class="msgClose"><i class="fa fa-times"></i></div>${ this.toProperCase(message) }`,
              timeout     : false,
              type        : 'error'
            });
          }
        }
      }
      // If it is a session error, then they must be logged out
      else if (data.type === 'X') {
        this.$window.location.assign('login.html');

        this.sessionObjAPI.clear();

        this.sessionObjAPI.setKey('error_msg', data.message);
      }
      // If it is an unauthorized error, display it on top,
      // but only once in case there are multiple calls on
      // the same page
      else if (data.type === 'I') {
        console.log(data.message);

        if (!$(this.messageSelector).find('.noty_error').length) {
          $(this.messageSelector).noty({
            closeWith   : ['click'],
            dismissQueue: true,
            force       : true,
            killer      : true,
            maxVisible  : 5,
            text        : `<div class="msgClose"><i class="fa fa-times"></i></div>${ this.toProperCase(data.message) }`,
            timeout     : false,
            type        : 'error',
          });
        }
      }
      // If it is a generic global error, then display it on top
      else if (data.type === 'G') {
        console.log(data.message);

        $(this.messageSelector).noty({
          closeWith   : ['click'],
          dismissQueue: true,
          force       : true,
          killer      : true,
          maxVisible  : 5,
          text        : `<div class="msgClose"><i class="fa fa-times"></i></div>${ this.toProperCase(data.message) }`,
          timeout     : false,
          type        : 'error',
        });
      }
      // Anything else is unknown, i.e. server error or ruby error
      // so log it in the console but just display a generic message
      else {
        console.log(data.message);

        if (!$(this.messageSelector).find('.noty_error').length) {
          $(this.messageSelector).noty({
            closeWith   : ['click'],
            dismissQueue: true,
            force       : true,
            maxVisible  : 5,
            text        : '<div class="msgClose"><i class="fa fa-times"></i></div>Unknown Error, please contact support (support@workshopsoftware.com) for further details.',
            timeout     : false,
            type        : 'error',
          });
        }
      }

    }
    else if (data && data.data && data.data.message) {
      if (data.data.type === 'G') {
        console.log(data.data.message);

        $(this.messageSelector).noty({
          closeWith   : ['click'],
          dismissQueue: true,
          force       : true,
          killer      : true,
          maxVisible  : 5,
          text        : `<div class="msgClose"><i class="fa fa-times"></i></div>${ this.toProperCase(data.data.message) }`,
          timeout     : false,
          type        : 'error',
        });
      }
      else {
        console.log(data.message);

        if (!$(this.messageSelector).find('.noty_error').length) {
          $(this.messageSelector).noty({
            closeWith   : ['click'],
            dismissQueue: true,
            force       : true,
            maxVisible  : 5,
            text        : '<div class="msgClose"><i class="fa fa-times"></i></div>Unknown Error, please contact support (support@workshopsoftware.com) for further details.',
            timeout     : false,
            type        : 'error',
          });
        }
      }
    }
    else if (!data) {
      return;
    }
    // If there is no message returned, inform them the error cannot be reached
    // and log what was returned
    else {
      console.log(data);

      if (!$(this.messageSelector).find('.noty_error').length) {
        $(this.messageSelector).noty({
          closeWith   : ['click'],
          dismissQueue: true,
          force       : true,
          maxVisible  : 5,
          text        : '<div class="msgClose"><i class="fa fa-times"></i></div>Error, Could Not Reach Server, Try Again, Later',
          timeout     : false,
          type        : 'error'
        });
      }
    }
  }

  show ( message : string, status : string, options ?: NotyOptions ) : void {
    const defaults : NotyOptions = {
      text: message,
      type: status
    };

    angular.extend(defaults, options ? options: {});

    $(this.messageSelector).noty(defaults);
  }
}
