export function DatePickerDirective () : ng.IDirective {
  const directive : ng.IDirective = {
    controller: 'DatePickerCtrl',
    restrict  : 'A',
    scope     : {
      column: '=',
      row   : '='
    },
  };

  return directive;
};
