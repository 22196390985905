import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  template: require('./table-cell.link.component.html')
})
export class TableCellLinkComponent {
  @Input() record : any;
  @Input() title = 'Open In New Window';

  @Output() onClick  : EventEmitter<any> = new EventEmitter();

  handleClick () {
    this.onClick.emit(this.record);
  }
}
