import { NgModule } from '@angular/core';

import { sessionServiceProvider } from './session.provider.ajs';
import { SessionService }         from './session.service';

@NgModule({
  providers: [
    // AJS upgraded service
    sessionServiceProvider,

    SessionService
  ]
})
export class SessionsModule {}
