import { ApiServiceAjs }                       from '../api/api.service.ajs';
import { CodeTypeModel }                       from '../core/models/code-type';
import { TableService }                        from '../table/table.service';
import { TransactionCenterBookingModel }       from './models/transaction-center.booking';
import { TransactionCenterInspectionModel }    from './models/transaction-center.inspection';
import { TransactionCenterOrderModel }         from './models/transaction-center.order';
import { TransactionCenterInspectionsService } from './inspections/transaction-center.inspections.service';

import TransactionCenterHtml from './transaction-center.html';

export function TransactionCenterStates (
  $stateProvider : ng.ui.IStateProvider
) {
  $stateProvider
  .state({
      controller  : 'TransactionCenterCtrl',
      controllerAs: '$ctrl',
      name        : 'app.transaction-center',
      templateUrl : TransactionCenterHtml,
      url         : '/transaction-center',
      redirectTo  : 'app.transaction-center.jobs'
  })
  .state({
    component: 'worTcBookings',
    data     : {
      tableCacheKey: 'transactionCenterBookings'
    },
    name   : 'app.transaction-center.bookings',
    resolve: {
      bookings: ( $state$ : ng.ui.IState, dateParsing : any, tableApi : TableService, workshopAPI : ApiServiceAjs ) : ng.IPromise<Array<TransactionCenterBookingModel>> => {
        const cache = tableApi.getCacheForTable($state$.data.tableCacheKey);

        return workshopAPI.get('/transaction_center/bookings', [
          cache.filtering.startDate || dateParsing.weekRangeStart(),
          cache.filtering.endDate   || dateParsing.weekRangeEnd(),
          cache.filtering.value     || '*',
          cache.sorting.field       || 'event_date',
          cache.sorting.direction   || 'desc',
          cache.paging.pageSize     || 10,
          0
        ]);
      }
    },
    url: '/bookings'
  })
  .state({
    component: 'worTcInspections',
    data     : {
      tableCacheKey: 'transactionCenterInspections'
    },
    name   : 'app.transaction-center.inspections',
    resolve: {
      inspections: ( $state$ : ng.ui.IState, tableApi : TableService, transactionCenterInspectionsApi : TransactionCenterInspectionsService ) : ng.IPromise<Array<TransactionCenterInspectionModel>> => {
        const cache = tableApi.getCacheForTable($state$.data.tableCacheKey);

        return transactionCenterInspectionsApi.getInspections({
          status: cache.filtering.status || 'D',
          value : cache.filtering.value  || '*',
        },{
          field    : cache.sorting.field     || 'inspection_date',
          direction: cache.sorting.direction || 'desc',
          pageSize : cache.paging.pageSize   || 10,
        });
      },
      statuses: ( workshopAPI : ApiServiceAjs ) : ng.IPromise<Array<CodeTypeModel>> => workshopAPI.get('/system/inspection_status_type_filters')
    },
    url: '/inspections'
  })
  .state({
    component: 'worTcJobs',
    data     : {
      tableCacheKey: 'transactionCenterJobs'
    },
    name   : 'app.transaction-center.jobs',
    resolve: {
      constraints     : ( workshopAPI : ApiServiceAjs ) : ng.IPromise<Array<CodeTypeModel>> => workshopAPI.get('/system/job_filter_constraint_types'),
      jobStatusOptions: ( workshopAPI : ApiServiceAjs ) : ng.IPromise<any> => workshopAPI.get('/system/filter_job_status_types'),
      jobStatuses     : ( workshopAPI : ApiServiceAjs ) : ng.IPromise<Array<CodeTypeModel>> => workshopAPI.get('/system/job_status_types'),
      jobs            : ( $state$ : ng.ui.IState, tableApi : TableService, workshopAPI : ApiServiceAjs ) : ng.IPromise<any> => {
        const cache = tableApi.getCacheForTable($state$.data.tableCacheKey);

        return workshopAPI.stream('/job_center', [
          cache.filtering.value       || '*',
          0,
          cache.paging.pageSize       || 10,
          cache.filtering.postDate    || '*',
          cache.filtering.constrainBy || '*',
          cache.filtering.jobStatus   || '*',
          cache.sorting.field         || 'invoice.post_date',
          cache.sorting.direction     || 'desc'
        ]);
      }
    },
    url: '/jobs'
  })
  .state({
    component: 'worTcOrders',
    data     : {
      tableCacheKey: 'transactionCenterOrders'
    },
    name   : 'app.transaction-center.orders',
    resolve: {
      orders: ( $state$ : ng.ui.IState, tableApi : TableService, workshopAPI : ApiServiceAjs ) : ng.IPromise<Array<TransactionCenterOrderModel>> => {
        const cache = tableApi.getCacheForTable($state$.data.tableCacheKey);

        return workshopAPI.get('/transaction_center/purchase_orders', [
          cache.filtering.startDate       || '*',
          cache.filtering.endDate         || '*',
          cache.filtering.outstandingOnly || false,
          cache.filtering.status          || '*',
          cache.filtering.value           || '*',
          cache.sorting.field             || 'order_date',
          cache.sorting.direction         ||'desc',
          cache.paging.pageSize           || 10,
          0
        ]);
      },
      statuses: ( workshopAPI : ApiServiceAjs ) : ng.IPromise<Array<CodeTypeModel>> => workshopAPI.get('/system/purchase_order_status_types')
    },
    url: '/orders'
  })
  .state({
    component: 'worTcVendorInvoices',
    data     : {
      tableCacheKey: 'transactionCenterVendorInvoices'
    },
    name   : 'app.transaction-center.vendor-invoices',
    resolve: {
      invoices: ( $state$ : ng.ui.IState, tableApi : TableService, workshopAPI : ApiServiceAjs ) : ng.IPromise<any> => {
        const cache = tableApi.getCacheForTable($state$.data.tableCacheKey);

        return workshopAPI.get('/transaction_center/supplier_invoices', [
          cache.filtering.startDate || '*',
          cache.filtering.endDate   || '*',
          cache.filtering.status    || 'I',
          cache.filtering.value     || '*',
          cache.sorting.field       ||'post_date',
          cache.sorting.direction   ||'desc',
          cache.paging.pageSize     || 10,
          0
        ]);
      },
      statuses: ( workshopAPI : ApiServiceAjs ) : ng.IPromise<Array<CodeTypeModel>> => workshopAPI.get('/system/vendor_invoice_type_filters')
    },
    url: '/vendor-invoices'
  });
};