export function SyncXeroCtrl (
  $uibModalInstance,
  $scope,
  messages,
  sessionObjAPI,
  workshopAPI
){
  $scope.email = sessionObjAPI.company().admin_email;

  $scope.upload = function () {
    workshopAPI.post('xero/upload', { email: $scope.email })
    .then(() => {
      $('.modal-content').addClass('flip');

      $scope.download();
    })
    .catch(err => {
      messages.error(err);
    });
  };

  $scope.download = function () {
    let conflicts;

    $scope.syncing = true;

    workshopAPI.get('/xero/contact_download')
    .then(data => {
      conflicts = data;

      return sessionObjAPI.refresh();
    })
    .then(() => {
      $scope.form.$setPristine();

      $uibModalInstance.close(conflicts);
    })
    .catch(err => {
      messages.error(err);

      $uibModalInstance.dismiss();
    })
    .finally(() => {
      $scope.syncing = false;
    });
  };

  $scope.close = function () {
    $uibModalInstance.dismiss();
  };
}