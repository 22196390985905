import { Inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hours'
})
export class HoursPipe implements PipeTransform {

  constructor(
    @Inject('$translate') public $translate : ng.translate.ITranslateService,
  ) {}

  transform( value : number ) : string | number {
    if (value > 1) {
      return value + this.$translate.instant('GENERAL_SPACE.FIELD.HRS');
    }
    else if (value <= 1 && value > 0) {
      return value + this.$translate.instant('BOOKING_SPACE.HR');
    }
    else {
      return 0;
    }
  }
}
