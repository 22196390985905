interface StripeStatusScope extends ng.IScope {
  getClass: () => Array<string>;
  status  : string;
}

export function StripeStatusCtrl (
  $scope: StripeStatusScope
) {

  $scope.getClass = function () : Array<string> {
    const classes = [];

    switch ($scope.status) {
      case 'A':
        classes.push('bg-success');
        break;
      case 'C':
        classes.push('bg-danger');
        break;
      case 'P':
      default:
        classes.push('bg-warning');
        break;
    };

    return classes;
  };
}