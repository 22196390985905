export function VendorBranchPaymentService (
  $q,
  $rootScope,
  dateParsing,
  Vendor,
  workshopAPI
) {
  const VendorBranchPayment = function (payment, vendor) {
    this.vendor  = new Vendor(vendor);
    this.payment = payment ? this.setupPayment(payment) : this.getDefaultPayment();

    this.crud = {
      save: () => this.payment.id ? 'patch': 'post'
    };

    this.routes = {
      save: () => this.payment.id ? '/branch_vendor_payment': '/branch_vendor_payments'
    };
  };

  VendorBranchPayment.prototype.addCurrencyFuncs = function (item) {
    item.getBalance = () => {
      const amount  = new BigDecimal( item.amount ? item.amount.toString(): '0' ).setScale(2, BigDecimal.ROUND_HALF_UP);
      const balance = new BigDecimal( item.vendor_invoice_balance_due ? item.vendor_invoice_balance_due.toString(): '0' ).setScale(2, BigDecimal.ROUND_HALF_UP);

      return balance.isLessThanOrEqualTo(BigDecimal.ZERO) ? 0 : balance.subtract(amount).toString();
    };

    return item;
  };

  VendorBranchPayment.prototype.delete = function() {
    const self = this;

    return workshopAPI.delete('/branch_vendor_payment', self.payment.id);
  };

  VendorBranchPayment.prototype.deleteSpecifiedPaymentItems = function (items) {
    const self    = this;
    const payment = {
      id                                    : self.payment.id,
      amount                                : self.payment.amount,
      branch_vendor_payment_items_attributes: items,
    };

    items = items || [];

    return items.length && self.payment.id ? workshopAPI.patch('/branch_vendor_payment', payment, 'branch_vendor_payment'): $q.when();
  };

  VendorBranchPayment.prototype.getAppliedAmount = function () {
    const self  = this;
    let   total = BigDecimal.ZERO;

    angular.forEach(self.payment.branch_vendor_payment_items_attributes, row => {
      const amount = new BigDecimal( row.amount ? row.amount.toString(): '0' ).setScale(2, BigDecimal.ROUND_HALF_UP);

      total = total.add(amount);
    });

    return total ? total.toString(): '0';
  };

  VendorBranchPayment.prototype.getDefaultPayment = function () {
    const self = this;

    return angular.copy({
      amount                                : 0.00,
      applied_amount                        : 0.00,
      branch_vendor_payment_items_attributes: [ self.getDefaultPaymentItem() ],
      branch_vender_payment_number          : null,
      company_id                            : $rootScope.Company.getId(),
      id                                    : '',
      note                                  : '',
      post_date                             : dateParsing.today(),
      reference                             : '',
      status                                : 'O',
      vendor_id                             : self.vendor.vendor.id
    });
  };

  VendorBranchPayment.prototype.getDefaultPaymentItem = function () {
    const self = this;
    const item = angular.copy({
      amount                    : 0.00,
      id                        : '',
      linked_company_id         : '',
      linked_vendor_id          : '',
      store_name                : '',
      untouched                 : true,
      vendor_invoice_balance_due: 0.00,
      vendor_invoice_id         : '',
      vendor_invoice_number     : '',
      vendor_invoice_post_date  : '',
      vendor_invoice_total      : 0.00,
      vendor_name               : ''
    });

    self.addCurrencyFuncs(item);

    return item;
  };

  VendorBranchPayment.prototype.getPaymentItemFromInvoice = function (invoice) {
    const self = this;
    const item = angular.copy({
      amount                    : invoice.balance_due,
      id                        : '',
      linked_company_id         : invoice.company_id,
      linked_vendor_id          : invoice.vendor_id,
      store_name                : '',
      untouched                 : false,
      vendor_invoice_balance_due: invoice.balance_due,
      vendor_invoice_id         : invoice.id,
      vendor_invoice_number     : invoice.vendor_invoice_number,
      vendor_invoice_post_date  : invoice.post_date,
      vendor_invoice_total      : invoice.total,
      vendor_name               : invoice.vendor_name
    });

    self.addCurrencyFuncs(item);

    return item;
  };

  VendorBranchPayment.prototype.process = function() {
    const self = this;

    return workshopAPI.get('/branch_vendor_payments/process_payment', self.payment.id);
  };

  VendorBranchPayment.prototype.save = function (itemsToDelete) {
    const self = this;

    return self.deleteSpecifiedPaymentItems(itemsToDelete)
      .then(() => workshopAPI[self.crud.save()](
        self.routes.save(),
        self.payment,
        'branch_vendor_payment',
        'branch_vendor_payment_items_attributes'
      ));
  };

  VendorBranchPayment.prototype.setupPayment = function(payment) {
    const self = this;

    angular.forEach(payment.branch_vendor_payment_items_attributes, item => self.addCurrencyFuncs(item));

    return payment;
  };

  VendorBranchPayment.prototype.updatePaymentItemFromInvoice = function(item, invoice) {
    item.amount                     = invoice.balance_due;
    item.linked_company_id          = invoice.company_id;
    item.linked_vendor_id           = invoice.vendor_id;
    item.store_name                 = invoice.store_name;
    item.untouched                  = false;
    item.vendor_invoice_balance_due = invoice.balance_due;
    item.vendor_invoice_id          = invoice.id;
    item.vendor_invoice_number      = invoice.vendor_invoice_number;
    item.vendor_invoice_post_date   = invoice.post_date;
    item.vendor_invoice_total       = invoice.total;
    item.vendor_name                = invoice.store_name;

    return item;
  };

  VendorBranchPayment.prototype.void = function () {
    const self = this;

    return workshopAPI.get('/branch_vendor_payments/void_payment', self.payment.id);
  };

  return VendorBranchPayment;
}