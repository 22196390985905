export function SyncQuickbooksCtrl (
  $uibModalInstance,
  $scope,
  $translate,
  messages,
  sessionObjAPI,
  STATUSCODES,
  workshopAPI
) {
  $scope.email = sessionObjAPI.company().admin_email;

  $scope.sync = function () {
    $scope.syncing = true;

    workshopAPI.post('quickbooks_online/sync', { email: $scope.email }).
    then(data => {
      $uibModalInstance.close(data);
    })
    .catch(err => {
      if ($scope.errorStatusCode === STATUSCODES.timeout) {
        messages.show($translate.instant('JS_SPACE.INTEGRATIONS.SYNC_STARTED_SUCCESSFULLY', {
          name: $translate.instant('COMPANY_SPACE.INTEGRATIONS.QUICKBOOKS.QUICKBOOKS_ONLINE')
        }), 'success');

        $uibModalInstance.dismiss();
      }
      else {
        messages.error(err);
      }
    })
    .finally(() => {
      $scope.syncing = false;
    });
  };
}