import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid';

import { SignUpComponent } from './sign-up.component';

export const SignUpStates : Array<NgHybridStateDeclaration> = [
  {
    component: SignUpComponent,
    name     : 'ajs.app.public.sign-up',
    url      : '/sign-up?token'
  }
];
