import { Inject, Injectable } from '@angular/core';

import { API } from '../api/api.constant.ajs';

import { ApiService }               from '../api/api.service';
import { CompanyIntegrationsModel } from '../companies/models/company.integrations';
import { ConfirmService }           from '../messages/confirm.service.ajs';
import { NetoWarehouseModel }       from './models/neto.warehouse';
import { SessionServiceAjs }        from '../sessions/session.service.ajs';

@Injectable({
  providedIn: 'root'
})
export class NetoService {
  constructor (
    @Inject('$translate')
    public  $translate     : ng.translate.ITranslateService,
    private confirmService : ConfirmService,
    private sessionService : SessionServiceAjs,
    private apiService     : ApiService
  ) {}

  activate () : ng.IPromise<boolean> {
    return this.confirmService.generic(this.$translate.instant('JS_SPACE.INTEGRATIONS.ACTIVATE', {
      name: 'Neto'
    }))
    .then(() => this.apiService.get('company_integration/add_neto'));
  }

  deactivate () : ng.IPromise<boolean> {
    return this.confirmService.generic(this.$translate.instant('JS_SPACE.INTEGRATIONS.DEACTIVATE', {
      name: 'Neto'
    }))
    .then(() => this.apiService.get('/company_integration/remove_neto'));
  }

  getAuthorizeUrl () : string {
    return `${ API.url }/neto/request_neto_connection`;
  }

  getSettings () : ng.IPromise<CompanyIntegrationsModel> {
    return this.apiService.get('/company_integrations');
  }

  getWarehouses () : ng.IPromise<Array<NetoWarehouseModel>> {
    return this.apiService.get('/neto/get_warehouse_list');
  }

  hasStoreDomain () : boolean {
    return !!( this.sessionService.company().neto_settings.neto_store_domain );
  }

  saveSettings ( settings : CompanyIntegrationsModel ) : ng.IPromise<CompanyIntegrationsModel> {
    return this.apiService.patch('/company_integrations', settings, 'company_integration');
  }
}