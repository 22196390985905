import VendorBranchInvoicesHtml from './vendor-invoices.branch.html';

export function BranchVendorInvoicesDirective () {
  return {
    controller: 'BranchVendorInvoicesLiteCtrl',
    restrict: 'E',
    templateUrl: VendorBranchInvoicesHtml,
    scope: {
      vendor: '=',
      invoices: '='
    }
  };
}
