import { blockUI } from 'angular';

import { CompanyMessagesModel } from '../companies/models/company.messages';
import { CustomerModel }        from '../customers/models/customer';
import { InvoiceModel }         from '../invoices/models/invoice';
import { WorkshopPaySmsModel }  from './models/workshoppay.sms';
import { WorkshopPayService }   from './workshoppay.service.ajs';

interface RequestSmsWorkshopPayPaymentScope extends ng.IScope {
  block          : blockUI.BlockUIService;
  companyMessages: CompanyMessagesModel;
  customer       : CustomerModel;
  invoice        : InvoiceModel;
  send           : () => void;
  sms            : WorkshopPaySmsModel;
}

export function RequestSmsWorkshopPayPaymentCtrl (
  $scope           : RequestSmsWorkshopPayPaymentScope,
  $translate       : ng.translate.ITranslateService,
  $uibModalInstance: any,
  blockUI          : blockUI.BlockUIService,
  companyMessages  : CompanyMessagesModel,
  customer         : CustomerModel,
  invoice          : InvoiceModel,
  messages         : any,
  workshopPayApi   : WorkshopPayService
) {
  $scope.block           = blockUI.instances.get('workshopPayPaymentRequestBlock');
  $scope.companyMessages = companyMessages;
  $scope.customer        = customer;
  $scope.invoice         = invoice;

  $scope.sms = {
    body        : companyMessages.default_sms_payment_request,
    invoice_id  : $scope.invoice.id,
    phone_number: customer.mobile
  };

  $scope.send = function () {
    $scope.block.start($translate.instant('GENERAL_SPACE.MESSAGE.SENDING'));

    workshopPayApi.sendSmsPaymentRequest($scope.sms)
    .then(() => {
      $uibModalInstance.close($scope.invoice);
    })
    .catch(err => {
      messages.error(err);
    })
    .finally(() => {
      $scope.block.stop();
    });
  };
};
