import { NgModule } from '@angular/core';

import { SharedModule }             from '../shared/shared.module';
import { TableModule }              from '../table/table.module';
import { VehicleCardComponent }     from './vehicle.card.component';
import { VehicleComponent }         from './vehicle.component';
import { VehicleDueDatesComponent } from './vehicle.due-dates.component';
import { VehiclesComponent }        from './vehicles.component';

@NgModule({
  declarations: [
    VehicleComponent,
    VehicleDueDatesComponent,
    VehiclesComponent,
    VehicleCardComponent
  ],
  entryComponents: [
    VehicleComponent,
    VehicleCardComponent
  ],
  exports: [
  	VehicleComponent,
    VehicleDueDatesComponent,
    VehiclesComponent,
    VehicleCardComponent
  ],
  imports: [
    SharedModule,
    TableModule
  ]
})
export class VehiclesModule {}
