import 'angular';

import 'angular-cookies';

import '../core/core.module.ajs';

import { SessionRegexCodeService } from './session.regex-codes.service';
import { SessionServiceAjs }       from './session.service.ajs';

angular.module('app-lite.sessions', [
  'ngCookies',

  'app-lite.core'
]).factory('regexCodes', SessionRegexCodeService)
  .service('sessionObjAPI', SessionServiceAjs);
