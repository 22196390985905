export function SyncMyobCtrl (
  $uibModalInstance,
  $scope,
  $translate,
  messages,
  STATUSCODES,
  workshopAPI
) {
  $scope.sync = function () {
    $scope.syncing = true;

    workshopAPI.get('myob/sync')
    .then(data => {
      $uibModalInstance.close(data);
    })
    .catch(err => {
      if ($scope.errorStatusCode === STATUSCODES.timeout) {
        messages.show($translate.instant('JS_SPACE.INTEGRATIONS.SYNC_STARTED_SUCCESSFULLY', { name: 'MYOB' }), 'success');

        $uibModalInstance.dismiss();
      }
      else {
        messages.error(err);
      }
    })
    .finally(() => {
      $scope.syncing = false;
    });
  };
};
