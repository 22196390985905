export function SubscribeConfirmationLiteCtrl ($scope, message, messages, options, workshopAPI, quote) {
  $scope.message = message;
  $scope.options = options;
  $scope.quote   = quote;

  $scope.confirm = function () {
    workshopAPI.get(options.routes.subscribe).then(() => {
      message.show('Success', 'success');
      $scope.$close();
    }).catch(err => {
      messages.error(err);
    });
  };
}