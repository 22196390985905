export function GridReorderService ($rootScope) {
  var ORDERSCALE = 1000000;

  return {
    /**
     * Once the index gets down to six decimal place, we do a
     * hard move on the item. This resets every index to be in
     * intervals of 1.000,000
     */
    resetIndexes: function (array) {
      angular.forEach(array, function (item, idx) {
        item.ordering = idx * ORDERSCALE;
      });
    },
    moveItem: function (array, direction) {
      var _this = this;

      // index of item being moved
      var itemIndex = 0;

      // the saved index of the last item
      var lastItemOrder = array[array.length - 1].ordering;

      // The item to move
      var item = {};

      /**
       * calculates the average of either the two items above
       * the item to move or the two items below it depending
       * on which direction it is being moved
       * @return {number} the average
       */
      var average = function () {
        var a = BigDecimal.ZERO;
        var b = BigDecimal.ZERO;
        var two = new BigDecimal('2');
        switch (direction) {
          case 'up':
            a = new BigDecimal(array[itemIndex - 1].ordering.toString());
            b = new BigDecimal(array[itemIndex - 2].ordering.toString());
            return a.add(b).divide(two).toString();
          case 'down':
            a = new BigDecimal(array[itemIndex + 1].ordering.toString());
            b = new BigDecimal(array[itemIndex + 2].ordering.toString());
            return a.add(b).divide(two).toString();
        }
      };

      // Determines whether or not the item can be moved.
      var immovable = function () {

        // Don't allow bom items to be moved
        return item.bom_parent_id ||

          // if it is the first item and attempting to be moved up
          (itemIndex <= 0 && direction === 'up') ||

          // or if it is the last item and attempting to be moved down
          (direction === 'down' && (itemIndex === array.length - 1));
      };

      /**
       * updates the new itemIndex. if an index is passed in, then
       * it is just set to that index. If not, it is incremented or
       * decremented based on the direction.
       * @param {integer} idx optional: the new itemIndex once it's been
       * moved
       */
      var setNewIndex = function (idx) {
        if (!angular.isUndefined(idx)) {
          itemIndex = idx;
        }
        else if (direction === 'down') {
          itemIndex++;
        }
        else {
          itemIndex--;
        }
      };

      // Called any time the array sort order needs to be updated.
      var sort = function () {
        array.sort(function (a, b) {
          var first = new BigDecimal (a.ordering.toString());
          var second = new BigDecimal (b.ordering.toString());

          // If the first order is less, put it before
          if (first.isLessThan(second)) {
            return -1;
          }

          // if the first is greater, put it after
          else if (first.isGreaterThan(second)) {
            return 1;
          }

          // If it is the same, keep it where it's at. Used for bom items.
          else {
            return 0;
          }
        });
      };

      /**
       * certain items needs to be skipped when moving around other items.
       * Bom items, for example.
       */
      var shouldSkip = function () {
        if (direction === 'down' && item.is_bom_parent) {
          var children = getChildren(itemIndex);
          var nextIndex = children[children.length - 1];

          return array[nextIndex + 1].is_bom_parent;

        }
        else if (direction === 'down') {
          return array[itemIndex + 1].is_bom_parent;
        }
        else {
          return array[itemIndex - 1].bom_parent_id;
        }
      };

      var getParentIndex = function (lastChild) {
        var parentIndex = 0;
        for (var i = lastChild; i >= 0; i--) {
          if (array[i].is_bom_parent) {
            parentIndex = i;
            break;
          }
        }

        return parentIndex;
      };

      /**
       * puts all the indexes of the children into an array
       * based on the index of the parent
       * @param  {integer} parentIndex the index of the parent
       * @return {array}   an array of all the children indexes
       */
      var getChildren = function (parentIndex) {
        var children = [];
        for (var i = parentIndex + 1; i < array.length; i++) {
          if (array[parentIndex].product_id === array[i].bom_parent_id) {
            children.push(i)
          }
          else {
            break;
          }
        }

        return children;
      };

      var setChildren = function (children, order) {
        for (var i = 0; i < children.length; i++) {
          array[children[i]].ordering = order;
        }
      };

      var moveGroup = function () {
        var newAverage;
        var children = getChildren(itemIndex);
        var toSkipChildren, toSkipParentIndex, toSkipParentOrder;

        if (!children.length) {
          move();
          return;
        }

        // If the last item in the group is the last item in the list, dont do anything
        if (direction === 'down' && array[ children[children.length - 1] ].ordering === lastItemOrder) {
          return;
        }

        // If we are moving the group down and there is a group below it
        else if (shouldSkip() && direction === 'down') {

          // Get the children of the group below it
          toSkipChildren = getChildren(children[children.length -1] + 1);

          // Save the index of the parent in the group below for reference
          toSkipParentIndex = getParentIndex(toSkipChildren[toSkipChildren.length - 1]);

          _this.resetIndexes(array);

          // Set the parent below's order to be the current one.
          array[toSkipParentIndex].ordering = item.ordering;

          for (var i = 0; i < toSkipChildren.length; i++) {
            array[toSkipChildren[i]].ordering = (array[toSkipParentIndex].ordering + (ORDERSCALE * (i + 1)));
          }

          item.ordering = array[toSkipChildren[toSkipChildren.length - 1]].ordering + ORDERSCALE;

          for (var i = 0; i < children.length; i++) {
            array[children[i]].ordering = (item.ordering + (ORDERSCALE * (i + 1)));
          }

          setNewIndex(item.ordering/ORDERSCALE);

        }

        // If we are moving a group up and the item above is a group as well
        else if (shouldSkip()) {
          toSkipParentIndex = getParentIndex(itemIndex - 1);
          toSkipChildren = getChildren(toSkipParentIndex);

          _this.resetIndexes(array);

          item.ordering = array[toSkipParentIndex].ordering;

          for (var i = 0; i < children.length; i++) {
            array[children[i]].ordering = (item.ordering + (ORDERSCALE * (i + 1)));
          }

          array[toSkipParentIndex].ordering = array[children[children.length - 1]].ordering + ORDERSCALE;

          for (var i = 0; i < toSkipChildren.length; i++) {
            array[toSkipChildren[i]].ordering = (array[toSkipParentIndex].ordering + (ORDERSCALE * (i + 1)));
          }

          setNewIndex(item.ordering/ORDERSCALE);

        }

        // If the item below the group is the last item in the list, switch them
        else if ( direction === 'down' && array[ children[children.length - 1] + 1 ].ordering === lastItemOrder ) {
          array[array.length-1].ordering = array[itemIndex].ordering;

          item.ordering = lastItemOrder;

          setChildren(children, lastItemOrder);

          setNewIndex( (array.length - children.length - 1) );
        }

        // Otherwise, were moving the group down in the middle of the grid, so get the average
        else if (direction === 'down') {
          var parentIndex = itemIndex;
          setNewIndex( (children[ children.length - 1]) );

          newAverage = average();

          item.ordering = newAverage;

          setChildren(children, newAverage);

          setNewIndex( (parentIndex + 1) );

        }

        /**
         * Everything from here on out assumes, the items are being moved up
         */

        // If the group is the first thing in the grid, do nothing
        else if (item.ordering === array[0].ordering) {
          return;
        }

        // if the group is meant to be moved to the top, just switch it
        else if (itemIndex < 2) {
          array[0].ordering = item.ordering;
          item.ordering = 0;

          setChildren(children, 0);

          setNewIndex(0);

        }

        // otherwise we are just moving the group up in the middle of the grid, so calculate the average
        else {
          newAverage = average();

          item.ordering = newAverage;

          setChildren(children, newAverage);

          setNewIndex();
        }


      };

      var skipTo = function () {
        var children = [];
        var parentIndex;

        if (direction === 'down') {
          children = getChildren(itemIndex + 1);

          if (!children.length) {
            move();
            return;
          }

          var toSkipParentIndex = itemIndex + 1;

          array[toSkipParentIndex].ordering = item.ordering;

          for (var i = 0; i < children.length; i++) {
            array[children[i]].ordering = array[toSkipParentIndex].ordering + (ORDERSCALE * (i + 1));
          }

          item.ordering = array[children[children.length - 1]].ordering + ORDERSCALE;

          setNewIndex(item.ordering/ORDERSCALE);

        }

        /**
         * There is no way the direction could be down and it would be
         * at this point so from here on out, we assume the direction is up.
         * Also, if it is up then there is no way it would be at this point
         * unless the item above it is a bom child, so we can assume that too.
         */
        else {
          parentIndex = getParentIndex(itemIndex - 1);
          children = getChildren(parentIndex);

          if (!children.length) {
            move();
            return;
          }

          // if the group above is the first item just switch them
          if (!parentIndex) {
            array[0].ordering = item.ordering;
            setChildren(children, item.ordering);
            item.ordering = 0;
            setNewIndex(0);
          }
          else {
            itemIndex = parentIndex + 1;
            item.ordering = average();
            setNewIndex(parentIndex);
          }

        }
      };

      var move = function () {
        // if the item is set to be moved to the top, just switch it
        if (itemIndex < 2 && direction !== 'down') {

          // Essentially switch the first and selected item's indexes
          array[0].ordering = item.ordering;
          item.ordering = 0;
        }

        // if the first item is being moved down one, just switch it.
        else if (!itemIndex) {
          item.ordering = array[1].ordering;
          array[1].ordering = 0;
        }

        // If the item is set to be moved to the bottom, just switch it
        else if (itemIndex === array.length - 2 && direction !== 'up') {

          // Essentially switch the last item and the selected item's indexes
          array[array.length - 1].ordering = item.ordering;
          item.ordering = lastItemOrder;
        }

        // If the item is the last item and moving up, just switch them
        else if (itemIndex === (array.length - 1) && direction === 'up') {
          item.ordering = array[array.length - 2].ordering;
          array[array.length - 2].ordering = lastItemOrder;
        }

        // If none of these, get the average and set the item's index to the average
        else {
          item.ordering = average();

          // If we have reached our limit on decimal places, reset the indexes
          if (new BigDecimal(average().toString()).scale() >=6) {
            _this.resetIndexes();
          }
        }

        setNewIndex();
      };

      sort();

      // update the lastItemOrder variable once we sort.
      lastItemOrder = array[array.length - 1].ordering;

      // Get the item that is meant to be moved
      item = array.filter(function (item, idx) {

        // save the index for reference
        if (item.selected && !item.bom_parent_id) {
          setNewIndex(idx);
        }
        return item.selected;
      })[0];

      // If this item cant be moved, return.
      if (immovable() || !item) {
        return itemIndex;
      }

      // If we are moving a bom parent, move its children too
      if (item.is_bom_parent) {
        moveGroup();
      }

      // If we should skip items, figure out where to skip to
      else if (shouldSkip()) {
        skipTo();
      }

      // if it is just a normal item, move it normally
      else {
        move();
      }

      sort();

      _this.resetIndexes(array);

      $rootScope.$broadcast('refocusGrid', itemIndex);

      return itemIndex;

    }
  };
}