import { Component, Input } from '@angular/core';

import { ErrorHandlingService }     from '../core/errors/error-handling.service';
import { InvoiceModel }             from './models/invoice';
import { InvoiceService }           from './invoice.service';
import { TableCellLinkComponent }   from '../table/table-cell.link.component';
import { TableCellTruthyComponent } from '../table/table-cell.truthy.component';
import { TableCountModel }          from '../table/models/table-count';
import { TableModel }               from '../table/models/table';
import { TableQueryModel }          from '../table/models/table-query';

@Component({
  selector   : 'wor-invoices',
  template: require('./invoices.component.html')
})
export class InvoicesComponent {
  @Input() invoices: Array<InvoiceModel>;

  tableSettings : TableModel = {
    columns: [
      {
        field : 'invoice_number',
        header: {
          title: 'Invoice #'
        }
      },
      {
        field : 'post_date',
        header: {
          title: 'Post Date'
        },
        pipe: 'globalDate'
      },
      {
        classes : [ 'text-left' ],
        bindings: {
          inputs: {
            field: 'total_labour_hours_worked'
          }
        },
        component: TableCellTruthyComponent,
        header   : {
          classes: [ 'text-left' ],
          title  : 'Is Service'
        }
      },
      {
        field : 'total',
        header: {
          title: 'Invoice Total'
        },
        pipe: 'globalCurrency'
      },
      {
        classes : [ 'text-right' ],
        bindings: {
          inputs: {
            title: 'View Invoice'
          },
          outputs: {
            onClick: ( invoice : InvoiceModel ) => this.viewInvoice(invoice)
          }
        },
        component: TableCellLinkComponent
      }
    ],
    pagination: this.invoiceService.defaultPagination,
    rows      : {
      select: {
        enabled : true,
        property: 'active'
      }
    },
    title: 'Customer History'
  };

  constructor(
    private errorHandlingService: ErrorHandlingService,
    private invoiceService      : InvoiceService
  ) {}

  getInvoiceCount () : ng.IPromise<TableCountModel> {
    return this.invoiceService.getInvoiceCount();
  }

  getInvoices ( params : TableQueryModel ) : ng.IPromise<Array<InvoiceModel>> {
    return this.invoiceService.getInvoices(params.offset, params.pageSize);
  }

  searchInvoices ( query : string ) : any {
    this.invoiceService
      .searchInvoices(query)
      .then(( invoices : Array<InvoiceModel> ) => this.invoices = invoices)
      .catch(err => this.errorHandlingService.show(err));
  }

  viewHistory () : void {
    window.open(this.invoiceService.getHistoryLink(), '_blank');
  }

  viewInvoice ( invoice : InvoiceModel ) : void {
    window.open(this.invoiceService.getInvoiceLink(invoice), '_blank');
  }
};

