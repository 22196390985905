import { Component, EventEmitter, Inject, Input, Output}    from '@angular/core';

@Component({
  template: require('./partstech.labor-estimate.actions.component.html')
})
export class PartstechLaborEstimateActionsComponent {
  @Input() record: any;

  @Output() onSelectClick: EventEmitter<any> = new EventEmitter();

  constructor (
    @Inject('$translate')
    public $translate: ng.translate.ITranslateService
  ) {}
}