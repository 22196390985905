import 'angular';

import '../api/api.module';
import '../core/core.module.ajs';
import '../grids/grids.module';
import '../messages/messages.module.ajs';

import { ProductBomItemsLiteCtrl }           from './product.bom-items';
import { ProductBranchStockLiteCtrl }        from './product.branch-stock';
import { ProductGroupQuickAddLiteCtrl }      from './product-group.quick-add';
import { ProductLiteCtrl }                   from './product';
import { ProductLiteService }                from './product.service';
import { ProductsAdvancedSearchLiteCtrl }    from './products.advanced-search';
import { ProductSearchCellCtrl }             from './product-search.cell';
import { ProductSearchCellDirective }        from './product-search.cell.directive';
import { ProductSearchLiteService }          from './product.search.service';
import { ProductsLiteCtrl }                  from './products';

angular.module('app-lite.products', [
  'app-lite.api',
  'app-lite.core',
  'app-lite.grids',
  'app-lite.messages'
]).controller('ProductBomItemsLiteCtrl', ProductBomItemsLiteCtrl)
  .controller('ProductBranchStockLiteCtrl', ProductBranchStockLiteCtrl)
  .controller('ProductGroupQuickAddLiteCtrl', ProductGroupQuickAddLiteCtrl)
  .controller('ProductLiteCtrl', ProductLiteCtrl)
  .controller('ProductSearchCellCtrl', ProductSearchCellCtrl)
  .controller('ProductsAdvancedSearchLiteCtrl', ProductsAdvancedSearchLiteCtrl)
  .controller('ProductsLiteCtrl', ProductsLiteCtrl)
  .directive('productSearchCell', ProductSearchCellDirective)
  .factory('ProductLite', ProductLiteService)
  .factory('ProductSearchLite', ProductSearchLiteService);
