import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm }               from '@angular/forms';

@Component({
  selector   : 'wor-confirm-password-control',
  template: require('./confirm-password-control.component.html'),

  viewProviders: [
    {
      provide    : ControlContainer,
      useExisting: NgForm
    }
  ]
})
export class ConfirmPasswordControlComponent {
  @Input() _required : boolean;

  @Input() disabled  : boolean;
  @Input() label     : string;
  @Input() model     : any;
  @Input() name      : string;
  @Input() password  : string;

  /**
   * if the required flag is present in the html
   * but not set to any value then it's value will
   * be an empty string. If that's the case, set an
   * internal variable to true here. We need this
   * truthy check for the label class.
   */
  @Input() set required ( _required : string | boolean ) {
    this._required = _required === '' || !!_required;
  }

  @Output() modelChange: EventEmitter<any> = new EventEmitter();
}
