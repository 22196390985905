import { ApiModel } from './models/api.model';

export const API: ApiModel = {
  domains: {
    aus: 'https://my.workshopsoftware.com',
    can: 'https://my.workshopsoftware.com',
    gbr: 'https://my.workshopsoftware.com',
    usa: 'https://my.workshopsoftware.com'
  },
  subdomain: 'my',
  url      : ''
};
