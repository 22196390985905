interface CaptureModalScope extends ng.IScope {
  capture: string;
  close  : () => void;
  title  : string;
  value  : string;
}

export function CaptureModalCtrl (
  $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
  $scope           : CaptureModalScope,
  capture          : string,
  title            : string
) {
  $scope.capture = capture;
  $scope.title   = title;

  $scope.close = function () : void {
    $uibModalInstance.close($scope.value);
  };
};