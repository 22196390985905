import * as angular from 'angular';

import { InspectionGroupCtrl }             from './inspection.group.ajs';
import { InspectionGroupsCtrl }            from './inspection.groups.ajs';
import { InspectionTemplateCtrl }          from './inspection.template.ajs';
import { InspectionTemplateGroupService }  from './inspection.template.group.service.ajs';
import { InspectionTemplateItemCtrl }      from './inspection.template.item.ajs';
import { InspectionTemplateItemDirective } from './inspection.template.item.directive.ajs';
import { InspectionTemplateService }       from './inspection.template.service.ajs';
import { InspectionTemplatesCtrl }         from './inspection.templates.ajs';

angular.module('app-lite.inspections', [])
  .controller('InspectionGroupCtrl', InspectionGroupCtrl)
  .controller('InspectionGroupsCtrl', InspectionGroupsCtrl)
  .controller('InspectionTemplateCtrl', InspectionTemplateCtrl)
  .controller('InspectionTemplateItemCtrl', InspectionTemplateItemCtrl)
  .controller('InspectionTemplatesCtrl', InspectionTemplatesCtrl)
  .directive('inspectionTemplateItem', InspectionTemplateItemDirective)
  .factory('InspectionTemplate', InspectionTemplateService)
  .factory('InspectionTemplateGroup', InspectionTemplateGroupService);
