import { CompanyCountryValidationsModel } from '../../companies/models/company.country-validations';
import { SessionServiceAjs }              from '../../sessions/session.service.ajs';

interface PhoneValidatorScope extends ng.IScope {
  defaultValidation   : string;
  formValidityKey     : string;
  getAndSetValidations: () => void;
  isMobile            : boolean;
  mask                : string;
  regex               : RegExp;
  validations         : CompanyCountryValidationsModel;
};


export function PhoneValidatorDirective (
  sessionObjAPI: SessionServiceAjs
) : ng.IDirective {
  const directive : ng.IDirective = {
    require: 'ngModel',
    scope  : {
      isMobile   : '<?',
      validations: '=?'
    },
    link (
      scope  : PhoneValidatorScope,
      element: ng.IRootElementService,
      attrs  : ng.IAttributes,
      ctrl   : ng.INgModelController
    ) {
      scope.defaultValidation = '^\\d+$';
      scope.formValidityKey   = 'phone';

      scope.getAndSetValidations = function () {
        const countryValidations = sessionObjAPI.countryValidation();
        let regex;

        if (scope.validations) {
          scope.mask = scope.isMobile && scope.validations.mobile?.mask
            ? scope.validations.mobile.mask
            : scope.validations.phone?.mask;

          regex = scope.isMobile && scope.validations.mobile?.validation
            ? scope.validations.mobile.validation
            : scope.validations.phone?.validation;
        }
        else {
          scope.mask = scope.isMobile && countryValidations.mobile?.mask
            ? countryValidations.mobile.mask
            : countryValidations.phone.mask;
          regex = scope.isMobile && countryValidations.mobile?.validation
            ? countryValidations.mobile.validation
            : countryValidations.phone?.validation;
        }

        scope.regex = new RegExp(regex || scope.defaultValidation);
      };

      ctrl.$formatters.push(( value : string ) => {
        scope.getAndSetValidations();

        if (!value) {
          return '';
        }

        if (value.match(scope.regex)) {
          return scope.mask
            ? value.replace(scope.regex, scope.mask)
            : value;
        }

        return value;
      });

      ctrl.$parsers.push(( value : string, keepPristine = false ) => {
        scope.getAndSetValidations();

        if (!value) {
          return '';
        }

        if (value.match(scope.regex)) {
          if (scope.mask) {
            ctrl.$viewValue = value.replace(scope.regex, scope.mask);
            ctrl.$render();

            if (keepPristine) {
              ctrl.$setPristine();
            }

            /**
             * Not all phone number formats have the same amount
             * of groups to capture. For example, UK mobile numbers
             * have two groups whereas Australia and US have three.
             * So, we need to dynamically build our replace string
             * to use the proper number of capture groups. Because
             * .match returns the complete match as the first item
             * in the array, we loop through the length minus one.
             *
             * REFERENCE WS-4084
             */
            const capturedGroups = value.match(scope.regex);
            let replaceWith      = '';

            for ( let i = 0; i < capturedGroups.length - 1; i++ ) {
              replaceWith = `${ replaceWith}$${ i + 1}`;
            }

            return value.replace(scope.regex, replaceWith);
          }

          return value;
        }

        ctrl.$setValidity(scope.formValidityKey, false);

        return value;
      });

      ctrl.$validators[scope.formValidityKey] = function ( modelValue : string, viewValue : string ) {
        scope.getAndSetValidations();

        if (!modelValue || scope.regex.test(modelValue)) {
          return true;
        }

        return false;
      };

      scope.$watch('validations', ( newValidations : CompanyCountryValidationsModel, oldValidations : CompanyCountryValidationsModel ) => {
        if (newValidations !== oldValidations) {
          angular.forEach(ctrl.$parsers, ( parser : Function ) => parser(ctrl.$modelValue, true));

          ctrl.$validate();
        }
      }, true);
    }
  };

  return directive;
}
