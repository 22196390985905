import { CustomerModel } from './models/customer';

interface SendCustomerPortalInvitationScope extends ng.IScope {
  block       : ng.blockUI.BlockUIService;
  confirm     : ( sendAsSms : boolean ) => void;
  customer    : CustomerModel;
  customerApi : any;
};

export function SendCustomerPortalInvitationCtrl (
  $scope           : SendCustomerPortalInvitationScope,
  $translate       : ng.translate.ITranslateService,
  $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
  blockUI          : ng.blockUI.BlockUIService,
  customer         : CustomerModel,
  Customer         : any,
  messages         : any
) {
  $scope.block       = blockUI.instances.get('invitationBlock');
  $scope.customer    = customer;
  $scope.customerApi = new Customer($scope.customer);

  $scope.confirm = function ( sendAsSms : boolean ) {
    $scope.block.start();

    $scope.customerApi.confirmPortalInvitation(sendAsSms)
    .then(() => {
      messages.show($translate.instant('CUSTOMER_SPACE.CUSTOMER_PORTAL.INVITATION_SENT'), 'success');

      $uibModalInstance.close();
    })
    .catch(err => {
      messages.error(err);
    })
    .finally(() => {
      $scope.block.stop();
    });
  };
};
